import { PRODUCT_CATEGORIES_MAP } from 'constants/products';

import { ROUTES } from 'router/constants';

import { getId } from 'helpers/id';

export const NAVIGATION_LINKS = [
  {
    id: 'retake-quiz',
    name: 'Retake the skin care quiz',
  },
  {
    id: getId(),
    getLink: () => ROUTES.SEARCH_HISTORY,
    name: 'SEARCH HISTORY',
  },
  {
    id: getId(),
    getLink: ({ currentCategoryId }) =>
      ROUTES.FAVORITES.replace(':categoryId', PRODUCT_CATEGORIES_MAP[currentCategoryId]),
    name: 'My saves',
  },
  {
    id: getId(),
    getLink: () => ROUTES.ABOUT_US,
    name: 'about us',
  },
  {
    id: getId(),
    getLink: () => ROUTES.ARTICLES,
    name: 'Skin Care Articles',
  },
];
