import { useIds as useDefaultIds } from 'hooks/video-capture/use-ids';

const useIds = () => {
  const [
    gradientId,
    clipPathInnerCircleId,
    wrapperCircleGradientId,
    clipPathWrapperCircleId,
    clipPathWrapperCircleLineId,
  ] = useDefaultIds(5);

  return {
    gradientId,
    clipPathInnerCircleId,
    wrapperCircleGradientId,
    clipPathWrapperCircleId,
    clipPathWrapperCircleLineId,
  };
};
export default useIds;
