import { createContext } from 'react';

const Context = createContext({
  path: null,
  search: null,
});

export const { Provider: PreviousPathProvider, Consumer: PreviousPathConsumer } = Context;

export default Context;
