import React, { useState, useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { lockBody, unlockBody } from 'helpers/styles';

import { ModalProvider } from '../../context';

import Window from '../window';

const removeModal = (prevState, id) => {
  const entities = {
    ...prevState.entities,
  };

  delete entities[id];

  return {
    ids: prevState.ids.filter((modalId) => modalId !== id),
    entities,
  };
};

function useStore() {
  const [modals, setModals] = useState({
    ids: [],
    entities: {},
  });

  return {
    modals,

    callbacks: useMemo(
      () => ({
        addModal: ({ id, isVisible, children, onOverlayClick }) => {
          setModals((prevState) => ({
            ids: [...prevState.ids, id],
            entities: {
              ...prevState.entities,
              [id]: {
                children,
                isVisible,
                isUnmounted: false,
                onOverlayClick,
                isAnimatedToVisible: false,
                isAnimatedToInvisible: !isVisible,
              },
            },
          }));
        },

        changeVisibleModal: ({ id, isVisible, children, onOverlayClick }) => {
          setModals((prevState) => ({
            ...prevState,
            entities: {
              ...prevState.entities,
              [id]: {
                ...prevState.entities[id],
                children: isVisible ? children : prevState.entities[id].children,
                isVisible,
                onOverlayClick,
                isAnimatedToVisible: isVisible ? prevState.entities[id].isAnimatedToVisible : false,
                isAnimatedToInvisible: isVisible ? false : prevState.entities[id].isAnimatedToInvisible,
              },
            },
          }));
        },

        onAnimatedToInvisible: ({ id }) => {
          setModals((prevState) => {
            const { isUnmounted } = prevState.entities[id];

            if (isUnmounted) {
              return removeModal(prevState, id);
            }

            return {
              ...prevState,
              entities: {
                ...prevState.entities,
                [id]: {
                  ...prevState.entities[id],
                  isAnimatedToInvisible: true,
                },
              },
            };
          });
        },

        onAnimatedToVisible: ({ id }) => {
          setModals((prevState) => {
            return {
              ...prevState,
              entities: {
                ...prevState.entities,
                [id]: {
                  ...prevState.entities[id],
                  isAnimatedToVisible: true,
                },
              },
            };
          });
        },

        unmountModal: ({ id }) => {
          setModals((prevState) => {
            const { isAnimatedToInvisible } = prevState.entities[id];

            if (isAnimatedToInvisible) {
              return removeModal(prevState, id);
            }

            return {
              ...prevState,
              entities: {
                ...prevState.entities,
                [id]: {
                  ...prevState.entities[id],
                  isUnmounted: true,
                  isAnimatedToVisible: false,
                },
              },
            };
          });
        },
      }),
      [setModals],
    ),
  };
}

function Root({ children }) {
  const { modals, callbacks } = useStore();

  useEffect(() => {
    const isVisibleModal = modals.ids.some((id) => modals.entities[id].isVisible);

    if (isVisibleModal) {
      lockBody();

      return;
    }

    unlockBody();
  }, [modals]);

  return (
    <ModalProvider value={callbacks}>
      {children}

      {createPortal(
        <div>
          {modals.ids.map((id) => {
            const {
              children: modalChildren,
              isVisible,
              isUnmounted,
              onOverlayClick,
              isAnimatedToVisible,
              isAnimatedToInvisible,
            } = modals.entities[id];

            if (!isVisible && isAnimatedToInvisible) {
              return null;
            }

            return (
              <Window
                id={id}
                key={id}
                isVisible={isVisible}
                isUnmounted={isUnmounted}
                onOverlayClick={onOverlayClick}
                isAnimatedToVisible={isAnimatedToVisible}
                onAnimatedToVisible={callbacks.onAnimatedToVisible}
                onAnimatedToInvisible={callbacks.onAnimatedToInvisible}
              >
                {modalChildren}
              </Window>
            );
          })}
        </div>,
        document.body,
      )}
    </ModalProvider>
  );
}

Root.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default Root;
