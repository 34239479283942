import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/modal';

import Content from './components/content';

function SignUpModal({ isVisible, isVersionAnonymous, closeModal, updateProfile }) {
  return (
    <Modal isVisible={isVisible} onOverlayClick={closeModal}>
      {() => (
        <Content
          closeModal={closeModal}
          updateProfile={updateProfile}
          isVersionAnonymous={isVersionAnonymous}
        />
      )}
    </Modal>
  );
}

SignUpModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  isVersionAnonymous: PropTypes.bool.isRequired,
};

export default SignUpModal;
