import { put, select } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import { replace } from 'connected-react-router';
import querystring from 'query-string';

import { PRODUCT_CATEGORIES_PATHS } from 'router/constants';

import { actions as productsActions } from 'store/modules/products';
import { selectors as routerSelectors } from 'store/modules/router';

function* tryToOpenProductsFilter() {
  const { pathname, search } = yield select(routerSelectors.getLocation);

  const { isExact: isProductMatchesPage = false } =
    matchPath(pathname, {
      path: PRODUCT_CATEGORIES_PATHS,
      exact: true,
    }) ?? {};

  if (!isProductMatchesPage) {
    return;
  }

  const { refine } = querystring.parse(search);
  const shouldOpenFilter = refine === '1';

  if (!shouldOpenFilter) {
    return;
  }

  yield put(productsActions.openFilter());
  yield put(replace(pathname));
}

export default function* loggedInProductsFlow() {
  yield* tryToOpenProductsFilter();
}
