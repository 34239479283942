import { createAsyncThunk } from '@reduxjs/toolkit';
import { replace } from 'connected-react-router';

import { ROUTES } from 'router/constants';
import { QUIZ_END_ID, ROUTES_BY_QUIZ_TYPE } from 'constants/quiz';

import * as appSelectors from 'store/modules/app/selectors';
import * as userSelectors from 'store/modules/user/selectors';

import * as quizHelpers from './helpers';
import * as quizSelectors from './selectors';

const goToNextQuestion = createAsyncThunk(
  'quiz/goToNextQuestion',
  async ({ currentQuestionId, quizType } = {}, { dispatch, getState }) => {
    const state = getState();

    const quiz = quizSelectors.getQuizByType(state, quizType);

    const { id: nextQuestionId } = quizHelpers.getNextQuestion({
      quiz,
      quizType,
      appVersion: appSelectors.getVersion(state),
      isAuthenticated: userSelectors.getIsAuthenticated(state),
      currentQuestionId,
    });

    if (nextQuestionId === QUIZ_END_ID) {
      dispatch(replace(ROUTES.DASHBOARD));

      return null;
    }

    const nextRoute = ROUTES_BY_QUIZ_TYPE[quizType].replace(':questionId', nextQuestionId);

    dispatch(replace(nextRoute));

    return nextQuestionId;
  },
);

const goToPrevQuestion = createAsyncThunk(
  'quiz/goToPrevQuestion',
  async ({ currentQuestionId, quizType } = {}, { dispatch, getState }) => {
    const { confirmedQuestionIds } = quizSelectors.getQuizByType(getState(), quizType);

    const { id: prevQuestionId } = quizHelpers.getPrevQuestion({
      quizType,
      currentQuestionId,
      confirmedQuestionIds,
    });

    const nextRoute = ROUTES_BY_QUIZ_TYPE[quizType].replace(':questionId', prevQuestionId);

    dispatch(replace(nextRoute));

    return prevQuestionId;
  },
);

export { goToNextQuestion, goToPrevQuestion };
