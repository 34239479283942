import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import { compose } from '@reduxjs/toolkit';

import { reveaClient } from 'api/revea';

import withApolloProvider from 'hocs/with-apollo-provider';

import { ImageCaptureType } from 'store/modules/image-capture/types';

import { withModalWrapper } from 'components/modals/hocs/with-modal-wrapper';

import { VideoCapture } from 'containers/video-capture';

import styles from './styles.module.scss';

function RetakeVideoCapture({ closeModal, onVideoCaptureCompleted, dataSubmissionId }) {
  return (
    <MemoryRouter>
      <div className={styles.wrapper} onClick={(event) => event.stopPropagation()}>
        <VideoCapture
          basePath="/"
          imageCaptureType={ImageCaptureType.RetakeOnError}
          dataSubmissionId={dataSubmissionId}
          onVideoCaptureComplete={() => {
            onVideoCaptureCompleted();
            closeModal();
          }}
          videoCaptureContext={{
            source: 'revea',
            analyticsContext: 'retake-video-capture-after-error',
            dataSubmissionId,
          }}
        />
      </div>
    </MemoryRouter>
  );
}

export default compose(withApolloProvider({ client: reveaClient }), withModalWrapper)(RetakeVideoCapture);
