export const DISTANCE = {
  IN: {
    FROM: 0.86,
    TO: 1,
  },
  OUT: {
    FROM: 0.7,
    TO: 1.07,
  },
};
