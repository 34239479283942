import { createContext } from 'react';

const FloatingElementsContent = createContext({
  variables: [],
  addElement: () => {},
  removeElement: () => {},
});

export const { Provider: FloatingElementsProvider, Consumer: FloatingElementsConsumer } = FloatingElementsContent;

export default FloatingElementsContent;
