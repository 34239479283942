import { useRef, useEffect } from 'react';

const State = {
  Mounting: 'mounting',
  Mounted: 'mounted',
  Unmounted: 'unmounted',
};

export default function useLifecycle() {
  const ref = useRef({
    state: State.Mounting,

    isMounting: () => ref.current.state === State.Mounting,
    isMounted: () => ref.current.state === State.Mounted,
    isUnmounted: () => ref.current.state === State.Unmounted,
  });

  useEffect(() => {
    ref.current.state = State.Mounted;

    return () => {
      ref.current.state = State.Unmounted;
    };
  }, []);

  return ref.current;
}
