import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'connected-react-router';
import querystring from 'query-string';

import { ROUTES } from 'router/constants';

import { datumApi } from 'api/datum';

import { normalizeProducts } from './helpers';

const searchProducts = createAsyncThunk('search/searchProducts', async (searchValue, { dispatch, getState }) => {
  const data = await datumApi.searchProducts(searchValue);

  const {
    entities: { products, meta },
    result: { data: productIds = [] } = {},
  } = normalizeProducts(data);

  const isProductsEmpty = productIds.length === 0;
  const path = isProductsEmpty ? ROUTES.SEARCH_NOT_FOUND : ROUTES.SEARCH;
  const action = isProductsEmpty ? replace : push;
  const search = querystring.stringify({ term: searchValue });

  const currentPath = getState().router.location.pathname;

  if (path !== currentPath) {
    dispatch(action(`${path}?${search}`));
  }

  return {
    meta,
    products,
    productIds,
  };
});

export { searchProducts };
