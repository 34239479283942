import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

function Window({
  id,
  children,
  isVisible,
  isUnmounted,
  onOverlayClick,
  onAnimatedToVisible,
  isAnimatedToVisible,
  onAnimatedToInvisible,
}) {
  const onAnimationEnd = ({ animationName }) => {
    if (animationName === styles.hideModal) {
      onAnimatedToInvisible({ id });
    } else if (animationName === styles.showModal) {
      onAnimatedToVisible({ id });
    }
  };

  const isHidden = !isVisible || isUnmounted;

  return (
    <div
      className={classnames(styles.window, {
        [styles.hidden]: isHidden,
        [styles.focused]: isAnimatedToVisible,
      })}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={styles.content} role={onOverlayClick && 'button'} onClick={onOverlayClick}>
        {children({ isHidden })}
      </div>
    </div>
  );
}

Window.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isUnmounted: PropTypes.bool.isRequired,
  onOverlayClick: PropTypes.func.isRequired,
  isAnimatedToVisible: PropTypes.bool.isRequired,
  onAnimatedToVisible: PropTypes.func.isRequired,
  onAnimatedToInvisible: PropTypes.func.isRequired,
};

export default memo(Window);
