import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import { SignUpModal } from 'components/modals';

import styles from './styles.module.scss';

function SignUpNotification({ isVersionAnonymous, updateProfile }) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const showModal = () => setIsVisibleModal(true);
  const closeModal = () => setIsVisibleModal(false);

  return (
    <>
      {isVersionAnonymous ? (
        <button id="signup-notice" type="button" onClick={showModal} className={styles.wrapper}>
          Don&apos;t lose your results. <span className={styles.link}>Sign up</span> to save your
          results and matches.
        </button>
      ) : (
        <div className={styles.wrapper}>
          <span>Complete your account setup to save your results for access at a later date.</span>

          <Link className={styles.link} onClick={showModal}>
            Sign Up
          </Link>
        </div>
      )}

      <SignUpModal
        isVisible={isVisibleModal}
        closeModal={closeModal}
        updateProfile={updateProfile}
        isVersionAnonymous={isVersionAnonymous}
      />
    </>
  );
}

SignUpNotification.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  isVersionAnonymous: PropTypes.bool.isRequired,
};

export default SignUpNotification;
