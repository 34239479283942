import { useState, useCallback } from 'react';

import { uploadVideoCaptureVideoToCloudinary, uploadVideoCaptureCenteredImageToCloudinary } from 'helpers/cloudinary';

import { useRequestState } from 'hooks/video-capture/use-request-state';

export const useUploadAssetsToCloudinary = ({ image, videoBlob, videoContextData, dataSubmissionId }) => {
  const [requestState, requestActions, requestHelpers] = useRequestState();
  const [videoUploadState, videoUploadActions, videoUploadHelpers] = useRequestState();
  const [imageUploadState, imageUploadActions, imageUploadHelpers] = useRequestState();

  const [state, setState] = useState({
    imageUrl: null,
    videoUrl: null,
    imageUploadProgress: 0,
    videoUploadProgress: 0,
  });
  const uploadVideo = useCallback(async () => {
    try {
      videoUploadActions.setToFetching();
      const videoUrl = await uploadVideoCaptureVideoToCloudinary({
        blob: videoBlob,
        metadata: videoContextData,
        dataSubmissionId,
        onUpdateProgress: (videoUploadProgress) => {
          setState((prevState) => ({ ...prevState, videoUploadProgress }));
        },
      });
      setState((prevState) => ({ ...prevState, videoUrl }));
      videoUploadActions.setToFetched();
    } catch (e) {
      videoUploadActions.setToFailed(e);
      throw e;
    }
  }, [setState]);
  const uploadImage = useCallback(async () => {
    try {
      imageUploadActions.setToFetching();
      const imageUrl = await uploadVideoCaptureCenteredImageToCloudinary({
        base64: image,
        dataSubmissionId,
        onUpdateProgress: (imageUploadProgress) => {
          setState((prevState) => ({ ...prevState, imageUploadProgress }));
        },
      });
      setState((prevState) => ({ ...prevState, imageUrl }));
      imageUploadActions.setToFetched();
    } catch (e) {
      imageUploadActions.setToFailed(e);
      throw e;
    }
  }, [setState]);
  const upload = useCallback(async () => {
    try {
      requestActions.setToFetching();
      if (!videoUploadHelpers.isStateFetched()) {
        await uploadVideo();
      }
      if (!imageUploadHelpers.isStateFetched()) {
        await uploadImage();
      }
      requestActions.setToFetched();
    } catch (e) {
      requestActions.setToFailed(e);
    }
  }, [videoUploadState, imageUploadState, uploadVideo, uploadImage]);
  return [{ ...requestState, ...state }, { upload }, requestHelpers];
};
