import { useEffect } from 'react';
import { CriticalErrorType, ImageCaptureState, STARTING_CAMERA_TEXTS } from 'constants/video-capture';

const useCameraAccessEffect = ({
  streamState,
  streamActions,
  streamHelpers,
  imageCaptureState,
  imageCaptureActions,
}) => {
  useEffect(() => {
    if (imageCaptureState.state !== ImageCaptureState.StartingCamera) {
      return;
    }
    if (streamHelpers.isStateIdle()) {
      imageCaptureActions.setText(STARTING_CAMERA_TEXTS.STARTING_CAMERA);
      streamActions.createStream();
      return;
    }
    if (streamHelpers.isStateReady() && !imageCaptureState.isVideoReady) {
      imageCaptureActions.setText(STARTING_CAMERA_TEXTS.WAITING_VIDEO);
      imageCaptureActions.resetCriticalError(CriticalErrorType.CameraAccess);
      return;
    }
    if (streamHelpers.isStateReady() && imageCaptureState.isVideoReady) {
      imageCaptureActions.setImageCaptureState(ImageCaptureState.LoadingDetector);
      return;
    }
    if (streamHelpers.isStateAccessDenied()) {
      imageCaptureActions.setCriticalError(CriticalErrorType.CameraAccess);
      return;
    }
    if (streamHelpers.isStateFailed()) {
      imageCaptureActions.setCriticalError(CriticalErrorType.UnknownError);
    }
  }, [imageCaptureState.state, imageCaptureState.isVideoReady, streamState]);
};
export default useCameraAccessEffect;
