import { getId } from 'helpers/id';

import { ROUTES } from 'router/constants';

export const NAVIGATION_LINKS = [
  {
    id: 'quiz',
    name: 'Take the skin care quiz',
  },
  {
    id: getId(),
    to: ROUTES.ABOUT_US,
    name: 'about us',
  },
  {
    id: getId(),
    to: ROUTES.ARTICLES,
    name: 'Skin Care Articles',
  },
];
