import { useEffect } from 'react';

import { IlluminationCheckState, ILLUMINATION_TEXTS } from 'constants/video-capture';

const useInstructionsEffect = ({ illuminationActions, illuminationState }) => {
  useEffect(() => {
    if (illuminationState.state !== IlluminationCheckState.Instructions) {
      return;
    }
    illuminationActions.setText(ILLUMINATION_TEXTS.INSTRUCTIONS);
  }, [illuminationState.state]);
};

export default useInstructionsEffect;
