import React from 'react';
import classnames from 'classnames';

import { LightningPlaces } from 'components/lightning-places';
import { CloudinaryImage } from 'components/cloudinary-image';
import { withModalWrapper } from 'components/modals/hocs/with-modal-wrapper';

import { ReactComponent as IconLifestyleNoGlasses } from 'static/svg/no-glasses.svg';
import { ReactComponent as IconLifestyleNoMakeup } from 'static/svg/no-makeup.svg';
import { ReactComponent as IconLifestyleNoMask } from 'static/svg/no-mask.svg';

import { Wrapper } from 'components/modals/components/wrapper';

import styles from './styles.module.scss';

const IlluminationCheckHelp = ({ closeModal }) => (
  <Wrapper closeModal={closeModal}>
    <div className={styles.content}>
      <div className={styles.section}>
        <p className={styles.title}>1. No makeup, sunscreen, glasses or face coverings</p>

        <p className={styles.description}>
          Makeup and sunscreen have ingredients that will impact your hypergraph scanning
        </p>

        <div className={styles.icons}>
          <IconLifestyleNoGlasses />

          <IconLifestyleNoMakeup />

          <IconLifestyleNoMask />
        </div>
      </div>

      <div className={styles.section}>
        <p className={styles.title}>2. Find good even lighting</p>

        <LightningPlaces />
      </div>

      <div>
        <p className={styles.title}>3. Check lighting for glare and shadow</p>

        <div className={styles.imagesWrapper}>
          <div className={styles.imagesContent}>
            <p className={styles.imagesTitle}>Do this</p>

            <CloudinaryImage
              src="static/landing-page/video-capture/Rectangle_2802_w8uejr.jpg"
              alt=""
              className={classnames(styles.image, styles.first)}
            />

            <CloudinaryImage
              src="static/landing-page/video-capture/Mask_group_g4idvv.jpg"
              alt=""
              className={classnames(styles.image)}
            />
          </div>

          <div className={styles.imagesContent}>
            <p className={classnames(styles.imagesTitle, styles.red)}>Not this</p>

            <CloudinaryImage
              src="static/landing-page/video-capture/Image_1_cjnmje.jpg"
              alt=""
              className={classnames(styles.image, styles.second, styles.red)}
            />

            <CloudinaryImage
              src="static/landing-page/video-capture/Mask_group2_zp3eyg.jpg"
              alt=""
              className={classnames(styles.image, styles.second, styles.red)}
            />

            <CloudinaryImage
              src="static/landing-page/video-capture/Mask_group3_ltnnzd.png"
              alt=""
              className={classnames(styles.image, styles.red)}
            />
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
);

export default withModalWrapper(IlluminationCheckHelp);
