import React from 'react';
import { Helmet } from 'react-helmet';

export function PreloadMediaPipeResources() {
  return (
    <Helmet>
      <link
        rel="preload"
        href="https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/face_mesh.binarypb"
        as="fetch"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/face_mesh_solution_packed_assets.data"
        crossOrigin=""
        as="fetch"
      />
      <link
        rel="preload"
        crossOrigin=""
        href="https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/face_mesh_solution_simd_wasm_bin.wasm"
        as="fetch"
      />
      <link
        rel="preload"
        crossOrigin=""
        href="https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/face_mesh_solution_packed_assets_loader.js"
        as="script"
      />
      <link
        rel="preload"
        crossOrigin=""
        href="https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/face_mesh_solution_simd_wasm_bin.js"
        as="script"
      />
    </Helmet>
  );
}
