import React from 'react';

import { ROUTES } from 'router/constants';

import Link from 'components/link';

import styles from './styles.module.scss';

function RetakeQuiz() {
  return (
    <div>
      <span>Complete full quiz to get event better product matches. </span>
      <br />
      <Link className={styles.link} to={ROUTES.QUIZ}>
        Take the Quiz
      </Link>
    </div>
  );
}

export default RetakeQuiz;
