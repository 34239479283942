/* eslint-disable no-await-in-loop */
import querystring from 'query-string';

import buildApi from 'api/build';
import { datumApi } from 'api/datum';

import CONFIG from 'constants/config';
import { PRODUCT_CATEGORIES, PRODUCT_CATEGORIES_LIST } from 'constants/products';

import { actions as appActions } from 'store/modules/app';
import { actions as userActions, selectors as userSelectors } from 'store/modules/user';
import { actions as productsActions, selectors as productsSelectors } from 'store/modules/products';

import { isNotEmpty } from 'helpers/is';

const tryToChangeAppVersion = (dispatch) => {
  const { version } = querystring.parse(window.location.search);

  if (isNotEmpty(version)) {
    dispatch(appActions.changeVersion(version));
  }
};

const tryToUpdateUserIdForExistUsers = ({ getState, dispatch }) => {
  const { id, token } = userSelectors.getUser(getState());

  if (!token || !!id) {
    return;
  }

  dispatch(userActions.updateToken(token));
};

const removeOldCategoryId = ({ dispatch, getState }) => {
  const categoryId = productsSelectors.getNavigationCategoryId(getState());
  const isContains = PRODUCT_CATEGORIES_LIST.includes(categoryId);

  if (!isContains) {
    dispatch(productsActions.changeNavigationCategoryId(PRODUCT_CATEGORIES.TREATMENT));
  }
};

const initDatumApi = ({ getState, dispatch }) => {
  const onChangeToken = (token) => dispatch(userActions.updateToken(token));
  const onUnauthorized = () => dispatch(userActions.logout());

  const token = userSelectors.getToken(getState());

  datumApi.init({ token, onChangeToken, onUnauthorized });
};

const checkNewIndexHTML = async (dispatch) => {
  if (CONFIG.IS_LOCAL_MODE) {
    return;
  }

  let isUpdated = false;

  while (!isUpdated) {
    isUpdated = await buildApi.isUpdatedBuild();

    await new Promise((resolve) => setTimeout(resolve, 120 * 1000));
  }

  dispatch(appActions.updatedBuild());
};

export const setupStore = ({ dispatch, getState }) => {
  tryToChangeAppVersion(dispatch);
  tryToUpdateUserIdForExistUsers({ dispatch, getState });

  removeOldCategoryId({ dispatch, getState });

  initDatumApi({ dispatch, getState });

  checkNewIndexHTML(dispatch);

  dispatch(appActions.persistStoreDone());
};
