import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import usePrevious from 'hooks/use-previous';

import { FloatingElement } from 'components/floating-elements';

import Item from '../item';

import useIsSmall from './hooks/use-is-small';
import useCSSNavigationHeight from './hooks/use-css-navigation-height';
import useNavigationItemProps from './hooks/use-navigation-item-props';

import styles from './styles.module.scss';

function Navigation({
  items,
  lastOpenedCategoryId,
  countOfProductsInCart,
  countOfComparedProducts,
}) {
  const { isSmall, unsetSmall } = useIsSmall();
  const [state, setState] = useState({
    shouldAnimateCart: false,
    shouldAnimateCompare: false,
  });
  const previousCountOfCart = usePrevious(countOfProductsInCart);
  const previousCountOfCompare = usePrevious(countOfComparedProducts);

  const itemProps = useNavigationItemProps({
    setState,
    lastOpenedCategoryId,
    countOfProductsInCart,
    countOfComparedProducts,
  });

  useEffect(() => {
    if (countOfComparedProducts > previousCountOfCompare && previousCountOfCompare !== null) {
      unsetSmall();
      setState((prevProps) => ({
        ...prevProps,
        shouldAnimateCompare: true,
      }));
    }

    if (countOfProductsInCart > previousCountOfCart && previousCountOfCart !== null) {
      unsetSmall();
      setState((prevProps) => ({
        ...prevProps,
        shouldAnimateCart: true,
      }));
    }
  }, [countOfComparedProducts, countOfProductsInCart]);

  useCSSNavigationHeight(isSmall);

  const onOverlayClick = useCallback(
    (event) => {
      event.preventDefault();
      unsetSmall();
    },
    [unsetSmall],
  );

  return (
    <FloatingElement variable="--navigation-height">
      <div
        className={classnames(styles.wrapper, {
          [styles.animateCart]: state.shouldAnimateCart,
          [styles.animateCompare]: state.shouldAnimateCompare,
        })}
      >
        <div className={classnames(styles.container, 'container-fluid')}>
          <ul className={styles.list}>
            {items.map(
              (item) =>
                !item.isHidden && (
                  <Item key={item.id} isSmall={isSmall} {...item} {...itemProps[item.id]} />
                ),
            )}
          </ul>

          {isSmall && <div className={styles.overlay} role="button" onClick={onOverlayClick} />}
        </div>
      </div>
    </FloatingElement>
  );
}

Navigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isHidden: PropTypes.bool,
    }),
  ).isRequired,
  countOfProductsInCart: PropTypes.number.isRequired,
  lastOpenedCategoryId: PropTypes.string.isRequired,
  countOfComparedProducts: PropTypes.number.isRequired,
};

export default Navigation;
