import 'allsettled-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import 'helpers/register-modals';

import 'scripts/uninstall-service-worker';

import 'styles/global.scss';

import App from 'containers/app';

ReactDOM.render(<App />, document.getElementById('root'));
