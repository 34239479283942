import React, { useRef, memo, useImperativeHandle, forwardRef } from 'react';

import { SuccessCircle } from 'components/video-capture/success-circle';

import {
  SUCCESS_SMALL_CIRCLE_RADIUS,
  SUCCESS_CIRCLES_VIEW_BOX,
  SUCCESS_CIRCLES_HALF_VIEW_BOX,
  SUCCESS_SMALL_CIRCLE_STROKE_WIDTH,
} from '../../constants';

import Slider from '../slider';
import useCircleApi from './hooks/use-circle-api';

import styles from './styles.module.scss';

const Circle = forwardRef(({ x, y, isLeftCursorFilled, isRightCursorFilled }, ref) => {
  const bigCircleRef = useRef(null);
  const leftSliderApiRef = useRef(null);
  const rightSliderApiRef = useRef(null);
  const successCircleApiRef = useRef(null);
  const isSuccess = isLeftCursorFilled && isRightCursorFilled;
  const circleApi = useCircleApi({ bigCircleRef, successCircleApiRef, leftSliderApiRef, rightSliderApiRef });
  useImperativeHandle(ref, () => circleApi, []);
  const ellipseRy = SUCCESS_SMALL_CIRCLE_RADIUS + SUCCESS_SMALL_CIRCLE_STROKE_WIDTH / 2;
  const ellipsWidth = ellipseRy * 1.6;

  return (
    <svg
      x={x - SUCCESS_CIRCLES_HALF_VIEW_BOX}
      y={y - SUCCESS_CIRCLES_HALF_VIEW_BOX}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={SUCCESS_CIRCLES_VIEW_BOX}
      height={SUCCESS_CIRCLES_VIEW_BOX}
      viewBox={`0 0 ${SUCCESS_CIRCLES_VIEW_BOX} ${SUCCESS_CIRCLES_VIEW_BOX}`}
      className={styles.wrapper}
    >
      <rect
        x={SUCCESS_CIRCLES_HALF_VIEW_BOX - ellipsWidth / 2}
        y={SUCCESS_CIRCLES_HALF_VIEW_BOX - ellipseRy / 2}
        rx="20"
        fill="rgba(0, 0, 0, 0.3)"
        width={ellipsWidth}
        height={ellipseRy + ellipseRy / 5}
        stroke="white"
        strokeDasharray="5 5"
        strokeDashoffset="5"
      />
      <g transform="rotate(90)">
        <Slider
          x={SUCCESS_CIRCLES_HALF_VIEW_BOX - 12}
          y={-SUCCESS_CIRCLES_HALF_VIEW_BOX + 2}
          ref={leftSliderApiRef}
          color="var(--revea--illumination-saturation--color)"
          stroke="var(--revea--illumination-shadow--color)"
        />
      </g>
      <g transform="rotate(90)">
        <Slider
          x={SUCCESS_CIRCLES_HALF_VIEW_BOX - 12}
          y={-SUCCESS_CIRCLES_HALF_VIEW_BOX - 20}
          ref={rightSliderApiRef}
          color="var(--revea--illumination-shadow--color)"
          stroke="var(--revea--illumination-saturation--color)"
        />
      </g>

      <SuccessCircle x={5} y={16} ref={successCircleApiRef} isShowing={isSuccess} />
    </svg>
  );
});
export default memo(Circle);
