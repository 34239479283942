import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'connected-react-router';

import { ROUTES } from 'router/constants';
import { QUIZ_TYPES } from 'constants/quiz';

import segment, { EVENTS } from 'libs/analytic-segment';

import { datumApi } from 'api/datum';

import * as userActions from 'store/modules/user/actions';
import { actions as loaderActions } from 'store/modules/loader';
import { selectors as searchSelectors } from 'store/modules/search';
import { actions as quizActions, selectors as quizSelectors } from 'store/modules/quiz';

import { prepareAnswersForApi } from 'helpers/quiz';

import { acceptPolicy } from './actions';
import { getPolicyAcceptedAt } from './selectors';
import { transformAnswersForRequest } from './helpers';

let timeoutIdSurveyResults = null;

const getSurveyResults = createAsyncThunk('user/getSurveyResults', async (_, { dispatch }) => {
  try {
    clearTimeout(timeoutIdSurveyResults);

    const { surveyResults } = await datumApi.getSurveyResults();

    dispatch(userActions.updateSurveyResults(surveyResults));
  } finally {
    timeoutIdSurveyResults = setTimeout(() => dispatch(getSurveyResults()), 3600000);
  }
});

const signUp = createAsyncThunk(
  'user/signUp',
  async ({ user, questions, skinReport }, { dispatch, getState, rejectWithValue }) => {
    dispatch(loaderActions.show());

    let policyAcceptedAt = getPolicyAcceptedAt(getState());
    const { term, barcode } = searchSelectors.getAnonymousSearch(getState());
    const hasSearchValue = !!term || !!barcode;

    if (!policyAcceptedAt) {
      policyAcceptedAt = new Date().toJSON();

      dispatch(acceptPolicy(policyAcceptedAt));
    }

    try {
      await datumApi.signUp({
        user: {
          ...user,
          policyAcceptedAt,
        },
        surveyResult: {
          version: QUIZ_TYPES.REGULAR,
          questions: prepareAnswersForApi(questions),
          reveaSkinProfile: skinReport.skinProfile,
          reveaDataSubmissionId: skinReport.dataSubmissionId,
        },
      });

      segment.track(EVENTS.QUIZ_SUBMITTED_EMAIL);

      dispatch(quizActions.saved(QUIZ_TYPES.REGULAR));
      dispatch(getSurveyResults());
      dispatch(
        replace(ROUTES.QUIZ_RESET, {
          redirectTo: hasSearchValue
            ? `${ROUTES.SEARCH_PRODUCTS_RESULTS}?${new URLSearchParams(term ? { term } : { barcode }).toString()}`
            : ROUTES.DASHBOARD,
        }),
      );

      return null;
    } catch (error) {
      console.info(error);

      return rejectWithValue({
        errors: error.response.data?.errors,
        message: error.response.statusText,
      });
    } finally {
      dispatch(loaderActions.hide());
    }
  },
);

const saveRegularQuiz = createAsyncThunk(
  'user/saveRegularQuiz',
  // eslint-disable-next-line no-unused-vars
  async ({ skinReport } = {}, { getState, dispatch, rejectWithValue }) => {
    try {
      const questions = quizSelectors.getRegularQuizAnswers(getState());

      dispatch(quizActions.save(QUIZ_TYPES.REGULAR));

      const { surveyResults } = await datumApi.addSurveyResults({
        surveyResult: {
          version: QUIZ_TYPES.REGULAR,
          questions: prepareAnswersForApi(questions),
          reveaSkinProfile: skinReport.skinProfile,
          reveaDataSubmissionId: skinReport.dataSubmissionId,
        },
      });

      dispatch(userActions.updateSurveyResults(surveyResults));
      dispatch(quizActions.saved(QUIZ_TYPES.REGULAR));

      dispatch(replace(ROUTES.QUIZ_RESET, { redirectTo: ROUTES.DASHBOARD }));

      return null;
    } catch (error) {
      dispatch(
        quizActions.failSave({
          error: "Can't save quiz.",
          quizType: QUIZ_TYPES.REGULAR,
        }),
      );

      return rejectWithValue({
        errors: error.response.data?.errors,
        message: error.response.statusText,
      });
    }
  },
);

const signUpAnonymous = createAsyncThunk('user/signUpAnonymous', async (quizAnswers, { dispatch }) => {
  try {
    await datumApi.signUp({
      surveyResult: {
        version: QUIZ_TYPES.SHORT,
        questions: transformAnswersForRequest(quizAnswers),
      },
    });

    dispatch(getSurveyResults());
  } catch (error) {
    console.info(error);

    throw new Error(error.response.statusText);
  }
});

const signUpAnonymousFromRegularQuiz = createAsyncThunk(
  'user/signUpAnonymousFromRegularQuiz',
  async (_, { getState, dispatch }) => {
    dispatch(loaderActions.show());

    try {
      const questions = quizSelectors.getRegularQuizAnswers(getState());

      const { 'selfie-process': __, ...otherQuestions } = questions;

      await datumApi.signUp({
        surveyResult: {
          version: QUIZ_TYPES.REGULAR,
          questions: prepareAnswersForApi(otherQuestions),
        },
      });

      segment.track(EVENTS.QUIZ_SUBMITTED_QUIZ);

      await dispatch(getSurveyResults());

      dispatch(quizActions.saved(QUIZ_TYPES.REGULAR));
      dispatch(push(ROUTES.PICKING_UP_PRODUCTS));
    } catch (error) {
      console.info(error);

      throw new Error(error.response.statusText);
    } finally {
      dispatch(loaderActions.hide());
    }
  },
);

const updateProfile = createAsyncThunk('user/updateProfile', async (userData, { dispatch, rejectWithValue }) => {
  dispatch(loaderActions.show());

  try {
    await datumApi.updateProfile({ user: userData });

    segment.track(EVENTS.SIGN_UP_EMAIL_SUBMITTED);

    return null;
  } catch (error) {
    console.info(error);
    return rejectWithValue({
      errors: error.response.data?.errors,
      message: error.response.statusText,
    });
  } finally {
    dispatch(loaderActions.hide());
  }
});

export { signUp, updateProfile, saveRegularQuiz, signUpAnonymous, getSurveyResults, signUpAnonymousFromRegularQuiz };
