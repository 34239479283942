import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import { DEFAULT_STYLES } from './constants';

import styles from './styles.module.scss';

function DefaultGauge({ size, onClick, children, className, percentage, customStyles }) {
  const gaugeStyles = useMemo(
    () =>
      buildStyles({
        ...DEFAULT_STYLES,
        ...customStyles,
      }),
    [customStyles],
  );

  const isClickable = !!onClick;

  const classNames = classnames(className, styles.wrapper, styles[size], {
    [styles.clickable]: isClickable,
  });

  return (
    <div className={classNames} onClick={onClick} role="button" tabIndex={0}>
      <CircularProgressbarWithChildren value={percentage} styles={gaugeStyles} strokeWidth={7}>
        {children}
      </CircularProgressbarWithChildren>
    </div>
  );
}

DefaultGauge.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  className: PropTypes.string,
  percentage: PropTypes.number,
  customStyles: PropTypes.shape({}),
};

DefaultGauge.defaultProps = {
  size: 'lg',
  onClick: null,
  children: null,
  className: null,
  percentage: 100,
  customStyles: null,
};

export default DefaultGauge;
