export const ImageCaptureType = {
  Main: 'main',
  RetakeOnError: 'retake-on-error',
};

export const ImageCaptureStep = {
  InitializeCamera: 0,
  IlluminationCheck: 1,
  CameraRestrictions: 2,
  Capture: 3,
  Uploading: 4,
  Completed: 5,
};
