import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import Logrocket from 'logrocket';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import CONFIG from 'constants/config';

import history from 'router/history';

import { actions as selfieActions } from './modules/selfie';

import { setupStore } from './setup';

import reducer from './reducers';
import rootSaga from './sagas';

import migrateToReduxPersist from './helpers/migrate-to-redux-persist';

migrateToReduxPersist();

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  sagaMiddleware,
  routerMiddleware(history),
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
];

if (CONFIG.IS_ENABLED_LOGROCKET) {
  middleware.push(
    Logrocket.reduxMiddleware({
      stateSanitizer: (state) => ({
        ...state,
        selfie: {
          ...state.selfie,
          selfies: null,
        },
      }),
      actionSanitizer: (action) => {
        if (!action.type || action.type === selfieActions.setSelfies.toString()) {
          return;
        }

        return action;
      },
    }),
  );
}

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware,
});

const persistor = persistStore(store, null, () => setupStore(store));

sagaMiddleware.run(rootSaga);

export { persistor };

export default store;
