import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { datumApi } from 'api/datum';

import { isEmpty } from 'helpers/is';

import makeRequest from 'store/sagas/helpers/make-request';
import watchRequest from 'store/sagas/helpers/watch-request';

import {
  actions as productListsActions,
  selectors as productListsSelectors,
} from 'store/modules/product-lists';
import { actions as productsActions, helpers as productsHelpers } from 'store/modules/products';

function* watchForAddOrRemoveProduct(action) {
  const isAddAction = action.type === productListsActions.addProductToUserList.toString();
  const callToAPI = isAddAction
    ? datumApi.addProductToUserList
    : datumApi.removeProductFromUserList;

  try {
    const data = yield* makeRequest({ callToAPI }, action);

    yield put(productListsActions.fetchUserProductListSuccess(data));
  } catch (error) {
    console.error('watchForAddOrRemoveProduct', error);
  }
}

function* watchForFetchUserProductsByList({ type, payload: listName }) {
  try {
    const productLists = yield* makeRequest(
      { callToAPI: datumApi.getUserProductLists },
      { type: productListsActions.fetchUserProductList.toString() },
    );

    if (isEmpty(productLists)) {
      console.error('product lists is empty');

      return;
    }

    const productIds = yield select((state) =>
      productListsSelectors.getProductIdsByListName(state, listName),
    );

    let meta = {};
    let products = {};

    if (productIds.length !== 0) {
      const productsData = yield call(datumApi.getProductsByIds, productIds);

      const { entities } = productsHelpers.normalizeProducts(productsData);

      meta = entities.meta;
      products = entities.products;
    }

    yield put(productsActions.addProducts({ products, meta }));

    yield put({
      type: `${type}Success`,
      meta: listName,
      payload: {
        products,
        productIds,
      },
    });
  } catch (error) {
    console.error('watchForFetchUserProductsByList', error);

    yield put({
      type: `${type}Fail`,
      meta: listName,
      error: error.message,
    });
  }
}

export default function* productListsRootSaga() {
  yield* watchRequest({
    action: productListsActions.fetchUserProductList,
    callToAPI: datumApi.getUserProductLists,
  });

  yield takeEvery(
    [productListsActions.addProductToUserList, productListsActions.removeProductFromUserList],
    watchForAddOrRemoveProduct,
  );

  yield takeLatest(productListsActions.fetchUserProductsByList, watchForFetchUserProductsByList);
}
