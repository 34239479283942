import { useEffect } from 'react';

import { takePictureFromVideo } from 'helpers/canvas';

const useOnCheckingEndEffect = ({
  videoRef,
  onCheckingEnd,
  onTakePicture,
  isCheckingStep,
  measurementApiRef,
  isHelpModalOpened,
  isValidIlluminationValues,
}) => {
  useEffect(() => {
    if (!isCheckingStep || !isValidIlluminationValues || isHelpModalOpened) {
      return () => {};
    }
    const timeoutId = setTimeout(() => {
      if (onTakePicture && videoRef.current) {
        onTakePicture(takePictureFromVideo({ videoNode: videoRef.current }));
      }
      measurementApiRef.current?.toggleSuccessState(true);
      onCheckingEnd();
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCheckingStep, isValidIlluminationValues, isHelpModalOpened]);
};

export default useOnCheckingEndEffect;
