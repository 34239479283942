import { createSlice } from '@reduxjs/toolkit';
import { createMigrate } from 'redux-persist';

import createPersistReducer from 'store/helpers/create-persist-reducer';
import { resetStore } from 'store/modules/actions';
import { logout } from 'store/modules/user/actions';

import * as helpers from './helpers';
import * as selectors from './selectors';
import * as constants from './constants';

const { reducer: defaultReducer, actions } = createSlice({
  name: constants.STORE_NAME,
  initialState: constants.DEFAULT_STATE,
  reducers: {
    reset: () => constants.DEFAULT_STATE,
    setDataSubmissionId: (draftState, { payload: { id, type } }) => {
      draftState.entities[type].id = id;
      draftState.entities[type].createdAt = Date.now();
    },
    resetDataSubmissionId: (draftState, { payload: { type } }) => {
      draftState.entities[type] = constants.DATA_SUBMISSION_DEFAULT_STATE;
    },
  },
  extraReducers: {
    [resetStore.toString()]: () => constants.DEFAULT_STATE,

    [logout.toString()]: () => constants.DEFAULT_STATE,
  },
});

const migrations = {
  0: () => constants.DEFAULT_STATE,
};

const reducer = createPersistReducer(
  {
    key: constants.STORE_NAME,
    version: 0,
    migrate: createMigrate(migrations, { debug: false }),
    blacklist: ['requests'],
  },
  defaultReducer,
);

export { selectors, reducer, actions, helpers, constants };
