import { connect } from 'react-redux';

import { selectors as appSelectors } from 'store/modules/app';

import { NotificationBuild } from 'components/modals';

const mapStateToProps = (state) => ({
  isUpdatedBuild: appSelectors.getIsUpdatedBuild(state),
});

export default connect(mapStateToProps)(NotificationBuild);
