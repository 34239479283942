import { useRef, useMemo, useEffect, useState } from 'react';
import { FaceMesh, FACEMESH_FACE_OVAL } from '@mediapipe/face_mesh';

import useLifecycle from 'hooks/use-lifecycle';
import { useCreateConst } from 'hooks/video-capture/use-create-const';

import { getErrorMessage } from 'helpers/request';

import { FACE_DETECTOR_OPTIONS, LIPS_HOLE, NOSE_HOLE, LEFT_EYE_HOLE, RIGHT_EYE_HOLE } from './constants';

const FaceDetectorState = {
  Idle: 'idle',
  Ready: 'ready',
  Failed: 'failed',
  Initializing: 'initializing',
};

const useFaceDetector = () => {
  const cacheRef = useRef({ faceMeshResult: null });
  const [state, setState] = useState({
    state: FaceDetectorState.Idle,
    error: null,
    detector: null,
  });
  const lifecycle = useLifecycle();
  const actions = useCreateConst(() => ({
    createDetector: async () => {
      try {
        if (lifecycle.isUnmounted()) {
          return;
        }
        setState((prevState) => ({
          ...prevState,
          state: FaceDetectorState.Initializing,
          error: null,
        }));
        const faceMesh = new (window.FaceMesh || FaceMesh)({
          locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
        });
        faceMesh.setOptions(FACE_DETECTOR_OPTIONS);
        faceMesh.onResults((result) => {
          cacheRef.current.faceMeshResult = result;
        });
        await faceMesh.initialize();
        if (lifecycle.isUnmounted()) {
          faceMesh.close();
          return;
        }
        setState((prevState) => ({
          ...prevState,
          state: FaceDetectorState.Ready,
          detector: {
            detect: async (videoElement) => {
              await faceMesh.send({ image: videoElement });
              const result = cacheRef.current.faceMeshResult;
              if (!result?.multiFaceLandmarks[0]) {
                return null;
              }
              const { videoWidth, videoHeight } = videoElement;
              const points = result.multiFaceLandmarks[0].map((point) => [point.x * videoWidth, point.y * videoHeight]);
              return {
                lips: LIPS_HOLE.map((i) => points[i]),
                nose: NOSE_HOLE.map((i) => points[i]),
                leftEye: LEFT_EYE_HOLE.map((i) => points[i]),
                rightEye: RIGHT_EYE_HOLE.map((i) => points[i]),
                silhouette: (window.FACEMESH_FACE_OVAL || FACEMESH_FACE_OVAL).map(([i]) => points[i]),
              };
            },
            destroy: () => {
              faceMesh.close();
            },
          },
        }));
      } catch (error) {
        console.error(error);
        if (lifecycle.isUnmounted()) {
          return;
        }
        setState((prevState) => ({
          ...prevState,
          error: getErrorMessage(error),
          state: FaceDetectorState.Failed,
        }));
      }
    },
  }));
  const helpers = useMemo(
    () => ({
      isStateIdle: () => state.state === FaceDetectorState.Idle,
      isStateReady: () => state.state === FaceDetectorState.Ready,
      isStateFailed: () => state.state === FaceDetectorState.Failed,
      isStateInitializing: () => state.state === FaceDetectorState.Initializing,
    }),
    [state],
  );
  useEffect(() => {
    if (!state.detector) {
      return () => {};
    }
    return () => {
      state.detector.destroy();
    };
  }, [state.detector]);
  return [state, actions, helpers];
};
export default useFaceDetector;
