import React, { memo } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Mask = ({ width, height, className, illuminationCanvasRef }) => (
  <div className={classnames(styles.imageWrapper, className)}>
    <canvas width={width} height={height} ref={illuminationCanvasRef} className={styles.canvas} />
  </div>
);

export default memo(Mask);
