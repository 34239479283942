import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';

import { ROUTES } from 'router/constants';

import SideBar from 'components/side-bar';

import { ReactComponent as Logo } from 'static/svg/logo.svg';

import SideBarLayout from './components/side-bar-layout';
import SideBarGuestContent from './components/side-bar-guest-content';
import SideBarPrivateContent from './components/side-bar-private-content';

import styles from './styles.module.scss';

function Header({ name, logout, history, goToQuiz, isAnonymous, onLogoClick, isAuthenticated, currentCategoryId }) {
  const [isOpenedSideBar, setIsOpenedSideBar] = useState(false);

  const openSideBar = useCallback(() => setIsOpenedSideBar(true), [setIsOpenedSideBar]);
  const closeSideBar = useCallback(() => setIsOpenedSideBar(false), [setIsOpenedSideBar]);

  const push = useCallback(
    (path) => {
      closeSideBar();
      history.push(path);
    },
    [closeSideBar],
  );

  const goToQuizHandler = useCallback(() => {
    goToQuiz();
    closeSideBar();
  }, [goToQuiz, closeSideBar]);

  const onLogout = useCallback(() => {
    closeSideBar();
    logout();
  }, [closeSideBar]);

  return (
    <Navbar expand="lg" className={classnames('animated-opacity', styles.header, { [styles.anonymous]: isAnonymous })}>
      <Navbar.Brand className={styles.brand} onClick={onLogoClick}>
        <Logo className={styles.logo} />
      </Navbar.Brand>

      {!isAnonymous && (
        <div className={styles.right}>
          {!isAuthenticated && (
            <Link className={styles.link} to={ROUTES.LOGIN}>
              login
            </Link>
          )}

          <Navbar.Toggle onClick={openSideBar} className={styles.button}>
            <span className={styles.buttonLine} />
            <span className={styles.buttonLine} />
            <span className={styles.buttonLine} />
          </Navbar.Toggle>

          <SideBar isOpened={isOpenedSideBar} onClose={closeSideBar}>
            {() => (
              <SideBarLayout onCloseSideBar={closeSideBar}>
                {isAuthenticated ? (
                  <SideBarPrivateContent
                    name={name}
                    push={push}
                    onLogout={onLogout}
                    currentCategoryId={currentCategoryId}
                  />
                ) : (
                  <SideBarGuestContent push={push} goToQuiz={goToQuizHandler} />
                )}
              </SideBarLayout>
            )}
          </SideBar>
        </div>
      )}
    </Navbar>
  );
}

Header.propTypes = {
  name: PropTypes.string,
  logout: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  goToQuiz: PropTypes.func.isRequired,
  isAnonymous: PropTypes.bool.isRequired,
  onLogoClick: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  currentCategoryId: PropTypes.string.isRequired,
};

Header.defaultProps = {
  name: null,
};

export default Header;
