export default {
  IS_LOCAL_MODE: process.env.IS_LOCAL,
  RELEASE_STAGE: process.env.RELEASE_STAGE,
  IS_PRODUCTION: process.env.RELEASE_STAGE === 'production',
  IS_STAGING: process.env.RELEASE_STAGE === 'staging',

  APP_VERSION: process.env.APP_VERSION,
  REVEA_API_BASE_URL: process.env.REVEA_API_BASE_URL,

  BLOG_BASE_URL: process.env.BLOG_BASE_URL,
  BACK_END_BASE_URL: process.env.BACK_END_BASE_URL,
  SKINLAB_BACK_END_BASE_URL: process.env.SKINLAB_BACK_END_BASE_URL,

  LOGROCKET_APP_ID: process.env.LOGROCKET_APP_ID,

  SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,

  IS_ENABLED_SEGMENT: !!process.env.SEGMENT_WRITE_KEY,
  IS_ENABLED_LOGROCKET: !!process.env.LOGROCKET_APP_ID,

  REVEA_CLOUDINARY_URL: `https://api.cloudinary.com/v1_1/${process.env.REVEA_CLOUDINARY_BACKET_ID}`,
};
