import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

function Spinner({ className, size, centered, variant, hidden, position }) {
  const classNames = classnames(styles.wrapper, className, styles[size], styles[variant], styles[position], {
    [styles.hidden]: hidden,
    [styles.centered]: centered,
  });

  return (
    <div className={classNames}>
      <span className={styles.spinner}>
        <span className={styles.spinnerCircle} />
        <span className={styles.spinnerCircle} />
        <span className={styles.spinnerCircle} />
      </span>
    </div>
  );
}

Spinner.propTypes = {
  size: PropTypes.string,
  hidden: PropTypes.bool,
  variant: PropTypes.string,
  centered: PropTypes.bool,
  position: PropTypes.string,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  size: 'md',
  hidden: false,
  variant: 'dark',
  position: 'center',
  centered: false,
  className: null,
};

export default Spinner;
