import { createMigrate } from 'redux-persist';

import { DEFAULT_STATE } from './constants';

const migrations = {
  1: (draftState) => {
    return {
      ...DEFAULT_STATE,
      ...draftState,
    };
  },
};

export default createMigrate(migrations);
