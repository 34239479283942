import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import withData from 'hocs/with-data';

import { selectors as cartSelectors } from 'store/modules/cart';
import { selectors as productsSelectors } from 'store/modules/products';
import { selectors as productListsSelectors } from 'store/modules/product-lists';

import Navigation from './components/root';

import { NAVIGATION_ITEMS } from './constants';

const getData = () => ({
  items: NAVIGATION_ITEMS,
});

const mapStateToProps = (state) => {
  const productCartIds = productListsSelectors.getCartIds(state);
  const numberOfProductsByProductId = cartSelectors.getNumberOfProductsByProductId(state);

  const countOfProductsInCart = productCartIds.reduce(
    (sum, productId) =>
      sum +
      (numberOfProductsByProductId[productId] ? Number(numberOfProductsByProductId[productId]) : 1),
    0,
  );

  return {
    lastOpenedCategoryId: productsSelectors.getNavigationCategoryId(state),
    countOfProductsInCart,
    countOfComparedProducts: productListsSelectors.getTotalCountOfComparedProducts(state),
  };
};

export default compose(connect(mapStateToProps), withData(getData))(Navigation);
