import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/modal';

import Content from './components/content';

function AddProductToCompare({ isVisible, push, closeModal, categoryId }) {
  return (
    <Modal isVisible={isVisible} onOverlayClick={closeModal}>
      {() => <Content push={push} closeModal={closeModal} categoryId={categoryId} />}
    </Modal>
  );
}

AddProductToCompare.propTypes = {
  push: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default AddProductToCompare;
