import React from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';

import Spinner from 'components/spinner';

import styles from './styles.module.scss';

function Loader({ isShowing }) {
  const transitions = useTransition(isShowing, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props} className={styles.loader}>
          <Spinner centered variant="light" />
        </animated.div>
      ),
  );
}

Loader.propTypes = {
  isShowing: PropTypes.bool.isRequired,
};

export default Loader;
