import { VERSIONS } from './constants';

export const getApp = (state) => state.app;

export const getIsUpdatedBuild = (state) => getApp(state).isUpdatedBuild;

export const getVersion = (state) => getApp(state).version;

export const getIsVersionSearch = (state) => getVersion(state) === VERSIONS.SEARCH;

export const getIsVersionAnonymous = (state) => getVersion(state) === VERSIONS.ANONYMOUS;

export const getIsFetchedRootComponent = (state) => getApp(state).isFetchedRootComponent;
