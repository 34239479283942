import { IDEAL_RESOLUTION } from 'constants/video-capture';

export const STREAM_OPTIONS = {
  streamWidth: IDEAL_RESOLUTION.ILLUMINATION.WIDTH,
  streamHeight: IDEAL_RESOLUTION.ILLUMINATION.HEIGHT,
  capabilities: {
    width: IDEAL_RESOLUTION.VIDEO_CAPTURE.WIDTH,
    height: IDEAL_RESOLUTION.VIDEO_CAPTURE.HEIGHT,
  },
  streamAspectRatio: IDEAL_RESOLUTION.ASPECT_RATIO,
};
