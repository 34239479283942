const shouldMigrate = (key) => {
  const json = localStorage.getItem(key);

  if (!json) {
    return false;
  }

  const data = JSON.parse(json);

  return !data._persist;
};

const migrateStateItemToReduxPersist = (storeKey) => {
  if (!shouldMigrate(storeKey)) {
    return;
  }

  try {
    const data = JSON.parse(localStorage.getItem(storeKey));
    const objectKeys = Object.keys(data);

    objectKeys.forEach((key) => {
      data[key] = JSON.stringify(data[key]);
    });

    localStorage.setItem(storeKey, JSON.stringify(data));
  } catch (error) {
    console.info(error);
  }
};

const migrateToReduxPersist = () => {
  const keys = ['skinguide_user', 'skinguide_compare', 'skinguide_quiz'];

  keys.forEach(migrateStateItemToReduxPersist);
};

export default migrateToReduxPersist;
