const TEXTS = {
  'v1.short:skin-tone': 'Tone',
  'v1.short:skin-milieu': 'Milieu',
  'v1.short:dermal-fibers': 'Dermal fibers',
  'v1.short:skin-hydration': 'Hydration',
  'v1.short:skin-smoothness': 'Smoothness',
  'v1.short:oxygen-saturation': 'Energy supply',
};

export const transformAnswersForRequest = (answers) =>
  Object.keys(answers).reduce(
    (acc, id) => [
      ...acc,
      {
        id,
        text: TEXTS[id],
        answers: [
          {
            id: answers[id].toString(),
            text: answers[id].toString(),
          },
        ],
      },
    ],
    [],
  );

export const transformSurveyResultsForStore = (surveyResults) =>
  surveyResults.map(({ questions, ...otherProperties }) => ({
    ...otherProperties,
    answers: questions.reduce(
      (acc, question) => ({
        ...acc,
        [question.id]: question.answers.map((answer) => answer.id),
      }),
      {},
    ),
  }));
