import React, { useRef, forwardRef, useImperativeHandle, memo } from 'react';
import classnames from 'classnames';

import { VIEW_BOX_SIZE, RANGES } from './constants';
import Circle from './components/circle';
import Ranges from './components/ranges';

const RangeMaskView = forwardRef(({ className, ranges, circleClassName, rangesClassName, range }, ref) => {
  const circleApiRef = useRef(null);
  const rangesApiRef = useRef(null);
  useImperativeHandle(ref, () => ({ rangesApiRef, circleApiRef }), []);
  return (
    <svg viewBox={`0 0 ${VIEW_BOX_SIZE} ${VIEW_BOX_SIZE}`} className={classnames(className)}>
      <Ranges
        x={RANGES.X}
        y={RANGES.Y}
        ref={rangesApiRef}
        width={RANGES.SIZE}
        height={RANGES.SIZE}
        ranges={ranges}
        range={range}
        className={rangesClassName}
      />

      <Circle ref={circleApiRef} x={0} y={0} width={VIEW_BOX_SIZE} height={VIEW_BOX_SIZE} className={circleClassName} />
    </svg>
  );
});

export const RangeMask = memo(RangeMaskView);
