import React, { memo, useRef, forwardRef, useImperativeHandle } from 'react';

import useCircleApi from './hooks/use-circle-api';
import { VIEW_BOX_SIZE, HALF_VIEW_BOX_SIZE, INNER_CIRCLE_STROKE_WIDTH, INNER_CIRCLE_RADIUS } from './constants';
import { getCircleCircumference } from './helpers';

import styles from './styles.module.scss';

const Circle = forwardRef(({ x, y, width, height, className }, ref) => {
  const circleRef = useRef(null);
  const circleApi = useCircleApi({ circleRef });
  useImperativeHandle(ref, () => circleApi, []);
  const circumference = getCircleCircumference(INNER_CIRCLE_RADIUS).toFixed(2);

  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${VIEW_BOX_SIZE} ${VIEW_BOX_SIZE}`}
      className={className}
    >
      {/* <circle
        r={HALF_VIEW_BOX_SIZE - 1}
        cx={HALF_VIEW_BOX_SIZE}
        cy={HALF_VIEW_BOX_SIZE}
        stroke="rgba(255, 255, 255, 0.3)"
        strokeWidth="1"
        fill="transparent"
      /> */}

      <circle
        r={INNER_CIRCLE_RADIUS}
        cx={HALF_VIEW_BOX_SIZE}
        cy={HALF_VIEW_BOX_SIZE}
        fill="transparent"
        stroke="white"
        strokeWidth={INNER_CIRCLE_STROKE_WIDTH}
      />

      <circle
        r={INNER_CIRCLE_RADIUS}
        cx={HALF_VIEW_BOX_SIZE}
        cy={HALF_VIEW_BOX_SIZE}
        ref={circleRef}
        fill="transparent"
        stroke="#00FFDF"
        className={styles.innerCircle}
        strokeWidth={INNER_CIRCLE_STROKE_WIDTH}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={circumference}
      />
    </svg>
  );
});

export default memo(Circle);
