import React from 'react';

import { isSafari, isMobileSafari } from 'react-device-detect';

import { getId } from 'helpers/id';

import { ReactComponent as AaIosSVG } from 'static/images/video-capture/ios-aa.svg';
import { ReactComponent as ChromeSVG } from 'static/images/video-capture/chrome.svg';
import { ReactComponent as SettingsSVG } from 'static/images/video-capture/ios-settings.svg';

import styles from './styles.module.scss';

const step1PNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229548/static/landing-page/video-capture/instructions/camera-settings-1_zzsk87.jpg';
const step2PNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229548/static/landing-page/video-capture/instructions/camera-settings-2_gqjfuj.jpg';
const step3PNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229548/static/landing-page/video-capture/instructions/camera-settings-3_mdbcss.jpg';
const step4PNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229549/static/landing-page/video-capture/instructions/camera-settings-4_lpj7ej.jpg';
const step5PNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229549/static/landing-page/video-capture/instructions/camera-settings-5_pr07lz.jpg';
const step6PNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229549/static/landing-page/video-capture/instructions/camera-settings-6_bjvujf.jpg';
const step7PNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229548/static/landing-page/video-capture/instructions/camera-settings-1_zzsk87.jpg';

const step1IosChromePNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229548/static/landing-page/video-capture/instructions/camera-access-ios-chrome-1_pxxikk.jpg';
const step2IosChromePNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229548/static/landing-page/video-capture/instructions/camera-access-ios-chrome-2_soqgtg.jpg';
const step3IosChromePNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229549/static/landing-page/video-capture/instructions/camera-access-ios-chrome-3_zzd6tb.jpg';
const step4IosChromePNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229548/static/landing-page/video-capture/instructions/camera-access-ios-chrome-4_mnpiwp.jpg';
const step5IosChromePNG =
  'https://res.cloudinary.com/hqb85rb4o/image/upload/q_auto/v1686229548/static/landing-page/video-capture/instructions/camera-access-ios-chrome-5_gpthia.jpg';

export const IOS_SAFARI_IMAGES = [step1PNG, step2PNG, step3PNG, step4PNG, step5PNG, step6PNG, step7PNG];

export const IOS_SAFARI_STEPS = [
  {
    id: getId(),
    text: (
      <>
        Tap <AaIosSVG /> to the left of the address bar
      </>
    ),
    img: step1PNG,
    pulse: {
      delay: 2200,
      styles: {
        bottom: '6.5%',
        left: '11%',
      },
    },
  },
  {
    id: getId(),
    text: (
      <>
        Tap “
        <b>
          Wesite Settings <SettingsSVG />
        </b>
        ”
      </>
    ),
    img: step2PNG,
    pulse: {
      delay: 1200,
      styles: {
        left: '40%',
        top: '30%',
      },
    },
  },
  {
    id: getId(),
    text: (
      <>
        Tap “<b>Camera</b>”
      </>
    ),
    img: step3PNG,
    pulse: {
      delay: 1200,
      styles: {
        left: '20%',
        top: '30%',
      },
    },
  },
  {
    id: getId(),
    text: (
      <>
        Select “<b>Allow</b>”
      </>
    ),
    img: step4PNG,
    pulse: {
      delay: 1200,
      styles: {
        bottom: '10%',
        left: '21%',
      },
    },
  },
  {
    id: getId(),
    text: (
      <>
        Select “<b>Allow</b>”
      </>
    ),
    img: step5PNG,
    delayPulse: null,
  },
  {
    id: getId(),
    text: (
      <>
        Tap “<b>Done</b>” to close the menu
      </>
    ),
    img: step6PNG,
    pulse: {
      delay: 1200,
      styles: {
        top: '15%',
        right: '2%',
      },
    },
  },
  {
    id: getId(),
    text: (
      <>
        Tap “<b>Retry Camera Access</b>”
      </>
    ),
    img: step7PNG,
    pulse: {
      delay: 1200,
      styles: {
        bottom: '40%',
        left: '50%',
      },
    },
  },
];

export const IOS_CHROME_IMAGES = [
  step1IosChromePNG,
  step2IosChromePNG,
  step3IosChromePNG,
  step4IosChromePNG,
  step5IosChromePNG,
];

export const IOS_CHROME_STEPS = [
  {
    id: getId(),
    text: (
      <>
        Tap “
        <b>
          Settings <SettingsSVG />
        </b>
        ” on Home Screen
      </>
    ),
    img: step1IosChromePNG,
    pulse: {
      delay: 2200,
      styles: {
        left: '40%',
        top: '47%',
      },
    },
  },
  {
    id: getId(),
    text: (
      <>
        Find “
        <b>
          <ChromeSVG className={styles.chrome} /> Chrome
        </b>
        ” and tap it
      </>
    ),
    img: step2IosChromePNG,
    pulse: {
      delay: 1200,
      styles: {
        left: '30%',
        bottom: '10%',
      },
    },
  },
  {
    id: getId(),
    text: (
      <>
        Enable “<b>Camera</b>” and go back to browser
      </>
    ),
    img: step3IosChromePNG,
    pulse: {
      delay: 1200,
      styles: {
        bottom: '15.5%',
        right: '6%',
      },
    },
  },
  {
    id: getId(),
    text: (
      <>
        Enable “<b>Camera</b>” and go back to browser
      </>
    ),
    img: step4IosChromePNG,
    pulse: null,
  },
  {
    id: getId(),
    text: (
      <>
        Tap “<b>Retry Camera Access</b>” in browser
      </>
    ),
    img: step5IosChromePNG,
    pulse: {
      delay: 1000,
      styles: {
        top: '45%',
        left: '50%',
      },
    },
  },
];

export const { IMAGES, STEPS } =
  isMobileSafari || isSafari
    ? {
        STEPS: IOS_SAFARI_STEPS,
        IMAGES: IOS_SAFARI_IMAGES,
      }
    : {
        STEPS: IOS_CHROME_STEPS,
        IMAGES: IOS_CHROME_IMAGES,
      };
