import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/modal';

import Content from './components/content';

function RemoveProductFromFavoritesModal({ isVisible, closeModal, product, removeProduct }) {
  return (
    <Modal isVisible={isVisible} onOverlayClick={closeModal}>
      {() => <Content product={product} closeModal={closeModal} removeProduct={removeProduct} />}
    </Modal>
  );
}

RemoveProductFromFavoritesModal.propTypes = {
  product: PropTypes.shape({}),
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
};

RemoveProductFromFavoritesModal.defaultProps = {
  product: null,
};

export default memo(RemoveProductFromFavoritesModal);
