export const MODALS = {
  VideoCaptureHelp: 'video-capture-help',
  RetakeVideoCapture: 'retake-video-capture',
  IlluminationCheckHelp: 'illumination-check-help',

  MATCH_SCORE_EXPLANATION: 'match-score-explanation',
  MATCH_SCORE_EXPLANATION_GUEST: 'match-score-explanation-guest',
};

export const MODAL_IDS = Object.values(MODALS);

export const DEFAULT_STATE_MODALS = MODAL_IDS.reduce(
  (partState, modalId) => ({
    ...partState,
    [modalId]: {
      isVisible: false,
    },
  }),
  {},
);
