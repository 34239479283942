import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Grid from './components/grid';
import Shape from './components/shape';

import { CHART_SIZE, MIDDLE_OF_CHART, RADAR_LIST } from './constants';

import styles from './styles.module.scss';

function RadarChart({ data, light, className, onShapeTransitionEnd }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${CHART_SIZE} ${CHART_SIZE}`}
          version="1"
          className={classnames(className, styles.svg)}
        >
          <g transform={`translate(${MIDDLE_OF_CHART} ${MIDDLE_OF_CHART})`}>
            <Grid light={light} />

            <g className={styles.shapeWrapper}>
              {RADAR_LIST.map(
                (id) =>
                  data[id] && (
                    <Shape
                      key={id}
                      id={id}
                      vector={data[id]}
                      className={styles.shape}
                      onShapeTransitionEnd={onShapeTransitionEnd}
                    />
                  ),
              )}
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

RadarChart.propTypes = {
  data: PropTypes.shape({}).isRequired,
  light: PropTypes.bool,
  className: PropTypes.string,
  onShapeTransitionEnd: PropTypes.func.isRequired,
};

RadarChart.defaultProps = {
  light: false,
  className: null,
};

export default RadarChart;
