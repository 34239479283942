import { useCreateConst } from 'hooks/video-capture/use-create-const';

const useCircleApi = ({ bigCircleRef, leftSliderApiRef, rightSliderApiRef, successCircleApiRef }) =>
  useCreateConst(() => {
    let isLeftFilled = false;
    let isRightFilled = false;
    const toggleSuccessCircle = () => {
      if (!successCircleApiRef.current || !bigCircleRef.current) {
        return;
      }
      const isSuccess = isLeftFilled && isRightFilled;
      bigCircleRef.current.style.opacity = isSuccess ? '1' : '0';
    };
    return {
      fillLeftSlider: (isFilled) => {
        // leftSliderApiRef.current?.setColor(
        //   isFilled ? 'var(--slider--background--filled)' : 'var(--slider--background--unfilled)',
        // );
        // leftSliderApiRef.current?.setStroke(
        //   isFilled ? 'var(--slider--stroke-color--filled)' : 'var(--slider--stroke-color--unfilled)',
        // );
        leftSliderApiRef.current?.setOpacity(isFilled ? 1 : 0.8);
        isLeftFilled = isFilled;
        toggleSuccessCircle();
      },
      fillRightSlider: (isFilled) => {
        // rightSliderApiRef.current?.setColor(
        //   isFilled ? 'var(--slider--background--filled)' : 'var(--slider--background--unfilled)',
        // );
        // rightSliderApiRef.current?.setStroke(
        //   isFilled ? 'var(--slider--stroke-color--filled)' : 'var(--slider--stroke-color--unfilled)',
        // );
        rightSliderApiRef.current?.setOpacity(isFilled ? 1 : 0.8);
        isRightFilled = isFilled;
        toggleSuccessCircle();
      },
      toggleSuccessState: (isSuccess) => {
        successCircleApiRef.current?.toggleOpacity(isSuccess);
      },
    };
  });
export default useCircleApi;
