import { ROUTES } from 'router/constants';

import { ReactComponent as Cart } from 'static/svg/cart.svg';
import { ReactComponent as Match } from 'static/svg/navigation-match.svg';
import { ReactComponent as Search } from 'static/svg/navigation-search.svg';
import { ReactComponent as Compare } from 'static/svg/navigation-compare.svg';
import { ReactComponent as Regimen } from 'static/svg/navigation-regimen.svg';
import { ReactComponent as Dashboard } from 'static/svg/navigation-dashboard.svg';

import styles from './components/root/styles.module.scss';

export const NAVIGATION_ITEMS = [
  {
    id: 'dashboard',
    name: 'DASHBOARD',
    link: ROUTES.DASHBOARD,
    Icon: Dashboard,
    paths: [ROUTES.DASHBOARD],
  },
  {
    id: 'matches',
    name: 'MATCHES',
    Icon: Match,
    paths: [ROUTES.PRODUCTS_TREATMENTS, ROUTES.PRODUCTS_MOISTURIZERS],
  },
  {
    id: 'compare',
    name: 'COMPARE',
    Icon: Compare,
    paths: [ROUTES.COMPARE],
    className: styles.compare,
  },
  {
    id: 'regimen',
    name: 'MY REGIMEN',
    link: null,
    Icon: Regimen,
    paths: [],
    isHidden: true,
  },
  {
    id: 'search',
    name: 'SEARCH',
    link: ROUTES.SEARCH_PRODUCTS,
    Icon: Search,
    paths: [
      ROUTES.SEARCH_PRODUCTS,
      ROUTES.SEARCH_PRODUCTS_RESULTS,
      ROUTES.SEARCH_PRODUCTS_BARCODE,
      ROUTES.SEARCH_PRODUCTS_SCAN_BARCODE,
    ],
  },
  {
    id: 'cart',
    name: 'Cart',
    link: ROUTES.CART,
    Icon: Cart,
    paths: [ROUTES.CART],
    isHidden: true,
    className: styles.cart,
  },
];
