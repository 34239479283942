import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { normalizeValue } from 'helpers/skin';

import DefaultGauge from '../default-gauge';

import styles from './styles.module.scss';

const CUSTOM_STYLES = {
  pathColor: 'rgb(153, 200, 216)',
};

function DashboardGauge({ size, percentage, className }) {
  const classNames = classnames(styles.gauge, className, styles[size]);
  const normalizedPercentage = normalizeValue(percentage);

  return (
    <DefaultGauge
      size={size}
      className={classNames}
      percentage={normalizedPercentage}
      customStyles={CUSTOM_STYLES}
    >
      <span className={styles.text}>{normalizedPercentage}%</span>
    </DefaultGauge>
  );
}

DashboardGauge.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  percentage: PropTypes.number.isRequired,
};

DashboardGauge.defaultProps = {
  size: null,
  className: null,
};

export default DashboardGauge;
