import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { selectors as userSelectors } from 'store/modules/user';

import Notification from './components/root';

const mapStateToProps = (state) => ({
  isAnonymous: userSelectors.getIsAnonymous(state),
  isAuthenticated: userSelectors.getIsAuthenticated(state),
  isCompletedFullQuiz: userSelectors.getIsCompletedFullQuiz(state),
});

export default compose(connect(mapStateToProps))(Notification);
