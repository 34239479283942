export const DEFAULTS = {
  TITLE: 'The Best Skincare Products for You? Take the Skin Care Quiz!',
  DESCRIPTION:
    'See your skincare score, with our clinically-based skincare quiz and get your personalized skin care routine, scored from over 10K skincare products.',
  KEYWORDS: 'skincare, skin care, skincare products, quiz, personalized skin care',
};

export const BLOG = {
  DESCRIPTION:
    "SkinGuide's ultimate skin care guide.  Learn about skin science, skincare product ingredients, get the best of skin care lists, and find out how the skin quiz can help you find the perfect skin care routine or regimen!",
  KEYWORDS:
    'skin care routine, skincare, beauty blogs, natural skin care routine, beauty blog, beauty bloggers, skin care quiz, simple skin care routine, skincare blog, skin care routine quiz, best skin care products, beauty products, skin care brands, best skin care ',
};

export const PRIVACY_POLICY = {
  TITLE: 'Privacy policy - Skinguide',
  DESCRIPTION: 'Privacy Policy for Skinguide.co',
};

export const TERMS_OF_SERVICE = {
  TITLE: 'Terms of Service - Skinguide',
  DESCRIPTION: 'Terms of Service for Skinguide.co',
};

export const LOGIN = {
  TITLE: 'Login to your personal skin care routine',
  DESCRIPTION:
    'Login to skinguide to take the skin quiz and see your personalized skin care product recommendations.',
  KEYWORDS:
    'personalized skincare products, skin care product recommendations, custom skin care, custom skincare routine',
};

export const ABOUT_US = {
  TITLE: 'About Skin Guide',
  DESCRIPTION:
    'We are a team of skincare enthusiasts, skin biologists, data scientists, and product developers with 75 years of experience in skincare and dermatology. We believe skincare should be based on science and data, not marketing hype or trial & error.',
  KEYWORDS:
    'skin biologists, skincare enthusiasts, makeup geek, skin science, skin scientists, skin care startup, personalized skincare company, simple skincare, natural skincare, best skincare',
};
