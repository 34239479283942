import { useRef, useState, useCallback } from 'react';

import { updateStateByKey, getMeasurementValues } from './helpers';
import { DEFAULT_STATE, DEFAULT_CACHE } from './constants';

const useFaceState = ({ thresholds, measurementApiRef }) => {
  const [state, setState] = useState(DEFAULT_STATE);
  const cacheRef = useRef(DEFAULT_CACHE);
  const onIlluminationChange = useCallback((values) => {
    if (values === null) {
      cacheRef.current.frameCountFaceIsNotDetected += 1;
      if (cacheRef.current.frameCountFaceIsNotDetected > 3) {
        cacheRef.current.frameCountFaceIsDetected = 0;
        cacheRef.current.frameCountFaceIsNotDetected = 0;
        measurementApiRef.current?.setValues({ shadow: 1, saturation: 1, ignoreSuccessState: true });
        setState(DEFAULT_STATE);
      }
      return;
    }
    const partOfNextState = {
      ...updateStateByKey({
        values,
        cacheRef,
        dataKey: 'shadowValue',
        cacheKey: 'frameCountOfShadowOnTheFace',
        stateKey: 'isTooMuchShadowOnTheFace',
        thresholdToOk: thresholds.shadow.toOk,
        thresholdFromOk: thresholds.shadow.fromOk,
      }),
      ...updateStateByKey({
        values,
        cacheRef,
        dataKey: 'saturationValue',
        cacheKey: 'frameCountOfLightOnTheFace',
        stateKey: 'isTooMuchLightOnTheFace',
        thresholdToOk: thresholds.saturation.toOk,
        thresholdFromOk: thresholds.saturation.fromOk,
      }),
      ...updateStateByKey({
        values,
        cacheRef,
        dataKey: 'backgroundSaturationValue',
        cacheKey: 'frameCountOfBackgroundBrightLight',
        stateKey: 'isBackgroundBrightLight',
        thresholdToOk: thresholds.backgroundSaturation.toOk,
        thresholdFromOk: thresholds.backgroundSaturation.fromOk,
      }),
    };
    cacheRef.current.frameCountFaceIsDetected += 1;
    cacheRef.current.frameCountFaceIsNotDetected = 0;
    const isFaceDetected = cacheRef.current.frameCountFaceIsDetected > 3;
    const isValidIlluminationValues =
      isFaceDetected &&
      !partOfNextState.isTooMuchLightOnTheFace &&
      !partOfNextState.isTooMuchShadowOnTheFace &&
      !partOfNextState.isBackgroundBrightLight;
    const nextState = {
      ...partOfNextState,
      isFaceDetected,
      isValidIlluminationValues,
    };
    const { shadowValue, saturationValue } = values;
    const { shadow, saturation } = getMeasurementValues({
      thresholds,
      shadow: shadowValue,
      saturation: saturationValue,
      isValidValues: isValidIlluminationValues,
    });
    measurementApiRef.current?.setValues({ shadow, saturation });
    setState((prevState) => {
      if (
        prevState.isBackgroundBrightLight === nextState.isBackgroundBrightLight &&
        prevState.isFaceDetected === nextState.isFaceDetected &&
        prevState.isTooMuchLightOnTheFace === nextState.isTooMuchLightOnTheFace &&
        prevState.isTooMuchShadowOnTheFace === nextState.isTooMuchShadowOnTheFace &&
        prevState.isValidIlluminationValues === nextState.isValidIlluminationValues
      ) {
        return prevState;
      }
      return nextState;
    });
  }, []);
  return [state, onIlluminationChange];
};
export default useFaceState;
