import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'router/constants';

import { selectors } from 'store/modules/user';

function PrivateRoute({
  redirectPath,
  withoutRedirect,
  isAuthenticated,
  component: Component,
  ...otherProps
}) {
  const guestContent = withoutRedirect ? null : <Redirect to={redirectPath} />;

  return (
    <Route
      {...otherProps}
      render={(props) => (isAuthenticated ? <Component {...props} /> : guestContent)}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
  redirectPath: PropTypes.string,
  withoutRedirect: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
};

PrivateRoute.defaultProps = {
  redirectPath: ROUTES.ROOT,
  withoutRedirect: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: selectors.getIsAuthenticated(state),
});

export default connect(mapStateToProps)(PrivateRoute);
