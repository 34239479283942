export const transportCreator = () => {
  let resolver = null;
  return {
    push: (data) => {
      if (!resolver) {
        return;
      }
      const r = resolver;
      resolver = null;
      setTimeout(() => {
        r(data);
      }, 0);
    },
    pull: () =>
      new Promise((resolve) => {
        resolver = resolve;
      }),
  };
};
