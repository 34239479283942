export const PantoneCardRoute = {
  Root: '/capture-flow',
};

export const Step = {
  Instructions: 'instructions',
  DiskSpace: 'disk-space',
  PantoneCardQuestion: 'pantone-card-question',
  IlluminationCheck: 'illumination-check',
  PantoneCardCapture: 'pantone-card-capture',
  PantoneCardSuccess: 'pantone-card-success',
  ImageCapture: 'image-capture',
  Results: 'results',
};

export const PANTONE_CARD_PHOTO_ID = 'pantone';

export const PHOTOS_INFO = [
  {
    id: 10,
    angle: 0,
  },
  {
    id: 8,
    angle: 12,
  },
  {
    id: 6,
    angle: 24,
  },
  {
    id: 4,
    angle: 36,
  },
  {
    id: 2,
    angle: 48,
  },
  {
    id: 0,
    angle: 60,
  },
  {
    id: 1,
    angle: 54,
  },
  {
    id: 3,
    angle: 42,
  },
  {
    id: 5,
    angle: 30,
  },
  {
    id: 7,
    angle: 18,
  },
  {
    id: 9,
    angle: 6,
  },
  {
    id: 11,
    angle: -6,
  },
  {
    id: 13,
    angle: -18,
  },
  {
    id: 15,
    angle: -30,
  },
  {
    id: 17,
    angle: -42,
  },
  {
    id: 19,
    angle: -54,
  },
  {
    id: 20,
    angle: -60,
  },
  {
    id: 18,
    angle: -48,
  },
  {
    id: 16,
    angle: -36,
  },
  {
    id: 14,
    angle: -24,
  },
  {
    id: 12,
    angle: -12,
  },
];
