import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { RetakeQuizModal } from 'components/modals';

import Navigation from '../side-bar-navigation';
import NavigationLink from '../side-bar-navigation-link';

import { NAVIGATION_LINKS } from './constants';

import styles from './styles.module.scss';

function SideBarPrivateContent({ push, name, currentCategoryId, onLogout }) {
  const [isVisibleRetakeQuizModal, setIsVisibleRetakeQuizModal] = useState(false);

  const navigationLinks = useMemo(() => {
    const linkProps = {
      'retake-quiz': {
        onClick: (event) => {
          event.preventDefault();

          setIsVisibleRetakeQuizModal(true);
        },
      },
    };

    return NAVIGATION_LINKS.map((link) => ({
      ...linkProps[link.id],
      id: link.id,
      to: link.getLink && link.getLink({ currentCategoryId }),
      name: link.name,
    }));
  }, [currentCategoryId]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <span className={styles.welcome}>Hello!</span>
          <span className={styles.email}>{name}</span>
        </div>

        <div className={styles.middle}>
          <Navigation push={push} links={navigationLinks} />
        </div>

        <div className={styles.bottom}>
          <NavigationLink onClick={onLogout}>logout</NavigationLink>
        </div>
      </div>

      <RetakeQuizModal
        push={push}
        isVisible={isVisibleRetakeQuizModal}
        closeModal={() => setIsVisibleRetakeQuizModal(false)}
      />
    </>
  );
}

SideBarPrivateContent.propTypes = {
  push: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  currentCategoryId: PropTypes.string.isRequired,
};

export default SideBarPrivateContent;
