import { SKIN_PARAMS_TYPES } from 'constants/skin';

export const CHART_SIZE = 360;
export const MIDDLE_OF_CHART = (CHART_SIZE / 2).toFixed(4);

export const PARAMETERS = [
  {
    key: SKIN_PARAMS_TYPES.HYDRATION,
    subject: () => 'HYDRATION',
    transform: () => 'translate(0, -18)',
  },
  {
    key: SKIN_PARAMS_TYPES.TONE,
    subject: () => 'SKIN TONE',
    transform: (x, y) => `translate(10, -7) rotate(-30 ${x} ${y})`,
  },
  {
    key: SKIN_PARAMS_TYPES.SMOOTHNESS,
    subject: () => 'SMOOTHNESS',
    transform: (x, y) => `translate(10, 0) rotate(30 ${x} ${y})`,
  },
  {
    key: SKIN_PARAMS_TYPES.DERMAL_FIBERS,
    subject: () => 'DERMAL FIBERS',
    transform: () => `translate(0, 18)`,
  },
  {
    key: SKIN_PARAMS_TYPES.MILIEU,
    subject: () => 'SKIN MILIEU',
    transform: (x, y) => `translate(-13, 5) rotate(-30 ${x} ${y})`,
  },
  {
    key: SKIN_PARAMS_TYPES.OXYGEN_SATURATION,
    subject: () => 'ENERGY SUPPLY',
    transform: (x, y) => `translate(-7, -7) rotate(30 ${x} ${y})`,
  },
];

export const RADARS = {
  skin: {
    color: 'rgba(189, 223, 204, 1)',
  },
  product: {
    color: 'rgba(153, 200, 216, 1)',
  },
};

export const RADAR_LIST = Object.keys(RADARS);

export const ANGLES = PARAMETERS.map((_, index) => (Math.PI * 2 * index) / PARAMETERS.length);
