export const createD = (points) => {
  if (points[0][0] !== points[points.length - 1][0] || points[0][1] !== points[points.length - 1][1]) {
    points.push(points[0]);
  }
  return points.reduce((d, [x, y], index) => {
    if (index === 0) {
      return `${d}M ${x} ${y} `;
    }
    if (points.length - 1 === index) {
      return `${d}L ${x} ${y} Z `;
    }
    return `${d}L ${x} ${y} `;
  }, '');
};

export const drawImage = ({ ctx, input }) =>
  ctx.drawImage(input, 0, 0, input.videoWidth, input.videoHeight, 0, 0, input.videoWidth, input.videoHeight);

export const fill = ({ ctx, width, height }) => {
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = '#000000';
  ctx.fillRect(0, 0, width, height);
};

export const clear = ({ ctx, width, height }) => {
  ctx.clearRect(0, 0, width, height);
};

export const drawPathByPoints = ({ ctx, fill: fillColor = 'black', points }) => {
  const p = new Path2D(createD(points));
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = fillColor;
  ctx.fill(p);
};

export const drawFace = ({ ctx, faceMesh }) => {
  drawPathByPoints({ ctx, fill: 'white', points: faceMesh.silhouette });
  [faceMesh.lips, faceMesh.nose, faceMesh.leftEye, faceMesh.rightEye].forEach((points) =>
    drawPathByPoints({ ctx, points }),
  );
};
