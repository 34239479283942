import { useState, useCallback, useEffect } from 'react';

const cachedImages = {};

export default function useIsImageLoaded(src) {
  const [{ isLoaded, isLoading }, setState] = useState({
    isLoaded: cachedImages[src] ?? false,
    isLoading: !(typeof cachedImages[src] === 'boolean'),
  });

  const onLoad = useCallback(() => {
    setState({ isLoaded: true, isLoading: false });
    cachedImages[src] = true;
  }, [src]);

  const onError = useCallback(() => {
    setState({ isLoaded: false, isLoading: false });
    cachedImages[src] = false;
  }, [src]);

  useEffect(() => {
    setState({
      isLoaded: cachedImages[src] ?? false,
      isLoading: !(typeof cachedImages[src] === 'boolean'),
    });
  }, [src]);

  return { isLoaded, isLoading, onLoad, onError };
}
