import useLifecycle from 'hooks/use-lifecycle';
import { useCreateConst } from 'hooks/video-capture/use-create-const';

import { delay } from 'helpers/promise';

import useCanvas from '../use-canvas';
import useScheduler from '../use-scheduler';
import * as canvasHelpers from './helpers/canvas';
import * as luminanceHelpers from './helpers/luminance';

const useIllumination = ({ width, height, videoRef, faceDetector, illuminationCanvasRef, onIlluminationChange }) => {
  const lifecycle = useLifecycle();
  const [, schedulerActions] = useScheduler();
  const [, maskCanvasCtx] = useCanvas({ width, height });
  const [, imageCanvasCtx] = useCanvas({ width, height });
  const check = useCreateConst(() => async () => {
    const illuminationCanvas = illuminationCanvasRef.current;
    const illuminationCanvasCtx = illuminationCanvas?.getContext('2d');
    const faceMesh = await faceDetector.detect(videoRef.current);
    if (lifecycle.isUnmounted()) {
      return;
    }
    if (faceMesh) {
      await delay(0);

      canvasHelpers.drawImage({ ctx: imageCanvasCtx, input: videoRef.current });
      canvasHelpers.fill({ ctx: maskCanvasCtx, width, height });
      canvasHelpers.drawFace({ ctx: maskCanvasCtx, faceMesh });
      const luminanceData = luminanceHelpers.createLuminanceData({
        width,
        height,
        maskCtx: maskCanvasCtx,
        imageCtx: imageCanvasCtx,
      });
      const { shadowPixels, saturationPixels } = luminanceHelpers.generateMasks({
        width,
        height,
        luminanceFace: luminanceData.luminanceFace,
        shadowThreshold: luminanceData.shadowThreshold,
        illuminationCanvasCtx,
      });
      onIlluminationChange({
        shadowValue: shadowPixels / luminanceData.pixelsInFace,
        saturationValue: saturationPixels / luminanceData.pixelsInFace,
        backgroundSaturationValue:
          luminanceData.backgroundSaturationPixels / (width * height - luminanceData.pixelsInFace),
      });
    } else {
      onIlluminationChange(null);
    }
  });

  const actions = useCreateConst(() => ({
    check: () => {
      schedulerActions.run(check);
    },
    stopChecking: () => {
      schedulerActions.stop();
    },
  }));
  return actions;
};

export default useIllumination;
