import { useState, useCallback, useEffect, useRef } from 'react';

const MIN_PX_FOR_OPEN = 50;
const MIN_PX_FOR_CLOSE = 100;

export default function useIsSmall() {
  const [isSmall, setIsSmall] = useState(true);
  const cache = useRef({
    prevTopScrollY: window.scrollY,
    prevDownScrollY: window.scrollY,
    isSmall,
  });

  cache.current.isSmall = isSmall;

  useEffect(() => {
    let passiveIfSupported = false;

    try {
      window.addEventListener(
        'test',
        null,
        Object.defineProperty({}, 'passive', {
          get() {
            passiveIfSupported = { passive: false };

            return passiveIfSupported;
          },
        }),
      );
    } catch (err) {
      console.log('passive is not supported');
    }

    const onScroll = () => {
      const differentTop = cache.current.prevTopScrollY - window.scrollY;
      const differentDown = cache.current.prevDownScrollY - window.scrollY;

      if (window.scrollY <= 0 || window.scrollY >= document.body.offsetHeight) {
        cache.current.prevTopScrollY = window.scrollY;
        cache.current.prevDownScrollY = window.scrollY;

        return;
      }

      if (differentTop > MIN_PX_FOR_OPEN && cache.current.isSmall) {
        setIsSmall(false);
      } else if (differentDown * -1 > MIN_PX_FOR_CLOSE && !cache.current.isSmall) {
        setIsSmall(true);
      }

      if (Math.abs(differentTop) > MIN_PX_FOR_OPEN) {
        cache.current.prevTopScrollY = window.scrollY;
      }

      if (Math.abs(differentDown) > MIN_PX_FOR_CLOSE) {
        cache.current.prevDownScrollY = window.scrollY;
      }
    };

    window.addEventListener('scroll', onScroll, passiveIfSupported);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {
    isSmall,
    unsetSmall: useCallback(() => setIsSmall(false), []),
  };
}
