import slugify from 'slugify';
import matchAll from 'string.prototype.matchall';

import { ROUTES } from 'router/constants';

export const generateProductPath = ({ id, brand, name }) => {
  const slug = slugify(`${brand}-${name}`, { lower: true, strict: true });

  return ROUTES.PRODUCT.replace(':productId', `${slug}-${id}`);
};

export const parseProductIdWithSlug = (articleIdWithSlug) => {
  const match = articleIdWithSlug.match(/^[A-Za-z0-9]+$/);

  let productId = match && match[0];

  if (!productId) {
    const matches = [...matchAll(articleIdWithSlug, /-([A-Za-z0-9]+)$/g)];
    const [, parsedProductId] = matches.length !== 0 ? matches[0] : [];

    productId = parsedProductId;
  }

  return productId || articleIdWithSlug;
};
