import { createSlice } from '@reduxjs/toolkit';

import { PRODUCT_CATEGORIES } from 'constants/products';

import { thunks as searchThunks } from 'store/modules/search';
import { logout } from 'store/modules/user/actions';

import createPersistReducer from 'store/helpers/create-persist-reducer';

import * as thunks from './thunks';
import * as helpers from './helpers';
import * as selectors from './selectors';

const DEFAULT_STATE = {
  entity: {
    entities: {
      meta: {},
      products: {},
      requests: {},
    },
    productIds: {
      treatment: [],
      moisturizer: [],
    },
    filters: {
      treatment: {
        isDefault: true,
        filter: {},
        defaultFilter: {},
      },
      moisturizer: {
        isDefault: true,
        filter: {},
        defaultFilter: {},
      },
    },
    requests: {
      treatment: {
        done: false,
        error: null,
        isFetching: false,
      },
      moisturizer: {
        done: false,
        error: null,
        isFetching: false,
      },
    },
  },

  brands: {
    entities: [],

    isFetching: false,
    isFirstRequestDone: false,
  },

  productsByBrand: {
    products: {},
    requests: {},
  },

  similarProductsByProductId: {},
  relatedProductsByProductId: {},

  categoryListings: {},

  isOpenedFilter: false,
  navigationCategoryId: PRODUCT_CATEGORIES.TREATMENT,
};

const { reducer: defaultReducer, actions } = createSlice({
  name: 'products',
  initialState: DEFAULT_STATE,
  reducers: {
    clearFilter: (draftState, { payload: { categoryId } }) => {
      draftState.entity.filters[categoryId].isDefault = false;
      draftState.entity.filters[categoryId].filter = {};
    },

    changeFilter: (draftState, { payload: { categoryId, filter } }) => {
      draftState.entity.filters[categoryId].isDefault = false;
      draftState.entity.filters[categoryId].filter = filter;
    },

    resetFilter: (draftState, { payload: { categoryId } }) => {
      draftState.entity.filters[categoryId].isDefault = true;
      draftState.entity.filters[categoryId].filter = draftState.entity.filters[categoryId].defaultFilter;
    },

    openFilter: (draftState) => {
      draftState.isOpenedFilter = true;
    },

    closeFilter: (draftState) => {
      draftState.isOpenedFilter = false;
    },

    changeNavigationCategoryId: (draftState, { payload: categoryId }) => {
      draftState.navigationCategoryId = categoryId;
    },

    addProducts: (draftState, { payload: { meta, products } }) => {
      draftState.entity.entities.meta = {
        ...draftState.entity.entities.meta,
        ...meta,
      };
      draftState.entity.entities.products = {
        ...draftState.entity.entities.products,
        ...products,
      };
    },
  },
  extraReducers: {
    [logout.toString()]: () => DEFAULT_STATE,

    [thunks.fetchProducts.pending]: (
      draftState,
      {
        meta: {
          arg: { categoryId },
        },
      },
    ) => {
      draftState.entity.requests[categoryId] = {
        done: draftState.entity.requests[categoryId].done,
        error: null,
        isFetching: true,
      };
    },

    [thunks.fetchProducts.rejected]: (
      draftState,
      {
        error,
        meta: {
          arg: { categoryId },
        },
      },
    ) => {
      draftState.entity.requests[categoryId] = {
        done: true,
        error,
        isFetching: false,
      };
    },

    [thunks.fetchProducts.fulfilled]: (
      draftState,
      {
        payload: {
          entities: { products, meta },
          productIds,
          defaultFilters,
        },
        meta: {
          arg: { categoryId },
        },
      },
    ) => {
      draftState.entity.requests[categoryId] = {
        done: true,
        error: null,
        isFetching: false,
      };

      draftState.entity.entities.meta = {
        ...draftState.entity.entities.meta,
        ...meta,
      };
      draftState.entity.entities.products = {
        ...draftState.entity.entities.products,
        ...products,
      };

      draftState.entity.productIds[categoryId] = productIds;

      const defaultFilter = defaultFilters[categoryId];

      if (defaultFilter && draftState.entity.filters[categoryId].isDefault) {
        draftState.entity.filters[categoryId].filter = defaultFilter;
        draftState.entity.filters[categoryId].defaultFilter = defaultFilter;
      }
    },

    [thunks.fetchProduct.pending]: (draftState, { meta: { arg: id } }) => {
      draftState.entity.entities.requests[id] = {
        done: false,
        isFetching: true,
        error: null,
      };
    },

    [thunks.fetchProduct.rejected]: (draftState, { error, meta: { arg: id } }) => {
      draftState.entity.entities.requests[id] = {
        done: true,
        isFetching: false,
        error,
      };
    },

    [thunks.fetchProduct.fulfilled]: (draftState, { payload: { meta, product }, meta: { arg: id } }) => {
      draftState.entity.entities.meta[id] = meta;
      draftState.entity.entities.products[id] = product;
      draftState.entity.entities.requests[id].done = true;
      draftState.entity.entities.requests[id].isFetching = false;
    },

    [searchThunks.searchProducts.fulfilled]: (draftState, { payload: { meta, products } }) => {
      draftState.entity.entities.meta = {
        ...draftState.entity.entities.meta,
        ...meta,
      };
      draftState.entity.entities.products = {
        ...draftState.entity.entities.products,
        ...products,
      };
    },

    [thunks.fetchProductBrands.pending]: (draftState) => {
      draftState.brands = {
        ...draftState.brands,
        isFetching: true,
      };
    },

    [thunks.fetchProductBrands.rejected]: (draftState) => {
      draftState.brands = {
        ...draftState.brands,
        isFetching: false,
        isFirstRequestDone: true,
      };
    },

    [thunks.fetchProductBrands.fulfilled]: (draftState, { payload: brands }) => {
      draftState.brands = {
        ...draftState.brands,
        entities: brands,
        isFetching: false,
        isFirstRequestDone: true,
      };
    },

    [thunks.fetchProductsBrand.pending]: (draftState, { meta: { arg: brandId } }) => {
      draftState.productsByBrand = {
        ...draftState.productsByBrand,
        requests: {
          ...draftState.productsByBrand.requests,
          [brandId]: {
            ...draftState.productsByBrand[brandId],
            error: null,
            isFetching: true,
          },
        },
      };
    },

    [thunks.fetchProductsBrand.rejected]: (draftState, { error, meta: { arg: brandId } }) => {
      draftState.productsByBrand = {
        ...draftState.productsByBrand,
        requests: {
          ...draftState.productsByBrand.requests,
          [brandId]: {
            ...draftState.productsByBrand[brandId],
            error,
            isFetching: false,
            isFirstRequestDone: true,
          },
        },
      };
    },

    [thunks.fetchProductsBrand.fulfilled]: (draftState, { payload: products, meta: { arg: brandId } }) => {
      draftState.productsByBrand = {
        ...draftState.productsByBrand,
        products: {
          ...draftState.productsByBrand.products,
          [brandId]: helpers.transformProductsBrandResponse(products),
        },
        requests: {
          ...draftState.productsByBrand.requests,
          [brandId]: {
            ...draftState.productsByBrand[brandId],
            error: null,
            isFetching: false,
            isFirstRequestDone: true,
          },
        },
      };
    },

    [thunks.fetchSimilarProductsByProductId.pending]: (draftState, { meta: { arg: productId } }) => {
      draftState.similarProductsByProductId = {
        ...draftState.similarProductsByProductId,
        [productId]: {
          ids: [],
          isRequestDone: false,
          ...draftState.similarProductsByProductId[productId],
          error: null,
          isFetching: true,
        },
      };
    },

    [thunks.fetchSimilarProductsByProductId.rejected]: (draftState, { error, meta: { arg: productId } }) => {
      draftState.similarProductsByProductId = {
        ...draftState.similarProductsByProductId,
        [productId]: {
          ...draftState.similarProductsByProductId[productId],
          error: error.message,
          isFetching: true,
          isRequestDone: true,
        },
      };
    },

    [thunks.fetchSimilarProductsByProductId.fulfilled]: (
      draftState,
      { payload: { meta, products, productIds }, meta: { arg: productId } },
    ) => {
      draftState.similarProductsByProductId = {
        ...draftState.similarProductsByProductId,
        [productId]: {
          ...draftState.similarProductsByProductId[productId],
          ids: productIds,
          isFetching: false,
          isRequestDone: true,
        },
      };

      draftState.entity.entities.meta = {
        ...draftState.entity.entities.meta,
        ...meta,
      };
      draftState.entity.entities.products = {
        ...draftState.entity.entities.products,
        ...productIds.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...products[id],
              isShortVersion: true,
            },
          }),
          {},
        ),
      };
    },

    [thunks.fetchRelatedProductsByProductId.pending]: (draftState, { meta: { arg: productId } }) => {
      draftState.relatedProductsByProductId = {
        ...draftState.relatedProductsByProductId,
        [productId]: {
          ids: [],
          isRequestDone: false,
          ...draftState.relatedProductsByProductId[productId],
          error: null,
          isFetching: true,
        },
      };
    },

    [thunks.fetchRelatedProductsByProductId.rejected]: (draftState, { error, meta: { arg: productId } }) => {
      draftState.relatedProductsByProductId = {
        ...draftState.relatedProductsByProductId,
        [productId]: {
          ...draftState.relatedProductsByProductId[productId],
          error: error.message,
          isFetching: true,
          isRequestDone: true,
        },
      };
    },

    [thunks.fetchRelatedProductsByProductId.fulfilled]: (
      draftState,
      { payload: { meta, products, productIds }, meta: { arg: productId } },
    ) => {
      draftState.relatedProductsByProductId = {
        ...draftState.relatedProductsByProductId,
        [productId]: {
          ...draftState.relatedProductsByProductId[productId],
          ids: productIds,
          isFetching: false,
          isRequestDone: true,
        },
      };

      draftState.entity.entities.meta = {
        ...draftState.entity.entities.meta,
        ...meta,
      };
      draftState.entity.entities.products = {
        ...draftState.entity.entities.products,
        ...productIds.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...products[id],
              isShortVersion: true,
            },
          }),
          {},
        ),
      };
    },

    [thunks.fetchCategoryListing.pending]: (
      draftState,
      {
        meta: {
          arg: { categoryId, letter },
        },
      },
    ) => {
      const categoryListing = draftState.categoryListings[categoryId] ?? {};

      draftState.categoryListings = {
        ...draftState.categoryListings,
        [categoryId]: {
          ...categoryListing,
          [letter]: {
            products: [],
            isRequestDone: false,
            ...categoryListing[letter],
            error: null,
            isFetching: true,
          },
        },
      };
    },

    [thunks.fetchCategoryListing.rejected]: (
      draftState,
      {
        error,
        meta: {
          arg: { categoryId, letter },
        },
      },
    ) => {
      const categoryListing = draftState.categoryListings[categoryId] ?? {};

      draftState.categoryListings = {
        ...draftState.categoryListings,
        [categoryId]: {
          ...categoryListing,
          [letter]: {
            ...categoryListing[letter],
            error: error.message,
            isFetching: false,
            isRequestDone: true,
          },
        },
      };
    },

    [thunks.fetchCategoryListing.fulfilled]: (
      draftState,
      {
        payload: products,
        meta: {
          arg: { categoryId, letter },
        },
      },
    ) => {
      const categoryListing = draftState.categoryListings[categoryId] ?? {};

      draftState.categoryListings = {
        ...draftState.categoryListings,
        [categoryId]: {
          ...categoryListing,
          [letter]: {
            ...categoryListing[letter],
            products,
            error: null,
            isFetching: false,
            isRequestDone: true,
          },
        },
      };
    },
  },
});

const reducer = createPersistReducer(
  {
    key: 'products',
    whitelist: ['navigationCategoryId'],
  },
  defaultReducer,
);

export { selectors, reducer, actions, thunks, helpers };
