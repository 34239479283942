import axios from 'axios';

class BuildAPI {
  constructor() {
    this.currentHTMLString = null;

    this.axios = axios.create({
      baseURL: '',
    });
  }

  async isUpdatedBuild() {
    try {
      const { data: htmlString } = await this.axios.get('/index.html');

      const isUpdated = htmlString !== this.currentHTMLString && this.currentHTMLString !== null;

      this.currentHTMLString = htmlString;

      return isUpdated;
    } catch {
      return false;
    }
  }
}

export default new BuildAPI();
