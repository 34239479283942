import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as ImagePlaceholderSVG } from 'static/svg/image-placeholder.svg';

import useIsImageLoaded from './hooks/use-is-image-loaded';

import styles from './styles.module.scss';

function ProductImage({ src, productBrand, productName, className }) {
  const { isLoaded, isLoading, onLoad, onError } = useIsImageLoaded(src);

  const classNamesImage = classnames(className, styles.image, { [styles.loading]: isLoading });
  const classNamesPlaceholder = classnames(className, styles.placeholder);

  const alt = `What's your SkinGuide match score for ${productBrand} ${productName}?`;

  const isEmptyImage = !src;
  const shouldRenderImage = !isEmptyImage && (isLoaded || isLoading);
  const shouldRenderPlaceholder = !isLoaded || isLoading;

  return (
    <>
      {shouldRenderImage && (
        <img src={src} className={classNamesImage} onLoad={onLoad} onError={onError} alt={alt} />
      )}

      {shouldRenderPlaceholder && <ImagePlaceholderSVG className={classNamesPlaceholder} />}
    </>
  );
}

ProductImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  productName: PropTypes.string.isRequired,
  productBrand: PropTypes.string.isRequired,
};

ProductImage.defaultProps = {
  className: null,
};

export default ProductImage;
