import { useState, useMemo } from 'react';

import { REQUEST_STATUSES } from 'constants/request';

import {
  getErrorMessage,
  isRequestStatusIdle,
  isRequestStatusFailed,
  isRequestStatusFetched,
  isRequestStatusFetching,
} from 'helpers/request';

export const useRequestState = () => {
  const [state, setState] = useState({
    state: REQUEST_STATUSES.IDLE,
    error: null,
  });

  const actions = useMemo(
    () => ({
      setToIdle: () => setState(() => ({ error: null, state: REQUEST_STATUSES.IDLE })),
      setToFailed: (error) => setState(() => ({ error: getErrorMessage(error), state: REQUEST_STATUSES.FAILED })),
      setToFetched: () => setState(() => ({ error: null, state: REQUEST_STATUSES.FETCHED })),
      setToFetching: () => setState(() => ({ error: null, state: REQUEST_STATUSES.FETCHING })),
    }),
    [setState],
  );

  const helpers = useMemo(
    () => ({
      isStateIdle: () => isRequestStatusIdle(state.state),
      isStateFailed: () => isRequestStatusFailed(state.state),
      isStateFetched: () => isRequestStatusFetched(state.state),
      isStateFetching: () => isRequestStatusFetching(state.state),
    }),
    [state],
  );

  return [state, actions, helpers];
};
