import { useEffect } from 'react';
import LogRocket from 'logrocket';

import { CriticalErrorType, ImageCaptureState, STARTING_CAMERA_TEXTS } from 'constants/video-capture';

export const useVideoRecorderInitializeEffect = ({
  stream,
  videoRecorderState,
  videoRecorderActions,
  videoRecorderHelpers,
  imageCaptureState,
  imageCaptureActions,
}) => {
  useEffect(() => {
    if (imageCaptureState.state !== ImageCaptureState.InitializingVideoRecorder) {
      return;
    }
    if (videoRecorderHelpers.isStateIdle()) {
      imageCaptureActions.setText(STARTING_CAMERA_TEXTS.INITIALIZING_VIDEO_RECORDER);
      videoRecorderActions.initialize(stream);
      return;
    }
    if (videoRecorderHelpers.isStateInitialized()) {
      imageCaptureActions.setText(STARTING_CAMERA_TEXTS.WAITING_VIDEO);
      imageCaptureActions.resetCriticalError(CriticalErrorType.CameraAccess);
      imageCaptureActions.setImageCaptureState(ImageCaptureState.Capturing);
      return;
    }
    if (videoRecorderHelpers.isStateInitializeFailed()) {
      imageCaptureActions.setCriticalError(CriticalErrorType.VideoRecorder);
      LogRocket.error('video-capture-video-recorder-initializing', videoRecorderState.error);
    }
  }, [imageCaptureState.state, videoRecorderState]);
};
