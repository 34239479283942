import React, { useRef, memo, useImperativeHandle, forwardRef } from 'react';
import classnames from 'classnames';

import { ReactComponent as CheckSvg } from 'static/images/video-capture/arrow-success.svg';

import { STROKE_WIDTH, WIDTH, HEIGHT, SVG_WIDTH, SVG_HEIGHT, HALF_SVG_WIDTH, HALF_SVG_HEIGHT } from './constants';

import useSuccessCircleApi from './hooks/use-success-circle-api';

import styles from './styles.module.scss';

const SuccessCircleView = forwardRef(
  ({ x, y, width = SVG_WIDTH, className, checkColor = '#3DC8AF', height = SVG_HEIGHT, isShowing = false }, ref) => {
    const svgRef = useRef(null);
    const successCircleApi = useSuccessCircleApi({ svgRef });
    useImperativeHandle(ref, () => successCircleApi, []);
    return (
      <svg
        x={x}
        y={y}
        ref={svgRef}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        style={{ opacity: isShowing ? 1 : 0 }}
        height={height}
        viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
        className={classnames(styles.wrapper, className)}
      >
        <rect
          rx={HEIGHT / 2}
          x={STROKE_WIDTH}
          y={STROKE_WIDTH}
          width={WIDTH}
          height={HEIGHT}
          fill="white"
          stroke={checkColor}
          strokeWidth={STROKE_WIDTH}
        />

        <CheckSvg x={HALF_SVG_WIDTH - 30 / 2} y={HALF_SVG_HEIGHT - 23 / 2} width="30" height="23" color="#3DC8AF" />
      </svg>
    );
  },
);
export const SuccessCircle = memo(SuccessCircleView);
