import React, { useCallback, useRef, useMemo } from 'react';

import { CriticalErrorType, ImageCaptureState, IDEAL_RESOLUTION } from 'constants/video-capture';

import { useStream } from 'hooks/video-capture/use-stream';
import Button from 'components/button';

import useFaceDetector from './hooks/use-face-detector';
import useBoxTextProps from './hooks/use-box-text-props';
import useImageCaptureState from './hooks/use-image-capture-state';
import useCameraAccessEffect from './hooks/use-camera-access-effect';
import useFaceDetectorLoadingEffect from './hooks/use-face-detector-loading-effect';
import { useVideoRecorder } from './hooks/use-video-recorder';
import { useVideoRecorderInitializeEffect } from './hooks/use-video-recorder-initialize-effect';
import CaptureVideo from './components/capture-video';
import { FaceState } from './constants';

import styles from './styles.module.scss';

export function VideoCapture({ onVideoCaptured }) {
  const [imageCaptureState, imageCaptureActions] = useImageCaptureState();
  const videoRef = useRef(null);
  const [streamState, streamActions, streamHelpers] = useStream({
    videoRef,
    streamWidth: IDEAL_RESOLUTION.VIDEO_CAPTURE.WIDTH,
    streamHeight: IDEAL_RESOLUTION.VIDEO_CAPTURE.HEIGHT,
    streamAspectRatio: IDEAL_RESOLUTION.ASPECT_RATIO,
  });
  const [detectorState, detectorActions, detectorHelpers] = useFaceDetector({ videoRef });
  const [videoRecorderState, videoRecorderActions, videoRecorderHelpers] = useVideoRecorder();
  useCameraAccessEffect({
    streamState,
    streamActions,
    streamHelpers,
    imageCaptureState,
    imageCaptureActions,
  });
  useFaceDetectorLoadingEffect({
    detectorState,
    detectorActions,
    detectorHelpers,
    imageCaptureState,
    imageCaptureActions,
  });
  useVideoRecorderInitializeEffect({
    stream: streamState.stream,
    videoRecorderState,
    videoRecorderActions,
    videoRecorderHelpers,
    imageCaptureState,
    imageCaptureActions,
  });

  const { onVideoReady } = useMemo(
    () => ({
      onVideoReady: () => imageCaptureActions.setIsVideoReady(true),
    }),
    [],
  );
  const shouldShowVideoContent =
    imageCaptureState.state === ImageCaptureState.Capturing || imageCaptureState.state === ImageCaptureState.Captured;
  const firstCriticalError = imageCaptureState.criticalErrors[0];
  const isFaceStateError =
    imageCaptureState.faceState !== null &&
    imageCaptureState.faceState !== FaceState.Detected &&
    imageCaptureState.state === ImageCaptureState.Capturing;
  const { text, isError } = useBoxTextProps({
    text: imageCaptureState.text,
    faceState: imageCaptureState.faceState,
    criticalError: firstCriticalError,
    isFaceStateError,
  });
  const handleVideoCaptured = useCallback(
    (props) => {
      onVideoCaptured(props);
    },
    [onVideoCaptured],
  );

  return (
    <CaptureVideo
      ref={videoRef}
      isError={isError}
      detectFace={detectorActions.detect}
      className={styles.video}
      setFaceState={imageCaptureActions.setFaceState}
      isVideoReady={shouldShowVideoContent}
      videoRecorder={videoRecorderState.recorder}
      onVideoReady={onVideoReady}
      isCriticalError={!!firstCriticalError}
      onVideoCaptured={handleVideoCaptured}
      imageCaptureState={imageCaptureState.state}
    >
      {text && (
        <p>
          {text}

          {firstCriticalError === CriticalErrorType.CameraAccess && (
            <>
              <br />

              <Button
                as="button"
                onClick={(event) => {
                  event.preventDefault();
                  streamActions.createStream();
                }}
                className={styles.errorLink}
              >
                ALLOW ACCESS
              </Button>
            </>
          )}
        </p>
      )}
    </CaptureVideo>
  );
}
