import { put, call } from 'redux-saga/effects';

export default function* makeRequest(
  { callToAPI, transformForRequest, transformFromResponse },
  { type, payload },
) {
  try {
    const dataForRequest = transformForRequest ? transformForRequest(payload) : payload;

    const { data } = yield call(callToAPI, dataForRequest);

    const transformedData = transformFromResponse ? transformFromResponse(data) : data;

    yield put({
      type: `${type}Success`,
      payload: transformedData,
      meta: payload,
    });

    return transformedData;
  } catch (error) {
    console.error(error);

    yield put({
      type: `${type}Fail`,
      error: error.message,
      meta: payload,
    });

    return null;
  }
}
