import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Title from 'components/title';
import Button from 'components/button';
import { SignUp } from 'components/forms';

import { ReactComponent as CloseSVG } from 'static/svg/close.svg';

import styles from './styles.module.scss';

function Content({ isVersionAnonymous, updateProfile, closeModal }) {
  const [responseError, setResponseError] = useState(null);

  const onSubmitHandler = async (data) => {
    const { error, payload } = await updateProfile(data);
    const { message, errors } = payload ?? {};

    if (error) {
      setResponseError({ message, errors });
    }
  };

  const titleText = isVersionAnonymous ? 'Save your results' : 'Create your account';
  const descriptionText = isVersionAnonymous
    ? 'Keep access to your matches and instantly score any products for your needs.'
    : 'Enter your email address and name to save your results for access at a later date.';
  const buttonText = isVersionAnonymous ? 'Save' : 'Create';

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal} role="button" onClick={(event) => event.stopPropagation()}>
        <div onClick={closeModal} role="button" tabIndex={0} className={styles.close}>
          <CloseSVG className={styles.closeIcon} />
        </div>

        <Title className={styles.title}>{titleText}</Title>

        <p className={styles.description}>{descriptionText}</p>

        <SignUp
          onSubmit={onSubmitHandler}
          responseError={responseError}
          renderSubmitButton={({ disabled }) => (
            <Button
              type="submit"
              disabled={disabled}
              variant="primary"
              className="px-5 signup-button-cta"
            >
              {buttonText}
            </Button>
          )}
        />
      </div>
    </div>
  );
}

Content.propTypes = {
  closeModal: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  isVersionAnonymous: PropTypes.bool.isRequired,
};

export default Content;
