import { useMemo, useState } from 'react';
import RecordRTC, { MediaStreamRecorder } from 'recordrtc';

export const VideoRecorderState = {
  IDLE: 'idle',
  INITIALIZED: 'initialized',
  INITIALIZE_FAILED: 'initialize-failed',
};

const DEFAULT_STATE = {
  error: null,
  state: VideoRecorderState.IDLE,
  recorder: null,
};

export const useVideoRecorder = () => {
  const [state, setState] = useState(DEFAULT_STATE);
  const actions = useMemo(
    () => ({
      initialize: (stream) => {
        try {
          setState((prevState) => ({ ...prevState, error: null }));
          const recorder = new RecordRTC(stream, {
            type: 'video',
            mimeType: 'video/webm;codecs=vp8',
            recorderType: MediaStreamRecorder,
          });
          setState((prevState) => ({
            ...prevState,
            state: VideoRecorderState.INITIALIZED,
            recorder,
          }));
        } catch (error) {
          setState((prevState) => ({
            ...prevState,
            error,
            state: VideoRecorderState.INITIALIZE_FAILED,
          }));
        }
      },
    }),
    [],
  );
  const helpers = useMemo(
    () => ({
      isStateIdle: () => state.state === VideoRecorderState.IDLE,
      isStateInitialized: () => state.state === VideoRecorderState.INITIALIZED,
      isStateInitializeFailed: () => state.state === VideoRecorderState.INITIALIZE_FAILED,
    }),
    [state.state],
  );
  return [state, actions, helpers];
};
