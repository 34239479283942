export const getBoundingRectFromPoints = (points, scale = 1) => {
  const { length } = points;
  let i = 0;
  let minX = points[0][0];
  let minY = points[0][1];
  let maxX = points[0][0];
  let maxY = points[0][1];
  for (; i < length; i += 1) {
    const [x, y] = points[i];
    if (minX > x) {
      minX = x;
    }
    if (maxX < x) {
      maxX = x;
    }
    if (minY > y) {
      minY = y;
    }
    if (maxY < y) {
      maxY = y;
    }
  }
  minX *= scale;
  minY *= scale;
  maxX *= scale;
  maxY *= scale;
  const width = maxX - minX;
  const height = maxY - minY;
  const aspectRatio = width / height;
  return {
    x: minX,
    y: minY,
    width,
    height,
    top: minY,
    left: minX,
    right: maxX,
    bottom: maxY,
    aspectRatio,
  };
};
export const scaleBoundingRect = (boundingRect, scale) =>
  Object.keys(boundingRect).reduce(
    (acc, key) => {
      if (key === 'aspectRatio') {
        return acc;
      }
      return { ...acc, [key]: boundingRect[key] * scale };
    },
    { ...boundingRect },
  );
export const addOffsetsToBoundingRect = (boundingRect, offsets) => {
  const topOffset = boundingRect.height * (offsets.top ?? 0);
  const leftOffset = boundingRect.width * (offsets.left ?? 0);
  const rightOffset = boundingRect.width * (offsets.right ?? 0);
  const bottomOffset = boundingRect.height * (offsets.bottom ?? 0);
  const top = boundingRect.top - topOffset;
  const left = boundingRect.left - leftOffset;
  const right = boundingRect.right + rightOffset;
  const bottom = boundingRect.bottom + bottomOffset;
  const width = right - left;
  const height = bottom - top;
  const aspectRatio = width / height;
  return {
    x: left,
    y: top,
    width,
    height,
    top,
    left,
    right,
    bottom,
    aspectRatio,
  };
};
