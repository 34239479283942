export function isValidEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}

export function removeItemFromArray(arr, itemToRemove) {
  return arr.filter((item) => item !== itemToRemove);
}

export function fillObjectFromObjectValues(object, data) {
  return Object.values(object).reduce(
    (acc, type) => ({
      ...acc,
      [type]: data,
    }),
    {},
  );
}

export const uniq = (arr) => Array.from(new Set(arr));
