import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

function Text({ name, value, placeholder, onChange, className, ...otherProps }) {
  const classNames = classnames(styles.input, className);

  const onChangeHandler = useCallback(
    ({ target: { value: newValue } }) =>
      onChange({
        name,
        value: newValue,
      }),
    [name, onChange],
  );

  return (
    <input
      {...otherProps}
      value={value}
      onChange={onChangeHandler}
      className={classNames}
      placeholder={placeholder}
    />
  );
}

Text.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
};

Text.defaultProps = {
  value: '',
  className: null,
};

export default Text;
