import React, { useRef, useState, useEffect } from 'react';

import Title from 'components/title';

import { ReactComponent as ProjectSVG } from 'static/svg/project-management.svg';

import styles from './styles.module.scss';

function Content() {
  const [countdown, setCountdown] = useState(7);
  const cacheRef = useRef();

  cacheRef.current = countdown;

  useEffect(() => {
    let timeoutId = null;

    const startCountdown = () => {
      timeoutId = setTimeout(() => {
        if (cacheRef.current === 0) {
          return;
        }

        setCountdown((prevState) => prevState - 1);
        startCountdown();
      }, 1000);
    };

    startCountdown();

    return () => clearTimeout(timeoutId);
  }, [setCountdown]);

  useEffect(() => {
    if (countdown === 0) {
      window.location.reload();
    }
  }, [countdown]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <ProjectSVG className={styles.icon} />

        <Title size="lg" className={styles.title}>
          Our site has been updated!
        </Title>

        <p className={styles.description}>
          The page will be reloaded in {countdown}s. We improve our site and it can&lsquo;t work
          without those updates.
        </p>
      </div>
    </div>
  );
}

export default Content;
