import { createSlice } from '@reduxjs/toolkit';

import createPersistReducer from 'store/helpers/create-persist-reducer';

import { actions as productListsActions, constants as productListsConstants } from 'store/modules/product-lists';
import { logout } from 'store/modules/user/actions';

import * as selectors from './selectors';

const { PRODUCT_LISTS } = productListsConstants;

const DEFAULT_STATE = {
  numberOfProductsByProductId: {},
};

const { reducer: defaultReducer, actions } = createSlice({
  name: 'cart',
  initialState: DEFAULT_STATE,
  reducers: {
    changeNumberOfProducts: (draftState, { payload: { productId, numberOfProducts } }) => {
      draftState.numberOfProductsByProductId[productId] = numberOfProducts;
    },
  },
  extraReducers: {
    [productListsActions.removeProductFromUserListSuccess]: (draftState, { meta: { productId, listName } }) => {
      if (listName === PRODUCT_LISTS.CART) {
        delete draftState.numberOfProductsByProductId[productId];
      }
    },

    [logout.toString()]: () => DEFAULT_STATE,
  },
});

const reducer = createPersistReducer(
  {
    key: 'cart',
    whitelist: ['numberOfProductsByProductId'],
  },
  defaultReducer,
);

export { selectors, reducer, actions };
