import React from 'react';
import classnames from 'classnames';

import { ReactComponent as WindowSVG } from 'static/images/video-capture/window.svg';
import { ReactComponent as BathroomSVG } from 'static/images/video-capture/bathroom.svg';

import styles from './styles.module.scss';

export function LightningPlaces({ className }) {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <div className={styles.item}>
        <WindowSVG />

        <p>In front of a window</p>
      </div>

      <div className={styles.item}>
        <BathroomSVG />

        <p>In front of bathroom vanity</p>
      </div>
    </div>
  );
}
