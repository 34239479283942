import { createRef } from 'react';

import { useCreateConst } from 'hooks/video-capture/use-create-const';

const useLinesRefs = () =>
  useCreateConst(() => ({
    range: createRef(),
    curve: createRef(),
    activeRange: createRef(),
  }));
export default useLinesRefs;
