export const DEFAULT_STATE = {
  searchValue: '',

  entities: {
    productIds: [],
  },

  viewedSearchesIds: [],

  anonymousSearch: {
    term: null,
    barcode: null,
  },

  error: null,
  isFetching: false,
  isFirstRequestDone: false,
};
