import React, { memo, forwardRef, useImperativeHandle } from 'react';
import classnames from 'classnames';

import { getStrokeWidth, getDForPartOfCircle, getPathDForVerticalLine } from './helpers';
import useLinesRefs from './hooks/use-lines-refs';
import useRangesApi from './hooks/use-ranges-api';
import { VIEW_BOX_SIZE } from './constants';

import styles from './styles.module.scss';

const Ranges = forwardRef(({ x, y, range, width, height, ranges, className }, ref) => {
  const refs = useLinesRefs();
  const rangesApi = useRangesApi({ range, ranges, refs });
  useImperativeHandle(ref, () => rangesApi, []);

  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${VIEW_BOX_SIZE} ${VIEW_BOX_SIZE}`}
      className={classnames(className)}
    >
      <path
        d={getDForPartOfCircle(ranges[0].uiFrom, ranges[0].uiTo)}
        ref={refs.range}
        className={classnames(styles.range)}
        strokeLinecap="round"
        style={{ opacity: 0 }}
      />

      {/* <path
        d={getPathDForPartOfCircle(-range / 2, range / 2)}
        ref={refs.activeRange}
        fill="rgba(255, 255, 255, 0.2)"
        fillRule="evenodd"
        strokeLinecap="round"
      /> */}

      <path
        d={getPathDForVerticalLine(0)}
        ref={refs.curve}
        fill="transparent"
        stroke="rgba(0, 130, 113, 1)"
        strokeLinecap="round"
        strokeWidth={getStrokeWidth(0, 8)}
      />
    </svg>
  );
});
export default memo(Ranges);
