import { ROUTES } from 'router/constants';

export const LOGIN_PATHS = [ROUTES.LOGIN, ROUTES.LOGIN_VERIFY];

export const OLD_SEARCH_PATHS = [ROUTES.SEARCH, ROUTES.SEARCH_NOT_FOUND];
export const SEARCH_PATHS = [
  ROUTES.SEARCH_PRODUCTS,
  ROUTES.SEARCH_PRODUCTS_RESULTS,
  ROUTES.SEARCH_PRODUCTS_BARCODE,
  ROUTES.SEARCH_PRODUCTS_SCAN_BARCODE,
];

export const BLOG_PATHS = [ROUTES.ARTICLES, ROUTES.ARTICLES_ARTICLE];

export const NAVIGATION_PATHS = [
  ROUTES.DASHBOARD,

  ROUTES.PRODUCT_CATEGORIES,

  ROUTES.CART,
  ROUTES.COMPARE,
  ROUTES.FAVORITES,

  ROUTES.CATEGORY_LISTING,

  ROUTES.BRAND,
  ROUTES.BRANDS,

  ROUTES.SEARCH_HISTORY,

  ...BLOG_PATHS,
  ...SEARCH_PATHS,
];

export const HEADER_PATHS = [
  ROUTES.QUIZ,

  ROUTES.DASHBOARD,

  ROUTES.CART,
  ROUTES.COMPARE,
  ROUTES.FAVORITES,

  ROUTES.PRODUCT,
  ROUTES.PICKING_UP_PRODUCTS,

  ROUTES.ABOUT_US,
  ROUTES.PRIVACY_POLICY,
  ROUTES.TERMS_OF_SERVICE,

  ROUTES.BRAND,
  ROUTES.BRANDS,

  ROUTES.SEARCH_HISTORY,
  ROUTES.CATEGORY_LISTING,

  ...BLOG_PATHS,
  ...LOGIN_PATHS,
  ...SEARCH_PATHS,
];

export const FOOTER_PATHS = [
  ROUTES.LOGIN,
  ROUTES.LOGIN_VERIFY,

  ROUTES.BRAND,
  ROUTES.BRANDS,

  ROUTES.ABOUT_US,

  ROUTES.FAVORITES,

  ROUTES.PRODUCT,
  ROUTES.PRODUCT_CATEGORIES,

  ROUTES.DASHBOARD,

  ROUTES.CATEGORY_LISTING,

  ROUTES.SEARCH_HISTORY,

  ...BLOG_PATHS,
  ...LOGIN_PATHS,
];
