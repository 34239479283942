import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

function RecentSearches({ searches, onSearchTextClick, className }) {
  return (
    <ul className={classnames(styles.list, className)}>
      {searches.map((text, index) => (
        <li key={index} onClick={() => onSearchTextClick(text)} className={styles.listItem}>
          {text}
        </li>
      ))}
    </ul>
  );
}

RecentSearches.propTypes = {
  searches: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  onSearchTextClick: PropTypes.func.isRequired,
};

RecentSearches.defaultProps = {
  className: null,
};

export default RecentSearches;
