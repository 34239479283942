import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { thunks as userThunks } from 'store/modules/user';
import { selectors as appSelectors } from 'store/modules/app';

import SignUpNotification from './components/root';

const mapStateToProps = (state) => ({
  isVersionAnonymous: appSelectors.getIsVersionAnonymous(state),
});

const mapDispatchToProps = {
  updateProfile: userThunks.updateProfile,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SignUpNotification);
