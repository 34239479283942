export const QUESTION_CONDITIONS = {
  'age-restriction': ({ answers }) => answers.age <= 15,
  'monthly-cycle': ({ answers }) => answers.gender === 'female' || answers.gender === 'non-binary',
  'cycle-skin-effect-a': ({ answers }) => answers['monthly-cycle'] === 'yes',
  'cycle-skin-effect-b': ({ answers }) => answers['cycle-skin-effect-a'] === 'yes',
  'treatment-texture': ({ answers }) => answers['product-types'].includes('treatment'),
  'moisturizer-texture': ({ answers }) => answers['product-types'].includes('moisturizer'),
  'spf-moisturizer': ({ answers }) => answers['product-types'].includes('moisturizer'),
  'cleanser-texture': ({ answers }) => answers['product-types'].includes('cleanser'),
  'makeup-remover': ({ answers }) => answers['product-types'].includes('cleanser'),
  'cleanser-preference': ({ answers }) => answers['product-types'].includes('cleanser'),
  acne: ({ answers }) => answers['skin-milieu-a'] === 'disagree' || answers['skin-milieu-a'] === 'strongly-disagree',
  'save-retaken-quiz': ({ isAuthenticated }) => isAuthenticated,
  email: ({ isAuthenticated }) => !isAuthenticated,
};

export const QUESTIONS_ORDER = [
  'application-time',
  'age',
  'age-restriction',
  'ethnicity',
  'gender',
  'top-benefits',
  'regimen',
  'lightning-instructions',
  'video-capture-demo',
  'video-capture',
  'skin-section',
  'skin-type',
  'sensitive-skin',
  'skin-hydration-b',
  'shine-control-a',
  'skin-tone-a',
  'under-eye-circles',
  'skin-smoothness-a',
  'skin-milieu-b',
  'oxygen-saturation-a',
  'dermal-fibers-a',
  'skin-hydration-a',
  'shine-control-b',
  'skin-tone-b',
  'skin-smoothness-b',
  'skin-milieu-a',
  'acne',
  'oxygen-saturation-b',
  'dermal-fibers-b',
  'monthly-cycle',
  'cycle-skin-effect-a',
  'cycle-skin-effect-b',
  'skin-affected-by',
  'sun-reaction',
  'uv-exposure-per-day',
  'preferences-section',
  'price',
  'ingredient-preference',
  'product-types',
  'cleanser-texture',
  'makeup-remover',
  'cleanser-preference',
  'saving-revea-quiz',
  'formulation-skin-report',
  'save-retaken-quiz',
  'email',
];

export const FIRST_QUESTION_ID = 'application-time';
export const LAST_QUESTION_ID = 'email';
export const LAST_QUESTION_ID_FOR_SKIN_EMAIL_VERSION = 'finish';

export const QUESTIONS = {
  'application-time': {
    id: 'application-time',
    sectionType: 'person',
    type: 'question',
    sectionTitle: 'Tell us about you',
    title: 'When do you typically apply your skincare products?',
    options: [
      {
        id: 'day',
        title: 'Day',
      },
      {
        id: 'night',
        title: 'Night',
      },
      {
        id: 'both',
        title: 'Both',
      },
    ],
  },
  age: {
    id: 'age',
    sectionType: 'person',
    type: 'carousel',
    sectionTitle: 'Tell us about you',
    title: 'What is  your age?',
    defaultValue: 50,
  },
  'age-restriction': {
    id: 'age-restriction',
    isFinish: true,
    sectionType: 'finish',
    type: 'thank-you',
  },
  ethnicity: {
    id: 'ethnicity',
    sectionType: 'person',
    type: 'question',
    sectionTitle: 'Tell us about you',
    title: 'What is your ethnicity?',
    options: [
      {
        id: 'american-indian',
        title: 'American Indian',
      },
      {
        id: 'asian',
        title: 'Asian',
      },
      {
        id: 'african-american',
        title: 'Black or African American',
      },
      {
        id: 'hispanic',
        title: 'Hispanic',
      },
      {
        id: 'middle-eastern',
        title: 'Middle Eastern',
      },
      {
        id: 'multi-ethnic',
        title: 'Multi-Ethnic',
      },
      {
        id: 'south-asian',
        title: 'South Asian',
      },
      {
        id: 'caucasian',
        title: 'White / Caucasian',
      },
      {
        id: 'other',
        title: 'Other',
      },
    ],
  },
  gender: {
    id: 'gender',
    sectionType: 'person',
    type: 'question',
    sectionTitle: 'Tell us about you',
    title: 'What is your gender?',
    options: [
      {
        id: 'female',
        title: 'Female',
      },
      {
        id: 'male',
        title: 'Male',
      },
      {
        id: 'non-binary',
        title: 'Non binary',
      },
    ],
  },
  'top-benefits': {
    id: 'top-benefits',
    sectionType: 'person',
    multiple: true,
    type: 'question',
    sectionTitle: 'Tell us about you',
    title: 'What benefits are most important to you?',
    hint: 'Select up to 3',
    limit: 3,
    options: [
      {
        id: 'increase-glow',
        title: 'Increase glow and radiance',
        inline: 'increase your glow and radiance',
      },
      {
        id: 'improve-tone',
        title: 'Even skin tone',
        inline: 'improve the evenness of your skin tone',
      },
      {
        id: 'repair-skin',
        title: 'Rejuvenate & repair',
        inline: 'rejuvenate and repair your skin',
      },
      {
        id: 'reduce-wrinkles',
        title: 'Reduce lines & wrinkles',
        inline: 'reduce lines and wrinkles',
      },
      {
        id: 'control-shine',
        title: 'Control oil & shine',
        inline: 'control oil and shine',
      },
      {
        id: 'control-acne',
        title: 'Control acne & blemishes',
        inline: 'reduce acne and breakouts',
      },
      {
        id: 'hydrate-skin',
        title: 'Increase hydration',
        inline: "improve your skin's hydration",
      },
    ],
  },
  regimen: {
    id: 'regimen',
    sectionType: 'person',
    multiple: true,
    type: 'question',
    sectionTitle: 'Tell us about you',
    title: 'What products are in your skincare regimen?',
    hint: 'Select all that apply',
    options: [
      {
        id: 'makeup-remover',
        title: 'Make up remover',
      },
      {
        id: 'cleanser',
        title: 'Cleanser',
      },
      {
        id: 'toner',
        title: 'Toner',
      },
      {
        id: 'treatment',
        title: 'Treatment / serum',
      },
      {
        id: 'moisturizer',
        title: 'Moisturizer / hydrator',
      },
      {
        id: 'eye-treatment',
        title: 'Eye treatment',
      },
      {
        id: 'mask',
        title: 'Masks',
      },
      {
        id: 'rx',
        title: 'Rx',
      },
    ],
  },
  'skin-section': {
    id: 'skin-section',
    sectionType: 'break',
    type: 'break',
    isBlocker: true,
    breakTitle: 'Tell us about your skin',
  },
  'skin-type': {
    id: 'skin-type',
    sectionType: 'skin',
    type: 'question',
    sectionTitle: 'Tell us about your skin',
    title: 'I would describe my skin type as...',
    options: [
      {
        id: 'oily',
        title: 'Oily',
      },
      {
        id: 'dry',
        title: 'Dry',
      },
      {
        id: 'normal',
        title: 'Normal',
      },
      {
        id: 'combination',
        title: 'Combination',
      },
      {
        id: 'it-changes',
        title: 'It changes',
      },
      {
        id: 'not-sure',
        title: 'Not sure ',
      },
    ],
  },
  'sensitive-skin': {
    id: 'sensitive-skin',
    sectionType: 'skin',
    binary: true,
    type: 'question',
    sectionTitle: 'Tell us about your skin',
    title: 'I have sensitive skin',
    options: [
      {
        id: 'yes',
        title: 'Yes',
      },
      {
        id: 'no',
        title: 'No',
      },
    ],
  },
  'skin-hydration-b': {
    id: 'skin-hydration-b',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'When I wake up my skin feels tight, irritated or dry.',
  },
  'shine-control-a': {
    id: 'shine-control-a',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'My skin rarely looks shiny.',
  },
  'skin-tone-a': {
    id: 'skin-tone-a',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'My skin tone is even and consistent.',
  },
  'under-eye-circles': {
    id: 'under-eye-circles',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'I frequently have dark under-eye circles.',
  },
  'skin-smoothness-a': {
    id: 'skin-smoothness-a',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'My skin feels smooth to the touch.',
  },
  'skin-milieu-b': {
    id: 'skin-milieu-b',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'I have unexplained breakouts or redness.',
  },
  'oxygen-saturation-a': {
    id: 'oxygen-saturation-a',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'My skin typically looks radiant and healthy.',
  },
  'dermal-fibers-a': {
    id: 'dermal-fibers-a',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'My skin feels firm, dense.',
  },
  'skin-hydration-a': {
    id: 'skin-hydration-a',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'When I wake up my skin feels hydrated and supple.',
  },
  'shine-control-b': {
    id: 'shine-control-b',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'When I wake up my skin feels oily.',
  },
  'skin-tone-b': {
    id: 'skin-tone-b',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'I frequently get sun spots, dark spots, or scarring.',
  },
  'skin-smoothness-b': {
    id: 'skin-smoothness-b',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'Without makeup I can easily see my pores.',
  },
  'skin-milieu-a': {
    id: 'skin-milieu-a',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'I rarely breakout or have skin reactions.',
  },
  acne: {
    id: 'acne',
    sectionType: 'skin',
    sectionTitle: 'Tell us about your skin',
    title: 'I have acne prone skin.',
    defaultValue: 0,
    type: 'slider',
  },
  'oxygen-saturation-b': {
    id: 'oxygen-saturation-b',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'My skin often looks dull or lacks glow.',
  },
  'dermal-fibers-b': {
    id: 'dermal-fibers-b',
    sectionType: 'skin',
    type: 'slider',
    defaultValue: 0,
    sectionTitle: 'Tell us about your skin',
    title: 'My skin is wrinkling or sagging.',
  },
  'monthly-cycle': {
    id: 'monthly-cycle',
    sectionType: 'skin',
    binary: true,
    type: 'question',
    sectionTitle: 'Tell us about your skin',
    title: 'Do you have a monthly menstrual cycle?',
    options: [
      {
        id: 'yes',
        title: 'Yes',
      },
      {
        id: 'no',
        title: 'No',
      },
    ],
  },
  'cycle-skin-effect-a': {
    id: 'cycle-skin-effect-a',
    sectionType: 'skin',
    binary: true,
    type: 'question',
    sectionTitle: 'Tell us about your skin',
    title: 'Does your monthly menstrual cycle affect your skin?',
    hint: 'Breakouts, sensitivity, inflammation, etc.',
    options: [
      {
        id: 'yes',
        title: 'Yes',
      },
      {
        id: 'no',
        title: 'No',
      },
    ],
  },
  'cycle-skin-effect-b': {
    id: 'cycle-skin-effect-b',
    sectionType: 'skin',
    multiple: true,
    type: 'question',
    sectionTitle: 'Tell us about your skin',
    title: 'In what ways does your cycle affect your skin?',
    hint: 'Select all that apply',
    options: [
      {
        id: 'dryness',
        title: 'Dryness',
      },
      {
        id: 'sensitivity',
        title: 'Sensitivity',
      },
      {
        id: 'breakouts',
        title: 'Breakouts',
      },
      {
        id: 'oiliness',
        title: 'Oiliness',
      },
      {
        id: 'inflammatory-hyperpigmentation',
        title: 'PIH (scarring, marks from breakouts)',
      },
      {
        id: 'inflammation-redness',
        title: 'Inflammation and redness',
      },
      {
        id: 'clogged-pores',
        title: 'Clogged pores',
      },
    ],
  },
  'skin-affected-by': {
    id: 'skin-affected-by',
    sectionType: 'skin',
    multiple: true,
    type: 'question',
    sectionTitle: 'Tell us about your skin',
    title: 'My skin is regularly affected by...',
    hint: 'Select all that apply',
    options: [
      {
        id: 'sleep',
        title: 'Sleep',
      },
      {
        id: 'diet',
        title: 'Diet',
      },
      {
        id: 'weather',
        title: 'Weather',
      },
      {
        id: 'stress',
        title: 'Stress',
      },
      {
        id: 'allergens',
        title: 'Allergens',
      },
      {
        id: 'none',
        title: 'None',
      },
    ],
  },
  'sun-reaction': {
    id: 'sun-reaction',
    sectionType: 'skin',
    type: 'question',
    sectionTitle: 'Tell us about your skin',
    title: 'In the sun I...',
    options: [
      {
        id: 'never-tan',
        title: 'Always burn / never tan',
      },
      {
        id: 'tan-minimally',
        title: 'Usually burn / tan minimally',
      },
      {
        id: 'tan-uniformly',
        title: 'Sometimes mild burn / tan uniformly',
      },
      {
        id: 'tan-well',
        title: 'Burn minimally / always tan well',
      },
      {
        id: 'tan-easily',
        title: 'Rarely burn / tan easily',
      },
      {
        id: 'never-burn',
        title: 'Never burn',
      },
    ],
  },
  'uv-exposure-per-day': {
    id: 'uv-exposure-per-day',
    sectionType: 'skin',
    sectionTitle: 'Tell us about your skin',
    type: 'question',
    title: 'How many hours do you spend in the sun without sunscreen each day?',
    options: [
      {
        id: '0-1hr',
        title: '0-1 hours',
      },
      {
        id: '1-3hr',
        title: '1-3 hours',
      },
      {
        id: '3-5hr',
        title: '3-5 hours',
      },
      {
        id: '5+hr',
        title: '5+ hours',
      },
    ],
  },
  'preferences-section': {
    id: 'preferences-section',
    sectionType: 'break',
    type: 'break',
    breakTitle: 'Tell us about your preferences',
  },
  price: {
    id: 'price',
    sectionType: 'preferences',
    multiple: true,
    type: 'question',
    sectionTitle: 'Tell us about your preferences',
    title: 'How would you classify most of your skincare products?',
    hint: 'Select all that apply',
    options: [
      {
        id: '$',
        title: '$',
      },
      {
        id: '$$',
        title: '$$',
      },
      {
        id: '$$$',
        title: '$$$',
      },
      {
        id: '$$$$',
        title: '$$$$',
      },
    ],
  },
  'ingredient-preference': {
    id: 'ingredient-preference',
    sectionType: 'preferences',
    multiple: true,
    type: 'question',
    sectionTitle: 'Tell us about your preferences',
    title: 'Do you have an ingredient restrictions?',
    hint: 'Select all that apply',
    options: [
      {
        id: 'vegan',
        title: 'Vegan',
      },
      {
        id: 'eu-approved',
        title: 'Adhere to EU guidelines',
      },
      {
        id: 'fragrance-free',
        title: 'Fragrance free',
      },
      {
        id: 'organic',
        title: 'Organic',
      },
      {
        id: 'gluten-free',
        title: 'Gluten free',
      },
      {
        id: 'cruelty-free',
        title: 'Cruelty free',
      },
      {
        id: 'no-preference',
        title: 'No preference',
      },
    ],
  },
  'product-types': {
    id: 'product-types',
    sectionType: 'preferences',
    multiple: true,
    type: 'question',
    sectionTitle: 'Tell us about your preferences',
    title: 'What type of products are you looking for?',
    hint: 'Select all that apply',
    options: [
      {
        id: 'treatment',
        title: 'Treatment / serum',
      },
      {
        id: 'moisturizer',
        title: 'Moisturizer / hydrator',
      },
    ],
  },
  'preferred-brand-product': {
    id: 'preferred-brand-product',
    sectionType: 'preferences',
    type: 'text',
    sectionTitle: 'Tell us about your preferences',
    title: 'Are there any products or brands that you love?',
  },
  'avoid-brand-product': {
    id: 'avoid-brand-product',
    sectionType: 'preferences',
    type: 'text',
    sectionTitle: 'Tell us about your preferences',
    title: 'Are there any products or brands that you hate?',
  },
  'cleanser-texture': {
    id: 'cleanser-texture',
    sectionType: 'preferences',
    multiple: true,
    type: 'question',
    sectionTitle: 'Tell us about your preferences',
    title: 'What cleanser textures do you like?',
    hint: 'Select all that apply',
    options: [
      {
        id: 'micellar',
        title: 'Micellar water',
      },
      {
        id: 'tonic',
        title: 'Tonic',
      },
      {
        id: 'foam',
        title: 'Foaming',
      },
      {
        id: 'gel',
        title: 'Gel',
      },
      {
        id: 'cream',
        title: 'Cream',
      },
      {
        id: 'lotion',
        title: 'Lotion',
      },
      {
        id: 'no-preference',
        title: 'No preference',
      },
    ],
  },
  'makeup-remover': {
    id: 'makeup-remover',
    sectionType: 'preferences',
    sectionTitle: 'Tell us about your preferences',
    title: 'Is it important that your cleanser removes make up?',
    type: 'question',
    options: [
      {
        id: 'yes',
        title: 'Yes',
      },
      {
        id: 'no',
        title: 'No',
      },
      {
        id: 'no-preference',
        title: 'No preference',
      },
    ],
  },
  'cleanser-preference': {
    id: 'cleanser-preference',
    sectionType: 'preferences',
    type: 'question',
    sectionTitle: 'Tell us about your preferences',
    title: 'Do you prefer cleansers that',
    options: [
      {
        id: 'rinse-off',
        title: 'Rinse off',
      },
      {
        id: 'towel-off',
        title: 'Tissue or towel off',
      },
      {
        id: 'no-preference',
        title: 'No preference',
      },
    ],
  },
  'lightning-instructions': {
    id: 'lightning-instructions',
    type: 'lightning-instructions',
    title: 'Find good lighting',
    hint: 'The best lighting can be found below',
    sectionType: 'person',
    sectionTitle: 'Scan',
  },
  'video-capture-demo': {
    id: 'video-capture-demo',
    type: 'video-capture-demo',
    sectionType: 'person',
    sectionTitle: 'Scan',
    title: 'Tutorial',
  },
  'video-capture': {
    id: 'video-capture',
    type: 'video-capture',
    sectionType: 'person',
    sectionTitle: 'Scan',
  },
  'saving-revea-quiz': {
    id: 'saving-revea-quiz',
    isFinish: true,
    sectionType: 'saving-revea-quiz',
    type: 'saving-revea-quiz',
  },
  'save-retaken-quiz': {
    id: 'save-retaken-quiz',
    isFinish: true,
    sectionType: 'save-retaken-quiz',
    type: 'save-retaken-quiz',
  },
  'formulation-skin-report': {
    id: 'formulation-skin-report',
    isFinish: true,
    sectionType: 'formulation-skin-report',
    type: 'formulation-skin-report',
  },
  email: {
    id: 'email',
    isFinish: true,
    sectionType: 'email',
    type: 'email',
  },
};
