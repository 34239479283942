export const createCanvasNode = ({ width, height }) => {
  const canvasNode = document.createElement('canvas');

  canvasNode.width = width;
  canvasNode.height = height;
  canvasNode.style.width = `${width}px`;
  canvasNode.style.heigh = `${height}px`;

  return canvasNode;
};

export const takePictureFromVideo = ({ videoNode, destinationImage = {} }) => {
  const { videoWidth: width, videoHeight: height } = videoNode;

  const canvasNode = createCanvasNode({
    width: destinationImage.width ?? width,
    height: destinationImage.height ?? height,
  });

  const context = canvasNode.getContext('2d');

  context.drawImage(
    videoNode,
    0,
    0,
    width,
    height,
    0,
    0,
    destinationImage.width ?? width,
    destinationImage.height ?? height,
  );

  const photo = canvasNode.toDataURL(`image/${destinationImage.format ?? 'png'}`, destinationImage.quality ?? 1.0);

  return photo;
};
