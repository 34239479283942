import { useEffect } from 'react';

const { documentElement } = document;

export default function useCSSNavigationHeight(isSmall) {
  useEffect(() => {
    if (isSmall) {
      documentElement.style.setProperty(
        '--navigation-height',
        'calc(40px + var(--navigation-safe-area-padding))',
      );

      return;
    }

    documentElement.style.setProperty(
      '--navigation-height',
      'calc(78px + var(--navigation-safe-area-padding))',
    );
  }, [isSmall]);
}
