import { useCreateConst } from 'hooks/video-capture/use-create-const';

const useSliderApi = ({ svgRef }) =>
  useCreateConst(() => ({
    toggleOpacity: (isShowing) => {
      if (!svgRef.current) {
        return;
      }
      svgRef.current.style.opacity = isShowing ? '1' : '0';
    },
  }));

export default useSliderApi;
