import { CRITICAL_ERROR_DATA } from 'constants/video-capture';
import { MESSAGES } from '../constants';

const useBoxTextProps = ({ text, faceState, criticalError, isFaceStateError }) => {
  if (criticalError) {
    return {
      isError: true,
      text: CRITICAL_ERROR_DATA[criticalError].text,
    };
  }
  if (isFaceStateError && !!faceState) {
    return {
      text: MESSAGES[faceState]?.description ?? '',
      isError: true,
    };
  }
  return {
    text,
    isError: false,
  };
};
export default useBoxTextProps;
