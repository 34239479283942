import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

function SideBarNavigationLink({ onClick, children, className }) {
  return (
    <button type="button" onClick={onClick} className={classnames(styles.link, className)}>
      {children}
    </button>
  );
}

SideBarNavigationLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SideBarNavigationLink.defaultProps = {
  className: null,
};

export default SideBarNavigationLink;
