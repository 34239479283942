import React, { useRef, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isEmpty } from 'helpers/is';

import Spinner from 'components/spinner';
import SearchForm from 'components/search-form';

import RecentSearches from '../recent-searches';
import ViewedSearches from '../viewed-searches';

import styles from './styles.module.scss';

function Content({
  onSubmit,
  closeModal,
  topSearches,
  searchValue,
  searchesError,
  viewedProducts,
  recentSearches,
  isAuthenticated,
  isFetchingSearches,
  viewedProductsError,
  isRefetchingSearches,
  isLoadingViewedProducts,
  addProductToViewedSearches,
}) {
  const searchRef = useRef();
  const [value, setValue] = useState(searchValue);

  const onSearchReset = () => setValue('');
  const onSubmitHandler = () => onSubmit(value);
  const onSearchTextClick = (term) => onSubmit(term);

  const { topList, recentList } = useMemo(() => {
    const { length: topLength } = topSearches;
    const { length: recentLength } = recentSearches;

    if (topLength <= 5 && recentLength <= 5) {
      return {
        topList: topSearches,
        recentList: recentSearches,
      };
    }

    if (topLength > 5 && recentLength < 5) {
      return {
        topList: topSearches.slice(0, 10 - recentLength),
        recentList: recentSearches,
      };
    }

    if (topLength < 5 && recentLength > 5) {
      return {
        topList: topSearches,
        recentList: recentSearches.slice(0, 10 - topLength),
      };
    }

    return {
      topList: topSearches.slice(0, 5),
      recentList: recentSearches.slice(0, 5),
    };
  }, [topSearches, recentSearches]);

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  const isSearchesWithoutError = isEmpty(searchesError);
  const isViewedProductsWithoutError = isEmpty(viewedProductsError);
  const shouldRenderTopSearches = isSearchesWithoutError && topList.length !== 0;
  const shouldRenderRecentSearches = isSearchesWithoutError && recentList.length !== 0;
  const shouldRenderViewedProducts = isViewedProductsWithoutError && viewedProducts.length !== 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal} role="button" onClick={(event) => event.stopPropagation()}>
        <div className={styles.form}>
          <SearchForm
            onBack={closeModal}
            isPopup
            onSubmit={onSubmitHandler}
            className={styles.search}
            searchRef={searchRef}
            searchValue={value}
            onSearchReset={onSearchReset}
            onSearchChange={setValue}
          />
        </div>
        <div className={classnames(styles.content, 'animated-opacity')}>
          {isFetchingSearches ? (
            <Spinner size="md" centered />
          ) : (
            <>
              {isRefetchingSearches && (
                <span>
                  <Spinner size="xs" position="end" className={styles.refetchSpinner} />
                </span>
              )}

              {shouldRenderRecentSearches && (
                <div className={styles.section}>
                  <p className={styles.title}>RECENT SEARCHES</p>

                  <RecentSearches searches={recentList} onSearchTextClick={onSearchTextClick} />
                </div>
              )}

              {shouldRenderTopSearches && (
                <div className={styles.section}>
                  <p className={styles.title}>trending SEARCHES</p>

                  <RecentSearches searches={topList} onSearchTextClick={onSearchTextClick} />
                </div>
              )}
            </>
          )}

          {isLoadingViewedProducts ? (
            <Spinner size="md" centered />
          ) : (
            shouldRenderViewedProducts && (
              <div className={styles.section}>
                <p className={styles.title}>VIEWED SEARCHES</p>

                <ViewedSearches
                  className={styles.products}
                  viewedSearches={viewedProducts}
                  isAuthenticated={isAuthenticated}
                  addProductToViewedSearches={addProductToViewedSearches}
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

Content.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  topSearches: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchesError: PropTypes.shape({}),
  recentSearches: PropTypes.arrayOf(PropTypes.string).isRequired,
  viewedProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isFetchingSearches: PropTypes.bool.isRequired,
  viewedProductsError: PropTypes.string,
  isRefetchingSearches: PropTypes.bool.isRequired,
  isLoadingViewedProducts: PropTypes.bool.isRequired,
  addProductToViewedSearches: PropTypes.func.isRequired,
};

Content.defaultProps = {
  searchesError: null,
  viewedProductsError: null,
};

export default Content;
