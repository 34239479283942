import { useRef } from 'react';

export const useCreateConst = (createConst) => {
  const ref = useRef();

  if (!ref.current?.isValueInitialized) {
    ref.current = {
      value: createConst(),
      isValueInitialized: true,
    };
  }

  return ref.current.value;
};
