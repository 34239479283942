import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link as ReactRouterLink } from 'react-router-dom';

import { ROUTES } from 'router/constants';

import { generateProductPath } from 'helpers/product';

import Link from 'components/link';
import ProductImage from 'components/product-image';
import { ProductGauge } from 'components/gauges';

import styles from './styles.module.scss';

function ViewedSearches({
  className,
  viewedSearches,
  isAuthenticated,
  addProductToViewedSearches,
}) {
  return (
    <ul className={classnames(styles.list, className)}>
      {viewedSearches.slice(0, 5).map(({ product, meta }) => {
        const productPath = generateProductPath(product);
        const onProductClick = () => addProductToViewedSearches(product.id);

        return (
          <li key={product.id} className={styles.listItem}>
            <ReactRouterLink to={productPath} onClick={onProductClick} className={styles.link}>
              <ProductGauge
                size="xxs"
                className={styles.gauge}
                matchScore={meta.matchScore}
                isAuthenticated={isAuthenticated}
              />

              <ProductImage
                src={product.coverImageUrl}
                className={styles.image}
                productName={product.name}
                productBrand={product.brand}
              />
            </ReactRouterLink>
          </li>
        );
      })}

      <li className={styles.listItem}>
        <Link isLink to={ROUTES.SEARCH_HISTORY}>
          VIEW ALL
        </Link>
      </li>
    </ul>
  );
}

ViewedSearches.propTypes = {
  className: PropTypes.string,
  viewedSearches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  addProductToViewedSearches: PropTypes.func.isRequired,
};

ViewedSearches.defaultProps = {
  className: null,
};

export default memo(ViewedSearches);
