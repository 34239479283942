import * as dataHelpers from 'helpers/data';

import { DataSubmissionType } from './types';

export const STORE_NAME = 'data-submission';

export const DATA_SUBMISSION_EXPIRED_TIMEOUT_HOURS = 23;

export const DATA_SUBMISSION_DEFAULT_STATE = {
  id: null,
  createdAt: null,
};

const ENTITIES_DEFAULT_STATE = dataHelpers.fillObjectFromObjectValues(
  DataSubmissionType,
  DATA_SUBMISSION_DEFAULT_STATE,
);

export const DEFAULT_STATE = {
  entities: ENTITIES_DEFAULT_STATE,
};
