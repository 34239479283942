import { PhoneSvg } from 'components/video-capture/phone-svg';

import warningSVG from 'static/images/video-capture/revea-warning.svg';

export const FACE_BOX_VIEW_SIZE = 80;
export const FACE_AXES_VIEW_SIZE = 75;
export const FACE_CIRCLE_VIEW_SIZE = 75;
export const FACE_SIZE_BORDERS_SCALE = 1.1;
export const THRESHOLD_ANGLES = 3;
export const FaceState = {
  Detected: 'detected',
  NoDetected: 'no-detected',
  TooBig: 'too-big',
  TooSmall: 'too-small',
  BeyondLeftBorder: 'beyond-left-border',
  BeyondRightBorder: 'beyond-right-border',
  BeyondTopBorder: 'beyond-top-border',
  BeyondBottomBorder: 'beyond-bottom-border',
};

export const MESSAGES = {
  [FaceState.Detected]: null,
  [FaceState.NoDetected]: {
    title: 'Reposition your face',
    description: 'Your face is out of view',
    image: warningSVG,
  },
  [FaceState.TooBig]: {
    title: 'Move phone back from your face',
    component: PhoneSvg,
  },
  [FaceState.TooSmall]: {
    title: 'Move phone closer to your face',
    component: PhoneSvg,
  },
  [FaceState.BeyondTopBorder]: {
    title: 'Move face within the frame',
  },
  [FaceState.BeyondLeftBorder]: {
    title: 'Move face within the frame',
  },
  [FaceState.BeyondRightBorder]: {
    title: 'Move face within the frame',
  },
  [FaceState.BeyondBottomBorder]: {
    title: 'Move face within the frame',
  },
};
