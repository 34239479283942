import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { getPriorityNotification } from './helpers';

import styles from './styles.module.scss';

function Notification({ isAnonymous, isAuthenticated, isCompletedFullQuiz }) {
  const { pathname } = useLocation();
  const { Component } = getPriorityNotification({
    pathname,
    isAnonymous,
    isAuthenticated,
    isCompletedFullQuiz,
  });

  const shouldRenderNotification = !!Component;

  return (
    shouldRenderNotification && (
      <div className={classnames(styles.wrapper, 'container-fluid animate-opacity')}>
        <Component />
      </div>
    )
  );
}

Notification.propTypes = {
  isAnonymous: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isCompletedFullQuiz: PropTypes.bool.isRequired,
};

export default Notification;
