import { createSlice } from '@reduxjs/toolkit';

import createPersistReducer from 'store/helpers/create-persist-reducer';

import { logout } from 'store/modules/user/actions';

import * as thunks from './thunks';
import * as helpers from './helpers';
import * as selectors from './selectors';
import * as constants from './constants';

import persistMigrate from './persist-migrate';

const { reducer: defaultReducer, actions } = createSlice({
  name: 'search',
  initialState: constants.DEFAULT_STATE,

  reducers: {
    reset: (draftState) => ({
      ...constants.DEFAULT_STATE,
      viewedSearchesIds: draftState.viewedSearchesIds,
    }),

    setAnonymousSearch: (draftState, { payload: { term = null, barcode = null } }) => {
      draftState.anonymousSearch.term = term;
      draftState.anonymousSearch.barcode = barcode;
    },

    clearAnonymousSearch: (draftState) => {
      draftState.anonymousSearch.term = null;
      draftState.anonymousSearch.barcode = null;
    },

    addProductToViewedSearches: (draftState, { payload: productId }) => {
      if (draftState.viewedSearchesIds.includes(productId)) {
        draftState.viewedSearchesIds.splice(draftState.viewedSearchesIds.indexOf(productId), 1);
      }

      draftState.viewedSearchesIds = [productId, ...draftState.viewedSearchesIds];

      if (draftState.viewedSearchesIds.length > 25) {
        draftState.viewedSearchesIds = draftState.viewedSearchesIds.slice(0, 25);
      }
    },
  },

  extraReducers: {
    [logout.toString()]: () => constants.DEFAULT_STATE,

    [thunks.searchProducts.pending]: (draftState) => ({
      ...draftState,

      error: null,
      isFetching: true,
    }),

    [thunks.searchProducts.rejected]: (draftState, { error }) => ({
      ...draftState,

      error: error.message,
      isFetching: false,
      isFirstRequestDone: true,
    }),

    [thunks.searchProducts.fulfilled]: (draftState, { payload: { productIds }, meta: { arg: searchValue } }) => ({
      ...draftState,

      searchValue,

      entities: {
        ...draftState.entities,
        productIds,
      },

      error: null,
      isFetching: false,
      isFirstRequestDone: true,
    }),
  },
});

const reducer = createPersistReducer(
  {
    key: 'search',
    version: 1,
    whitelist: ['viewedSearchesIds', 'anonymousSearch'],
    migrate: persistMigrate,
  },
  defaultReducer,
);

export { selectors, thunks, helpers, reducer, actions };
