import { QUESTIONS } from 'constants/quiz/regular';

export const getAnswersByQuestion = {
  slider: ({ answer }) => [
    {
      id: String(answer),
      text: String(answer),
    },
  ],
  question: ({ question: { multiple, options }, answer }) => {
    const values = multiple ? answer : [answer];

    return values.map((answerId) => {
      const { title } = options.find((option) => answerId === option.id);

      return {
        id: answerId,
        text: title,
      };
    });
  },
  carousel: ({ answer }) => [
    {
      id: String(answer),
      text: String(answer),
    },
  ],
};

export const prepareAnswersForApi = (questionAnswers) =>
  Object.keys(questionAnswers).map((questionKey) => {
    const answer = questionAnswers[questionKey];
    const question = QUESTIONS[questionKey];

    return {
      id: questionKey,
      text: question.title,
      answers: getAnswersByQuestion[question.type]({ question, answer }),
    };
  });
