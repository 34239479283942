import { all, fork, take, select, cancel } from 'redux-saga/effects';

import { actions as appActions } from 'store/modules/app';
import { actions as userActions, selectors as userSelectors } from 'store/modules/user';

import { isEmpty } from 'helpers/is';

import quizSaga from './entities/quiz';
import userSaga from './entities/user';
import productListsSaga from './entities/product-lists';
import loggedInUserFlow from './entities/user/logged-in-flow';
import loggedInProductsFlow from './entities/products/logged-in-flow';
import loggedInProductListsFlow from './entities/product-lists/logged-in-flow';

function* loggedInFlow() {
  yield all([fork(loggedInProductListsFlow), fork(loggedInUserFlow), fork(loggedInProductsFlow)]);
}

function* mainFlow() {
  while (true) {
    const token = yield select(userSelectors.getToken);

    if (isEmpty(token)) {
      yield take(userActions.updateToken);
    }

    const loggedInUserFlowTask = yield fork(loggedInFlow);

    yield take(userActions.logout);

    yield cancel(loggedInUserFlowTask);
  }
}

export default function* rootSaga() {
  yield take(appActions.persistStoreDone);

  yield all([fork(productListsSaga), fork(userSaga), fork(mainFlow), fork(quizSaga)]);
}
