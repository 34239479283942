import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/modal';

import Content from './components/content';

function RetakeQuiz({ isVisible, push, closeModal }) {
  return (
    <Modal isVisible={isVisible} onOverlayClick={closeModal}>
      {() => <Content push={push} closeModal={closeModal} />}
    </Modal>
  );
}

RetakeQuiz.propTypes = {
  push: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RetakeQuiz;
