import { QUIZ_TYPES } from 'constants/quiz';

export const getQuiz = (state) => state.quiz;

export const getRegularQuiz = (state) => getQuiz(state)[QUIZ_TYPES.REGULAR];

export const getRegularQuizAnswers = (state) => getRegularQuiz(state).answersByQuestionIds;

export const getIsStartedRegularQuiz = (state) => getRegularQuiz(state).isStarted;

export const getQuizByType = (state, quizType) => getQuiz(state)[quizType];
