import { useEffect } from 'react';
import gsap from 'gsap';

const useInstructionsEffect = ({ isInstructionsStep, measurementApiRef, onInstructionsEnd, lifecycle }) => {
  useEffect(() => {
    if (!isInstructionsStep) {
      return () => {};
    }
    const values = {
      value: 0,
    };
    const timeline = gsap.timeline().to(values, {
      value: 0.8,
      duration: 2,
      onUpdate: () => {
        if (lifecycle.isUnmounted()) {
          return;
        }
        measurementApiRef.current?.setValues({
          shadow: values.value,
          saturation: values.value,
          ignoreSuccessState: true,
        });
      },
      onComplete: onInstructionsEnd,
    });
    return () => {
      timeline.kill();
    };
  }, [isInstructionsStep]);
};

export default useInstructionsEffect;
