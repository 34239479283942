import { useEffect } from 'react';

import { useRequestState } from './use-request-state';

const cachedUrls = [];

export const usePreloadImages = (urls) => {
  const [state, actions, helpers] = useRequestState();

  useEffect(() => {
    if (urls.length === 0) {
      return;
    }

    const cacheAssets = async () => {
      try {
        actions.setToFetching();

        const promises = urls.map((imageUrl) =>
          cachedUrls.includes(imageUrl)
            ? Promise.resolve()
            : new Promise((resolve, reject) => {
                const image = new Image();

                image.src = imageUrl;
                image.onload = () => {
                  cachedUrls.push(imageUrl);

                  resolve(null);
                };
                image.onerror = reject;
              }),
        );

        await Promise.all(promises);

        actions.setToFetched();
      } catch (e) {
        actions.setToFailed(e);
      }
    };

    cacheAssets();
  }, [urls]);

  return [state, actions, helpers];
};
