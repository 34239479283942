import { useState, useCallback } from 'react';

export default function useStore() {
  const [state, setState] = useState({ ids: [], elements: {}, variables: {}, priorities: {} });

  const addElement = useCallback(
    ({ id, element, variable, priority }) =>
      setState((prevState) => {
        const priorities = {
          ...prevState.priorities,
          [id]: priority,
        };

        return {
          ids: [...prevState.ids, id].sort((idA, idB) => priorities[idB] - priorities[idA]),
          elements: {
            ...prevState.elements,
            [id]: element,
          },
          variables: {
            ...prevState.variables,
            [id]: variable,
          },
          priorities,
        };
      }),
    [setState],
  );

  const removeElement = useCallback(
    (id) =>
      setState((prevState) => {
        const elements = { ...prevState.elements };
        const variables = { ...prevState.variables };
        const priorities = { ...prevState.priorities };

        delete elements[id];
        delete variables[id];
        delete priorities[id];

        const ids = prevState.ids
          .filter((currentId) => currentId !== id)
          .sort((idA, idB) => priorities[idB] - priorities[idA]);

        return {
          ids,
          elements,
          variables,
          priorities,
        };
      }),
    [setState],
  );

  const updateElement = useCallback(
    ({ id, element }) =>
      setState((prevState) => ({
        ...prevState,
        elements: {
          ...prevState.elements,
          [id]: element,
        },
      })),
    [setState],
  );

  return {
    state,

    addElement,
    removeElement,
    updateElement,
  };
}
