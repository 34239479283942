import { useCreateConst } from 'hooks/video-capture/use-create-const';

const useMeasurementApi = ({ circleApiRef, shadowApiRef, saturationApiRef }) =>
  useCreateConst(() => {
    let shadowValue = 0;
    let saturationValue = 0;
    const isSuccessValue = (value) => value === 1;
    const checkIsSuccess = () => {
      const isSuccess = isSuccessValue(shadowValue) && isSuccessValue(saturationValue);
      shadowApiRef.current?.toggleBorderColor(isSuccess);
      saturationApiRef.current?.toggleBorderColor(isSuccess);
    };
    const setShadowValue = (value, ignoreSuccessState) => {
      shadowValue = value;
      shadowApiRef.current?.setValue(value);
      circleApiRef.current?.fillRightSlider(ignoreSuccessState ? false : isSuccessValue(value));
      if (ignoreSuccessState) {
        shadowApiRef.current?.toggleBorderColor(false);
        return;
      }
      checkIsSuccess();
    };
    const setSaturationValue = (value, ignoreSuccessState) => {
      saturationValue = value;
      saturationApiRef.current?.setValue(value);
      circleApiRef.current?.fillLeftSlider(ignoreSuccessState ? false : isSuccessValue(value));
      if (ignoreSuccessState) {
        saturationApiRef.current?.toggleBorderColor(false);
        return;
      }
      checkIsSuccess();
    };
    return {
      setValues: ({ shadow, saturation, ignoreSuccessState = false }) => {
        setShadowValue(shadow, ignoreSuccessState);
        setSaturationValue(saturation, ignoreSuccessState);
      },
      setShadowValue,
      setSaturationValue,
      toggleSuccessState: (isSuccess) => {
        circleApiRef.current?.toggleSuccessState(isSuccess);
      },
    };
  });
export default useMeasurementApi;
