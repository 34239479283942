export const VIEW_BOX_SIZE = 100;
const CURVES_OFFSET = 1;
const CURVES_SIZE = VIEW_BOX_SIZE - CURVES_OFFSET;
export const CURVES = {
  SIZE: CURVES_SIZE,
  X: CURVES_OFFSET / 2,
  Y: CURVES_OFFSET / 2,
};
const RANGES_OFFSET = 1;
const RANGES_SIZE = VIEW_BOX_SIZE - RANGES_OFFSET;
export const RANGES = {
  SIZE: RANGES_SIZE,
  X: RANGES_OFFSET / 2,
  Y: RANGES_OFFSET / 2,
};
