import { useEffect } from 'react';

const useOnVideoReadyEffect = ({ videoState, onVideoReady }) => {
  useEffect(() => {
    if (!videoState.isPlaying || !videoState.isLoadedMetaData) {
      return;
    }
    onVideoReady();
  }, [videoState]);
};
export default useOnVideoReadyEffect;
