import React from 'react';
import classnames from 'classnames';
import { AdvancedImage, lazyload, responsive as responsivePlugin, placeholder } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { dpr } from '@cloudinary/url-gen/actions/delivery';

import styles from './styles.module.scss';

const cloud = new Cloudinary({
  cloud: {
    cloudName: 'hqb85rb4o',
  },
});

export const CloudinaryImage = React.memo(
  ({ src, transform, alt, className, imgClassName, responsive = true, ...props }) => {
    const img = cloud.image(src);

    transform?.(img);

    img.delivery(dpr(`${Math.floor(window.devicePixelRatio)}.0`));

    const plugins = React.useMemo(() => {
      const pluginsList = [lazyload()];

      if (responsive) {
        pluginsList.push(responsivePlugin());
      }

      pluginsList.push(placeholder({ mode: 'blur' }));

      return pluginsList;
    }, []);

    return (
      <picture className={classnames(styles.picture, className)}>
        <AdvancedImage {...props} alt={alt} cldImg={img} plugins={plugins} className={imgClassName} />
      </picture>
    );
  },
);
