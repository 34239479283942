import { useRef, useState, useCallback, useEffect } from 'react';
import gsap from 'gsap';

import { delay } from 'helpers/promise';
import { takePictureFromVideo } from 'helpers/canvas';

import { FaceState } from '../../../../constants';

import { useScheduler } from '../use-scheduler';
import { getFaceState } from './helpers';

export const TILE_DURATION = 500;
const VALID_FRAMES_FOR_TAKING_PHOTO = 8;
const VALID_FRAMES_FOR_CHANGING_FACE_STATE = 3;
const useFaceState = ({
  ranges,
  videoRef,
  rangeIndex,
  detectFace,
  videoRecorder,
  onVideoCaptured,
  onAngleCaptured,
  rangeMaskApiRef,
  isCapturingStep,
  isHelpWindowOpened,
}) => {
  const [, schedulerActions] = useScheduler();
  const [state, setState] = useState(null);
  const cacheRef = useRef({
    image: null,
    faceState: state,
    rangeIndex,
    tweenAngles: { yaw: 0 },
    wasFaceTooFar: false,
    isCapturingAngle: false,
    isHelpWindowOpened,
    temporaryFaceState: null,
    countOfTemporaryState: 0,
    countOfDifferentState: 0,
    countOfSuccessfulFrames: 0,
    timestampStartRecordingAt: null,
  });
  cacheRef.current.rangeIndex = rangeIndex;
  cacheRef.current.isHelpWindowOpened = isHelpWindowOpened;
  const setFaceState = useCallback((faceState) => {
    if (cacheRef.current.faceState === faceState) {
      return;
    }
    cacheRef.current.faceState = faceState;
    setState(faceState);
  }, []);
  const resetCache = () => {
    cacheRef.current.isCapturingAngle = false;
    cacheRef.current.temporaryFaceState = null;
    cacheRef.current.countOfTemporaryState = 0;
    cacheRef.current.countOfDifferentState = 0;
    cacheRef.current.countOfSuccessfulFrames = 0;
    cacheRef.current.timestampStartRecordingAt = null;
  };
  const detect = useCallback(async () => {
    if (cacheRef.current.rangeIndex >= ranges.length || cacheRef.current.isHelpWindowOpened) {
      return;
    }
    const range = ranges[cacheRef.current.rangeIndex];
    const { videoWidth, videoHeight } = videoRef.current;
    const prediction = await detectFace();
    const isPredictionEmpty = !prediction;
    const faceState = getFaceState({
      prediction,
      videoWidth,
      videoHeight,
      isDistanceOut: cacheRef.current.faceState === FaceState.Detected || !cacheRef.current.wasFaceTooFar,
    });
    if (prediction && faceState === FaceState.Detected) {
      const isAnglesChanged =
        Math.round(cacheRef.current.tweenAngles.yaw) !== Math.round(prediction.rotation.angle.yaw);
      if (isAnglesChanged) {
        gsap.to(cacheRef.current.tweenAngles, { ...prediction.rotation.angle });
        rangeMaskApiRef.current?.rangesApiRef.current?.highlightRange(cacheRef.current.tweenAngles.yaw);
      }
    }
    if (cacheRef.current.isCapturingAngle) {
      return;
    }
    if (cacheRef.current.temporaryFaceState !== faceState) {
      cacheRef.current.temporaryFaceState = faceState;
      cacheRef.current.countOfTemporaryState = 0;
      cacheRef.current.countOfDifferentState += 1;
      return;
    }
    if (cacheRef.current.countOfTemporaryState < VALID_FRAMES_FOR_CHANGING_FACE_STATE) {
      cacheRef.current.countOfTemporaryState += 1;
      cacheRef.current.countOfDifferentState = 0;
      return;
    }
    setFaceState(faceState);
    if (faceState === FaceState.TooSmall) {
      cacheRef.current.wasFaceTooFar = true;
    } else if (faceState === FaceState.Detected) {
      cacheRef.current.wasFaceTooFar = false;
    }
    if (isPredictionEmpty || faceState !== FaceState.Detected) {
      const recorderState = videoRecorder.getState();
      cacheRef.current.timestampStartRecordingAt = null;
      rangeMaskApiRef.current?.rangesApiRef.current?.focusRange(cacheRef.current.rangeIndex);
      if (recorderState === 'recording') {
        videoRecorder.pauseRecording();
      }
      return;
    }
    const recorderState = videoRecorder.getState();
    const isAngleValid = prediction.rotation.angle.yaw >= range.from && prediction.rotation.angle.yaw <= range.to;
    if (!isAngleValid) {
      cacheRef.current.countOfSuccessfulFrames = 0;
      cacheRef.current.timestampStartRecordingAt = null;
      rangeMaskApiRef.current?.rangesApiRef.current?.focusRange(cacheRef.current.rangeIndex);
      if (recorderState === 'recording') {
        videoRecorder.pauseRecording();
      }
      return;
    }
    cacheRef.current.countOfSuccessfulFrames += 1;
    if (cacheRef.current.countOfSuccessfulFrames < VALID_FRAMES_FOR_TAKING_PHOTO) {
      return;
    }
    if (recorderState === 'inactive') {
      videoRecorder.startRecording();
      cacheRef.current.timestampStartRecordingAt = Date.now();
    } else if (recorderState === 'paused') {
      videoRecorder.resumeRecording();
      cacheRef.current.timestampStartRecordingAt = Date.now();
    }
    rangeMaskApiRef.current?.rangesApiRef.current?.activeRange(cacheRef.current.rangeIndex);
    if (Date.now() - cacheRef.current.timestampStartRecordingAt < TILE_DURATION) {
      return;
    }
    cacheRef.current.isCapturingAngle = true;
    rangeMaskApiRef.current?.rangesApiRef.current?.completeRange(cacheRef.current.rangeIndex);
    const isCenteredAngle = range.from < 0 && range.to > 0;
    if (isCenteredAngle) {
      cacheRef.current.image = takePictureFromVideo({ videoNode: videoRef.current });
    }
    if (recorderState === 'recording') {
      videoRecorder.pauseRecording();
    }
    await delay(200);
    if (cacheRef.current.rangeIndex === ranges.length - 1) {
      videoRecorder.stopRecording(() =>
        onVideoCaptured({
          image: cacheRef.current.image,
          videoBlob: videoRecorder.getBlob(),
        }),
      );
      return;
    }
    onAngleCaptured();
    resetCache();
  }, [videoRecorder]);
  useEffect(() => {
    if (!isCapturingStep) {
      return () => {};
    }
    schedulerActions.run(detect);
    return () => {
      schedulerActions.stop();
    };
  }, [isCapturingStep]);
  return state;
};
export default useFaceState;
