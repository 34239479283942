import { isEmpty } from 'helpers/is';

export const transformProductsPayloadFromResponse = ({ products, productIds }) =>
  productIds.reduce((acc, productId) => {
    const { category } = products[productId];

    if (isEmpty(acc[category])) {
      // eslint-disable-next-line no-param-reassign
      acc[category] = [];
    }

    acc[category].push(productId);

    return acc;
  }, {});

export const transformProductListsPayloadFromResponse = (data) =>
  data.reduce((acc, { list, productId }) => {
    if (isEmpty(acc[list])) {
      // eslint-disable-next-line no-param-reassign
      acc[list] = [];
    }

    acc[list].push(productId);

    return acc;
  }, {});
