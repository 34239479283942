import gsap from 'gsap';

import { useCreateConst } from 'hooks/video-capture/use-create-const';

import { INNER_CIRCLE_RADIUS } from '../constants';
import { getCircleCircumference, getStrokeOffset } from '../helpers';

const useCurvesApi = ({ circleRef }) => {
  const circleApi = useCreateConst(() => ({
    animate: () => {
      const circumference = getCircleCircumference(INNER_CIRCLE_RADIUS);
      const offsetFill = getStrokeOffset(circumference, 100).toFixed(2);
      return gsap.to(circleRef.current, { duration: 0.3, attr: { 'stroke-dashoffset': offsetFill } });
    },
    interruptAnimation: () => {
      const circumference = getCircleCircumference(INNER_CIRCLE_RADIUS);
      const offsetFill = getStrokeOffset(circumference, 0).toFixed(2);
      return gsap.to(circleRef.current, { duration: 0.3, attr: { 'stroke-dashoffset': offsetFill } });
    },
  }));
  return circleApi;
};
export default useCurvesApi;
