import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

function Title({ tag, className, children, size, sansSerif }) {
  const Tag = tag;

  return (
    <Tag
      className={classnames(styles.title, className, styles[size], {
        [styles.sansSerif]: sansSerif,
      })}
    >
      {children}
    </Tag>
  );
}

Title.propTypes = {
  tag: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  ]).isRequired,
  sansSerif: PropTypes.bool,
  className: PropTypes.string,
};

Title.defaultProps = {
  tag: 'h3',
  size: null,
  sansSerif: false,
  className: null,
};

export default Title;
