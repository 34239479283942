export const PRODUCT_CATEGORIES = {
  TREATMENT: 'treatment',
  MOISTURIZER: 'moisturizer',
};

export const PLURAL_PRODUCT_CATEGORIES = {
  TREATMENTS: 'treatments',
  MOISTURIZERS: 'moisturizers',
};

export const PRODUCT_CATEGORIES_LIST = Object.values(PRODUCT_CATEGORIES);

export const PLURAL_PRODUCT_CATEGORIES_LIST = Object.values(PLURAL_PRODUCT_CATEGORIES);

export const PRODUCT_CATEGORIES_MAP = (() => {
  const map = {};

  map[(map[PRODUCT_CATEGORIES.TREATMENT] = 'treatments')] = PRODUCT_CATEGORIES.TREATMENT;
  map[(map[PRODUCT_CATEGORIES.MOISTURIZER] = 'moisturizers')] = PRODUCT_CATEGORIES.MOISTURIZER;

  return map;
})();
