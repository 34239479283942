import { ApiVersion } from './types';
export const IMAGE_WIDTH = 512;
export const IMAGE_ASPECT_RATIO = 4 / 3;
export const IMAGE_HEIGHT = IMAGE_WIDTH * IMAGE_ASPECT_RATIO;
export const THRESHOLDS = {
  [ApiVersion.V1]: {
    shadowToOk: 0.08,
    shadowFromOk: 0.15,
    saturationToOk: 0.01,
    saturationFromOk: 0.15,
    backgroundSaturationToOk: 1,
    backgroundSaturationFromOk: 1,
  },
  [ApiVersion.V2]: {
    shadowToOk: 0.07,
    shadowFromOk: 0.1,
    saturationToOk: 0.03,
    saturationFromOk: 0.05,
    backgroundSaturationToOk: 0.07,
    backgroundSaturationFromOk: 0.1,
  },
};
