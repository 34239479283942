import { CRITICAL_ERROR_DATA } from 'constants/video-capture';

const useBoxTextProps = ({ text, checkState, criticalError, isIlluminationChecking }) => {
  if (criticalError) {
    return {
      text: CRITICAL_ERROR_DATA[criticalError].text,
      isError: true,
    };
  }
  if (isIlluminationChecking) {
    return checkState;
  }
  return {
    text,
    isError: false,
  };
};
export default useBoxTextProps;
