import { createSlice } from '@reduxjs/toolkit';

import { REQUEST_STATUSES } from 'constants/request';

import { isNotEmpty } from 'helpers/is';
import { removeItemFromArray } from 'helpers/data';
import { isRequestStatusIdle } from 'helpers/request';

import { logout } from 'store/modules/user/actions';

import * as helpers from './helpers';
import * as selectors from './selectors';
import * as constants from './constants';

import { PRODUCT_LIST_NAMES } from './constants';
import { ENTITIES_DEFAULT_STATE, PROCESSING_PRODUCTS_DEFAULT_STATE } from './default-states';

const DEFAULT_STATE = {
  entities: ENTITIES_DEFAULT_STATE,

  processingProductIds: PROCESSING_PRODUCTS_DEFAULT_STATE,

  request: {
    error: null,
    status: REQUEST_STATUSES.IDLE,
  },
};

const { reducer, actions } = createSlice({
  name: 'product-lists',
  initialState: DEFAULT_STATE,
  reducers: {
    fetchUserProductList: (draftState) => {
      const isFirstRequest = isRequestStatusIdle(draftState.request.status);

      draftState.request = {
        error: null,
        status: isFirstRequest ? REQUEST_STATUSES.FETCHING : REQUEST_STATUSES.REFETCHING,
      };
    },
    fetchUserProductListSuccess: (draftState, { payload }) => {
      const productIdsByList = helpers.transformProductListsPayloadFromResponse(payload);

      PRODUCT_LIST_NAMES.forEach((listName) => {
        const { productIds } = draftState.entities[listName];

        draftState.entities[listName].productIds = productIdsByList[listName] ?? productIds;
      });

      draftState.request = {
        error: null,
        status: REQUEST_STATUSES.FETCHED,
      };
    },
    fetchUserProductListFail: (draftState, { error }) => {
      draftState.request = {
        error,
        status: REQUEST_STATUSES.FETCHED,
      };
    },

    fetchUserProductsByList: (draftState, { payload: listName }) => {
      const isFirstRequest = isRequestStatusIdle(draftState.entities[listName].request.status);

      draftState.entities[listName].request.status = isFirstRequest
        ? REQUEST_STATUSES.FETCHING
        : REQUEST_STATUSES.REFETCHING;
    },
    fetchUserProductsByListSuccess: (draftState, { payload, meta: listName }) => {
      draftState.entities[listName].productIdsByCategory = helpers.transformProductsPayloadFromResponse(payload);
      draftState.entities[listName].request = {
        error: null,
        status: REQUEST_STATUSES.FETCHED,
      };
    },
    fetchUserProductsByListFail: (draftState, { error, meta: listName }) => {
      draftState.entities[listName].request = {
        error,
        status: REQUEST_STATUSES.FETCHED,
      };
    },

    addProductToUserList: (draftState, { payload: { productId, listName } }) => {
      draftState.entities[listName].productIds.push(productId);
      draftState.processingProductIds[listName].push(productId);
    },
    addProductToUserListSuccess: (draftState, { meta: { productId, listName } }) => {
      draftState.processingProductIds[listName] = removeItemFromArray(
        draftState.processingProductIds[listName],
        productId,
      );
    },
    addProductToUserListFail: (draftState, { meta: { productId, listName } }) => {
      draftState.entities[listName].productIds = removeItemFromArray(
        draftState.entities[listName].productIds,
        productId,
      );
      draftState.processingProductIds[listName] = removeItemFromArray(
        draftState.processingProductIds[listName],
        productId,
      );
    },

    removeProductFromUserList: (draftState, { payload: { productId, listName, categoryId } }) => {
      draftState.entities[listName].productIds = removeItemFromArray(
        draftState.entities[listName].productIds,
        productId,
      );
      draftState.processingProductIds[listName].push(productId);

      if (isNotEmpty(categoryId)) {
        draftState.entities[listName].productIdsByCategory[categoryId] = draftState.entities[
          listName
        ].productIdsByCategory[categoryId]?.filter((id) => id !== productId);
      }
    },
    removeProductFromUserListSuccess: (draftState, { meta: { productId, listName } }) => {
      draftState.processingProductIds[listName] = removeItemFromArray(
        draftState.processingProductIds[listName],
        productId,
      );
    },
    removeProductFromUserListFail: (draftState, { meta: { productId, listName, categoryId } }) => {
      draftState.entities[listName].productIds.push(productId);
      draftState.processingProductIds[listName] = removeItemFromArray(
        draftState.processingProductIds[listName],
        productId,
      );

      if (isNotEmpty(categoryId)) {
        // eslint-disable-next-line no-unused-expressions
        draftState.entities[listName].productIdsByCategory[categoryId]?.push(productId);
      }
    },
  },

  extraReducers: {
    [logout.toString()]: () => DEFAULT_STATE,
  },
});

export { selectors, reducer, actions, helpers, constants };
