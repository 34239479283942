import React, { memo, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import usePrevious from 'hooks/use-previous';

import { PARAMETERS, ANGLES, RADARS } from '../../constants';
import { polarToX, polarToY, pathDefinition } from '../../helpers';

import styles from './styles.module.scss';

const Shape = ({ id, vector, onShapeTransitionEnd, className }) => {
  const animateRef = useRef();

  const d = pathDefinition(
    PARAMETERS.map((parameter, index) => {
      const value = vector[parameter.key];
      const angle = ANGLES[index];
      const distance = value / 2;

      return [polarToX(angle, distance), polarToY(angle, distance)];
    }),
  );

  const { color } = RADARS[id];

  const previousD = usePrevious(d);

  const nextValues = useMemo(() => {
    return `${previousD};${d}`;
  }, [d]);

  useEffect(() => {
    const animateNode = animateRef.current;

    if (!animateNode) {
      return null;
    }

    animateNode.addEventListener('endEvent', onShapeTransitionEnd);

    return () => {
      animateNode.removeEventListener('endEvent', onShapeTransitionEnd);
    };
  }, [animateRef.current]);

  useEffect(() => {
    const animateNode = animateRef.current;

    if (!animateNode && previousD === d) {
      return;
    }

    animateNode.beginElement();
  }, [previousD, d]);

  return (
    <path
      fill={color}
      stroke={color}
      className={classnames(styles.shape, className)}
      onTransitionEnd={onShapeTransitionEnd}
    >
      <animate ref={animateRef} attributeType="XML" attributeName="d" values={nextValues} dur="0.8s" fill="freeze" />
    </path>
  );
};

Shape.propTypes = {
  id: PropTypes.string.isRequired,
  vector: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
  onShapeTransitionEnd: PropTypes.func.isRequired,
};

export default memo(Shape);
