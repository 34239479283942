export const SKIN_PARAMS_TYPES = {
  TONE: 'tone',
  MILIEU: 'milieu',
  HYDRATION: 'hydration',
  SMOOTHNESS: 'smoothness',
  DERMAL_FIBERS: 'dermalFibers',
  OXYGEN_SATURATION: 'oxygenSaturation',
};

export const DEFAULT_SKIN_PARAMS_ORDER = [
  'hydration',
  'tone',
  'smoothness',
  'dermalFibers',
  'milieu',
  'oxygenSaturation',
];

export const SKIN_DICTIONARY = {
  tone: 'Skin tone',
  milieu: 'Milieu',
  hydration: 'Hydration',
  smoothness: 'Smoothness',
  dermalFibers: 'Dermal fibers',
  oxygenSaturation: 'Energy supply',
};

export const SKIN_DASH_DICTIONARY = (() => {
  const dictionary = {};

  dictionary[(dictionary.tone = 'tone')] = 'tone';
  dictionary[(dictionary.milieu = 'milieu')] = 'milieu';
  dictionary[(dictionary.hydration = 'hydration')] = 'hydration';
  dictionary[(dictionary.smoothness = 'smoothness')] = 'smoothness';
  dictionary[(dictionary.dermalFibers = 'dermal-fibers')] = 'dermalFibers';
  dictionary[(dictionary.oxygenSaturation = 'oxygen-saturation')] = 'oxygenSaturation';

  return dictionary;
})();

export const SKIN_PARAM_DESCRIPTIONS = {
  hydration:
    'Hydration gives your skin its healthy glow. It is the quality of your skin barrier (ability to hold water in and keep intruders out), your skin’s ability to prevent trans-epidermal water loss, the rate of cell renewal, and the moisture sealing effect of sebum production.',
  tone:
    'Skin tone is the evenness of pigment (melanin) across the face. Unevenness (light and dark spots) can be caused by tissue damage, UV exposure, hormonal changes, and genetics. Correcting pigmentation distribution, preventing damage, reducing breakouts can improve skin tone.',
  smoothness:
    'Smoothness measures the surface texture of the skin. Pore size, fine lines, and dry skin can make skin feel rough and look less radiant. Optimizing hydration and skin composition, exfoliating, minimizing pore size and plumping fine lines, improves smoothness and skin glow.',
  dermalFibers:
    'Dermal fibers are a measure of the skin’s collagen/elastin and ability to repair. Aging, environmental exposure, and hormonal changes degrade fibers causing wrinkles and loss of elasticity. Preventing damage, combating senescence, and boosting collagen restore skin’s healthy, firm appearance.',
  milieu:
    'Skin milieu is the skin’s environment, from the micro-vasculature system providing skin with nutrients to the skin’s microbiome. Products, hormonal shifts, and skin barrier damage affect skin milieu. Balancing it can prevent breakouts, sensitivity, acne, redness, and inflammation.',
  oxygenSaturation:
    'Energy supply is the skin’s microcirculation (deliver nutrients and remove waste) and mitochondrial health (ability to rejuvenate). Low Energy can lead to visible dullness and premature skin aging. Antioxidants can stimulate microcirculation, boost radiance, and slow down skin aging.',
};

export const SKIN_PARAMETER_INFO = {
  tone: `<span>Skin tone</span> is the evenness or unevenness of skin color across the face. Most of the color you see on the face is from the presence of pigment (melanin). Skin tone reflects how and where melanin is distributed in the skin. This can be affected by tissue damage, UV exposure, hormonal changes and genetics. Unevenness may show up as dark or light spots or scarring following a local inflammation like a breakout (also known as post inflammatory hyperpigmentation). Skin tone can be improved by reducing and evening pigmentation and preventing or reducing the root causes of inflammation.`,
  milieu: `<span>Skin Milieu</span> is your skin’s environment. It encompasses everything from the micro-vasculature system that provides skin with nutrients, your skin’s immune defense system, and your skin’s microbiome. Your skin microbiome is made up of the microorganisms that live on, and interact with, your skin. These microorganisms interact with your skin (sweat, sebum, etc) and play an important role in maintaining a healthy skin environment. Your microbiome can be affected by skincare products, environment, hormonal fluctuations, and changes in skin barrier and sebum production. Signs of an unhealthy environment include breakouts, sensitivity and reactions, acne, or inflammation. 
  <br/>
  <br/>
  You can improve your skin milieu by reducing inflammation and using ingredients that promote a healthy skin microbiome. 
  `,
  hydration: `
  The <span>hydration parameter</span> is a measure of your overall skin hydration, skin barrier function, and your skin’s ability to properly regulate moisture levels. Water fundamentally gives your skin its healthy glow and is critical to your long term skin health. Skin hydration is the overall outcome determined by the quality of your skin barrier (ability to hold water in and keep intruders out), your skin’s ability to prevent too much trans-epidermal water loss (TEWL), the rate of cell renewal, and the moisture sealing effect of sebum production. 
  <br/>
  <br/>
  Your skin barrier function is essential to maintaining optimal hydration levels in skin and the entire body and it reflects your skin health. It forms the protective layer between the inside and outside. Skin barrier holds in nutrients and water, retains proper pH levels and forms a protective barrier against pathogens. A disrupted skin barrier can lead to manifestations from dryness to skin diseases such as atopic dermatitis. It can be improved by increasing the water holding capacity in the skin surface layers and preventing its disruption. 
  `,
  smoothness: `<span>Skin smoothness</span> is a measure of the skin's surface texture. It evaluates how uniform and tightly arranged the top layers of skin are to create a surface that is soft and smooth to the touch. Widened or sagging pores and the presence of fine lines have an impact on skin smoothness. When skin is not smooth it not only feels rough, it also reflects less light and looks less radiant.
  <br/>
  <br/>
  Skin smoothness can be affected by optimizing hydration and skin lipid composition, facilitating regular desquamation (exfoliation), minimizing pore size and plumping the micro relief (fine lines). Improving skin smoothness not only makes your skin feel soft, it also increases your radiance and glow.  
  `,
  dermalFibers: `<span>Dermal fibers</span> are a measure of the collagen elastin matrix and the skin’s ability to heal and repair. Your collagen elastin matrix consists of a dense and elastic network of fibers, located in the deeper layers of the skin. It provides the elastic lift to resist gravity, the structural support to create density and youthful firmness of the skin. Over time, the collagen elastin matrix degrades and becomes entangled due to the accumulation of damage and the senescence (cellular aging) of fibroblasts, the collagen elastin matrix producing cells. The impact of (chronological) age, environmental exposure and hormonal changes determines the rate for this degradation to occur. Visually it leads to wrinkle formation, loss of firmness and elasticity. 
  <br/>
  <br/>
  Ingredients that promote wound healing, combat senescence, protect the skin and boost new collagen elastin production can help improve your collagen and elastin production to maintain and restore your skin’s healthy, firm appearance.
  `,
  oxygenSaturation: `<span>Energy supply</span> includes oxygen saturation, the amount of oxygen rich microcirculation in your skin, which is essential to deliver nutrients and remove waste. It also includes the mitochondrial health or redox state of the skin, essentially your skin’s ability to rejuvenate and convert oxygen into biological energy without oxidative damage. Low Energy supply from lack of oxygen saturation and compromised mitochondrial health can lead to visible dullness and fatigue, resulting over time in premature skin aging.  
  <br/>
  <br/>
  Energy supply can be improved by stimulating microcirculation, optimizing the skin’s mitochondrial energy capacity and through the use of antioxidants, thereby boosting healthy radiance and ultimately slowing down the aging process. 
  `,
};

export const INGREDIENTS = {
  tone: ['Glycerin', 'Sorbitan Olivate'],
  milieu: [],
  hydration: ['Glycerin', 'Sorbitan Olivate', 'Squalene', 'Glycerin', 'Sorbitan Olivate'],
  smoothness: ['Squalene'],
  dermalFibers: [],
  oxygenSaturation: [],
};
