import React, { lazy } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';

import retry from 'libs/retry';

import { ROUTES, PRODUCT_CATEGORIES_PATHS } from 'router/constants';
import GuestRoute from 'router/routes/guest';
import PrivateRoute from 'router/routes/private';

import Header from 'containers/header';
import Loader from 'containers/loader';
import PrevPath from 'containers/prev-path';
import Navigation from 'containers/navigation';
import ScrollToTop from 'containers/scroll-to-top';
import Notification from 'containers/notification';
import ModalNotificationBuild from 'containers/modal-notification-build';

import Footer from 'components/footer';
import Helmet from 'components/helmet';
import Spinner from 'components/spinner';
import { Suspense } from 'components/suspense';

import {
  BLOG_PATHS,
  LOGIN_PATHS,
  FOOTER_PATHS,
  HEADER_PATHS,
  SEARCH_PATHS,
  OLD_SEARCH_PATHS,
  NAVIGATION_PATHS,
} from './constants';

import usePaddingBottom from './hooks/use-padding-bottom';

import { PantoneCardRoute } from './pantone-card-flow/constants';

import styles from './styles.module.scss';

const Test = lazy(() => retry(() => import('pages/test')));
const PantoneCardFlowRouter = lazy(() => retry(() => import('./pantone-card-flow/router')));

const Cart = lazy(() => retry(() => import('pages/cart')));
const Quiz = lazy(() => retry(() => import('pages/quiz')));
const Login = lazy(() => retry(() => import('pages/login')));
const Brand = lazy(() => retry(() => import('pages/brand')));
const Brands = lazy(() => retry(() => import('pages/brands')));
const Compare = lazy(() => retry(() => import('pages/compare')));
// const Landing = lazy(() => retry(() => import('pages/landing')));
const LandingV2 = lazy(() => retry(() => import('pages/landing-v2')));
const Product = lazy(() => retry(() => import('pages/product')));
const AboutUs = lazy(() => retry(() => import('pages/about-us')));
const Products = lazy(() => retry(() => import('pages/products')));
const BlogRoot = lazy(() => retry(() => import('pages/blog-root')));
const NotFound = lazy(() => retry(() => import('pages/not-found')));
const Favorites = lazy(() => retry(() => import('pages/favorites')));
const Dashboard = lazy(() => retry(() => import('pages/dashboard')));
const QuizReset = lazy(() => retry(() => import('pages/quiz-reset')));
const MobileOnly = lazy(() => retry(() => import('pages/mobile-only')));
const QuizQuestion = lazy(() => retry(() => import('pages/quiz-question')));
const PrivacyPolicy = lazy(() => retry(() => import('pages/privacy-policy')));
const TermsOfService = lazy(() => retry(() => import('pages/terms-of-service')));
const CategoryListing = lazy(() => retry(() => import('pages/category-listing')));
const PickingUpProducts = lazy(() => retry(() => import('pages/picking-up-products')));
const DashboardSkinParameter = lazy(() => retry(() => import('pages/dashboard-skin-parameter')));

const SearchProducts = lazy(() => retry(() => import('pages/search-products')));
const SearchRedirect = lazy(() => retry(() => import('pages/search/redirect')));
const SearchHistory = lazy(() => retry(() => import('pages/search-history')));

function Root() {
  const paddingBottom = usePaddingBottom();

  const { isExact: isLandingPage = false } = useRouteMatch(ROUTES.ROOT) || {};
  const { isExact } = useRouteMatch(ROUTES.SHORT_QUIZ_QUESTION) || {};

  return (
    <PrevPath>
      <div
        className={classnames(!isExact && styles.root, { [styles.landing]: isLandingPage })}
        style={{ paddingBottom }}
      >
        <Helmet />

        <Route exact path={HEADER_PATHS} component={Header} />

        <Notification />

        <Suspense fallback={<Spinner centered size="md" />}>
          <Switch>
            <Route path={PantoneCardRoute.Root} component={PantoneCardFlowRouter} />

            <Route exact path={ROUTES.BLOG}>
              <Redirect to={ROUTES.ARTICLES} />
            </Route>

            <GuestRoute exact path={ROUTES.ROOT} component={LandingV2} />
            <GuestRoute exact path={LOGIN_PATHS} component={Login} />

            <PrivateRoute exact path={PRODUCT_CATEGORIES_PATHS} component={Products} />
            <PrivateRoute path={ROUTES.DASHBOARD} exact component={Dashboard} redirectPath={ROUTES.LOGIN} />
            <PrivateRoute path={ROUTES.DASHBOARD_SKIN_PARAMETER} exact component={DashboardSkinParameter} />
            <PrivateRoute exact path={ROUTES.CART} component={Cart} />
            <PrivateRoute exact path={ROUTES.COMPARE} component={Compare} />
            <PrivateRoute exact path={ROUTES.FAVORITES} component={Favorites} />

            <Route exact path={[ROUTES.QUIZ, ROUTES.QUIZ_RETAKE]} component={Quiz} />
            <Route path={ROUTES.QUIZ_QUESTION} component={QuizQuestion} />
            <Route path={ROUTES.QUIZ_RESET} component={QuizReset} />

            <Route exact path={BLOG_PATHS} component={BlogRoot} />
            <Route exact path={ROUTES.BRAND} component={Brand} />
            <Route exact path={ROUTES.BRANDS} component={Brands} />
            <Route exact path={ROUTES.PRODUCT} component={Product} />
            <Route exact path={ROUTES.ABOUT_US} component={AboutUs} />
            <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route exact path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService} />
            <Route exact path={ROUTES.CATEGORY_LISTING} component={CategoryListing} />
            <Route exact path={ROUTES.PICKING_UP_PRODUCTS} component={PickingUpProducts} />

            <Route path={SEARCH_PATHS} component={SearchProducts} />
            <Route exact path={OLD_SEARCH_PATHS} component={SearchRedirect} />
            <Route exact path={ROUTES.SEARCH_HISTORY} component={SearchHistory} />

            <Route path={ROUTES.MOBILE_ONLY} component={MobileOnly} />

            <Route path={ROUTES.TEST} component={Test} />

            <Route exact path="*" component={NotFound} />
          </Switch>

          <Route exact path={FOOTER_PATHS} component={Footer} />

          <PrivateRoute path={NAVIGATION_PATHS} component={Navigation} withoutRedirect />
        </Suspense>

        <Loader />
        <ScrollToTop />
        <ModalNotificationBuild />
      </div>
    </PrevPath>
  );
}

export default Root;
