import React, { useRef, useMemo } from 'react';

import { CriticalErrorType, IlluminationCheckState } from 'constants/video-capture';

import { useStream } from 'hooks/video-capture/use-stream';

import Button from 'components/button';

import useFaceDetector from './hooks/use-face-detector';
import useBoxTextProps from './hooks/use-box-text-props';
import useIlluminationState from './hooks/use-illumination-state';
import useInstructionsEffect from './hooks/use-instructions-effect';
import useCameraAccessEffect from './hooks/use-camera-access-effect';
import usePrepareCapturingEffect from './hooks/use-prepare-capturing-effect';
import useInitializeFaceDetectorEffect from './hooks/use-initialize-face-detector-effect';
import { STREAM_OPTIONS } from './constants';
import IlluminationVideo from './components/illumination-video';

import styles from './styles.module.scss';

function IlluminationCheck({ onSuccess, onCameraRestrictions, onTakePicture }) {
  const [illuminationState, illuminationActions] = useIlluminationState();
  const videoRef = useRef(null);
  const [faceDetectorState, faceDetectorActions, faceDetectorHelpers] = useFaceDetector();
  const [streamState, streamActions, streamHelpers] = useStream({ ...STREAM_OPTIONS, videoRef });
  useInitializeFaceDetectorEffect({
    illuminationState,
    illuminationActions,
    faceDetectorState,
    faceDetectorActions,
    faceDetectorHelpers,
  });
  useCameraAccessEffect({
    streamState,
    streamActions,
    streamHelpers,
    illuminationState,
    illuminationActions,
    onCameraRestrictions,
  });
  useInstructionsEffect({ illuminationState, illuminationActions });
  usePrepareCapturingEffect({ illuminationState, illuminationActions });
  const {
    onVideoReady,
    onCheckingEnd,
    onFaceDetected,
    onFaceNoDetected,
    onInstructionsEnd,
    onPrepareCapturingEnd,
  } = useMemo(
    () => ({
      onVideoReady: () => illuminationActions.setIsVideoReady(true),
      onCheckingEnd: () => {
        illuminationActions.setIlluminationState(IlluminationCheckState.PrepareCapturing);
        streamActions.stopStream();
      },
      onFaceDetected: () => illuminationActions.resetCriticalError(CriticalErrorType.NoFaceDetected),
      onFaceNoDetected: () => illuminationActions.setCriticalError(CriticalErrorType.NoFaceDetected),
      onInstructionsEnd: () => illuminationActions.setIlluminationState(IlluminationCheckState.Checking),
      onPrepareCapturingEnd: onSuccess,
    }),
    [onSuccess],
  );
  const shouldShowVideoContent =
    illuminationState.state === IlluminationCheckState.Instructions ||
    illuminationState.state === IlluminationCheckState.Checking ||
    illuminationState.state === IlluminationCheckState.PrepareCapturing;
  const firstCriticalError = illuminationState.criticalErrors[0];
  const { isError, text } = useBoxTextProps({
    text: illuminationState.text,
    checkState: illuminationState.check,
    criticalError: firstCriticalError,
    isIlluminationChecking: illuminationState.state === IlluminationCheckState.Checking,
  });

  return (
    <IlluminationVideo
      ref={videoRef}
      isError={isError}
      className={styles.video}
      isVideoReady={shouldShowVideoContent}
      onVideoReady={onVideoReady}
      onTakePicture={onTakePicture}
      faceDetector={faceDetectorState.detector}
      setCheckState={illuminationActions.setCheck}
      onCheckingEnd={onCheckingEnd}
      isCriticalError={!!firstCriticalError}
      onFaceDetected={onFaceDetected}
      onFaceNoDetected={onFaceNoDetected}
      onInstructionsEnd={onInstructionsEnd}
      onPrepareCapturingEnd={onPrepareCapturingEnd}
      illuminationCheckState={illuminationState.state}
    >
      {text && (
        <p>
          {text}

          {firstCriticalError === CriticalErrorType.CameraAccess && (
            <>
              <br />

              <Button
                as="button"
                onClick={(event) => {
                  event.preventDefault();
                  streamActions.createStream();
                }}
                className={styles.errorLink}
              >
                ALLOW ACCESS
              </Button>
            </>
          )}
        </p>
      )}
    </IlluminationVideo>
  );
}
export { IlluminationCheck };
