import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CONFIG from 'constants/config';

import { isNotEmpty } from 'helpers/is';

import { selectors } from 'store/modules/user';

if (CONFIG.IS_ENABLED_LOGROCKET) {
  LogRocket.init(CONFIG.LOGROCKET_APP_ID);
}

const withLogRocket = (WrappedComponent) => {
  function WithLogRocket({ email, ...otherProps }) {
    useEffect(() => {
      if (CONFIG.IS_ENABLED_LOGROCKET && isNotEmpty(email)) {
        LogRocket.identify(email);
      }
    }, [email]);

    return <WrappedComponent {...otherProps} />;
  }

  const mapStateToProps = (state) => {
    return {
      email: selectors.getEmail(state),
    };
  };

  WithLogRocket.propTypes = {
    email: PropTypes.string,
  };

  WithLogRocket.defaultProps = {
    email: null,
  };

  return connect(mapStateToProps)(WithLogRocket);
};

export default withLogRocket;
