import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const createPersistReducer = (config, reducer) =>
  persistReducer(
    {
      ...config,
      storage,
      keyPrefix: 'skinguide_',
    },
    reducer,
  );

export default createPersistReducer;
