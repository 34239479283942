export const isEmpty = (v) => v === null || v === undefined;

export const isNotEmpty = (v) => !isEmpty(v);

export const isEmptyOrEmptyString = (v) => v === '' || isEmpty(v);

export const isString = (v) => typeof v === 'string';

export const isEmptyObject = (v) => Object.keys(v).length === 0;

export const isNotEmptyObject = (v) => !isEmptyObject(v);
