import { memo } from 'react';
import { compose } from '@reduxjs/toolkit';

import withModals from 'hocs/with-modals';
import withSegment from 'hocs/with-segment';
import withFloatingElements from 'hocs/with-floating-elements';

import Root from './components/root';

export default compose(withSegment, withFloatingElements, withModals, memo)(Root);
