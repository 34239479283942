import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FloatingElementsProvider } from 'contexts/floating-elements';

import useStore from './hooks/use-store';

import styles from './styles.module.scss';

function FloatingElements({ children }) {
  const {
    state: { ids, elements, variables },
    addElement,
    removeElement,
    updateElement,
  } = useStore();

  const contextValue = useMemo(
    () => ({
      variables,
      addElement,
      removeElement,
      updateElement,
    }),
    [variables, addElement, removeElement, updateElement],
  );

  const shouldRenderElements = ids.length !== 0;

  return (
    <FloatingElementsProvider value={contextValue}>
      {children}

      {shouldRenderElements && (
        <div className={styles.wrapper}>{ids.map((id) => elements[id])}</div>
      )}
    </FloatingElementsProvider>
  );
}

FloatingElements.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default FloatingElements;
