import { pickHex } from 'helpers/color';
import { transformValueForNewRange } from 'helpers/range';

import { INNER_CIRCLE_RANGE_START, INNER_CIRCLE_RANGE_END } from './constants';

export const pickColorFromGradient = (gradient, value) => {
  const index = gradient.findIndex((color) => color.offset >= value * 100);
  const firstColor = gradient[index - 1] ?? gradient[0];
  const secondColor = gradient[index];
  const valueRelativeToColor = (value * 100 - firstColor.offset) / 100;
  const distance = secondColor.offset / 100 - firstColor.offset / 100;
  const percentRelativeToColor = distance === 0 ? 0 : valueRelativeToColor / distance;
  return pickHex(secondColor.rgbColor, firstColor.rgbColor, percentRelativeToColor);
};
export const correctValueForNewRange = (value) =>
  transformValueForNewRange({
    value,
    rangeEnd: INNER_CIRCLE_RANGE_END,
    rangeStart: INNER_CIRCLE_RANGE_START,
  });
export const getArrowOpacity = (value) => (value >= 0.9 ? (1 - value) * 10 : 1);
export const getRotateAngleForSlider = (value) => (value === 1 ? 0 : (1 - value) * 180);
