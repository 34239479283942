import React from 'react';
import { ApolloProvider } from '@apollo/client';

const withApolloProvider = ({ client }) => (WrappedComponent) =>
  function WithApolloProvider(props) {
    return (
      <ApolloProvider client={client}>
        <WrappedComponent {...props} />
      </ApolloProvider>
    );
  };

export default withApolloProvider;
