import React from 'react';

import styles from './styles.module.scss';

export function CameraRestrictions({ isUnsupportedDevice }) {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>We’re sorry!</p>

      <p>
        Your camera hardware cannot capture the required image resolution. If another device is available, please try
        again.
        <br />
        <br />
        {isUnsupportedDevice && (
          <>
            Reason: user media not found.
            <br />
            <br />
          </>
        )}
        Follow us on instagram for updates on phone support.
      </p>
    </div>
  );
}
