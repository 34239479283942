import { useEffect } from 'react';
import LogRocket from 'logrocket';

import { CriticalErrorType, ImageCaptureState, FACE_DETECTOR_TEXTS } from 'constants/video-capture';

const useFaceDetectorLoadingEffect = ({
  detectorState,
  detectorActions,
  detectorHelpers,
  imageCaptureState,
  imageCaptureActions,
}) => {
  useEffect(() => {
    if (imageCaptureState.state !== ImageCaptureState.LoadingDetector) {
      return;
    }
    if (detectorHelpers.isStateIdle()) {
      imageCaptureActions.setText(FACE_DETECTOR_TEXTS.INITIALIZING_FACE_DETECTOR);
      detectorActions.create();
      return;
    }
    if (detectorHelpers.isStateInitializing()) {
      imageCaptureActions.setText(FACE_DETECTOR_TEXTS.INITIALIZING_FACE_DETECTOR);
      return;
    }
    if (detectorHelpers.isStateReady()) {
      imageCaptureActions.resetCriticalError(CriticalErrorType.FaceDetectorFailed);
      imageCaptureActions.setImageCaptureState(ImageCaptureState.InitializingVideoRecorder);
      imageCaptureActions.setText(null);
      return;
    }
    if (detectorHelpers.isStateFailed()) {
      imageCaptureActions.setCriticalError(CriticalErrorType.FaceDetectorFailed);
      LogRocket.error('video-capture-face-detector-initializing', detectorState.error);
    }
  }, [imageCaptureState.state, detectorState]);
};
export default useFaceDetectorLoadingEffect;
