import React, { memo, useRef, useEffect } from 'react';
import classnames from 'classnames';

import CONFIG from 'constants/config';
// import { useQuizLayoutContext } from '^/hooks/use-quiz-layout-context';
// import { useDataSubmissionIDContext } from '^/hooks/use-data-submission-id-context';
// import { QuestionHeader } from '^/components/quiz-question-header';
// import { QuestionWrapper } from '^/components/quiz-question-wrapper';

import styles from './styles.module.scss';

const VideoInstructionsDemo = memo(({ className }) => {
  // const dataSubmissionID = useDataSubmissionIDContext();
  // const quizLayoutContext = useQuizLayoutContext();
  const cacheRef = useRef({
    isVideoEnded: false,
    isVideoStarted: false,
    isButtonWasEnabled: false,
  });

  useEffect(() => {
    if (CONFIG.IS_LOCAL_BUILD) {
      return;
    }
    // quizLayoutContext.disableConfirmButton();
  }, []);

  const videoRef = useRef(null);
  // const analyticContext = useAnalyticsContext();

  return (
    <div className={classnames(styles.wrapper, className)}>
      <video
        ref={videoRef}
        loop
        muted
        autoPlay
        className={styles.video}
        playsInline
        poster="https://res.cloudinary.com/hqb85rb4o/video/upload/v1664291552/static/video/video-capture-demo-220920-cropped-v2_jgfxja.jpeg"
        onTimeUpdate={(event) => {
          if (event.currentTarget.currentTime <= 1 && !cacheRef.current.isVideoStarted) {
            cacheRef.current.isVideoStarted = true;
            cacheRef.current.isVideoEnded = false;
          } else if (
            event.currentTarget.currentTime >= event.currentTarget.duration - 1 &&
            !cacheRef.current.isVideoEnded
          ) {
            cacheRef.current.isVideoEnded = true;
            cacheRef.current.isVideoStarted = false;
          }

          if (event.currentTarget.currentTime > 16 && !cacheRef.current.isButtonWasEnabled) {
            // quizLayoutContext.enableConfirmButton();
            cacheRef.current.isButtonWasEnabled = true;
          }
        }}
      >
        <source
          src="https://res.cloudinary.com/hqb85rb4o/video/upload/v1664291552/static/video/video-capture-demo-220920-cropped-v2_jgfxja.mp4"
          type="video/mp4"
        />

        <source
          src="https://res.cloudinary.com/hqb85rb4o/video/upload/v1664291552/static/video/video-capture-demo-220920-cropped-v2_jgfxja.webm"
          type="video/webm"
        />
      </video>
    </div>
  );
});

export default VideoInstructionsDemo;
