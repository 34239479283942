import createPersistReducer from 'store/helpers/create-persist-reducer';

import { actions as defaultActions, reducer as defaultReducer } from './reducer';

import * as customActions from './actions';
import * as selectors from './selectors';
import * as thunks from './thunks';

const reducer = createPersistReducer(
  {
    key: 'selfie',
    version: 2,
    blacklist: ['selfies', 'requests'],
  },
  defaultReducer,
);

const actions = {
  ...customActions,
  ...defaultActions,
};

export { thunks, selectors, reducer, actions };
