import React from 'react';
import classnames from 'classnames';

import { ReactComponent as FaceSVG } from 'static/images/video-capture/small-face.svg';
import { ReactComponent as IphoneSVG } from 'static/images/video-capture/iphone.svg';
import { ReactComponent as IphoneStrokeSVG } from 'static/images/video-capture/iphone-stroke.svg';

import styles from './styles.module.scss';

const PhoneSvg = ({ className }) => (
  <div className={classnames(styles.wrapper, className)}>
    <div className={styles.inner}>
      <FaceSVG className={styles.face} />
      <IphoneSVG className={styles.iphone} />
      <IphoneStrokeSVG className={styles.iphoneStroke} />
    </div>
  </div>
);

export { PhoneSvg };
