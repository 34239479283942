import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text } from 'components/fields';

import styles from './styles.module.scss';

function Range({ name, value, min, max, prefix, placeholder, onChange, className }) {
  const classNames = classnames(styles.range, className);

  const onChangeHandler = useCallback(
    ({ name: fieldName, value: fieldValue }) =>
      onChange({
        name,
        value: {
          ...value,
          [fieldName]: fieldValue === '' ? null : Number(fieldValue),
        },
      }),
    [name, onChange, value],
  );

  const description = `${prefix}${min} - ${prefix}${max}+`;

  return (
    <div className={classNames}>
      <p className={styles.description}>{description}</p>

      <div className={styles.inputs}>
        <Text
          min={min}
          max={max}
          name="min"
          type="number"
          value={value.min || ''}
          onChange={onChangeHandler}
          className={styles.input}
          placeholder={placeholder.min}
        />

        <span className={styles.separator}>to</span>

        <Text
          min={min}
          max={max}
          name="max"
          type="number"
          value={value.max || ''}
          onChange={onChangeHandler}
          className={styles.input}
          placeholder={placeholder.max}
        />
      </div>
    </div>
  );
}

Range.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  prefix: PropTypes.string,
  className: PropTypes.string,
};

Range.defaultProps = {
  value: { min: '', max: '' },
  placeholder: {},
  prefix: '',
  className: null,
};

export default Range;
