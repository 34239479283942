import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import { datumApi } from 'api/datum';

import { getFilters } from './selectors';
import { productsSchema } from './schema';
import { createSearchParamsForFilters, parseFiltersFromResponse } from './helpers';

const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async ({ categoryId }, { getState }) => {
    const { isDefault, filter } = getFilters(getState())[categoryId];

    const data = await datumApi.getProducts({
      filter: createSearchParamsForFilters(filter),
      categoryId,
      withFilter: !isDefault,
    });

    const {
      entities: { products, meta, filters },
      result,
    } = normalize(data, productsSchema);

    const nextState = {
      entities: {
        meta,
        products,
      },
      productIds: result.data.sort((a, b) => meta[b].matchScore - meta[a].matchScore),
    };

    if (filters) {
      nextState.defaultFilters = parseFiltersFromResponse(filters);
    }

    return nextState;
  },
);

const fetchProduct = createAsyncThunk('products/fetchProduct', async (productId) => {
  const { data: product, meta } = await datumApi.getProduct(productId);

  return {
    meta,
    product,
  };
});

const fetchProductBrands = createAsyncThunk('products/fetchProductBrands', async () => {
  const { data: brands } = await datumApi.getProductBrands();

  return brands;
});

const fetchProductsBrand = createAsyncThunk('products/fetchProductsBrand', async (brandId) => {
  const { data: products } = await datumApi.getProductsBrand(brandId);

  return products;
});

const fetchSimilarProductsByProductId = createAsyncThunk(
  'products/fetchSimilarProductsByProductId',
  async (productId) => {
    const data = await datumApi.getSimilarProductsByProductId(productId);

    const {
      result: { data: productIds },
      entities: { products, meta },
    } = normalize(data, productsSchema);

    return {
      meta,
      products,
      productIds,
    };
  },
);

const fetchRelatedProductsByProductId = createAsyncThunk(
  'products/fetchRelatedProductsByProductId',
  async (productId) => {
    const data = await datumApi.getRelatedProductsByProductId(productId);

    const {
      result: { data: productIds },
      entities: { products, meta },
    } = normalize(data, productsSchema);

    return {
      meta,
      products,
      productIds,
    };
  },
);

const fetchCategoryListing = createAsyncThunk(
  'products/fetchCategoryListing',
  async ({ category, letter }) => {
    const apiMethodName =
      letter === '0-9' ? 'getCategoryListingByNum' : 'getCategoryListingByLetter';

    const { data: products } = await datumApi[apiMethodName](category, letter);

    return products;
  },
);

export {
  fetchProduct,
  fetchProducts,
  fetchProductBrands,
  fetchProductsBrand,
  fetchCategoryListing,
  fetchRelatedProductsByProductId,
  fetchSimilarProductsByProductId,
};
