import { addOffsetsToBoundingRect } from 'helpers/geometry';

import { FaceState, THRESHOLD_ANGLES, FACE_BOX_VIEW_SIZE, FACE_SIZE_BORDERS_SCALE } from '../../../../constants';
import { DISTANCE } from './constants';

export const isValidAngle = ({ angle, degrees }) =>
  -THRESHOLD_ANGLES + angle < degrees && degrees < angle + THRESHOLD_ANGLES;
export const getFaceStateByBoundingRects = ({
  videoWidth,
  videoHeight,
  isDistanceOut,
  faceBoundingRect,
  photoAreaBoundingRect,
  faceBoundingRectWithOffsets,
}) => {
  const heightScale = faceBoundingRect.height / photoAreaBoundingRect.height;
  if (heightScale > (isDistanceOut ? DISTANCE.OUT.TO : DISTANCE.IN.TO)) {
    return FaceState.TooBig;
  }
  if (heightScale < (isDistanceOut ? DISTANCE.OUT.FROM : DISTANCE.IN.FROM)) {
    return FaceState.TooSmall;
  }
  if (faceBoundingRect.left < 0) {
    return FaceState.BeyondLeftBorder;
  }
  if (faceBoundingRect.right > videoWidth) {
    return FaceState.BeyondRightBorder;
  }
  if (faceBoundingRectWithOffsets.top < 0) {
    return FaceState.BeyondTopBorder;
  }
  if (faceBoundingRect.bottom > videoHeight) {
    return FaceState.BeyondBottomBorder;
  }
  return FaceState.Detected;
};
export const getBoundingRectFromPoints = (points, scale = 1) => {
  const { length } = points;
  let i = 0;
  let minX = points[0][0];
  let minY = points[0][1];
  let maxX = points[0][0];
  let maxY = points[0][1];
  for (; i < length; i += 1) {
    const [x, y] = points[i];
    if (minX > x) {
      minX = x;
    }
    if (maxX < x) {
      maxX = x;
    }
    if (minY > y) {
      minY = y;
    }
    if (maxY < y) {
      maxY = y;
    }
  }
  minX *= scale;
  minY *= scale;
  maxX *= scale;
  maxY *= scale;
  const width = maxX - minX;
  const height = maxY - minY;
  const aspectRatio = width / height;
  return {
    x: minX,
    y: minY,
    width,
    height,
    top: minY,
    left: minX,
    right: maxX,
    bottom: maxY,
    aspectRatio,
  };
};
export const getPhotoAreaBoundingRect = ({ videoWidth, videoHeight }) => {
  const faceBoxSize = (Math.min(videoWidth, videoHeight) * FACE_BOX_VIEW_SIZE) / 100;
  const photoAreaSize = faceBoxSize * FACE_SIZE_BORDERS_SCALE;
  const topLeftX = (videoWidth - photoAreaSize) / 2;
  const topLeftY = (videoHeight - photoAreaSize) / 2;
  const bottomRightX = videoWidth - topLeftX;
  const bottomRightY = videoHeight - topLeftY;
  return getBoundingRectFromPoints([
    [topLeftX, topLeftY],
    [bottomRightX, bottomRightY],
  ]);
};
export const getFaceState = ({ prediction, videoWidth, videoHeight, isDistanceOut }) => {
  if (!prediction) {
    return FaceState.NoDetected;
  }
  const photoAreaBoundingRect = getPhotoAreaBoundingRect({ videoWidth, videoHeight });
  const faceBoundingRect = getBoundingRectFromPoints(prediction.mesh);
  const faceBoundingRectWithOffsets = addOffsetsToBoundingRect(faceBoundingRect, { top: 0.2 });
  const faceState = getFaceStateByBoundingRects({
    videoWidth,
    videoHeight,
    isDistanceOut,
    faceBoundingRect,
    photoAreaBoundingRect,
    faceBoundingRectWithOffsets,
  });
  return faceState;
};
