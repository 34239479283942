import { put, takeEvery, select } from 'redux-saga/effects';

import { QUIZ_END_ID, QUIZ_TYPES, QUIZZES } from 'constants/quiz';

import segment, { EVENTS } from 'libs/analytic-segment';

import { selectors as appSelectors } from 'store/modules/app';
import { selectors as selfieSelectors } from 'store/modules/selfie';
import { selectors as userSelectors } from 'store/modules/user';
import {
  thunks as quizThunks,
  actions as quizActions,
  helpers as quizHelpers,
  selectors as quizSelectors,
} from 'store/modules/quiz';

function* nextQuestionHandler({ payload: { isBreak, currentQuestionId, quizType } }) {
  const quiz = yield select((state) => quizSelectors.getQuizByType(state, quizType));
  const appVersion = yield select((state) => appSelectors.getVersion(state));
  const isAuthenticated = yield select(userSelectors.getIsAuthenticated);
  const { isSkipped: isSelfieSkipped } = yield select(selfieSelectors.getSelfie);

  const { id: nextQuestionId } = quizHelpers.getNextQuestion({
    quiz,
    quizType,
    appVersion,
    isAuthenticated,
    isSelfieSkipped,
    currentQuestionId,
  });

  const isLastQuestion =
    nextQuestionId === QUIZ_END_ID ||
    nextQuestionId === QUIZZES[QUIZ_TYPES.REGULAR].LAST_QUESTION_ID ||
    nextQuestionId === QUIZZES[QUIZ_TYPES.REGULAR].LAST_QUESTION_ID_FOR_SKIN_EMAIL_VERSION;

  if (isLastQuestion) {
    yield put(quizActions.done(quizType));

    segment.track(EVENTS.QUIZ_COMPLETE);

    if (isAuthenticated) {
      return;
    }
  }

  yield put(
    quizThunks.goToNextQuestion({
      quizType,
      currentQuestionId,
      shouldReplaceHistory: isBreak,
    }),
  );
}

export default function* quizSaga() {
  yield takeEvery(quizActions.nextQuestion, nextQuestionHandler);
}
