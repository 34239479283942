import { createSlice } from '@reduxjs/toolkit';

import { REQUEST_STATUSES } from 'constants/request';

import { getErrorMessage } from 'helpers/request';

import { saveSelfies } from './thunks';
import { updateProgress } from './actions';

const DEFAULT_STATE = {
  selfies: [],
  cloudinaryUrls: [],

  requests: {
    saveSelfies: {
      state: REQUEST_STATUSES.IDLE,
      error: null,
      progress: 0,
    },
  },

  isSkipped: false,
};

export const { reducer, actions } = createSlice({
  name: 'selfie',
  initialState: DEFAULT_STATE,
  reducers: {
    setSelfies: (draftState, { payload: selfies }) => {
      draftState.selfies = selfies;
      draftState.isSkipped = false;
    },

    setCloudinaryUrls: (draftState, { payload: cloudinaryUrls }) => {
      draftState.cloudinaryUrls = cloudinaryUrls;
    },

    skip: (draftState) => {
      draftState.isSkipped = true;
    },

    reset: () => DEFAULT_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(updateProgress, (draftState, { payload: progress }) => {
      draftState.requests.saveSelfies.progress = progress;
    });

    builder.addCase(saveSelfies.pending, (draftState) => {
      draftState.requests.saveSelfies.state = REQUEST_STATUSES.FETCHING;
      draftState.requests.saveSelfies.progress = 0;
    });

    builder.addCase(saveSelfies.fulfilled, (draftState, { payload: cloudinaryUrls }) => {
      draftState.selfies = [];
      draftState.cloudinaryUrls = cloudinaryUrls;
      draftState.requests.saveSelfies.state = REQUEST_STATUSES.FETCHED;
    });

    builder.addCase(saveSelfies.rejected, (draftState, { error }) => {
      draftState.requests.saveSelfies.state = REQUEST_STATUSES.FAILED;
      draftState.requests.saveSelfies.error = getErrorMessage(error);
    });
  },
});
