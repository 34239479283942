import React, { memo, useRef, forwardRef, useImperativeHandle } from 'react';
import classnames from 'classnames';

import Circle from './components/circle';
import MeasurementPart from './components/measurement-part';

import useMeasurementApi from './hooks/use-measurement-api';

import { VIEW_BOX_WIDTH, VIEW_BOX_HEIGHT, VIEW_BOX_HALF_WIDTH, SUCCESS_CIRCLES_HALF_VIEW_BOX } from './constants';

const Measurement = forwardRef(({ className, shadowValue = 0, saturationValue = 0 }, ref) => {
  const circleApiRef = useRef(null);
  const shadowApiRef = useRef(null);
  const saturationApiRef = useRef(null);
  const measurementApi = useMeasurementApi({ circleApiRef, shadowApiRef, saturationApiRef });
  useImperativeHandle(ref, () => measurementApi, []);
  const isShadowSuccess = shadowValue === 1;
  const isSaturationSuccess = saturationValue === 1;
  const isSuccess = isShadowSuccess && isSaturationSuccess;

  return (
    <svg viewBox={`0 0 ${VIEW_BOX_WIDTH} ${VIEW_BOX_HEIGHT}`} className={classnames(className)}>
      <MeasurementPart
        ref={saturationApiRef}
        value={saturationValue}
        color="var(--revea--illumination-saturation--color)"
        borderColor="var(--revea--illumination-shadow--color)"
        isSuccess={isSuccess}
      />

      <g transform={`translate(${VIEW_BOX_WIDTH}, 0) scale(-1,1)`}>
        <MeasurementPart
          ref={shadowApiRef}
          value={shadowValue}
          color="var(--revea--illumination-shadow--color)"
          borderColor="var(--revea--illumination-saturation--color)"
          isSuccess={isSuccess}
        />
      </g>

      <Circle
        x={VIEW_BOX_HALF_WIDTH}
        y={VIEW_BOX_HEIGHT - SUCCESS_CIRCLES_HALF_VIEW_BOX - 3}
        ref={circleApiRef}
        isLeftCursorFilled={isSaturationSuccess}
        isRightCursorFilled={isShadowSuccess}
      />
    </svg>
  );
});
export const IlluminationMeasurement = memo(Measurement);
