import React, { memo, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  MAIN_CIRCLE_VIEW_BOX_SIZE,
  SLIDER_HALF_HEIGHT,
  WRAPPER_CIRCLE,
  MAIN_CIRCLE_HALF_VIEW_BOX_SIZE,
} from '../../constants';
import Arrow from '../arrow';
import Slider from '../slider';
import useIds from './hooks/use-ids';
import useMeasurementPartApi from './hooks/use-measurement-part-api';

import { getArrowOpacity, getRotateAngleForSlider, correctValueForNewRange } from './helpers';

import styles from './styles.module.scss';

const MeasurementPart = forwardRef(({ color, value: valueFromProps, borderColor, isSuccess }, ref) => {
  const arrowRef = useRef(null);
  const sliderRef = useRef(null);
  const lineMaskRef = useRef(null);
  const sliderApiRef = useRef(null);
  const strokeMaskRef = useRef(null);
  const innerCircleRef = useRef(null);
  const ids = useIds();
  const measurementPartApi = useMeasurementPartApi({
    arrowRef,
    sliderRef,
    lineMaskRef,
    strokeMaskRef,
    innerCircleRef,
  });
  useImperativeHandle(ref, () => measurementPartApi, []);
  const value = correctValueForNewRange(valueFromProps);
  const arrowOpacity = getArrowOpacity(valueFromProps);
  const rotateSlider = getRotateAngleForSlider(valueFromProps === 1 ? valueFromProps : value);

  return (
    <svg viewBox={`0 0 ${MAIN_CIRCLE_VIEW_BOX_SIZE} ${MAIN_CIRCLE_VIEW_BOX_SIZE}`} className={styles.wrapper}>
      <defs>
        <clipPath id={ids.clipPathInnerCircleId.toString()}>
          <rect x="0" y="0" width="50%" height="100%" />
        </clipPath>

        <mask id={ids.clipPathWrapperCircleId.toString()}>
          <rect x="0" y="0" width="100%" height="100%" fill="white" />

          <rect x="50%" y="0" width="50%" height="100%" fill="black" />

          <circle cx="50%" cy="100%" r="50" fill="black" />

          <rect
            x="0"
            y="0"
            ref={strokeMaskRef}
            fill="black"
            width="50%"
            style={{ transform: `rotate(${rotateSlider}deg)` }}
            height="100%"
            className={styles.rotateElement}
          />
        </mask>

        <mask id={ids.clipPathWrapperCircleLineId.toString()}>
          <rect x="0" y="0" width="100%" height="100%" fill="white" />

          <rect x="50%" y="0" width="50%" height="100%" fill="black" />

          <rect
            x="50%"
            y="0"
            ref={lineMaskRef}
            fill="black"
            style={{ transform: `rotate(${rotateSlider}deg)` }}
            width="50%"
            height="100%"
            className={styles.rotateElement}
          />
        </mask>
      </defs>

      <circle
        cx={WRAPPER_CIRCLE.CX}
        cy={WRAPPER_CIRCLE.CY}
        r={WRAPPER_CIRCLE.RADIUS}
        stroke="white"
        strokeWidth={1}
        fill="none"
        mask={`url(#${ids.clipPathWrapperCircleId})`}
        strokeDasharray="5 5"
        strokeDashoffset="5"
      />

      <circle
        cx={WRAPPER_CIRCLE.CX}
        cy={WRAPPER_CIRCLE.CY}
        r={WRAPPER_CIRCLE.RADIUS}
        fill="none"
        ref={innerCircleRef}
        mask={`url(#${ids.clipPathWrapperCircleLineId})`}
        stroke="#fff"
        strokeWidth={WRAPPER_CIRCLE.STROKE_WIDTH}
      />

      <g ref={arrowRef} style={{ transform: `rotate(8deg)`, opacity: arrowOpacity }} className={styles.arrow}>
        <Arrow x={MAIN_CIRCLE_HALF_VIEW_BOX_SIZE - 25} y={MAIN_CIRCLE_VIEW_BOX_SIZE - 24} />
      </g>

      <g
        ref={sliderRef}
        style={{
          opacity: isSuccess ? 0 : 1,
          transform: `rotate(${rotateSlider}deg)`,
        }}
        className={styles.rotateElement}
      >
        <svg x={0} y={0} width={MAIN_CIRCLE_HALF_VIEW_BOX_SIZE + SLIDER_HALF_HEIGHT} height={MAIN_CIRCLE_VIEW_BOX_SIZE}>
          <rect width="100%" height="100%" fill="none" />

          <g transform={`translate(${MAIN_CIRCLE_HALF_VIEW_BOX_SIZE + SLIDER_HALF_HEIGHT}, 361) rotate(90)`}>
            <Slider ref={sliderApiRef} color={color} stroke={borderColor} />
          </g>
        </svg>
      </g>
    </svg>
  );
});
export default memo(MeasurementPart);
