import { useCreateConst } from 'hooks/video-capture/use-create-const';

const useSliderApi = ({ arrowRef }) =>
  useCreateConst(() => ({
    setOpacity: (opacity) => {
      if (!arrowRef.current) {
        return;
      }
      arrowRef.current.style.opacity = opacity.toString();
    },
    setColor: (color) => {
      if (!arrowRef.current) {
        return;
      }
      arrowRef.current.style.fill = color;
    },
    setStroke: (color) => {
      if (!arrowRef.current) {
        return;
      }
      arrowRef.current.style.stroke = color;
    },
  }));

export default useSliderApi;
