import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { MODALS } from 'constants/modals';

export default function useCallbacks({
  onClick,
  openModal,
  matchScore,
  withModals,
  performanceVector,
}) {
  const { push } = useHistory();

  const onPrivateGaugeClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      onClick();

      if (withModals) {
        openModal(MODALS.MATCH_SCORE_EXPLANATION, {
          product: {
            matchScore,
            performanceVector,
          },
        });
      }
    },
    [openModal, onClick, withModals, matchScore, performanceVector],
  );

  const onGuestGaugeClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      onClick();

      if (withModals) {
        openModal(MODALS.MATCH_SCORE_EXPLANATION_GUEST, { push });
      }
    },
    [openModal, onClick, withModals, push],
  );

  return {
    onGuestGaugeClick,
    onPrivateGaugeClick,
  };
}
