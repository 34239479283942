import React from 'react';
import classnames from 'classnames';

import { ReactComponent as ArrowSVG } from 'static/images/video-capture/hint-arrow.svg';

import styles from './styles.module.scss';

export const ImageCaptureArrowHint = ({ position, className, withAnimation }) => (
  <div className={classnames(styles.wrapper, className, styles[position], { [styles.withAnimation]: withAnimation })}>
    <ArrowSVG className={styles.arrow} />
    <ArrowSVG className={styles.arrow} />
    <ArrowSVG className={styles.arrow} />
  </div>
);
