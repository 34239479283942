import React, { useRef, useState, useLayoutEffect } from 'react';
import classnames from 'classnames';

import Link from 'components/link';

import styles from './styles.module.scss';

const CIRCLE_TOP = 0.2;
const CIRCLE_WIDTH = 0.84;

export function LayoutMask({
  footer,
  isError = false,
  children,
  className,
  isAppReady,
  withoutHelp = false,
  needHelpText,
  onNeedHelpClick,
  isCriticalError = false,
  withTransparency = false,
}) {
  const [state, setState] = useState(null);
  const wrapperRef = useRef(null);
  useLayoutEffect(() => {
    const { current: wrapper } = wrapperRef;
    if (!wrapper) {
      return () => {};
    }
    const styleElement = document.createElement('style');
    const updateStyles = () => {
      const size = (wrapper.offsetWidth * CIRCLE_WIDTH) / 2;
      const top = wrapper.offsetHeight * CIRCLE_TOP;
      const gradient = `radial-gradient(50% ${top + size}px, circle closest-side, transparent 84%, black 84%)`;
      const webkitGradient = `-webkit-${gradient}`;
      const stylesString = `
      .${styles.inner} {
        -webkit-mask-image: ${webkitGradient};
        -webkit-mask-image: ${gradient};
        mask-image: ${webkitGradient};
        mask-image: ${gradient};
      }
      `;
      styleElement.textContent = stylesString;
      setState({ top, size });
    };
    const resizeObserver = new ResizeObserver(updateStyles);
    resizeObserver.observe(wrapper);
    document.head.appendChild(styleElement);
    return () => {
      resizeObserver.unobserve(wrapper);
      document.head.removeChild(styleElement);
    };
  }, []);
  return (
    <div
      style={{
        '--content-size': `${(state?.size ?? 0) * 2}px`,
        '--content-offset-top': `${state?.top ?? 0}px`,
      }}
      className={classnames(styles.wrapper, className)}
    >
      <div
        ref={wrapperRef}
        className={classnames(styles.inner, {
          [styles.error]: isError,
          [styles.initializing]: !isAppReady,
          [styles.criticalError]: isCriticalError,
          [styles.withTransparency]: withTransparency,
        })}
      >
        <div className={styles.content}>{children}</div>

        {isAppReady && !withoutHelp && (
          <Link
            as="button"
            underline
            onClick={(event) => {
              event.preventDefault();

              onNeedHelpClick?.();
            }}
            className={styles.needHelpLink}
          >
            {needHelpText || 'Need help?'}
          </Link>
        )}
      </div>

      {footer}
    </div>
  );
}
