import React, { useEffect } from 'react';
import LogRocket from 'logrocket';

import Button from 'components/button';
import { useUploadAssetsToCloudinary } from './hooks/use-upload-assets-to-cloudinary';

import styles from './styles.module.scss';

export function VideoUploader({ image, videoBlob, onUploaded, videoContextData, dataSubmissionId }) {
  const [uploadState, uploadActions, uploadHelpers] = useUploadAssetsToCloudinary({
    image,
    videoBlob,
    dataSubmissionId,
    videoContextData,
  });

  useEffect(() => {
    uploadActions.upload();
    if (!videoBlob || videoBlob.size === 0) {
      LogRocket.error('video-capture-uploading', { message: 'Video blob is empty' });
    }
  }, []);

  useEffect(() => {
    if (!uploadHelpers.isStateFetched()) {
      return;
    }

    onUploaded({
      videoUrl: uploadState.videoUrl,
      imageUrl: uploadState.imageUrl,
    });
  }, [uploadState]);

  const progress = uploadState.imageUploadProgress / 2 + uploadState.videoUploadProgress / 2;

  return (
    <div className={styles.wrapper}>
      {uploadHelpers.isStateFailed() && (
        <div>
          <p className={styles.title}>Upload failed</p>

          <p>Something went wrong. Try to upload again.</p>

          <Button as="button" variant="primary" onClick={uploadActions.upload}>
            Try to upload again
          </Button>
        </div>
      )}

      {uploadHelpers.isStateFetching() && (
        <div className="text-black">
          <p className={styles.title}>Uploading...</p>

          <p className={styles.upload}>{(progress * 100).toFixed(0)}%</p>
        </div>
      )}
    </div>
  );
}
