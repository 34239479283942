import React, { useRef, useLayoutEffect } from 'react';
import Modal from 'react-modal';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';

import { MODALS } from 'constants/modals';

import styles from './styles.module.scss';

Modal.setAppElement(document.getElementById('root'));

const modalRoot = document.getElementById('modals');

export const withModalWrapper = (Wrapper) =>
  NiceModal.create((props) => {
    const modal = useModal();
    const contentScrollRef = useRef();

    const isRetakingVideoCapture = modal.id === MODALS.RetakeVideoCapture;

    const isBlockingUI = isRetakingVideoCapture;

    const classNames = React.useMemo(() => {
      return {
        content: {
          afterOpen: styles.contentAfterOpen,
          beforeClose: styles.contentBeforeClose,
          base: classnames(styles.content, { [styles.full]: isRetakingVideoCapture }),
        },
        overlay: {
          base: styles.overlay,
          afterOpen: styles.overlayAfterOpen,
          beforeClose: styles.overlayBeforeClose,
        },
      };
    }, [modal.id]);

    useLayoutEffect(() => {
      if (modal.visible || !contentScrollRef.current) {
        return undefined;
      }

      enableBodyScroll(contentScrollRef.current);

      return () => {
        contentScrollRef.current?.removeEventListener('click', modal.hide);
      };
    }, [modal.visible]);

    return (
      <Modal
        isOpen={modal.visible}
        className={classNames.content}
        overlayClassName={classNames.overlay}
        contentRef={(element) => {
          contentScrollRef.current = element;
        }}
        onAfterOpen={() => {
          disableBodyScroll(contentScrollRef.current);
        }}
        onAfterClose={() => {
          modal.remove();
          props?.onClose?.();
        }}
        onRequestClose={isBlockingUI ? () => {} : modal.hide}
        closeTimeoutMS={300}
        parentSelector={() => modalRoot}
        contentElement={(divProps, children) => (
          <div {...divProps} onClick={modal.hide}>
            {children}
          </div>
        )}
      >
        <Wrapper {...props} closeModal={modal.hide} />
      </Modal>
    );
  });
