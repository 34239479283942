import React, { memo, useMemo, useRef, forwardRef } from 'react';
import classnames from 'classnames';
import composeRefs from '@seznam/compose-react-refs';
import NiceModal from '@ebay/nice-modal-react';

import { MODALS } from 'constants/modals';
import { IlluminationCheckState } from 'constants/video-capture';

import { LayoutMask } from 'components/video-capture/layout-mask';
import { IlluminationMeasurement } from 'components/video-capture/illumination-measurement';

import useLifecycle from 'hooks/use-lifecycle';
import { useVideoState } from 'hooks/video-capture/use-video-state';

import Mask from '../mask';
import Illumination from '../illumination';
import useThresholds from './hooks/use-thresholds';
import useIllumination from './hooks/use-illumination';
import useInstructionsEffect from './hooks/use-instructions-effect';
import useOnVideoReadyEffect from './hooks/use-on-video-ready-effect';
import useOnFaceDetectEffect from './hooks/use-on-face-detect-effect';
import useOnCheckingEndEffect from './hooks/use-on-checking-end-effect';
import useChangeCheckStateEffect from './hooks/use-change-check-state-effect';
import useOnPrepareCapturingEndEffect from './hooks/use-on-prepare-capturing-end-effect';

import { ApiVersion } from './types';

import styles from './styles.module.scss';

const IlluminationVideo = forwardRef(
  (
    {
      isError,
      children,
      className,
      isVideoReady,
      faceDetector,
      onVideoReady,
      onCheckingEnd,
      setCheckState,
      onTakePicture,
      onFaceDetected,
      isCriticalError,
      onFaceNoDetected,
      onInstructionsEnd,
      onPrepareCapturingEnd,
      illuminationCheckState,
    },
    forwardVideoRef,
  ) => {
    const videoRef = useRef(null);
    const measurementApiRef = useRef(null);
    const illuminationCanvasRef = useRef(null);
    const helpModal = NiceModal.useModal(MODALS.IlluminationCheckHelp);
    const lifecycle = useLifecycle();
    const [videoState, videoStateActions] = useVideoState();
    const thresholds = useThresholds(ApiVersion.V2);
    const [illuminationState, onIlluminationChange] = useIllumination({ thresholds, measurementApiRef });
    const isCheckingStep = illuminationCheckState === IlluminationCheckState.Checking;
    const isInstructionsStep = illuminationCheckState === IlluminationCheckState.Instructions;
    const isPrepareImageCapturing = illuminationCheckState === IlluminationCheckState.PrepareCapturing;
    useOnVideoReadyEffect({ videoState, onVideoReady });
    useInstructionsEffect({ lifecycle, isInstructionsStep, measurementApiRef, onInstructionsEnd });
    useOnFaceDetectEffect({
      isCheckingStep,
      onFaceDetected,
      onFaceNoDetected,
      isFaceNotDetected: !illuminationState.isFaceDetected,
    });
    useChangeCheckStateEffect({
      illuminationState,
      setCheckState,
      isCheckingStep,
      isValidIlluminationValues: illuminationState.isValidIlluminationValues,
    });
    useOnCheckingEndEffect({
      videoRef,
      onCheckingEnd,
      onTakePicture: onTakePicture
        ? (image) => {
            onTakePicture({
              image,
              metadata: {
                thresholdShadowToOk: thresholds.shadow.toOk,
                thresholdShadowFromOk: thresholds.shadow.fromOk,
                thresholdSaturationToOk: thresholds.saturation.toOk,
                thresholdSaturationFromOk: thresholds.saturation.fromOk,
                thresholdBackgroundSaturationToOk: thresholds.backgroundSaturation.toOk,
                thresholdBackgroundSaturationFromOk: thresholds.backgroundSaturation.fromOk,
              },
            });
          }
        : undefined,
      isCheckingStep,
      isHelpModalOpened: helpModal.visible,
      measurementApiRef,
      isValidIlluminationValues: illuminationState.isValidIlluminationValues,
    });

    useOnPrepareCapturingEndEffect({ onPrepareCapturingEnd, isPrepareImageCapturing });

    const { width, height } = useMemo(() => {
      if (!isCheckingStep) {
        return {
          width: 0,
          height: 0,
        };
      }
      return {
        width: videoRef.current.videoWidth,
        height: videoRef.current.videoHeight,
      };
    }, [isCheckingStep]);

    return (
      <div className={classnames(styles.wrapper, className)}>
        <div className={styles.videoWrapper}>
          <div className={styles.videoContent}>
            <div className={styles.videoInner}>
              <video
                ref={composeRefs(forwardVideoRef, videoRef)}
                muted
                onPlay={videoStateActions.videoPlaying}
                autoPlay
                controls={false}
                className={styles.video}
                playsInline
                onLoadedMetadata={videoStateActions.videoLoadedMetadata}
              />

              {isCheckingStep && (
                <Mask
                  width={videoRef.current?.videoWidth}
                  height={videoRef.current?.videoHeight}
                  illuminationCanvasRef={illuminationCanvasRef}
                />
              )}
            </div>

            {isCheckingStep && (
              <Illumination
                width={width}
                height={height}
                videoRef={videoRef}
                faceDetector={faceDetector}
                onIlluminationChange={onIlluminationChange}
                illuminationCanvasRef={illuminationCanvasRef}
              />
            )}
          </div>
        </div>

        <LayoutMask
          isError={isError}
          isAppReady={isVideoReady && !isPrepareImageCapturing}
          onNeedHelpClick={() => {
            helpModal.show();
          }}
          isCriticalError={isCriticalError}
        >
          {children}
        </LayoutMask>

        <IlluminationMeasurement ref={measurementApiRef} className={styles.measurement} />
      </div>
    );
  },
);

export default memo(IlluminationVideo);
