import { memo, useEffect } from 'react';

import useIlluminationCheck from './hooks/use-illumination';

const Illumination = ({ width, height, videoRef, faceDetector, illuminationCanvasRef, onIlluminationChange }) => {
  const illuminationActions = useIlluminationCheck({
    width,
    height,
    videoRef,
    faceDetector,
    onIlluminationChange,
    illuminationCanvasRef,
  });
  useEffect(() => {
    illuminationActions.check();
    return illuminationActions.stopChecking;
  }, []);
  return null;
};

export default memo(Illumination);
