import React from 'react';

import { withModalWrapper } from 'components/modals/hocs/with-modal-wrapper';

import { Wrapper } from 'components/modals/components/wrapper';

import VideoCaptureDemo from 'components/quiz/components/video-instructions-demo';

import styles from './styles.module.scss';

const VideoCaptureHelp = ({ closeModal }) => (
  <Wrapper title="Tutorial" closeModal={closeModal}>
    <div className={styles.wrapper}>
      <VideoCaptureDemo className={styles.content} />
    </div>
  </Wrapper>
);

export default withModalWrapper(VideoCaptureHelp);
