import classnames from 'classnames';
import React from 'react';

import { getErrorMessage } from 'helpers/request';

import Button from 'components/button';

import styles from './styles.module.scss';

export function ErrorMessageWithButton({ error = null, onClick = null, className }) {
  return (
    <div className={classnames(styles.wrapper, className)}>
      <p className={styles.error}>{getErrorMessage(error)}</p>

      <Button as="button" onClick={onClick} className={styles.button}>
        Try again
      </Button>
    </div>
  );
}
