/* eslint-disable no-param-reassign */
import { useMemo, useEffect, useState, useRef } from 'react';
import { FaceMesh } from '@mediapipe/face_mesh';

import useLifecycle from 'hooks/use-lifecycle';
import { useCreateConst } from 'hooks/video-capture/use-create-const';

import { getYAWFromFaceMesh } from 'helpers/media-pipe';
import { getErrorMessage } from 'helpers/request';

import { transportCreator } from './helpers';
import { FACE_DETECTOR_OPTIONS } from './constants';

export const FaceDetectorState = {
  Idle: 'idle',
  Ready: 'ready',
  Failed: 'failed',
  Initializing: 'initializing',
};

const useFaceDetector = ({ videoRef }) => {
  const transport = useCreateConst(() => transportCreator());
  const [state, setState] = useState({
    state: FaceDetectorState.Idle,
    error: null,
  });
  const cacheRef = useRef({
    faceDetector: null,
  });
  const lifecycle = useLifecycle();
  const actions = useCreateConst(() => ({
    create: async () => {
      try {
        if (lifecycle.isUnmounted()) {
          return;
        }
        setState((prevState) => ({
          ...prevState,
          state: FaceDetectorState.Initializing,
          error: null,
        }));
        cacheRef.current.faceDetector = new (window.FaceMesh || FaceMesh)({
          locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
        });
        cacheRef.current.faceDetector.setOptions(FACE_DETECTOR_OPTIONS);
        cacheRef.current.faceDetector.onResults((results) => transport.push(results));
        await cacheRef.current.faceDetector.initialize();
        if (lifecycle.isUnmounted()) {
          cacheRef.current.faceDetector.close();
          return;
        }
        setState((prevState) => ({
          ...prevState,
          state: FaceDetectorState.Ready,
        }));
      } catch (error) {
        console.error(error);
        if (lifecycle.isUnmounted()) {
          return;
        }
        setState((prevState) => ({
          ...prevState,
          error: getErrorMessage(error),
          state: FaceDetectorState.Failed,
        }));
      }
    },
    detect: async () => {
      cacheRef.current.faceDetector?.send({ image: videoRef.current });
      const result = await transport.pull();
      if (!result.multiFaceLandmarks[0]) {
        return null;
      }
      const scaledCoordinates = result.multiFaceLandmarks[0].reduce(
        (acc, { x, y }) => {
          if (acc.minX > x) {
            acc.minX = x;
          }
          if (acc.maxX < x) {
            acc.maxX = x;
          }
          if (acc.minY > y) {
            acc.minY = y;
          }
          if (acc.maxY < y) {
            acc.maxY = y;
          }
          return acc;
        },
        {
          minX: 1,
          maxX: 0,
          minY: 1,
          maxY: 0,
        },
      );
      const { videoWidth, videoHeight } = videoRef.current;
      const top = scaledCoordinates.minY * videoHeight;
      const left = scaledCoordinates.minX * videoWidth;
      const right = scaledCoordinates.maxX * videoWidth;
      const bottom = scaledCoordinates.maxY * videoHeight;
      return {
        mesh: [
          [left, top],
          [right, top],
          [left, bottom],
          [right, bottom],
        ],
        rotation: {
          angle: {
            yaw: getYAWFromFaceMesh(result.multiFaceLandmarks[0]),
          },
        },
      };
    },
    destroy: () => {
      cacheRef.current.faceDetector?.close();
    },
  }));
  const helpers = useMemo(
    () => ({
      isStateIdle: () => state.state === FaceDetectorState.Idle,
      isStateReady: () => state.state === FaceDetectorState.Ready,
      isStateFailed: () => state.state === FaceDetectorState.Failed,
      isStateInitializing: () => state.state === FaceDetectorState.Initializing,
    }),
    [state],
  );
  useEffect(
    () => () => {
      actions.destroy();
    },
    [],
  );
  return [state, actions, helpers];
};
export default useFaceDetector;
