if (window.navigator.serviceWorker) {
  window.navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

if (window.caches) {
  window.caches.keys().then(function (names) {
    for (let name of names) caches.delete(name);
  });
}
