export const ROUTES = {
  ROOT: '/',

  SHORT_QUIZ: '/short-quiz',
  SHORT_QUIZ_QUESTION: '/short-quiz/:questionId',

  QUIZ: '/quiz',
  QUIZ_RESET: '/quiz-reset',
  QUIZ_RETAKE: '/quiz/retake',
  QUIZ_QUESTION: '/quiz/:questionId',

  MOBILE_ONLY: '/mobile-only',

  LOGIN: '/login',
  LOGIN_VERIFY: '/login/verify',

  CART: '/cart',
  COMPARE: '/compare/:categoryId',
  FAVORITES: '/favorites/:categoryId',

  BRANDS: '/skincare/brands',
  BRAND: '/skincare/brands/:brandId',

  CATEGORY_LISTING: '/skincare/products-:categoryId',

  BLOG: '/blog',
  ARTICLES: '/articles',
  ARTICLES_ARTICLE: '/articles/:categoryId/:articleIdWithSlug',
  ARTICLES_ARTICLE_PREVIEW: '/articles/:articleId/preview',

  DASHBOARD: '/dashboard',
  DASHBOARD_SKIN_PARAMETER: '/dashboard/:skinParameter',

  PICKING_UP_PRODUCTS: '/matching-skincare-products',

  PRODUCT: '/skincare/products/:productId',
  PRODUCT_CATEGORIES: '/skincare/products/:categoryId',
  PRODUCTS_CLEANSERS: '/skincare/products/cleansers',
  PRODUCTS_TREATMENTS: '/skincare/products/treatments',
  PRODUCTS_MOISTURIZERS: '/skincare/products/moisturizers',

  SEARCH_PRODUCTS: '/search-products',
  SEARCH_PRODUCTS_RESULTS: '/search-products/results',
  SEARCH_PRODUCTS_BARCODE: '/search-products/barcode',
  SEARCH_PRODUCTS_SCAN_BARCODE: '/search-products/scan-barcode',

  SEARCH: '/search',
  SEARCH_HISTORY: '/search/history',
  SEARCH_NOT_FOUND: '/search/not-found',

  ABOUT_US: '/about',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',

  TEST: '/__test',

  NOT_FOUND: '/404',
};

export const VideoCaptureRoute = {
  VideoCapture: '/',
  VideoCaptureInitializeCamera: '/initialize-camera',
  VideoCaptureRecording: '/recording',
  VideoCaptureUploading: '/uploading',
  VideoCaptureIllumination: '/illumination-check',
  VideoCaptureCameraRestrictions: '/camera-restriction',
};

export const PRODUCT_CATEGORIES_PATHS = [ROUTES.PRODUCTS_MOISTURIZERS, ROUTES.PRODUCTS_TREATMENTS];
