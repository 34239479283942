import { useRef } from 'react';

import { useCreateConst } from 'hooks/video-capture/use-create-const';

const useScheduler = () => {
  const cache = useRef({
    isRunning: false,
    shouldStop: false,
  });

  const resetCache = () => {
    cache.current.isRunning = false;
    cache.current.shouldStop = false;
  };

  const actions = useCreateConst(() => ({
    run: (cb) => {
      if (cache.current.isRunning) {
        return;
      }
      cache.current.isRunning = true;
      let previousAnimationTimeStamp = null;
      async function run(timestamp = 0) {
        if (cache.current.shouldStop) {
          resetCache();
          return;
        }
        const diff = timestamp - (previousAnimationTimeStamp ?? 0);
        if (previousAnimationTimeStamp === null || diff > 30) {
          await cb();
          if (cache.current.shouldStop) {
            resetCache();
            return;
          }
          previousAnimationTimeStamp = timestamp;
        }
        window.requestAnimationFrame(run);
      }
      run();
    },
    stop: () => {
      cache.current.shouldStop = true;
    },
  }));
  return [cache.current.isRunning, actions];
};

export default useScheduler;
