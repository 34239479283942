import { useRef } from 'react';

const usePersistFunction = (fn) => {
  const fnRef = useRef(fn);
  const persistFn = useRef();

  fnRef.current = fn;

  if (!persistFn.current) {
    persistFn.current = (...args) => fnRef.current?.(...args);
  }

  return persistFn.current;
};

export default usePersistFunction;
