import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link as DefaultLink } from 'react-router-dom';

import styles from './styles.module.scss';

function Link({ id, children, onClick, className, isLink, to }) {
  const classNames = classnames(className, styles.link);

  let Component = DefaultLink;
  const props = {
    onClick,
  };

  if (!!onClick && !isLink) {
    Component = 'button';
  } else if (to) {
    props.to = to;
  }

  if (id) {
    props.id = id;
  }

  return (
    <Component {...props} className={classNames}>
      {children}
    </Component>
  );
}

Link.propTypes = {
  id: PropTypes.string,
  to: PropTypes.string,
  isLink: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Link.defaultProps = {
  id: null,
  to: null,
  isLink: false,
  onClick: null,
  className: null,
};

export default Link;
