export const FACE_DETECTOR_OPTIONS = {
  maxNumFaces: 1,
  refineLandmarks: false,
  minTrackingConfidence: 0.3,
  minDetectionConfidence: 0.3,
};
export const LIPS_HOLE = [61, 185, 40, 39, 37, 0, 267, 269, 270, 409, 291, 375, 321, 405, 314, 17, 84, 181, 91, 146];
export const LEFT_EYE_HOLE = [
  130,
  113,
  124,
  156,
  70,
  63,
  105,
  66,
  107,
  55,
  221,
  189,
  244,
  243,
  112,
  26,
  22,
  23,
  24,
  110,
  25,
];
export const RIGHT_EYE_HOLE = [
  359,
  255,
  339,
  254,
  253,
  252,
  256,
  341,
  463,
  464,
  413,
  441,
  285,
  336,
  296,
  334,
  293,
  300,
  383,
  353,
  342,
];
export const NOSE_HOLE = [166, 60, 99, 97, 2, 326, 328, 290, 392, 309, 458, 461, 354, 19, 125, 241, 238, 79];
