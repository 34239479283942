import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ROUTES } from 'router/constants';

import Button from 'components/button';
import { Modal } from 'components/modal';

import { ReactComponent as CloseSVG } from 'static/svg/close.svg';

import { STEPS } from './constants';

import styles from './styles.module.scss';

function MatchScoreGuest({ push, isVisible, closeModal }) {
  const onButtonClick = () => {
    push(ROUTES.QUIZ);
    closeModal();
  };

  return (
    <Modal isVisible={isVisible} onOverlayClick={closeModal}>
      {() => (
        <div className={styles.wrapper}>
          <div className={styles.modal} onClick={(event) => event.stopPropagation()} role="button">
            <div onClick={closeModal} role="button" tabIndex={0} className={styles.close}>
              <CloseSVG className={styles.closeIcon} />
            </div>

            <div>
              <h3 className={styles.title}>How Skinguide Works:</h3>
              <p className={styles.subtitle}>Receive Objective, unbiased, & personalized recommendations</p>

              <ul className={styles.list}>
                {STEPS.map(({ id, classNameImage, image, title, description }) => (
                  <li key={id} className={styles.listItem}>
                    <p className={styles.listTitle}>{title}</p>
                    <p className={styles.listDescription}>{description}</p>

                    <img src={image} alt="" className={classnames(styles.image, classNameImage)} />
                  </li>
                ))}
              </ul>

              <Button onClick={onButtonClick} className={classnames(styles.button, 'btn-primary btn-lg')}>
                FIND MY MATCHES
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

MatchScoreGuest.propTypes = {
  push: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default MatchScoreGuest;
