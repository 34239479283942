import { createSlice } from '@reduxjs/toolkit';

import * as thunks from './thunks';
import * as selectors from './selectors';

const DEFAULT_STATE = {
  products: {
    entities: {
      meta: {},
      products: {},
    },
    requests: {},
    productIds: {},
  },
};

const { reducer, actions } = createSlice({
  name: 'blog',
  initialState: DEFAULT_STATE,

  extraReducers: {
    [thunks.fetchProductsByIds.pending]: (
      draftState,
      {
        meta: {
          arg: { articleId },
        },
      },
    ) => {
      draftState.products.requests[articleId] = {
        done: draftState.products.requests[articleId]?.done,
        error: null,
        isFetching: true,
      };
    },

    [thunks.fetchProductsByIds.rejected]: (
      draftState,
      {
        error,
        meta: {
          arg: { articleId },
        },
      },
    ) => {
      draftState.products.requests[articleId] = {
        done: true,
        error,
        isFetching: false,
      };
    },

    [thunks.fetchProductsByIds.fulfilled]: (
      draftState,
      {
        payload: {
          entities: { products, meta },
        },
        meta: {
          arg: { articleId, productIds },
        },
      },
    ) => {
      draftState.products.requests[articleId] = {
        done: true,
        error: null,
        isFetching: false,
      };

      draftState.products.productIds[articleId] = productIds;

      draftState.products.entities.meta = {
        ...draftState.products.entities.meta,
        ...meta,
      };
      draftState.products.entities.products = {
        ...draftState.products.entities.products,
        ...products,
      };
    },
  },
});

export { selectors, thunks, reducer, actions };
