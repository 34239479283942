import React from 'react';
import { compose } from '@reduxjs/toolkit';
import { ConnectedRouter } from 'connected-react-router';
import NiceModal from '@ebay/nice-modal-react';

import withLogRocket from 'hocs/with-logrocket';

import RootPage from 'pages/root';

import { ModalRoot } from 'components/modal';

import GuestRoute from './routes/guest';
import PrivateRoute from './routes/private';

import history from './history';

function RootRouter() {
  return (
    <ConnectedRouter history={history}>
      <NiceModal.Provider>
        <ModalRoot>
          <RootPage />
        </ModalRoot>
      </NiceModal.Provider>
    </ConnectedRouter>
  );
}

export { GuestRoute, PrivateRoute };

export default compose(withLogRocket)(RootRouter);
