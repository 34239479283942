import { useState, useMemo } from 'react';

import { IlluminationCheckState } from 'constants/video-capture';

const useIlluminationState = () => {
  const [state, setState] = useState({
    check: {
      text: null,
      isError: false,
    },
    text: null,
    state: IlluminationCheckState.InitializingFaceDetector,
    isVideoReady: false,
    criticalErrors: [],
  });
  const actions = useMemo(
    () => ({
      setText: (text) => setState((prevState) => ({ ...prevState, text })),
      setPartialState: (part) => setState((prevState) => ({ ...prevState, ...part })),
      setIsVideoReady: (isVideoReady) => setState((prevState) => ({ ...prevState, isVideoReady })),
      setIlluminationState: (nextState) => setState((prevState) => ({ ...prevState, state: nextState })),
      setCriticalError: (criticalError) =>
        setState((prevState) => ({
          ...prevState,
          criticalErrors: [...prevState.criticalErrors, criticalError],
        })),
      resetCriticalError: (criticalError) =>
        setState((prevState) => ({
          ...prevState,
          criticalErrors: prevState.criticalErrors.filter((type) => type !== criticalError),
        })),
      setCheck: (check) => setState((prevState) => ({ ...prevState, check })),
    }),
    [],
  );
  return [state, actions];
};

export default useIlluminationState;
