import { useEffect } from 'react';

const useOnPrepareCapturingEndEffect = ({ onPrepareCapturingEnd, isPrepareImageCapturing }) => {
  useEffect(() => {
    if (!isPrepareImageCapturing) {
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      onPrepareCapturingEnd();
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isPrepareImageCapturing]);
};

export default useOnPrepareCapturingEndEffect;
