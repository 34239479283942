import React from 'react';

const withData = (getData = () => {}) => (WrappedComponent) =>
  function WithData(props) {
    const data = getData(props);

    return <WrappedComponent {...props} {...data} />;
  };

export default withData;
