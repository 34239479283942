import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';

import { selectors as userSelectors } from 'store/modules/user';

import { MatchScoreExplanation } from 'components/modals';

const mapStateToProps = (state) => {
  return {
    skinProfile: userSelectors.getSkinProfile(state),
  };
};

export default compose(connect(mapStateToProps))(MatchScoreExplanation);
