import { getId } from 'helpers/id';

// import { ReactComponent as TwitterSVG } from 'static/svg/twitter-circle.svg';
import { ReactComponent as FacebookSVG } from 'static/svg/facebook-circle.svg';
// import { ReactComponent as LinkedinSVG } from 'static/svg/linkedin-circle.svg';
import { ReactComponent as InstagramSVG } from 'static/svg/instagram-circle.svg';

export const SOCIAL_NETWORKS = [
  {
    id: getId(),
    link: 'https://www.instagram.com/skinguide.co/',
    Icon: InstagramSVG,
    color: '#E1306C',
  },
  {
    id: getId(),
    link: 'https://www.facebook.com/pages/category/Health-Beauty/SkinGuide-101858984524615/',
    Icon: FacebookSVG,
    color: '#4267B2',
  },
  // {
  //   id: getId(),
  //   link: 'https://twitter.com/',
  //   Icon: TwitterSVG,
  //   color: '#1DA1F2',
  // },
  // {
  //   id: getId(),
  //   link: 'https://linkedin.com/',
  //   Icon: LinkedinSVG,
  //   color: '#2867B2',
  // },
];
