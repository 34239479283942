import { useCreateConst } from 'hooks/video-capture/use-create-const';

import { correctValueForNewRange, getArrowOpacity, getRotateAngleForSlider } from '../helpers';

const useMeasurementPartApi = ({ arrowRef, sliderRef, lineMaskRef, strokeMaskRef, innerCircleRef }) =>
  useCreateConst(() => {
    let prevValue = 0;
    return {
      setValue: (newValue) => {
        if (prevValue === newValue || !sliderRef.current || !lineMaskRef.current || !strokeMaskRef.current) {
          return;
        }
        const value = correctValueForNewRange(newValue);
        const arrowOpacity = getArrowOpacity(newValue);
        const rotateSlider = getRotateAngleForSlider(newValue === 1 ? newValue : value);
        sliderRef.current.style.transform = `translate3d(0, 0, 0) rotate(${rotateSlider}deg)`;
        lineMaskRef.current.style.transform = `translate3d(0, 0, 0) rotate(${rotateSlider}deg)`;
        strokeMaskRef.current.style.transform = `translate3d(0, 0, 0) rotate(${rotateSlider}deg)`;
        sliderRef.current.style.opacity = newValue === 1 ? '0' : '1';
        if (arrowRef.current) {
          arrowRef.current.style.opacity = arrowOpacity.toString();
        }
        prevValue = newValue;
      },
      toggleBorderColor: (isSuccess) => {
        if (!innerCircleRef.current) {
          return;
        }
        innerCircleRef.current.style.stroke = isSuccess ? '#3DC8AF' : '#ffffff';
      },
    };
  });
export default useMeasurementPartApi;
