import { REQUEST_STATUSES } from 'constants/request';

import { PRODUCT_LIST_NAMES } from './constants';

export const PRODUCT_LIST_DEFAULT_STATE = {
  productIds: [],
  productIdsByCategory: {},

  request: {
    error: null,
    status: REQUEST_STATUSES.IDLE,
  },
};

export const ENTITIES_DEFAULT_STATE = PRODUCT_LIST_NAMES.reduce(
  (acc, listName) => ({
    ...acc,
    [listName]: PRODUCT_LIST_DEFAULT_STATE,
  }),
  {},
);

export const PROCESSING_PRODUCTS_DEFAULT_STATE = PRODUCT_LIST_NAMES.reduce(
  (acc, listName) => ({
    ...acc,
    [listName]: [],
  }),
  {},
);
