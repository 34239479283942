import { REQUEST_STATUSES } from 'constants/request';

export const isRequestStatusIdle = (status) => status === REQUEST_STATUSES.IDLE;

export const isRequestStatusFetching = (status) => status === REQUEST_STATUSES.FETCHING;

export const isRequestStatusFetched = (status) => status === REQUEST_STATUSES.FETCHED;

export const isRequestStatusRefetching = (status) => status === REQUEST_STATUSES.REFETCHING;

export const isRequestStatusFailed = (status) => status === REQUEST_STATUSES.FAILED;

export const getRequestStatuses = (state) => ({
  isIdle: isRequestStatusIdle(state),
  isFailed: isRequestStatusFailed(state),
  isFetched: isRequestStatusFetched(state),
  isFetching: isRequestStatusFetching(state),
});

export const createDefaultRequestState = () => ({
  state: REQUEST_STATUSES.IDLE,
  error: null,
});

export const fetcher = async (...args) => {
  const request = await fetch(...args);

  if (request.status < 200 || request.status > 299) {
    throw new Error(request.statusText);
  }

  return request.json();
};

export const getErrorMessage = (error) => {
  if (typeof error?.error === 'string') {
    return error.error;
  }

  if (typeof error?.message === 'string') {
    return error.message;
  }

  return String(error);
};
