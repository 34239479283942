import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { updateDataSubmissionMutation } from 'api/revea/queries';

export const useUpdateDataSubmission = ({ dataSubmissionId }) => {
  const [mutate, state] = useMutation(updateDataSubmissionMutation, {
    onError: (error) => {
      console.error(error);
    },
  });

  const updateDataSubmission = useCallback((input) => mutate({ variables: { id: dataSubmissionId, input } }), [
    mutate,
    dataSubmissionId,
  ]);

  return [state, updateDataSubmission];
};
