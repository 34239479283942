import { transformValueForNewRange } from 'helpers/range';

import { MAX_LIMIT_COUNT_FOR_CHANGE_STATE, MIN_LIMIT_COUNT_FOR_CHANGE_STATE } from './constants';

const correctValuesForRange = ({ value, oldRangeStart }) => {
  let newValue = transformValueForNewRange({
    value,
    oldRangeEnd: 0.3,
    oldRangeStart,
    rangeStart: 0,
    rangeEnd: 1,
  });
  if (newValue < 0) {
    newValue = 0;
  } else if (newValue > 1) {
    newValue = 1;
  }
  return newValue;
};
export const getMeasurementValues = ({ shadow, saturation, thresholds, isValidValues }) => {
  const newSaturation = correctValuesForRange({
    value: saturation,
    oldRangeStart: isValidValues ? thresholds.saturation.fromOk : thresholds.saturation.toOk,
  });
  const newShadow = correctValuesForRange({
    value: shadow,
    oldRangeStart: isValidValues ? thresholds.shadow.fromOk : thresholds.shadow.toOk,
  });
  return {
    shadow: 1 - newShadow,
    saturation: 1 - newSaturation,
  };
};
export const updateStateByKey = ({ values, dataKey, cacheRef, cacheKey, stateKey, thresholdToOk, thresholdFromOk }) => {
  const isDataLess = values[dataKey] <= thresholdToOk;
  const isDataMore = values[dataKey] >= thresholdFromOk;
  const nextState = {};
  if ((isDataMore && cacheRef.current[cacheKey] < 0) || (isDataLess && cacheRef.current[cacheKey] > 0)) {
    cacheRef.current[cacheKey] = 0;
  }
  if (isDataMore) {
    cacheRef.current[cacheKey] += 1;
  }
  if (isDataLess) {
    cacheRef.current[cacheKey] += -1;
  }
  if (cacheRef.current[cacheKey] >= MAX_LIMIT_COUNT_FOR_CHANGE_STATE) {
    nextState[stateKey] = true;
  }
  if (cacheRef.current[cacheKey] <= -MIN_LIMIT_COUNT_FOR_CHANGE_STATE) {
    nextState[stateKey] = false;
  }
  return nextState;
};
