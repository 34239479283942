import React, { useState, useMemo } from 'react';

import { MODALS, MODAL_IDS, DEFAULT_STATE_MODALS } from 'constants/modals';

import { ModalsProvider } from 'contexts/modals';

import ProductMatchScoreExplanation from 'containers/product-match-score-explanation';

import { MatchScoreGuest } from 'components/modals';

const MODALS_BY_ID = {
  [MODALS.MATCH_SCORE_EXPLANATION]: ProductMatchScoreExplanation,
  [MODALS.MATCH_SCORE_EXPLANATION_GUEST]: MatchScoreGuest,
};

const withProductGaugeModals = (WrappedComponent) =>
  function WithProductGaugeModals(props) {
    const [state, setState] = useState(DEFAULT_STATE_MODALS);

    const callbacks = useMemo(
      () => ({
        openModal: (modalId, payload) =>
          setState((prevState) => ({
            ...prevState,
            [modalId]: {
              isVisible: true,
              payload,
            },
          })),

        closeModal: (modalId) =>
          setState((prevState) => ({
            ...prevState,
            [modalId]: {
              isVisible: false,
            },
          })),
      }),
      [setState],
    );

    return (
      <ModalsProvider value={callbacks}>
        {MODAL_IDS.map((modalId) => {
          const Modal = MODALS_BY_ID[modalId];
          const { isVisible, payload } = state[modalId];

          return (
            isVisible && (
              <Modal
                {...payload}
                key={modalId}
                isVisible={isVisible}
                closeModal={() => callbacks.closeModal(modalId)}
              />
            )
          );
        })}

        <WrappedComponent {...props} />
      </ModalsProvider>
    );
  };

export default withProductGaugeModals;
