import { ROUTES } from 'router/constants';

import { PRODUCT_CATEGORIES } from './products';

export const CATEGORIES = [
  {
    id: PRODUCT_CATEGORIES.TREATMENT,
    name: 'TREATMENTS',
    link: ROUTES.PRODUCTS_TREATMENTS,
  },
  {
    id: PRODUCT_CATEGORIES.MOISTURIZER,
    name: 'MOISTURIZERS',
    link: ROUTES.PRODUCTS_MOISTURIZERS,
  },
];
