import CONFIG from 'constants/config';

export const EVENTS = {
  QUIZ_STARTED: 'Started Quiz',
  QUIZ_RESET: 'Reset Quiz',
  QUIZ_COMPLETE_QUESTION: 'Completed Step',
  QUIZ_GO_TO_PREVIOUS_QUESTION: 'Navigated Back',
  QUIZ_COMPLETE: 'Completed Quiz',
  QUIZ_SUBMITTED_QUIZ: 'Submitted Quiz',
  QUIZ_SUBMITTED_EMAIL: 'Submitted Email',

  PRODUCTS_VIEWED_PMP_FILTERS: 'Viewed Filters',
  PRODUCTS_VIEWED_MATCH_SCORE_EXPLANATION: 'Viewed Match score',

  PRODUCT_SAVED: 'Saved Product',
  PRODUCT_ADDED_TO_CART: 'Added to Cart',

  CART_CHECKOUT: 'Checkout',

  COMPARE_VIEWED_PAGE: 'Viewed Compare page',
  COMPARE_ADDED_PRODUCT_TO_COMPARE: 'Added product to compare',

  NAVIGATION_VIEWED_PMP: 'Viewed PMP',
  NAVIGATION_VIEWED_PDP: 'Viewed PDP',

  SEARCH_SUBMITTED: 'Submitted Search',

  SIGN_UP_EMAIL_SUBMITTED: 'Email Submitted',

  SELFIE_STARTED: 'Started Selfie',
  SELFIE_CAPTURE: 'Capture Selfie',
  SELFIE_CONFIRM: 'Confirm Selfie',
  SELFIE_RETAKE: 'Retake Selfie',
  SELFIE_SKIP_DESKTOP: 'Skip Desktop Selfie',
};

const EVENTS_DATA = {
  [EVENTS.QUIZ_STARTED]: () => ({ category: 'Quiz' }),
  [EVENTS.QUIZ_RESET]: () => ({ category: 'Quiz' }),
  [EVENTS.QUIZ_COMPLETE_QUESTION]: ({ questionId }) => ({
    label: questionId,
    category: 'Quiz',
  }),
  [EVENTS.QUIZ_GO_TO_PREVIOUS_QUESTION]: ({ questionId }) => ({
    label: questionId,
    category: 'Quiz',
  }),
  [EVENTS.QUIZ_COMPLETE]: () => ({ category: 'Quiz' }),
  [EVENTS.QUIZ_SUBMITTED_QUIZ]: () => ({ category: 'Quiz' }),
  [EVENTS.QUIZ_SUBMITTED_EMAIL]: () => ({ category: 'Quiz' }),

  [EVENTS.PRODUCTS_VIEWED_PMP_FILTERS]: () => ({
    category: 'Products',
  }),
  [EVENTS.PRODUCTS_VIEWED_MATCH_SCORE_EXPLANATION]: () => ({
    category: 'Products',
  }),

  [EVENTS.PRODUCT_SAVED]: () => ({
    category: 'Product',
  }),
  [EVENTS.PRODUCT_ADDED_TO_CART]: () => ({
    category: 'Product',
  }),

  [EVENTS.COMPARE_VIEWED_PAGE]: ({ categoryId }) => ({
    label: categoryId,
    category: 'Compare',
  }),
  [EVENTS.COMPARE_ADDED_PRODUCT_TO_COMPARE]: () => ({
    category: 'Compare',
  }),

  [EVENTS.NAVIGATION_VIEWED_PMP]: ({ categoryId }) => ({
    label: categoryId,
    category: 'Navigation',
  }),
  [EVENTS.NAVIGATION_VIEWED_PDP]: () => ({
    category: 'Navigation',
  }),

  [EVENTS.SEARCH_SUBMITTED]: ({ term }) => ({
    category: 'Search',
    label: term,
  }),

  [EVENTS.SIGN_UP_EMAIL_SUBMITTED]: () => ({ category: 'Sign up' }),

  [EVENTS.CART_CHECKOUT]: (totalPrice) => ({ category: 'Cart', value: totalPrice }),

  [EVENTS.SELFIE_RETAKE]: () => ({ category: 'Selfie' }),
  [EVENTS.SELFIE_CAPTURE]: () => ({ category: 'Selfie' }),
  [EVENTS.SELFIE_CONFIRM]: () => ({ category: 'Selfie' }),
  [EVENTS.SELFIE_STARTED]: (label) => ({ label, category: 'Selfie' }),
  [EVENTS.SELFIE_SKIP_DESKTOP]: () => ({ category: 'Selfie' }),
};

const segment = {
  load() {
    window.analytics.load(CONFIG.SEGMENT_WRITE_KEY);
  },

  page(...params) {
    window.analytics.page(...params);
  },

  identify(id, payload) {
    window.analytics.identify(id, payload);
  },

  track(name, payload) {
    window.analytics.track(name, EVENTS_DATA[name](payload));
  },

  reset() {
    window.analytics.reset();
  },
};

export default segment;
