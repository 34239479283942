import React, { Suspense as ReactSuspense, useLayoutEffect, useState } from 'react';

import SuspenseContext from 'contexts/suspense';

function Fallback({ onMount, onUnmount, children }) {
  useLayoutEffect(() => {
    onMount();

    return onUnmount;
  }, []);

  return children ?? null;
}

export function Suspense({ fallback, children }) {
  const [suspended, setSuspended] = useState(false);

  return (
    <ReactSuspense
      fallback={
        <Fallback onMount={() => setSuspended(true)} onUnmount={() => setSuspended(false)}>
          {fallback}
        </Fallback>
      }
    >
      <SuspenseContext.Provider value={suspended}>{children}</SuspenseContext.Provider>
    </ReactSuspense>
  );
}
