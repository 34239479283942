import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/fields';

import { validateFields } from './helpers';

import styles from './styles.module.scss';

function SignUp({ name, email, version, onSubmit, responseError, renderSubmitButton }) {
  const [state, setState] = useState({
    values: {
      name: name || '',
      email: email || '',
    },
    errors: null,
    errorMessage: null,
  });

  const onSubmitHandler = (event) => {
    event.preventDefault();

    const errors = validateFields(state.values);

    if (errors) {
      setState((prevState) => ({
        ...prevState,
        errors,
      }));

      return;
    }

    setState((prevState) => ({
      ...prevState,
      errors: null,
      errorMessage: null,
    }));

    onSubmit(state.values);
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      errors: responseError?.errors,
      errorMessage: responseError?.message,
    }));
  }, [responseError]);

  const onChangeField = ({ name: fieldName, value }) => {
    if (state.errors && state.errors[fieldName]) {
      setState((prevState) => {
        let errors = {
          ...prevState.errors,
        };

        delete errors[fieldName];

        if (Object.keys(errors).length === 0) {
          errors = null;
        }

        return {
          ...prevState,
          errors,
          errorMessage: null,
        };
      });
    }

    setState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [fieldName]: value,
      },
    }));
  };

  const isInvalidName = !!state.errors?.name;
  const isInvalidEmail = !!state.errors?.email;

  const isGlobalError = !isInvalidName && !isInvalidEmail && !!state.errorMessage;

  const isDisabledButton = isInvalidName || isInvalidEmail || !state.values.name || !state.values.email;

  const isSecondaryVersion = version === 'secondary';

  return (
    <form onSubmit={onSubmitHandler} className={classnames(styles.wrapper, styles[version])} noValidate>
      <div className={styles.nameWrapper}>
        <Text
          name="name"
          type="name"
          value={state.values.name}
          onChange={onChangeField}
          className={classnames(styles.field, { [styles.invalid]: isInvalidName })}
          placeholder={isSecondaryVersion ? 'Your Name' : 'Name'}
        />

        {isInvalidName && <p className={styles.fieldError}>{state.errors?.name.toString()}</p>}
      </div>

      <div className={styles.emailWrapper}>
        <Text
          name="email"
          type="email"
          value={state.values.email}
          onChange={onChangeField}
          className={classnames(styles.field, { [styles.invalid]: isInvalidEmail })}
          placeholder={isSecondaryVersion ? 'Your Email' : 'Email'}
        />

        {isInvalidEmail && <p className={styles.fieldError}>{state.errors?.email.toString()}</p>}
      </div>

      {isGlobalError && <p className={styles.fieldError}>{state.globalError}</p>}

      {renderSubmitButton({ disabled: isDisabledButton })}
    </form>
  );
}

SignUp.propTypes = {
  version: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  responseError: PropTypes.string,
  renderSubmitButton: PropTypes.func.isRequired,
};

SignUp.defaultProps = {
  version: null,
  responseError: null,
};

export default SignUp;
