import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { normalizeValue } from 'helpers/skin';

import ModalsContext from 'contexts/modals';

import MatchScoreGauge from '../match-score-gauge';
import UnknownScoreGauge from '../unknown-score-gauge';

import useCallbacks from './hooks/use-callbacks';

function ProductGauge({ size, onClick, className, withModals, matchScore, isAuthenticated, performanceVector }) {
  const { openModal } = useContext(ModalsContext);
  const { onGuestGaugeClick, onPrivateGaugeClick } = useCallbacks({
    onClick,
    openModal,
    matchScore,
    withModals,
    performanceVector,
  });

  return (
    <div className={classnames(className)}>
      {isAuthenticated ? (
        <MatchScoreGauge size={size} onClick={onPrivateGaugeClick} percentage={normalizeValue(matchScore)} />
      ) : (
        <UnknownScoreGauge size={size} onClick={onGuestGaugeClick} />
      )}
    </div>
  );
}

ProductGauge.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  withModals: PropTypes.bool,
  matchScore: PropTypes.number,
  isAuthenticated: PropTypes.bool.isRequired,
  performanceVector: PropTypes.shape({}),
};

ProductGauge.defaultProps = {
  size: 'lg',
  onClick: () => {},
  className: null,
  withModals: false,
  matchScore: 0,
  performanceVector: {},
};

export default ProductGauge;
