import React from 'react';
import PropTypes from 'prop-types';

import Link from '../side-bar-navigation-link';

import styles from './styles.module.scss';

function SideBarNavigation({ push, links }) {
  return (
    <ul className={styles.navigation}>
      {links.map(({ id, to, onClick, name }) => {
        return (
          <li key={id} className={styles.item}>
            <Link onClick={to ? () => push(to) : onClick}>{name}</Link>
          </li>
        );
      })}
    </ul>
  );
}

SideBarNavigation.propTypes = {
  push: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired])
        .isRequired,
      to: PropTypes.string,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};

export default SideBarNavigation;
