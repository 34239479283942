import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';

import { isNotEmpty } from 'helpers/is';

import styles from './styles.module.scss';

function Item({ link, Icon, name, count, paths, isSmall, className, onAnimationEnd }) {
  const isActive = isNotEmpty(useRouteMatch(paths));

  return (
    <li
      className={classnames(styles.item, {
        [styles.small]: isSmall,
        [styles.active]: isActive,
      })}
    >
      <Link to={link} className={styles.link}>
        <span className={classnames(styles.iconWrapper)}>
          <Icon className={styles.icon} />

          {!!count && (
            <span className={classnames(styles.count, className)} onAnimationEnd={onAnimationEnd}>
              <span className={styles.countValue}>{count}</span>
            </span>
          )}
        </span>

        <span className={styles.name}>{name}</span>
      </Link>
    </li>
  );
}

Item.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  paths: PropTypes.arrayOf(PropTypes.string).isRequired,
  count: PropTypes.number,
  isSmall: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onAnimationEnd: PropTypes.func,
};

Item.defaultProps = {
  count: null,
  className: null,
  onAnimationEnd: null,
};

export default Item;
