import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PARAMETERS, ANGLES } from '../../constants';
import { polarToX, polarToY, pathDefinition } from '../../helpers';

import styles from './styles.module.scss';

const Grid = ({ light }) => {
  const strokeColor = light ? '#fff' : '#d8d8d8';

  return (
    <g>
      <path
        d={pathDefinition(
          PARAMETERS.map((_, index) => [
            polarToX(ANGLES[index], 0.5),
            polarToY(ANGLES[index], 0.5),
          ]),
        )}
        fill="transparent"
        stroke={strokeColor}
        strokeWidth="1"
      />

      {PARAMETERS.map((parameter, index) => {
        const angle = ANGLES[index];

        const x = polarToX(angle, 0.5).toFixed(4);
        const y = polarToY(angle, 0.5).toFixed(4);

        return (
          <Fragment key={index}>
            <line
              x1={polarToX(angle, 0.5)}
              y1={polarToY(angle, 0.5)}
              x2={0}
              y2={0}
              stroke={strokeColor}
              strokeWidth="1"
            />

            <text
              x={x}
              y={y}
              dy={5}
              fontSize={10}
              transform={parameter.transform(x, y)}
              className={classnames(styles.text, { [styles.light]: light })}
              letterSpacing={0.2}
            >
              {parameter.subject(x, y)}
            </text>
          </Fragment>
        );
      })}
    </g>
  );
};

Grid.propTypes = {
  light: PropTypes.bool,
};

Grid.defaultProps = {
  light: false,
};

export default Grid;
