import React from 'react';
import PropTypes from 'prop-types';

import { ROUTES } from 'router/constants';

import Title from 'components/title';
import Button from 'components/button';

import { ReactComponent as SaveSVG } from 'static/svg/search-save.svg';
import { ReactComponent as SearchSVG } from 'static/svg/search-input.svg';
import { ReactComponent as MatchesSVG } from 'static/svg/navigation-match.svg';

import { ReactComponent as CloseSVG } from 'static/svg/close.svg';

import styles from './styles.module.scss';

function Content({ push, categoryId, closeModal }) {
  const onSavedClick = () => push(ROUTES.FAVORITES.replace(':categoryId', categoryId));
  const onSearchClick = () => push(ROUTES.SEARCH);
  const onMatchesClick = () => push(ROUTES.PRODUCT_CATEGORIES.replace(':categoryId', categoryId));

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal} role="button" onClick={(event) => event.stopPropagation()}>
        <div onClick={closeModal} role="button" tabIndex={0} className={styles.close}>
          <CloseSVG className={styles.closeIcon} />
        </div>

        <Title className={styles.title}>Add to Compare From:</Title>

        <button type="button" className={styles.button} onClick={onMatchesClick}>
          <MatchesSVG className={styles.icon} /> <span>MY MATCHES</span>
        </button>
        <button type="button" className={styles.button} onClick={onSavedClick}>
          <SaveSVG className={styles.icon} /> <span>MY SAVES</span>
        </button>
        <button type="button" className={styles.button} onClick={onSearchClick}>
          <SearchSVG className={styles.icon} /> <span>SEARCH</span>
        </button>

        <Button
          variant="outline-secondary"
          size="lg"
          onClick={closeModal}
          className={styles.closeButton}
        >
          <span>CANCEL</span>
        </Button>
      </div>
    </div>
  );
}

Content.propTypes = {
  push: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default Content;
