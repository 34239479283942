import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import CONFIG from 'constants/config';

import segment from 'libs/analytic-segment';

import { selectors } from 'store/modules/user';

// eslint-disable-next-line import/no-mutable-exports
let withSegment = (WrappedComponent) => WrappedComponent;

if (CONFIG.IS_ENABLED_SEGMENT) {
  withSegment = (WrappedComponent) => {
    function WithSegment({ id, email, isPermanentUser, name, ...otherProps }) {
      const location = useLocation();

      useEffect(() => {
        segment.load();
      }, []);

      useEffect(() => {
        if (isPermanentUser) {
          segment.identify(id, { email, name });
        }
      }, [isPermanentUser]);

      useEffect(() => {
        segment.page();
      }, [location.pathname, location.search]);

      return <WrappedComponent {...otherProps} />;
    }

    WithSegment.propTypes = {
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      isPermanentUser: PropTypes.bool.isRequired,
    };

    WithSegment.defaultProps = {
      id: null,
      name: null,
      email: null,
    };

    const mapStateToProps = (state) => {
      return {
        id: selectors.getUserId(state),
        name: selectors.getName(state),
        email: selectors.getEmail(state),
        isPermanentUser: selectors.getIsPermanent(state),
      };
    };

    return connect(mapStateToProps)(WithSegment);
  };
}

export default withSegment;
