import { CATEGORIES } from 'constants/products-navigation';

import { PRODUCT_LISTS } from './constants';

export const getProductLists = (state) => state.productLists;

export const getProductList = (state, listName) => getProductLists(state).entities[listName];

export const getProductIdsByListName = (state, listName) =>
  getProductList(state, listName).productIds;

export const getCartIds = (state) => getProductIdsByListName(state, PRODUCT_LISTS.CART);

export const getSavedIds = (state) => getProductIdsByListName(state, PRODUCT_LISTS.SAVED);

export const getComparedIds = (state) => getProductIdsByListName(state, PRODUCT_LISTS.COMPARED);

export const getTotalCountOfComparedProducts = (state) => getComparedIds(state).length;

export const getIsContainsProductInList = (state, { productId, listName }) =>
  getProductIdsByListName(state, listName).includes(productId);

export const getProcessingProductIdsByListName = (state, listName) =>
  getProductLists(state).processingProductIds[listName];

export const getIsProcessingProduct = (state, { productId, listName }) =>
  getProcessingProductIdsByListName(state, listName).includes(productId);

export const getRequestByList = (state, listName) => getProductList(state, listName).request;

export const getProductIdsByCategoryMap = (state, listName) =>
  getProductList(state, listName).productIdsByCategory;

export const getProductCountersByCategory = (state, listName) => {
  const productIdsByCategoryMap = getProductIdsByCategoryMap(state, listName);

  return CATEGORIES.reduce((acc, { id }) => {
    // eslint-disable-next-line no-param-reassign
    acc[id] = productIdsByCategoryMap[id]?.length ?? 0;

    return acc;
  }, {});
};

export const getProductIdsByCategory = (state, { listName, categoryId }) =>
  getProductIdsByCategoryMap(state, listName)[categoryId] ?? [];

export const getTotalCountOfProductsCart = (state) => getCartIds(state).length;
