export const MAX_LIMIT_COUNT_FOR_CHANGE_STATE = 4;
export const MIN_LIMIT_COUNT_FOR_CHANGE_STATE = 4;
export const DEFAULT_STATE = {
  isFaceDetected: false,
  isValidIlluminationValues: false,
  isTooMuchLightOnTheFace: false,
  isBackgroundBrightLight: false,
  isTooMuchShadowOnTheFace: false,
};
export const DEFAULT_CACHE = {
  frameCountFaceIsDetected: 0,
  frameCountOfLightOnTheFace: 0,
  frameCountFaceIsNotDetected: 0,
  frameCountOfShadowOnTheFace: 0,
  frameCountOfBackgroundBrightLight: 0,
};
