import React, { useState, memo, useRef, forwardRef, useEffect } from 'react';
import classnames from 'classnames';
import composeRefs from '@seznam/compose-react-refs';
import NiceModal from '@ebay/nice-modal-react';

import { MODALS } from 'constants/modals';
import { ImageCaptureState } from 'constants/video-capture';

import usePersistFunction from 'hooks/use-persist-function';
import { useVideoState } from 'hooks/video-capture/use-video-state';

import { SuccessCircle } from 'components/video-capture/success-circle';
import { LayoutMask } from 'components/video-capture/layout-mask';

import { FaceState, MESSAGES } from '../../constants';
import { ImageCaptureArrowHint } from '../image-capture-arrow-hint';
import { RangeMask } from '../range-mask';
import useFaceState from './hooks/use-face-state';
import useOnVideoReadyEffect from './hooks/use-on-video-ready-effect';

import styles from './styles.module.scss';

const RANGE = 10;
const UI_RANGE = 20;
const COUNT_POSITIVE_ANGLES = 4;
const CENTERED_ANGLE = {
  from: (RANGE / 2) * -1,
  to: RANGE / 2,
  uiFrom: (UI_RANGE / 2) * -1,
  uiTo: UI_RANGE / 2,
};
export const POSITIVE_RANGES = Array.from(new Array(COUNT_POSITIVE_ANGLES), (_, index) => {
  const angle = index * RANGE;
  const uiAngle = index * UI_RANGE;
  return {
    from: angle,
    to: index === COUNT_POSITIVE_ANGLES - 1 ? 180 : angle + RANGE,
    uiFrom: uiAngle,
    uiTo: index === COUNT_POSITIVE_ANGLES - 1 ? 180 : uiAngle + UI_RANGE,
  };
});
export const RANGES = [
  ...POSITIVE_RANGES.slice().reverse(),
  CENTERED_ANGLE,
  ...POSITIVE_RANGES.map((range) => ({
    from: range.to * -1,
    to: range.from * -1,
    uiFrom: range.uiFrom * -1,
    uiTo: range.uiTo * -1,
  })),
];
const CaptureVideo = forwardRef(
  (
    {
      isError,
      isCriticalError,
      isVideoReady,
      children,
      className,
      videoRecorder,
      setFaceState,
      onVideoReady,
      onVideoCaptured,
      imageCaptureState,
      detectFace,
    },
    forwardVideoRef,
  ) => {
    const [rangeIndex, setRangeIndex] = useState(0);
    const [videoState, videoStateActions] = useVideoState();
    const [isHelpWindowOpened, setIsHelpWindowOpened] = useState(false);
    const videoRef = useRef(null);
    const successCircleRef = useRef(null);
    const rangeMaskApiRef = useRef(null);
    const whiteBackgroundRef = useRef(null);
    const wrapperHintRef = useRef(null);
    const isCapturingStep = imageCaptureState === ImageCaptureState.Capturing;
    const faceState = useFaceState({
      ranges: RANGES,
      videoRef,
      rangeIndex,
      onAngleCaptured: usePersistFunction(() => {
        if (rangeIndex === RANGES.length - 1) {
          return;
        }
        setRangeIndex(rangeIndex + 1);
      }),
      detectFace,
      videoRecorder,
      isCapturingStep,
      onVideoCaptured: usePersistFunction(onVideoCaptured),
      rangeMaskApiRef,
      isHelpWindowOpened,
    });
    useOnVideoReadyEffect({ videoState, onVideoReady });
    useEffect(() => {
      setFaceState(faceState);
    }, [faceState]);
    useEffect(() => {
      rangeMaskApiRef.current?.rangesApiRef.current?.focusRange(rangeIndex);
    }, [rangeIndex]);
    const isFaceNotDetected = !!faceState && faceState !== FaceState.Detected;
    useEffect(() => {
      if (
        !isVideoReady ||
        isFaceNotDetected ||
        isHelpWindowOpened ||
        !(rangeIndex === 0 || rangeIndex === RANGES.length - 1)
      ) {
        if (wrapperHintRef.current) {
          wrapperHintRef.current.style.opacity = '0';
        }
        return () => {};
      }
      const timeoutId = setInterval(() => {
        if (wrapperHintRef.current) {
          wrapperHintRef.current.style.opacity = '1';
        }
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
        if (wrapperHintRef.current) {
          wrapperHintRef.current.style.opacity = '0';
        }
      };
    }, [isFaceNotDetected, rangeIndex, isHelpWindowOpened]);

    return (
      <div
        className={classnames(styles.wrapper, className, {
          [styles.videoReady]: isVideoReady,
          [styles.faceNotDetected]: isFaceNotDetected || !faceState,
        })}
      >
        <div ref={whiteBackgroundRef} className={styles.whiteBackground} />

        <div className={styles.captureMaskWrapper}>
          <div className={styles.captureMaskInner}>
            <RangeMask
              ref={rangeMaskApiRef}
              range={UI_RANGE}
              ranges={RANGES}
              className={styles.captureMask}
              rangesClassName={styles.ranges}
              circleClassName={styles.circle}
            />

            <div ref={successCircleRef} className={styles.successCircle}>
              <SuccessCircle isShowing />
            </div>
          </div>
        </div>

        <div className={styles.videoWrapper}>
          <video
            ref={composeRefs(forwardVideoRef, videoRef)}
            muted
            onPlay={videoStateActions.videoPlaying}
            autoPlay
            controls={false}
            className={styles.video}
            playsInline
            onLoadedMetadata={videoStateActions.videoLoadedMetadata}
          />
        </div>

        <div ref={wrapperHintRef} className={styles.hintWrapper}>
          <div className={styles.hintInner}>
            <div className={styles.hintContent}>
              <div className={styles.arrowWrapper}>
                <ImageCaptureArrowHint position="left" withAnimation={rangeIndex > COUNT_POSITIVE_ANGLES} />
              </div>

              <div className={styles.arrowWrapper}>
                <ImageCaptureArrowHint position="right" withAnimation={rangeIndex < COUNT_POSITIVE_ANGLES} />
              </div>
            </div>
          </div>
        </div>

        <LayoutMask
          isError={isError || isFaceNotDetected}
          className={styles.mask}
          isAppReady={isVideoReady}
          onNeedHelpClick={() => {
            setIsHelpWindowOpened(true);

            NiceModal.show(MODALS.VideoCaptureHelp, {
              onClose: () => {
                setIsHelpWindowOpened(false);
              },
            });
          }}
          isCriticalError={isCriticalError}
        >
          {isFaceNotDetected || !faceState ? (
            <>
              {isFaceNotDetected && <p className={styles.title}>{MESSAGES[faceState]?.title}</p>}

              {children}
            </>
          ) : (
            <p className={styles.topHint}>Slowly rotate face to place the green line inside the shaded tile</p>
          )}
        </LayoutMask>
      </div>
    );
  },
);
export default memo(CaptureVideo);
