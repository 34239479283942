import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import classnames from 'classnames';

import { usePreloadImages } from 'hooks/video-capture/use-preload-images';

import { IMAGES, STEPS } from './constants';

import styles from './styles.module.scss';

export const Animation = () => {
  const [isShowingPulse, setIsShowingPulse] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  usePreloadImages(IMAGES);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        setStepIndex((prevIndex) => (prevIndex + 1) % STEPS.length);
        setIsShowingPulse(false);
      },
      stepIndex === 0 ? 3000 : 2000,
    );
    const timeoutPulseId = STEPS[stepIndex].pulse
      ? setTimeout(() => {
          setIsShowingPulse(true);
        }, STEPS[stepIndex].pulse.delay)
      : null;
    return () => {
      clearTimeout(timeoutId);
      if (timeoutPulseId) {
        clearTimeout(timeoutPulseId);
      }
    };
  }, [stepIndex]);

  const currentStep = STEPS[stepIndex];

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{currentStep.text}</p>

      <AnimatePresence initial>
        <motion.div
          key={stepIndex}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className={styles.imageWrapper}>
            <div className={styles.imageInner}>
              <img src={currentStep.img} alt="instructions" />

              <div
                style={currentStep.pulse?.styles}
                className={classnames(styles.pulsatingCircle, { [styles.animate]: isShowingPulse })}
              />
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
