import { createSlice } from '@reduxjs/toolkit';

import * as selectors from './selectors';

const DEFAULT_STATE = {
  counter: 0,
  isShowing: false,
};

const { reducer, actions } = createSlice({
  name: 'loader',
  initialState: DEFAULT_STATE,
  reducers: {
    show: (draftState) => {
      draftState.counter += 1;
      draftState.isShowing = true;
    },

    hide: (draftState) => {
      draftState.counter -= 1;
      draftState.isShowing = !!draftState.counter;
    },
  },
});

export { selectors, reducer, actions };
