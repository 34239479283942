import { connectRouter } from 'connected-react-router';

import history from 'router/history';

import { reducer as app } from './modules/app';
import { reducer as user } from './modules/user';
import { reducer as cart } from './modules/cart';
import { reducer as quiz } from './modules/quiz';
import { reducer as blog } from './modules/blog';
import { reducer as selfie } from './modules/selfie';
import { reducer as search } from './modules/search';
import { reducer as loader } from './modules/loader';
import { reducer as products } from './modules/products';
import { reducer as productLists } from './modules/product-lists';
import { reducer as imageCapture } from './modules/image-capture';
import { reducer as dataSubmission } from './modules/data-submission';

export default {
  app,
  user,
  cart,
  blog,
  quiz,
  selfie,
  search,
  loader,
  router: connectRouter(history),
  products,
  productLists,
  imageCapture,
  dataSubmission,
};
