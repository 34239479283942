import { QUIZ_END_ID, QUIZZES } from 'constants/quiz';

import { isEmpty, isNotEmpty } from 'helpers/is';

export const getFirstUnansweredQuestion = ({ quizType, confirmedQuestionIds }) => {
  const { QUESTIONS, QUESTIONS_ORDER } = QUIZZES[quizType];

  if (confirmedQuestionIds.length === 0) {
    return QUESTIONS[QUESTIONS_ORDER[0]];
  }

  return null;
};

export const getNextQuestion = ({
  quiz,
  quizType,
  appVersion,
  isSelfieSkipped,
  isAuthenticated,
  currentQuestionId,
}) => {
  const { QUESTIONS, QUESTIONS_ORDER, QUESTION_CONDITIONS = {} } = QUIZZES[quizType];

  const currentQuestionIndex = currentQuestionId && QUESTIONS_ORDER.indexOf(currentQuestionId);
  const isCurrentIndexExist = currentQuestionIndex && currentQuestionIndex !== -1;
  const lastConfirmedQuestionId = quiz.confirmedQuestionIds[quiz.confirmedQuestionIds.length - 1];

  let currentIndex = -1;

  if (isCurrentIndexExist) {
    currentIndex = currentQuestionIndex;
  } else if (isNotEmpty(lastConfirmedQuestionId)) {
    currentIndex = QUESTIONS_ORDER.indexOf(lastConfirmedQuestionId);
  }

  for (let questionIndex = currentIndex + 1; questionIndex < QUESTIONS_ORDER.length; questionIndex += 1) {
    const question = QUESTIONS[QUESTIONS_ORDER[questionIndex]];
    const condition = QUESTION_CONDITIONS[question.id];

    if (
      isEmpty(condition) ||
      condition({
        answers: quiz.answersByQuestionIds,
        isQuizDone: quiz.isDone,
        appVersion,
        isSelfieSkipped,
        isAuthenticated,
      })
    ) {
      return question;
    }
  }

  return {
    id: QUIZ_END_ID,
  };
};

export const getPrevQuestion = ({ quizType, currentQuestionId, confirmedQuestionIds }) => {
  const { QUESTIONS, QUESTIONS_ORDER } = QUIZZES[quizType];

  const currentQuestionIndex = QUESTIONS_ORDER.indexOf(currentQuestionId);

  for (let questionIndex = currentQuestionIndex - 1; questionIndex >= 0; questionIndex -= 1) {
    const question = QUESTIONS[QUESTIONS_ORDER[questionIndex]];

    if (question.type !== 'break' && confirmedQuestionIds.includes(question.id)) {
      return question;
    }
  }

  return null;
};

export const isAvailableQuestionId = ({ currentQuestionId, confirmedQuestionIds }) =>
  confirmedQuestionIds.includes(currentQuestionId);
