import addHours from 'date-fns/addHours';
import differenceInHours from 'date-fns/differenceInHours';

import { DATA_SUBMISSION_EXPIRED_TIMEOUT_HOURS } from './constants';

// After 23 hours, the submission is considered expired, on backend side stop working data submission worker in 24 hours after created.
export const isDataSubmissionExpired = (createdAt) =>
  differenceInHours(new Date(), new Date(createdAt)) > DATA_SUBMISSION_EXPIRED_TIMEOUT_HOURS;

export const getExpiredDataSubmissionDate = (createdAt) =>
  addHours(new Date(createdAt), DATA_SUBMISSION_EXPIRED_TIMEOUT_HOURS);
