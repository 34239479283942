const videoYaw = 50;

export const SelfieId = {
  Left: 'left',
  Right: 'right',
  Center: 'center',
};

export const SELFIE_ORDER = [SelfieId.Left, SelfieId.Center, SelfieId.Right];
export const SELFIE_DATA = {
  [SelfieId.Left]: {
    headMetaTitle: 'Left',
    title: 'Left',
    label: 'Right Facing',
    serverKey: 'FACE_LEFT',
    hint: 'Turn face to align dotted and solid lines',
    angles: {
      yaw: 50,
      videoYaw,
      previewYaw: 40,
    },
  },
  [SelfieId.Right]: {
    headMetaTitle: 'Right',
    title: 'right',
    label: 'Left Facing',
    serverKey: 'FACE_RIGHT',
    hint: 'Turn face to align dotted and solid lines',
    angles: {
      yaw: -50,
      videoYaw: videoYaw * -1,
      previewYaw: -40,
    },
  },
  [SelfieId.Center]: {
    headMetaTitle: 'Center',
    title: 'Front',
    label: 'Front Facing',
    serverKey: 'FACE_CENTER',
    hint: 'Turn face to align dotted and solid lines',
    angles: {
      yaw: 0,
      videoYaw: 0,
      previewYaw: 0,
    },
  },
};
export const IDEAL_RESOLUTION = {
  ILLUMINATION: {
    WIDTH: 320,
    HEIGHT: 240,
  },
  IMAGE_CAPTURE: {
    WIDTH: 1920,
    HEIGHT: 1440,
  },
  VIDEO_CAPTURE: {
    WIDTH: 1600,
    HEIGHT: 1200,
  },
  ASPECT_RATIO: 4 / 3,
};

export const IlluminationAnimationStep = {
  PhonePosition: 'phone-position',
  ArrowsInfo: 'arrows-info',
  Complete: 'complete',
  Success: 'success',
};

export const ANIMATION_STEP_ORDER = [
  IlluminationAnimationStep.PhonePosition,
  IlluminationAnimationStep.ArrowsInfo,
  IlluminationAnimationStep.Complete,
  IlluminationAnimationStep.Success,
];
export const ANIMATION_STEP_DATA = {
  [IlluminationAnimationStep.PhonePosition]: {
    text: 'hold phone at eye level ',
  },
  [IlluminationAnimationStep.ArrowsInfo]: {
    text: 'ADJUST LIGhting so arrows move to the center. ',
  },
  [IlluminationAnimationStep.Complete]: {
    text: 'ADJUST LIGhting so arrows move to the center. ',
  },
  [IlluminationAnimationStep.Success]: {
    text: 'SUCCESS!',
  },
};
export const IlluminationCheckState = {
  InitializingFaceDetector: 'initializing-face-detector',
  StartingCamera: 'starting-camera',
  Instructions: 'instructions',
  Checking: 'checking',
  PrepareCapturing: 'prepare-capturing',
};

export const FACE_DETECTOR_TEXTS = {
  INITIALIZING_FACE_DETECTOR: 'Initializing face detector...',
};
export const STARTING_CAMERA_TEXTS = {
  WAITING_VIDEO: 'Starting video...',
  STARTING_CAMERA: 'Starting camera...',
  INITIALIZING_VIDEO_RECORDER: 'Initializing video recorder...',
};
export const ILLUMINATION_TEXTS = {
  INSTRUCTIONS: 'ADJUST LIGhting so arrows move to the center.',
  INITIALIZING_FACE_DETECTOR: 'Initializing face detector...',
  PREPARE_CAPTURING: 'Keep this lighting and hold still.',
};
export const ImageCaptureState = {
  StartingCamera: 'starting-camera',
  LoadingDetector: 'loading-detector',
  InitializingVideoRecorder: 'initializing-video-recorder',
  Capturing: 'capturing',
  Captured: 'captured',
};

export const CriticalErrorType = {
  CameraAccess: 'camera-access',
  WeakInternet: 'weak-internet',
  UnknownError: 'unknown-error',
  NoFaceDetected: 'no-face-detected',
  FaceDetectorFailed: 'face-detector-failed',
  VideoRecorder: 'video-recorder',
};

export const CRITICAL_ERROR_DATA = {
  [CriticalErrorType.CameraAccess]: {
    title: 'ALLOW CAMERA ACCESS',
    text: 'Please enable camera access to start your diagnostics.',
    iconId: '',
  },
  [CriticalErrorType.WeakInternet]: {
    title: 'WEAK INTERNET',
    text: 'poor internet. Please Move to Better connection.',
    iconId: '',
  },
  [CriticalErrorType.NoFaceDetected]: {
    title: 'ERROR',
    text: 'no face detected',
    iconId: '',
  },
  [CriticalErrorType.UnknownError]: {
    title: 'Unknown ERROR',
    text: 'Unknown error',
    iconId: '',
  },
  [CriticalErrorType.FaceDetectorFailed]: {
    title: 'Failed to load face detector',
    text: 'Try to reload page',
    iconId: '',
  },
  [CriticalErrorType.VideoRecorder]: {
    title: 'Failed to initialize video recorder',
    text: 'Try to reload page',
    iconId: '',
  },
};

export const MinorErrorType = {
  FaceState: 'face-state',
  Glasses: 'glasses',
};

export const MINOR_ERROR_DATA = {
  [MinorErrorType.Glasses]: {
    title: 'ENABLE CAMERA ACCESS',
    text: 'Please enable camera access to start your diagnostics.',
    iconId: '',
  },
  [MinorErrorType.FaceState]: {
    title: '',
    text: '',
    iconId: '',
  },
};
