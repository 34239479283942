import { useCreateConst } from 'hooks/video-capture/use-create-const';

const useCanvas = ({ width, height }) =>
  useCreateConst(() => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    return [canvas, canvas.getContext('2d', { willReadFrequently: true })];
  });

export default useCanvas;
