import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import segment, { EVENTS } from 'libs/analytic-segment';

import { Modal } from 'components/modal';

import Content from './components/content';

function Search({ onSubmit, isVisible, closeModal, ...otherProps }) {
  const onSubmitHandler = useCallback(
    (newSearchValue) => {
      segment.track(EVENTS.SEARCH_SUBMITTED, { term: newSearchValue });
      onSubmit(newSearchValue);
    },
    [onSubmit],
  );

  return (
    <Modal isVisible={isVisible} onOverlayClick={closeModal}>
      {() => <Content {...otherProps} onSubmit={onSubmitHandler} closeModal={closeModal} />}
    </Modal>
  );
}

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default Search;
