import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import segment, { EVENTS } from 'libs/analytic-segment';

import { normalizeValue } from 'helpers/skin';

import { Modal } from 'components/modal';
import RadarChart from 'components/radar-chart';

import { ReactComponent as CloseSVG } from 'static/svg/close.svg';

import styles from './styles.module.scss';

function MatchScoreExplanation({ product, skinProfile, isVisible, closeModal }) {
  const chartData = useMemo(
    () => ({
      skin: Object.keys(skinProfile).reduce(
        (acc, key) => ({ ...acc, [key]: 1 - skinProfile[key] }),
        {},
      ),
      product: product?.performanceVector,
    }),
    [skinProfile, product?.performanceVector],
  );

  useEffect(() => {
    if (isVisible) {
      segment.track(EVENTS.PRODUCTS_VIEWED_MATCH_SCORE_EXPLANATION);
    }
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} onOverlayClick={closeModal}>
      {() => (
        <div className={styles.modal} onClick={(event) => event.stopPropagation()} role="button">
          <div onClick={closeModal} role="button" tabIndex={0} className={styles.close}>
            <CloseSVG className={styles.closeIcon} />
          </div>

          <RadarChart data={chartData} className={styles.chart} />

          <div className={styles.container}>
            <div className={classnames('container-fluid')}>
              <div className={styles.labels}>
                <div className={styles.label}>
                  <div className={styles.labelBox} />
                  <p className={styles.labelText}>PRODUCT</p>
                </div>
                <div className={styles.label}>
                  <div className={styles.labelBox} />
                  <p className={styles.labelText}>YOUR SKIN NEEDS</p>
                </div>
              </div>
            </div>

            <div className={classnames('container-fluid', styles.info)}>
              <div className={styles.infoInner}>
                <div className={styles.match}>
                  <p className={styles.matchPercentage}>{normalizeValue(product.matchScore)}%</p>
                  <p className={styles.matchText}>match</p>
                </div>

                <p className={styles.description}>
                  The match score measures how well a product fits your skin needs across the six
                  areas.
                  <br />
                  <br />
                  From your skin quiz, we create your unique needs map from over 1M potential
                  combinations of needs. We then score each product’s ingredients against your
                  individual needs and determine the match score.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

MatchScoreExplanation.propTypes = {
  product: PropTypes.shape({
    performanceVector: PropTypes.shape({}),
    matchScore: PropTypes.number,
  }),
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  skinProfile: PropTypes.shape({}).isRequired,
};

MatchScoreExplanation.defaultProps = {
  product: null,
};

export default MatchScoreExplanation;
