export const QUESTIONS_ORDER = [
  'welcome-quiz',
  'v1.short:skin-hydration',
  'v1.short:skin-tone',
  'v1.short:skin-smoothness',
  'v1.short:dermal-fibers',
  'v1.short:skin-milieu',
  'v1.short:oxygen-saturation',
];

export const FIRST_QUESTION_ID = QUESTIONS_ORDER[0];
export const LAST_QUESTION_ID = 'v1.short:oxygen-saturation';

export const QUESTIONS = {
  'welcome-quiz': {
    id: 'welcome-quiz',
    type: 'welcome-quiz',
  },
  'v1.short:skin-hydration': {
    id: 'v1.short:skin-hydration',
    type: 'question',
    title: 'Hydration',
    description:
      'The hydration parameter is a measure of your overall skin hydration, skin barrier function, and your skin’s ability to properly regulate moisture levels.',
    info:
      'Water fundamentally gives your skin its healthy glow and is critical to your long term skin health. Skin hydration is the overall outcome determined by the quality of your skin barrier (ability to hold water in and keep intruders out), your skin’s ability to prevent too much trans-epidermal water loss (TEWL), the rate of cell renewal, and the moisture sealing effect of sebum production. Your skin barrier function is essential to maintaining optimal hydration levels in skin and the entire body and it reflects your skin health. It forms the protective layer between the inside and outside. Skin barrier holds in nutrients and water, retains proper pH levels and forms a protective barrier against pathogens. A disrupted skin barrier can lead to manifestations from dryness to skin diseases such as atopic dermatitis. It can be improved by increasing the water holding capacity in the skin surface layers and preventing its disruption.',
    sliderLabels: ['Unhydrated', 'Hydrated'],
  },
  'v1.short:skin-tone': {
    id: 'v1.short:skin-tone',
    type: 'question',
    title: 'Skin tone',
    description:
      'Skin tone is the evenness or unevenness of skin color across the face. Most of the color you see on the face is from the presence of pigment (melanin).',
    info:
      'Skin tone reflects how and where melanin is distributed in the skin. This can be affected by tissue damage, UV exposure, hormonal changes and genetics. Unevenness may show up as dark or light spots or scarring following a local inflammation like a breakout (also known as post inflammatory hyperpigmentation). Skin tone can be improved by reducing and evening pigmentation and preventing or reducing the root causes of inflammation.',
    sliderLabels: ['Uneven', 'Even'],
  },
  'v1.short:skin-smoothness': {
    id: 'v1.short:skin-smoothness',
    type: 'question',
    title: 'Smoothness',
    description:
      "Skin smoothness is a measure of the skin's surface texture. It evaluates how uniform and tightly arranged the top layers of skin are to create a surface that is soft and smooth to the touch. Widened or sagging pores and the presence of fine lines have an impact on skin smoothness. When skin is not smooth it not only feels rough, it also reflects less light and looks less radiant.",
    info:
      'Skin smoothness can be affected by optimizing hydration and skin lipid composition, facilitating regular desquamation (exfoliation), minimizing pore size and plumping the micro relief (fine lines). Improving skin smoothness not only makes your skin feel soft, it also increases your radiance and glow.',
    sliderLabels: ['Rough', 'Smooth'],
  },
  'v1.short:dermal-fibers': {
    id: 'v1.short:dermal-fibers',
    type: 'question',
    title: 'Dermal Fibers',
    info:
      'Your collagen elastin matrix consists of a dense and elastic network of fibers, located in the deeper layers of the skin.  Over time, the collagen elastin matrix degrades and becomes entangled due to the accumulation of damage and the senescence (cellular aging) of fibroblasts, the collagen elastin matrix producing cells. The impact of (chronological) age, environmental exposure and hormonal changes determines the rate for this degradation to occur. Visually it leads to wrinkle formation, loss of firmness and elasticity. Ingredients that promote wound healing, combat senescence, protect the skin and boost new collagen elastin production can help improve your collagen and elastin production to maintain and restore your skin’s healthy, firm appearance.',
    description:
      'Dermal fibers provide the elastic lift to resist gravity, the structural support to create density and youthful firmness of the skin. It’s a measure of the collagen elastin matrix and the skin’s ability to heal and repair.',
    sliderLabels: ['Lines/Wrinkles', 'Firm'],
  },
  'v1.short:skin-milieu': {
    id: 'v1.short:skin-milieu',
    type: 'question',
    title: 'Milieu',
    description:
      'Skin Milieu is your skin’s environment. Signs of an unhealthy environment include breakouts, sensitivity and reactions, acne, or inflammation. Healthy skin milieu makes your skin resilient.',
    info:
      'Skin Milieu encompasses everything from the micro-vasculature system that provides skin with nutrients, your skin’s immune defense system, and your skin’s microbiome. Your skin microbiome is made up of the microorganisms that live on, and interact with, your skin. These microorganisms interact with your skin (sweat, sebum, etc) and play an important role in maintaining a healthy skin environment. Your microbiome can be affected by skincare products, environment, hormonal fluctuations, and changes in skin barrier and sebum production. You can improve your skin milieu by reducing inflammation and using ingredients that promote a healthy skin microbiome.',
    sliderLabels: ['Unhealthy', 'Healthy'],
  },
  'v1.short:oxygen-saturation': {
    id: 'v1.short:oxygen-saturation',
    type: 'question',
    title: 'Energy supply',
    description:
      'Energy supply includes oxygen saturation and redox state of the skin. Low Energy supply from lack of oxygen saturation can lead to visible dullness and fatigue, resulting over time in premature skin aging.',
    info:
      'Energy supply includes oxygen saturation, the amount of oxygen rich microcirculation in your skin, which is essential to deliver nutrients and remove waste. It also includes the mitochondrial health or redox state of the skin, essentially your skin’s ability to rejuvenate and convert oxygen into biological energy without oxidative damage. Energy supply can be improved by stimulating microcirculation, optimizing the skin’s mitochondrial energy capacity and through the use of antioxidants, thereby boosting healthy radiance and ultimately slowing down the aging process.',
    sliderLabels: ['Dull', 'Radiant'],
  },
};
