import { createSlice } from '@reduxjs/toolkit';

import createPersistReducer from 'store/helpers/create-persist-reducer';

import { VERSIONS } from './constants';
import * as selectors from './selectors';
import * as customActions from './actions';

const DEFAULT_STATE = {
  version: VERSIONS.DEFAULT,
  isUpdatedBuild: false,
  isFetchedRootComponent: false,
};

const { reducer: defaultReducer, actions: reducerActions } = createSlice({
  name: 'app',
  initialState: DEFAULT_STATE,
  reducers: {
    changeVersion: (draftState, { payload: version }) => {
      draftState.version = version;
    },

    updatedBuild: (draftState) => {
      draftState.isUpdatedBuild = true;
    },

    fetchedRootComponent: (draftState) => {
      draftState.isFetchedRootComponent = true;
    },
  },
});

const reducer = createPersistReducer(
  {
    key: 'app',
    whitelist: ['version'],
  },
  defaultReducer,
);

const actions = {
  ...customActions,
  ...reducerActions,
};

export { selectors, reducer, actions };
