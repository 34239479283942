import React from 'react';

import { FloatingElements } from 'components/floating-elements';

const withFloatingElements = (WrappedComponent) =>
  function WithFloatingElements(props) {
    return (
      <FloatingElements>
        <WrappedComponent {...props} />
      </FloatingElements>
    );
  };

export default withFloatingElements;
