import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import createPersistReducer from 'store/helpers/create-persist-reducer';

import * as thunks from './thunks';
import * as helpers from './helpers';
import * as selectors from './selectors';
import * as customActions from './actions';

const DEFAULT_STATE = {
  id: null,
  name: null,
  email: null,
  token: null,
  skinProfile: {
    tone: null,
    milieu: null,
    hydration: null,
    smoothness: null,
    dermalFibers: null,
    oxygenSaturation: null,
  },
  isAnonymous: false,
  isPermanent: false,
  surveyResults: [],
  isAuthenticated: false,
  policyAcceptedAt: null,
};

const { reducer: defaultReducer, actions: reducerActions } = createSlice({
  name: 'user',
  initialState: DEFAULT_STATE,
  reducers: {
    updateToken: (draftState, { payload: token }) => {
      const { sub, name, email, status, skinProfile } = jwtDecode(token);

      draftState.id = sub.replace('user:', '');
      draftState.name = name;
      draftState.token = token;
      draftState.email = email;
      draftState.isPermanent = status === 'new' || status === 'verified';
      draftState.isAnonymous = status === 'anonymous';
      draftState.skinProfile = skinProfile;
    },

    authenticated: (draftState) => {
      draftState.isAnonymous = false;
      draftState.isAuthenticated = true;
    },
  },
  extraReducers: {
    [customActions.updateSurveyResults]: (draftState, { payload: surveyResults }) => ({
      ...draftState,
      surveyResults: helpers.transformSurveyResultsForStore(surveyResults),
    }),

    [customActions.logout]: () => DEFAULT_STATE,

    [thunks.signUp.fulfilled]: (draftState) => {
      draftState.isAnonymous = false;
      draftState.isAuthenticated = true;
    },

    [thunks.signUpAnonymous.fulfilled]: (draftState) => {
      draftState.isAuthenticated = true;
    },

    [thunks.signUpAnonymousFromRegularQuiz.fulfilled]: (draftState) => {
      draftState.isAuthenticated = true;
    },

    [customActions.acceptPolicy]: (draftState, { payload: policyAcceptedAt = new Date().toJSON() }) => {
      draftState.policyAcceptedAt = policyAcceptedAt;
    },
  },
});

const actions = {
  ...customActions,
  ...reducerActions,
};

const reducer = createPersistReducer(
  {
    key: 'user',
  },
  defaultReducer,
);

export { selectors, reducer, actions, thunks };
