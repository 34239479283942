import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SOCIAL_NETWORKS } from './constants';

import { getStyles } from './helpers';

import styles from './styles.module.scss';

function SocialNetworks({ className }) {
  const classNames = classnames(styles.socialNetworks, className);

  return (
    <ul className={classNames}>
      {SOCIAL_NETWORKS.map(({ id, link, Icon, color }) => (
        <li className={styles.item} key={id} style={getStyles({ color })}>
          <a className={styles.link} href={link} target="_blank" rel="noopener noreferrer">
            <Icon className={styles.icon} />
          </a>
        </li>
      ))}
    </ul>
  );
}

SocialNetworks.propTypes = {
  className: PropTypes.string,
};

SocialNetworks.defaultProps = {
  className: null,
};

export default SocialNetworks;
