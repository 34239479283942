import { createContext } from 'react';

const ModalsContext = createContext({
  openModal: () => {},
  closeModal: () => {},
});

export const { Provider: ModalsProvider, Consumer: ModalsConsumer } = ModalsContext;

export default ModalsContext;
