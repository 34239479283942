import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as CloseSVG } from 'static/svg/search-close.svg';
import { ReactComponent as SearchSVG } from 'static/svg/search-search.svg';

import styles from './styles.module.scss';

function SearchForm({
  onBack,
  isPopup,
  onSubmit,
  searchRef,
  isLanding,
  className,
  placeholder,
  searchValue,
  onSearchReset,
  withoutSearch,
  onSearchChange,
  onSearchButtonClick,
}) {
  const [hasFocus, setHasFocus] = useState(false);
  const onSubmitHandler = useCallback(
    (event) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  const onSearchChangeHandler = (event) => onSearchChange(event.currentTarget.value);

  const shouldRenderSearchButton = !isPopup && !withoutSearch;

  const onFocus = () => setHasFocus(true);
  const onBlur = () => setHasFocus(false);

  return (
    <form
      action="."
      onSubmit={onSubmitHandler}
      className={classnames(styles.form, className, {
        [styles.popup]: isPopup,
        [styles.landing]: isLanding,
        [styles.hasFocus]: hasFocus,
        [styles.withoutSearch]: withoutSearch,
      })}
    >
      {isPopup && (
        <button type="button" onClick={onBack} className={styles.buttonBack}>
          <span className={styles.backArrow} />
        </button>
      )}

      {shouldRenderSearchButton && (
        <button
          type="submit"
          onClick={onSearchButtonClick}
          className={classnames(styles.buttonSearch, { [styles.active]: !!searchValue })}
        >
          <SearchSVG className={styles.iconSearch} />
        </button>
      )}

      <input
        ref={searchRef}
        type="search"
        value={searchValue}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onSearchChangeHandler}
        className={styles.input}
        placeholder={placeholder ?? 'Search product'}
      />

      {!isLanding && (
        <button
          type="button"
          onClick={onSearchReset}
          className={classnames(styles.buttonClose, { [styles.visible]: !!searchValue })}
        >
          <CloseSVG className={styles.iconClose} />
        </button>
      )}
    </form>
  );
}

SearchForm.propTypes = {
  onBack: PropTypes.func,
  isPopup: PropTypes.bool,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  searchRef: PropTypes.shape({}).isRequired,
  isLanding: PropTypes.bool,
  searchValue: PropTypes.string,
  onSearchReset: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSearchButtonClick: PropTypes.func,
};

SearchForm.defaultProps = {
  onBack: () => {},
  isPopup: false,
  onSubmit: () => {},
  isLanding: false,
  className: null,
  searchValue: '',
  onSearchReset: () => {},
  onSearchChange: () => {},
  onSearchButtonClick: () => {},
};

export default SearchForm;
