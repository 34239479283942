import { QUIZ_TYPES } from 'constants/quiz';

export const DEFAULT_STATE_OF_QUIZ = {
  error: null,
  isDone: false,
  isSaved: false,
  isSaving: false,
  isStarted: false,
  answersByQuestionIds: {},
  confirmedQuestionIds: [],
};

export const DEFAULT_STATE = {
  [QUIZ_TYPES.SHORT]: DEFAULT_STATE_OF_QUIZ,
  [QUIZ_TYPES.REGULAR]: DEFAULT_STATE_OF_QUIZ,
};
