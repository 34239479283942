import React from 'react';
import PropTypes from 'prop-types';
import { Helmet as DefaultHelmet } from 'react-helmet';

import { DEFAULTS } from 'constants/meta-info';

function Helmet({ title, description, keywords, children }) {
  return (
    <DefaultHelmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {children}
    </DefaultHelmet>
  );
}

Helmet.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  keywords: PropTypes.string,
  description: PropTypes.string,
};

Helmet.defaultProps = {
  title: DEFAULTS.TITLE,
  children: null,
  keywords: DEFAULTS.KEYWORDS,
  description: DEFAULTS.DESCRIPTION,
};

export default Helmet;
