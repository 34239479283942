import React, { useEffect } from 'react';

import { IDEAL_RESOLUTION } from 'constants/video-capture';

import { useStream } from 'hooks/video-capture/use-stream';

import Button from 'components/button';

import { ReactComponent as PhotoSVG } from 'static/images/video-capture/photo.svg';

import { Animation } from './components/animation';

import styles from './styles.module.scss';

export const STREAM_OPTIONS = {
  streamWidth: IDEAL_RESOLUTION.VIDEO_CAPTURE.WIDTH,
  streamHeight: IDEAL_RESOLUTION.VIDEO_CAPTURE.HEIGHT,
  capabilities: {
    width: IDEAL_RESOLUTION.VIDEO_CAPTURE.WIDTH,
    height: IDEAL_RESOLUTION.VIDEO_CAPTURE.HEIGHT,
  },
  streamAspectRatio: IDEAL_RESOLUTION.ASPECT_RATIO,
};
export function InitializeCamera({ onSuccess, onUnsupportedDevice, onCameraRestrictions }) {
  const [streamState, streamActions, streamHelpers] = useStream({ ...STREAM_OPTIONS });

  useEffect(() => {
    if (streamHelpers.isStateIdle()) {
      const timeoutId = setTimeout(() => {
        streamActions.createStream();
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (streamHelpers.isStateOverconstrainedError()) {
      onCameraRestrictions();
      return () => {};
    }

    if (streamHelpers.isStateUnsupportedDevice()) {
      onUnsupportedDevice();
      return () => {};
    }

    if (streamHelpers.isStateReady()) {
      onSuccess();
      return () => {};
    }

    return () => {};
  }, [streamState.state]);

  const shouldRenderInitializingText = streamHelpers.isStateIdle() || streamHelpers.isStateInitializing();
  const shouldRenderInstructions = streamHelpers.isStateAccessDenied();

  return (
    <div className={styles.wrapper}>
      {shouldRenderInitializingText && (
        <>
          <p className={styles.initTitle}>Initializing camera...</p>

          <PhotoSVG className={styles.icon} />
        </>
      )}

      {shouldRenderInstructions && (
        <div className="relative w-full flex flex-1 flex-col items-center justify-center">
          <p className="font-lg-body-1 mb-2">How to allow camera access</p>

          <Animation />

          <div className={styles.buttonWrapper}>
            <Button as="button" variant="primary" onClick={streamActions.createStream}>
              Retry camera access
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
