import { isValidEmail } from 'helpers/data';

export const validateFields = ({ name, email }) => {
  const errors = {};

  if (!isValidEmail(email)) {
    errors.email = 'Email is invalid';
  }

  if (name.length <= 1) {
    errors.name = 'Name is too short';
  }

  return Object.keys(errors).length === 0 ? null : errors;
};
