const MAX_RETRIES = 5;

export default function retry(fn, retries = 0) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        const timeout = 2 ** retries * 1000;

        setTimeout(() => {
          if (retries >= MAX_RETRIES) {
            // reject('maximum retries exceeded');

            window.location.reload();

            reject(error);

            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retries + 1).then(resolve, reject);
        }, timeout);
      });
  });
}
