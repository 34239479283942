import { schema } from 'normalizr';

export const meta = new schema.Entity('meta', {}, { idAttribute: 'productId' });
export const filter = new schema.Entity('filters');
export const product = new schema.Entity('products');

const productsSchema = {
  meta: [meta],
  data: [product],
  filters: [filter],
};

export { productsSchema };
