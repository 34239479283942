import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button as DefaultButton } from 'react-bootstrap';

import Spinner from 'components/spinner';

import { ReactComponent as Check } from 'static/svg/checkmark.svg';

import styles from './styles.module.scss';

const Button = forwardRef(
  (
    {
      loading,
      checked,
      children,
      disabled,
      variant,
      isLoading,
      className,
      loadingProgress,
      contentClassName,
      ...props
    },
    ref,
  ) => {
    const classNames = classnames('text-uppercase', className, styles.button, {
      active: checked,
      [styles.loading]: isLoading,
      [styles[variant]]: !!variant,
    });

    return (
      <DefaultButton {...props} variant={variant} ref={ref} disabled={disabled || loading} className={classNames}>
        {checked && <Check className={styles.check} />}

        <span className={classnames(styles.children, contentClassName)}>{children}</span>

        {isLoading && (
          <span className={styles.spinnerWrapper}>
            <Spinner className={styles.spinner} centered size="sm" variant="light" />
          </span>
        )}

        {loading && (
          <div className={styles.lineWrapper}>
            <div style={{ transform: `translateX(-${100 - loadingProgress}%)` }} className={styles.line} />
          </div>
        )}
      </DefaultButton>
    );
  },
);

Button.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  checked: false,
  isLoading: false,
  className: null,
};

export default Button;
