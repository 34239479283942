import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as Checkmark } from 'static/svg/checkmark.svg';

import styles from './styles.module.scss';

function Checkbox({ label, name, value, onChange, className, classNameLabel }) {
  const classNames = classnames(styles.checkbox, className, { [styles.checked]: value });

  const onClick = useCallback(() => onChange({ name, value: !value }), [name, onChange, value]);

  const shouldRenderLabel = !!label;

  return (
    <div onClick={onClick} role="button" tabIndex={0} className={classNames}>
      <span className={styles.border}>
        <Checkmark className={styles.icon} />
      </span>

      {shouldRenderLabel && (
        <span className={classnames(styles.label, classNameLabel)}>{label}</span>
      )}
    </div>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
};

Checkbox.defaultProps = {
  value: false,
  className: null,
  classNameLabel: null,
};

export default Checkbox;
