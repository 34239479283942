import * as dataHelpers from 'helpers/data';

import { ImageCaptureType, ImageCaptureStep } from './types';

export const STORE_NAME = 'image-capture';

export const IMAGE_CAPTURE_DEFAULT_STATE = {
  step: ImageCaptureStep.IlluminationCheck,
  assetsUrls: null,
  isAssetsUploadedToCloudinary: false,
  isAssetsUrlsUploadedToServer: false,
};

const ENTITIES_DEFAULT_STATE = dataHelpers.fillObjectFromObjectValues(ImageCaptureType, IMAGE_CAPTURE_DEFAULT_STATE);

export const DEFAULT_STATE = {
  entities: ENTITIES_DEFAULT_STATE,
};
