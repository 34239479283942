import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CONFIG from 'constants/config';
import { SELFIE_DATA, SelfieId } from 'constants/video-capture';

import { useUpdateDataSubmission } from 'hooks/use-update-data-submission';

import { actions as imageCaptureActions, selectors as imageCaptureSelectors } from 'store/modules/image-capture';

import Spinner from 'components/spinner';
import { VideoCaptureFlow } from 'components/video-capture-flow';
import { ErrorMessageWithButton } from 'components/error-message-with-button';

import styles from './styles.module.scss';

const DEBUG = CONFIG.IS_LOCAL_MODE && true;
const SKIP_VIDEO_CAPTURE = CONFIG.IS_LOCAL_MODE && true;

export function VideoCapture({
  basePath,
  dataSubmissionId,
  imageCaptureType,
  videoCaptureContext,
  onVideoCaptureComplete,
}) {
  const dispatch = useDispatch();
  const [updateDataSubmissionState, updateDataSubmission] = useUpdateDataSubmission({ dataSubmissionId });

  const { assetsUrls, isAssetsUploadedToCloudinary, isAssetsUrlsUploadedToServer } = useSelector((state) =>
    imageCaptureSelectors.getImageCaptureEntityByType(state, imageCaptureType),
  );

  const onUploadingSuccess = useCallback(
    (urls /*{ videoUrl: string; imageUrl: string }*/) => {
      dispatch(imageCaptureActions.uploadedToCloudinary({ type: imageCaptureType, urls }));
    },
    [dispatch],
  );

  const uploadVideoToServer = () => {
    if (SKIP_VIDEO_CAPTURE) {
      updateDataSubmission({
        assets: [
          {
            url:
              'https://res.cloudinary.com/hl7aznolk/video/upload/v1654688076/selfie-video/4ccd414e-b7a3-498e-9dba-22d0ea38a3b8/video-1654688073301.mp4',
            type: 'VIDEO',
          },
        ],
      });

      return;
    }

    updateDataSubmission({
      assets: [
        {
          url: assetsUrls?.imageUrl,
          type: SELFIE_DATA[SelfieId.Center].serverKey,
        },
        {
          url: DEBUG
            ? 'https://res.cloudinary.com/hl7aznolk/video/upload/v1654688076/selfie-video/4ccd414e-b7a3-498e-9dba-22d0ea38a3b8/video-1654688073301.mp4'
            : assetsUrls?.videoUrl,
          type: 'VIDEO',
        },
      ],
    });
  };

  const isIdle =
    !updateDataSubmissionState.loading && !updateDataSubmissionState.data && !updateDataSubmissionState.error;
  const isLoading = updateDataSubmissionState.loading;
  const isFetched = !!updateDataSubmissionState.data;
  const isFailed = !!updateDataSubmissionState.error;

  useEffect(() => {
    if ((isAssetsUploadedToCloudinary && !isAssetsUrlsUploadedToServer) || SKIP_VIDEO_CAPTURE) {
      uploadVideoToServer();
    }
  }, [isAssetsUploadedToCloudinary]);

  useEffect(() => {
    if (!isFetched) {
      return;
    }

    dispatch(imageCaptureActions.uploadedToServer({ type: imageCaptureType }));
  }, [updateDataSubmissionState]);

  useEffect(() => {
    if (!isAssetsUrlsUploadedToServer) {
      return;
    }

    onVideoCaptureComplete();
  }, [isAssetsUrlsUploadedToServer]);

  return isAssetsUploadedToCloudinary ? (
    <div className={styles.wrapper}>
      {(isIdle || isLoading || isFetched) && <Spinner centered />}

      {isFailed && <ErrorMessageWithButton error={updateDataSubmissionState.error} onClick={uploadVideoToServer} />}
    </div>
  ) : (
    <VideoCaptureFlow
      basePath={basePath}
      dataSubmissionId={dataSubmissionId}
      videoContextData={videoCaptureContext}
      onUploadingSuccess={onUploadingSuccess}
    />
  );
}
