// main circle view box
export const SLIDER_WIDTH = 31;
const WRAPPER_CIRCLE_DIAMETER = 363;
export const MAIN_CIRCLE_VIEW_BOX_SIZE = WRAPPER_CIRCLE_DIAMETER + SLIDER_WIDTH;
export const MAIN_CIRCLE_HALF_VIEW_BOX_SIZE = MAIN_CIRCLE_VIEW_BOX_SIZE / 2;
export const SUCCESS_BIG_CIRCLE_DIAMETER = 72;
export const SUCCESS_BIG_CIRCLE_RADIUS = SUCCESS_BIG_CIRCLE_DIAMETER / 2;
export const SUCCESS_BIG_CIRCLE_STROKE_WIDTH = 1;
export const SUCCESS_SMALL_CIRCLE_DIAMETER = SUCCESS_BIG_CIRCLE_DIAMETER;
export const SUCCESS_SMALL_CIRCLE_RADIUS = SUCCESS_SMALL_CIRCLE_DIAMETER / 2;
export const SUCCESS_SMALL_CIRCLE_STROKE_WIDTH = 1;
export const SUCCESS_CIRCLES_VIEW_BOX = SUCCESS_BIG_CIRCLE_DIAMETER + SUCCESS_BIG_CIRCLE_STROKE_WIDTH;
export const SUCCESS_CIRCLES_HALF_VIEW_BOX = SUCCESS_CIRCLES_VIEW_BOX / 2;
export const VIEW_BOX_WIDTH = MAIN_CIRCLE_VIEW_BOX_SIZE;
export const VIEW_BOX_HEIGHT = MAIN_CIRCLE_VIEW_BOX_SIZE + SUCCESS_CIRCLES_HALF_VIEW_BOX;
export const VIEW_BOX_HALF_WIDTH = VIEW_BOX_WIDTH / 2;
export const VIEW_BOX_HALF_HEIGHT = VIEW_BOX_HEIGHT / 2;
// SLIDER
export const SLIDER_HEIGHT = 18;
export const SLIDER_HALF_WIDTH = SLIDER_WIDTH / 2;
export const SLIDER_HALF_HEIGHT = SLIDER_HEIGHT / 2;
// wrapper circle
const WRAPPER_CIRCLE_RADIUS = WRAPPER_CIRCLE_DIAMETER / 2;
const WRAPPER_CIRCLE_STROKE_WIDTH = 6;
export const WRAPPER_CIRCLE = {
  RADIUS: WRAPPER_CIRCLE_RADIUS,
  STROKE_WIDTH: WRAPPER_CIRCLE_STROKE_WIDTH,
  CX: MAIN_CIRCLE_HALF_VIEW_BOX_SIZE,
  CY: MAIN_CIRCLE_HALF_VIEW_BOX_SIZE,
};
// wrapper circle
const INNER_CIRCLE_RADIUS = WRAPPER_CIRCLE.RADIUS - 18;
const INNER_CIRCLE_STROKE_WIDTH = 16;
export const INNER_CIRCLE = {
  RADIUS: INNER_CIRCLE_RADIUS,
  STROKE_WIDTH: INNER_CIRCLE_STROKE_WIDTH,
  CX: MAIN_CIRCLE_HALF_VIEW_BOX_SIZE,
  CY: MAIN_CIRCLE_HALF_VIEW_BOX_SIZE,
};
// shadow and saturation
export const SHADOW_CIRCLE = {
  GRADIENT: [
    { offset: 0, rgbColor: [73, 10, 227, 1] },
    { offset: 30, rgbColor: [86, 29, 247, 1] },
    { offset: 100, rgbColor: [205, 213, 211, 1] },
  ],
};
export const SATURATION_CIRCLE = {
  GRADIENT: [
    { offset: 0, rgbColor: [238, 155, 143, 1] },
    { offset: 30, rgbColor: [255, 182, 171, 1] },
    { offset: 100, rgbColor: [205, 213, 211, 1] },
  ],
};
