import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SWRConfig } from 'swr';

import { datumApi } from 'api/datum';

import RootRouter from 'router';

import store, { persistor } from 'store';

import Spinner from 'components/spinner';

const SWR_CONFIG = {
  fetcher: (methodName, ...args) => datumApi[methodName](...args),
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
        <SWRConfig value={SWR_CONFIG}>
          <RootRouter />
        </SWRConfig>
      </PersistGate>
    </Provider>
  );
}

export default App;
