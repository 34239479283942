import { HALF_VIEW_BOX_SIZE } from './constants';

export const getPathDForPartOfCircle = (angleFrom, angleTo) => {
  const valueFrom = 500 + (angleFrom / 90) * 500;
  const valueTo = 500 + (angleTo / 90) * 500;
  return `M ${HALF_VIEW_BOX_SIZE},10 C ${valueFrom},100 ${valueFrom},900 ${HALF_VIEW_BOX_SIZE},990 M ${HALF_VIEW_BOX_SIZE},10  C  ${valueTo},100 ${valueTo},900  ${HALF_VIEW_BOX_SIZE}, 990`;
};
export const getDForPartOfCircle = (angleFrom, angleTo) => {
  const valueFrom = 500 + (angleFrom / 90) * 500;
  const valueTo = 500 + (angleTo / 90) * 500;
  return `M ${HALF_VIEW_BOX_SIZE},10 C ${valueFrom},100 ${valueFrom},900 ${HALF_VIEW_BOX_SIZE},990 M ${HALF_VIEW_BOX_SIZE},990 C ${valueTo},900 ${valueTo},100  ${HALF_VIEW_BOX_SIZE},10`;
};
export const getPathDForVerticalLine = (angle) => {
  const value = HALF_VIEW_BOX_SIZE + (angle / 90) * 500;
  return `M ${HALF_VIEW_BOX_SIZE},10 C ${value},100 ${value},900 ${HALF_VIEW_BOX_SIZE},990`;
};
export const getStrokeWidth = (angle, strokeWidth = 3) => Math.abs(1 - strokeWidth + Math.abs(5 * (angle / 180)));
