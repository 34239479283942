import { useEffect } from 'react';

const useChangeCheckStateEffect = ({ setCheckState, isCheckingStep, illuminationState, isValidIlluminationValues }) => {
  useEffect(() => {
    if (!isCheckingStep) {
      return;
    }

    if (isValidIlluminationValues) {
      setCheckState({ text: 'Success. Hold Still.', isError: false });

      return;
    }

    const errors = [];

    if (illuminationState.isTooMuchLightOnTheFace) {
      errors.push('too much glare.');
    }
    if (illuminationState.isTooMuchShadowOnTheFace) {
      errors.push('too much shadow.');
    }
    if (illuminationState.isBackgroundBrightLight) {
      errors.push('Too much light from behind.');
    }
    setCheckState({ text: errors.join('\n'), isError: true });
  }, [isCheckingStep, illuminationState, isValidIlluminationValues]);
};

export default useChangeCheckStateEffect;
