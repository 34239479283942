import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Modal } from 'components/modal';

import styles from './styles.module.scss';

function SideBar({ fluid, isOpened, children, onClose }) {
  return (
    <Modal onOverlayClick={onClose} isVisible={isOpened}>
      {({ isHidden }) => (
        <div className={classnames(styles.container, { [styles.fluid]: fluid })}>
          <div
            role="button"
            onClick={(e) => e.stopPropagation()}
            className={classnames(styles.aside, { [styles.hidden]: isHidden })}
          >
            {children()}
          </div>
        </div>
      )}
    </Modal>
  );
}

SideBar.propTypes = {
  fluid: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
};

SideBar.defaultProps = {
  fluid: false,
};

export default SideBar;
