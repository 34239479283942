import { call, put, fork, select } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';

import { datumApi } from 'api/datum';

import { ROUTES } from 'router/constants';

import { selectors as routerSelectors } from 'store/modules/router';
import {
  actions as productListActions,
  constants as productListsConstants,
} from 'store/modules/product-lists';

import { isEmpty } from 'helpers/is';

function* addProductsToList({ productIds, listName }) {
  for (let index = 0; index < productIds.length; index += 1) {
    const productId = productIds[index];

    try {
      yield call(datumApi.addProductToUserList, { listName, productId });
    } catch (error) {
      console.log('addProductsToList ', error);
    }
  }
}

function* addComparedAndSaved({ comparedProductIds, favoriteProductIds }) {
  yield* addProductsToList({
    productIds: comparedProductIds,
    listName: productListsConstants.PRODUCT_LISTS.COMPARED,
  });

  yield* addProductsToList({
    productIds: favoriteProductIds,
    listName: productListsConstants.PRODUCT_LISTS.SAVED,
  });
}

function* tryToAddComparedAndSavedProductsFromLocalStorage() {
  const key = 'skinguide_compare';

  const json = window.localStorage.getItem(key);

  if (isEmpty(json)) {
    return false;
  }

  try {
    const {
      productIds: productIdsByCategoryJSON,
      favoriteProductIds: favoriteProductIdsJSON,
    } = JSON.parse(json);

    const productIdsByCategory = JSON.parse(productIdsByCategoryJSON);
    const favoriteProductIds = JSON.parse(favoriteProductIdsJSON);

    const comparedProductIds = Object.values(productIdsByCategory).flat();

    yield* addComparedAndSaved({ comparedProductIds, favoriteProductIds });

    return true;
  } catch (error) {
    console.error('tryToAddComparedAndSavedProductsFromLocalStorage', error);

    return false;
  } finally {
    window.localStorage.removeItem(key);
  }
}

function* tryUpdateProductLists(isUpdatedProductLists) {
  const currentPathName = yield select(routerSelectors.getPathname);

  const { isExact: isNotSuitablePageForUpdate = false } =
    matchPath(currentPathName, {
      path: [ROUTES.COMPARE, ROUTES.FAVORITES, ROUTES.CART],
      exact: true,
    }) ?? {};

  if (isNotSuitablePageForUpdate && !isUpdatedProductLists) {
    return;
  }

  yield put(productListActions.fetchUserProductList());
}

export default function* loggedInUserFlow() {
  const isUpdated = yield* tryToAddComparedAndSavedProductsFromLocalStorage();

  yield fork(tryUpdateProductLists, isUpdated);
}
