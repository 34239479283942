import React from 'react';
import PropTypes from 'prop-types';

import { ROUTES } from 'router/constants';

import Title from 'components/title';
import Button from 'components/button';

import { ReactComponent as CloseSVG } from 'static/svg/close.svg';

import styles from './styles.module.scss';

function Content({ push, closeModal }) {
  const onMatchesClick = () => push(ROUTES.QUIZ_RESET, { redirectTo: ROUTES.QUIZ_RETAKE });

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal} role="button" onClick={(event) => event.stopPropagation()}>
        <div onClick={closeModal} role="button" tabIndex={0} className={styles.close}>
          <CloseSVG className={styles.closeIcon} />
        </div>

        <Title className={styles.title}>Retake Your Quiz</Title>

        <p className={styles.description}>Update your product matches based on your most up to date skin needs</p>

        <button type="button" className={styles.button} onClick={onMatchesClick}>
          <span>Retake the Quiz</span>
        </button>

        <Button size="lg" variant="outline-secondary" onClick={closeModal} className={styles.closeButton}>
          <span>CANCEL</span>
        </Button>
      </div>
    </div>
  );
}

Content.propTypes = {
  push: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Content;
