import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { SELFIE_ORDER } from 'constants/selfie';

import { getSelfie } from './selectors';
import { updateProgress } from './actions';

const saveSelfieToCloudinary = async (base64, position, onUpdate) => {
  const body = new FormData();

  body.append('file', base64);
  body.append('upload_preset', 'selfie');
  body.append('tags[]', 'selfie');
  body.append('tags[]', 'selfie-via-camera');
  body.append('tags[]', `selfie-${position}`);
  body.append('tags[]', 'skinguide');
  body.append('tags[]', process.env.RELEASE_STAGE ?? 'local');

  const url = `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_BACKET_ID}/image/upload`;

  const data = await axios({
    method: 'POST',
    data: body,
    url,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent) => {
      const totalLength = progressEvent.lengthComputable
        ? progressEvent.total
        : progressEvent.target.getResponseHeader('content-length') ||
          progressEvent.target.getResponseHeader('x-decompressed-content-length');

      if (totalLength !== null) {
        onUpdate(Math.round((progressEvent.loaded * 100) / totalLength));
      }
    },
  });

  return data;
};

const saveSelfies = createAsyncThunk('selfie/saveSelfies', async (_, { dispatch, getState }) => {
  const { selfies } = getSelfie(getState());

  const cloudinaryUrls = [];

  const onUpdateProgress = ({ percentage, index }) => {
    const resultPercentage = Math.round((percentage + index * 100) / SELFIE_ORDER.length);

    dispatch(updateProgress(resultPercentage));
  };

  for (let i = 0; i < SELFIE_ORDER.length; i += 1) {
    const {
      data: { secure_url: secureUrl },
    } = await saveSelfieToCloudinary(selfies[i], SELFIE_ORDER[i], (percentage) =>
      onUpdateProgress({ percentage, index: i }),
    );

    cloudinaryUrls.push(secureUrl);
  }

  return cloudinaryUrls;
});

export { saveSelfies };
