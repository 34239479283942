import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DefaultGauge from '../default-gauge';

import styles from './styles.module.scss';

function MatchScoreGauge({
  text,
  size,
  onClick,
  classNames,
  percentage,
  withoutMatch,
  customStyles,
}) {
  const classNamesText = classnames(styles.text, classNames.text);
  const classNamesContent = classnames(styles.content, classNames.content, styles[size]);
  const classNamesPercentage = classnames(styles.percentage, classNames.percentage);

  return (
    <DefaultGauge
      size={size}
      onClick={onClick}
      className={classNames.gauge}
      percentage={percentage}
      customStyles={customStyles}
    >
      <div className={classNamesContent}>
        <span className={classNamesPercentage}>{percentage.toFixed(0)}%</span>

        {!withoutMatch && <span className={classNamesText}>{text}</span>}
      </div>
    </DefaultGauge>
  );
}

MatchScoreGauge.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  classNames: PropTypes.shape({
    text: PropTypes.string,
    gauge: PropTypes.string,
    content: PropTypes.string,
    percentage: PropTypes.string,
  }),
  percentage: PropTypes.number.isRequired,
  customStyles: PropTypes.shape({}),
  withoutMatch: PropTypes.bool,
};

MatchScoreGauge.defaultProps = {
  size: 'lg',
  text: 'Match',
  onClick: null,
  classNames: {},
  customStyles: null,
  withoutMatch: false,
};

export default MatchScoreGauge;
