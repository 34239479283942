import { ROUTES } from 'router/constants';

import * as SHORT from './short';
import * as REGULAR from './regular';

export const QUIZ_TYPES = {
  SHORT: 'short.v1',
  REGULAR: 'regular.v1',
};

export const QUIZ_END_ID = 'quiz-end';

export const QUIZZES = {
  [QUIZ_TYPES.SHORT]: SHORT,
  [QUIZ_TYPES.REGULAR]: REGULAR,
};

export const ROUTES_BY_QUIZ_TYPE = {
  [QUIZ_TYPES.SHORT]: ROUTES.SHORT_QUIZ_QUESTION,
  [QUIZ_TYPES.REGULAR]: ROUTES.QUIZ_QUESTION,
};
