import axios from 'axios';
import qs from 'qs';

import { isNotEmpty } from 'helpers/is';

class DatumAPI {
  constructor(baseUrl = '') {
    this.baseUrl = `${baseUrl}/api`;
    this.onChangeToken = null;
    this.onUnauthorized = null;

    this.axios = axios.create({
      baseURL: this.baseUrl,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    });
  }

  init({ onChangeToken = () => {}, onUnauthorized = () => {}, token }) {
    if (isNotEmpty(token)) {
      this._updateToken(token);
    }

    this.onChangeToken = onChangeToken;
    this.onUnauthorized = onUnauthorized;
  }

  _updateToken(token) {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  async request(path, options) {
    let data = null;
    let token = null;

    try {
      const response = await this.axios({
        ...options,
        url: path,
      });

      data = response.data;
      token = response.headers['x-access-token'];
    } catch (error) {
      if (error?.response?.status === 401) {
        this.onUnauthorized();
      }

      console.log(error);

      throw error;
    }

    if (isNotEmpty(token)) {
      this._updateToken(token);
      this.onChangeToken(token);
    }

    return data;
  }

  signUp(data) {
    return this.request('/signup', {
      data,
      method: 'post',
    });
  }

  login(data) {
    return this.request('/login', {
      data,
      method: 'post',
    });
  }

  verifyCode(data) {
    return this.request('/login/verify', {
      data,
      method: 'post',
    });
  }

  getProductBrands() {
    return this.request('/product-brands');
  }

  getProductsBrand(brandId) {
    return this.request(`/product-brands/${brandId}/products`);
  }

  updateProfile(data) {
    return this.request('/profile', {
      data,
      method: 'put',
    });
  }

  getSurveyResults() {
    return this.request('/profile/survey-results');
  }

  addSurveyResults(data) {
    return this.request('/profile/survey-results', {
      data,
      method: 'post',
    });
  }

  getProducts({ categoryId, withFilter, filter }) {
    const params = {};

    if (withFilter) {
      params.filter = {
        category: categoryId,
        ...filter,
      };
    } else {
      params.filterId = categoryId;
    }

    return this.request('/products', {
      params,
    });
  }

  getProduct(productId) {
    return this.request(`/products/${productId}`);
  }

  getSimilarProductsByProductId(productId) {
    return this.request(`/products/${productId}/similar-products`);
  }

  getRelatedProductsByProductId(productId) {
    return this.request(`/products/${productId}/related-products`);
  }

  getProductsByIds = (ids) => this.request('/products/batch', { params: { ids } });

  searchProducts({ term, barcode }) {
    return this.request('/products/search', { params: { term, barcode } });
  }

  getCategoryListingByNum(category) {
    return this.request(`/products/name-starts-with-num`, { params: { category } });
  }

  getCategoryListingByLetter(category, letter) {
    return this.request(`/products/name-starts-with-${letter}`, {
      params: { category },
    });
  }

  getProfile() {
    return this.request(`/profile`);
  }

  getUserProductLists = () => this.request('/user-products');

  addProductToUserList = ({ productId, listName }) =>
    this.request('/user-products', {
      method: 'post',
      data: { userProduct: { productId, list: listName } },
    });

  removeProductFromUserList = ({ productId, listName }) =>
    this.request('/user-products', {
      method: 'delete',
      data: { userProduct: { productId, list: listName } },
    });

  getProductSearches = () => this.request('/product-searches');

  getViewedSearches = (ids) => this.getProductsByIds(ids);
}

const datumApi = new DatumAPI(process.env.BACK_END_BASE_URL);

export { datumApi };
