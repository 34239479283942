import { createSlice } from '@reduxjs/toolkit';
import { createMigrate } from 'redux-persist';

import createPersistReducer from 'store/helpers/create-persist-reducer';
import { resetStore } from 'store/modules/actions';
import { logout } from 'store/modules/user/actions';

import * as selectors from './selectors';
import { STORE_NAME, DEFAULT_STATE, IMAGE_CAPTURE_DEFAULT_STATE } from './constants';

const { reducer: defaultReducer, actions } = createSlice({
  name: STORE_NAME,
  initialState: DEFAULT_STATE,
  reducers: {
    reset: () => DEFAULT_STATE,
    resetImageCaptureByType: (draftState, { payload: { type } }) => {
      draftState.entities[type] = IMAGE_CAPTURE_DEFAULT_STATE;
    },
    setImageCaptureStep: (draftState, { payload: { type, step } }) => {
      draftState.entities[type].step = step;
    },
    uploadedToCloudinary: (draftState, { payload: { type, urls } }) => {
      draftState.entities[type].assetsUrls = urls;
      draftState.entities[type].isAssetsUploadedToCloudinary = true;
    },
    uploadedToServer: (draftState, { payload: { type } }) => {
      draftState.entities[type].isAssetsUrlsUploadedToServer = true;
    },
  },
  extraReducers: {
    [resetStore.toString()]: () => DEFAULT_STATE,

    [logout.toString()]: () => DEFAULT_STATE,
  },
});

const migrations = {
  0: () => DEFAULT_STATE,
};

const reducer = createPersistReducer(
  {
    key: STORE_NAME,
    version: 0,
    migrate: createMigrate(migrations, { debug: false }),
  },
  defaultReducer,
);

export { selectors, reducer, actions };
