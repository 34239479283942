import { gql } from '@apollo/client';

export const createDataSubmissionMutation = gql`
  mutation dataSubmissionCreateMutation($input: DataSubmissionInput) {
    createDataSubmission(input: $input) {
      id
    }
  }
`;

export const updateDataSubmissionMutation = gql`
  mutation dataSubmissionUpdateMutation($id: ID!, $input: DataSubmissionInput!) {
    updateDataSubmission(id: $id, input: $input) {
      id
    }
  }
`;

export const getAnalysisProgressOfDataSubmissionQuery = gql`
  query dataSubmissionGetAnalysisProgressQuery($dataSubmissionId: ID!) {
    analysisProgress(dataSubmissionId: $dataSubmissionId) {
      complete
      steps {
        id
        text
        complete
      }
      error {
        message
        reason
      }
    }
  }
`;

export const getDataSubmissionQuery = gql`
  query formulationSkinReportQuery($id: ID!) {
    dataSubmission(id: $id) {
      id

      skinReport {
        dataSubmissionId

        skinProfile {
          dermalFibers
          energySupply
          hydration
          milieu
          radiance
          texture
          tone
        }
      }
    }
  }
`;
