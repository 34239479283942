import { useMemo } from 'react';

import { THRESHOLDS } from '../constants';
import { ApiVersion } from '../types';

const useThresholds = (version) =>
  useMemo(
    () => ({
      shadow: {
        toOk: THRESHOLDS[version ?? ApiVersion.V1].shadowToOk,
        fromOk: THRESHOLDS[version ?? ApiVersion.V1].shadowFromOk,
      },
      saturation: {
        toOk: THRESHOLDS[version ?? ApiVersion.V1].saturationToOk,
        fromOk: THRESHOLDS[version ?? ApiVersion.V1].saturationFromOk,
      },
      backgroundSaturation: {
        toOk: THRESHOLDS[version ?? ApiVersion.V1].backgroundSaturationToOk,
        fromOk: THRESHOLDS[version ?? ApiVersion.V1].backgroundSaturationFromOk,
      },
    }),
    [version],
  );

export default useThresholds;
