import { memo, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { getId } from 'helpers/id';

import ModalContext from '../../context';

function Modal({ children, isVisible, onOverlayClick }) {
  const { current: id } = useRef(getId());
  const { addModal, changeVisibleModal, unmountModal } = useContext(ModalContext);

  useEffect(() => {
    addModal({ id, children, isVisible, onOverlayClick });

    return () => unmountModal({ id, children, isVisible });
  }, []);

  useEffect(() => {
    changeVisibleModal({ id, children, isVisible, onOverlayClick });
  }, [children, isVisible, onOverlayClick]);

  return null;
}

Modal.propTypes = {
  children: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default memo(Modal);
