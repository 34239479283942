import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { ROUTES } from 'router/constants';

import SocialNetworks from 'components/social-networks';

import styles from './styles.module.scss';

function Footer({ className }) {
  return (
    <div className={classnames(styles.footer, className, 'container-fluid')}>
      <SocialNetworks className={styles.socialNetworks} />

      <ul className={styles.navigation}>
        <li className={styles.navigationItem}>
          <RouterLink to={ROUTES.ABOUT_US} className={styles.navigationLink}>
            About us
          </RouterLink>
        </li>

        <li className={styles.navigationItem}>
          <a href="mailto:hello@skinguide.co" className={styles.navigationLink}>
            CONTACT US
          </a>
        </li>
      </ul>

      <ul className={styles.list}>
        <li className={styles.item}>
          <RouterLink className={styles.link} to={ROUTES.PRIVACY_POLICY}>
            Privacy Policy
          </RouterLink>
        </li>
        <li className={styles.item}>
          <RouterLink className={styles.link} to={ROUTES.TERMS_OF_SERVICE}>
            Terms of Service
          </RouterLink>
        </li>
        <li className={styles.item}>
          <RouterLink className={styles.link} to={ROUTES.BRANDS}>
            Brands
          </RouterLink>
        </li>
        <li className={styles.item}>
          <RouterLink
            to={ROUTES.CATEGORY_LISTING.replace(':categoryId', 'treatments')}
            className={styles.link}
          >
            Treatments
          </RouterLink>
        </li>
        <li className={styles.item}>
          <RouterLink
            to={ROUTES.CATEGORY_LISTING.replace(':categoryId', 'moisturizers')}
            className={styles.link}
          >
            Moisturizers
          </RouterLink>
        </li>
      </ul>

      <span className={styles.copy}>Copyright © 2020 Skinguide.co</span>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: null,
};

export default Footer;
