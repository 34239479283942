import { create, numberDependencies, normDependencies } from 'mathjs';

const { norm, number: mathNumber } = create({ normDependencies, numberDependencies }, {});

const SLOPE = 21.853941;
const trianglesRight = [
  [50, 117, 123],
  [50, 123, 187],
];
const trianglesCenter = [
  [9, 108, 151],
  [9, 151, 337],
];
const trianglesLeft = [
  [280, 352, 411],
  [280, 346, 352],
];
const vectorSubtraction = (a, b) => a.map((valueA, index) => valueA - b[index]);
const triangleAreaHerons = (triangle) => {
  const a = mathNumber(norm(vectorSubtraction(triangle[0], triangle[1])));
  const b = mathNumber(norm(vectorSubtraction(triangle[1], triangle[2])));
  const c = mathNumber(norm(vectorSubtraction(triangle[2], triangle[0])));
  const s = (a + b + c) / 2;
  const area = Math.sqrt(s * (s - a) * (s - b) * (s - c));
  return area;
};
const triangleFromIndex = (triangle, landmarks3d) => {
  const [p1, p2, p3] = triangle;
  const { x: p1x, y: p1y } = landmarks3d[p1];
  const { x: p2x, y: p2y } = landmarks3d[p2];
  const { x: p3x, y: p3y } = landmarks3d[p3];
  return [
    [p1y, p1x],
    [p2y, p2x],
    [p3y, p3x],
  ];
};
const getTriangleArea = (triangles, landmarks3d) =>
  triangles.reduce((acc, triangle) => acc + triangleAreaHerons(triangleFromIndex(triangle, landmarks3d)), 0);
export const getYAWFromFaceMesh = (landmarks3d) => {
  const rightArea = getTriangleArea(trianglesRight, landmarks3d);
  const centerArea = getTriangleArea(trianglesCenter, landmarks3d);
  const leftArea = getTriangleArea(trianglesLeft, landmarks3d);
  const triangleRatio = (leftArea - rightArea) / centerArea;
  const yaw = triangleRatio * SLOPE;
  return yaw;
};
