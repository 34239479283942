import { useMemo, useContext } from 'react';

import FloatingElementContext from 'contexts/floating-elements';

export default function usePaddingBottom() {
  const { variables } = useContext(FloatingElementContext);

  const [firstVariable, ...otherVariables] = Object.values(variables);

  const paddingBottom = useMemo(
    () =>
      otherVariables.reduce(
        (padding, variable) => `${padding} + var(${variable})`,
        `var(${firstVariable})`,
      ),
    [otherVariables],
  );

  if (!firstVariable) {
    return null;
  }

  if (otherVariables.length === 0) {
    return `var(${firstVariable})`;
  }

  return `calc(${paddingBottom})`;
}
