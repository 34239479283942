import { useMemo, useState } from 'react';

import { ImageCaptureStep } from 'store/modules/image-capture/types';

export const useVideoCaptureState = () => {
  const [state, setState] = useState({
    state: ImageCaptureStep.InitializeCamera,
    image: null,
    videoUrl: null,
    metadata: null,
    imageUrl: null,
    videoBlob: null,
  });
  const actions = useMemo(
    () => ({
      initializeCameraSuccess: () =>
        setState((prevProps) => ({ ...prevProps, state: ImageCaptureStep.IlluminationCheck })),
      uploaded: ({ videoUrl, imageUrl }) =>
        setState((prevProps) => ({ ...prevProps, videoUrl, imageUrl, state: ImageCaptureStep.Completed })),
      recordingSuccess: ({ videoBlob, image, metadata }) =>
        setState((prevProps) => ({ ...prevProps, videoBlob, image, state: ImageCaptureStep.Uploading, metadata })),
      cameraRestrictionsFailed: () =>
        setState((prevProps) => ({ ...prevProps, state: ImageCaptureStep.CameraRestrictions })),
      illuminationCheckSuccess: () => setState((prevProps) => ({ ...prevProps, state: ImageCaptureStep.Capture })),
    }),
    [setState],
  );
  return [state, actions];
};
