import { useRef, useState, useEffect } from 'react';

import CONFIG from 'constants/config';

import { getErrorMessage } from 'helpers/request';

import { useCreateConst } from '../use-create-const';

import { StreamState } from './constants';
import { UnsupportedDeviceError } from './errors';

const useStream = ({ videoRef, streamWidth, streamHeight, streamAspectRatio, capabilities }) => {
  const [state, setState] = useState({
    state: StreamState.Idle,
    error: null,
    stream: null,
    errorMessage: null,
    capabilities: null,
  });
  const cacheRef = useRef(state);
  cacheRef.current = state;

  const actions = useCreateConst(() => ({
    createStream: async () => {
      try {
        setState((prevState) => ({ ...prevState, state: StreamState.Initializing }));

        if (!window?.navigator?.mediaDevices?.getUserMedia) {
          throw new UnsupportedDeviceError({
            name: 'MEDIA_DEVICES_IS_UNDEFINED',
            cause: null,
            message: 'Cannot find any camera devices',
          });
        }

        const stream = await window.navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'user',
            width: { ideal: streamWidth },
            height: { ideal: streamHeight },
            aspectRatio: { ideal: streamAspectRatio },
          },
          audio: false,
        });
        cacheRef.current.stream = stream;

        if (capabilities && CONFIG.IS_LOCAL_MODE) {
          const videoTrack = stream.getVideoTracks()[0];
          let cameraSettings = null;
          let cameraCapabilities = null;

          try {
            cameraCapabilities = videoTrack.getCapabilities();
          } catch (error) {
            cameraSettings = videoTrack.getSettings();
          }

          const maxStreamWidth = cameraCapabilities?.width?.max ?? cameraSettings?.width ?? 0;
          const maxStreamHeight = cameraCapabilities?.height?.max ?? cameraSettings?.height ?? 0;
          const maxAspectRatio = cameraCapabilities?.aspectRatio ?? cameraSettings?.aspectRatio ?? 0;

          console.log({ maxStreamWidth, maxStreamHeight, cameraCapabilities, cameraSettings });

          if (maxStreamWidth < capabilities.width || maxStreamHeight < capabilities.height) {
            setState((prevState) => ({
              ...prevState,
              capabilities: {
                width: maxStreamWidth,
                height: maxStreamHeight,
                aspectRatio: maxAspectRatio,
              },
            }));

            throw new UnsupportedDeviceError({
              name: 'CAMERA_CAPABILITIES_ERROR',
              cause: null,
              message: 'Camera capabilities is not supported',
            });
          }
        }

        if (videoRef) {
          const video = videoRef.current;

          if (Reflect.has(video, 'srcObject')) {
            try {
              video.srcObject = stream;
            } catch (err) {
              if (err.name !== 'TypeError') {
                throw err;
              }
              // Even if they do, they may only support MediaStream
              video.src = URL.createObjectURL(stream);
            }
          } else {
            video.src = URL.createObjectURL(stream);
          }
        }

        setState((prevState) => ({ ...prevState, stream, state: StreamState.Ready }));
      } catch (error) {
        console.log('error', error);

        let nextState = StreamState.Failed;

        if (error instanceof UnsupportedDeviceError) {
          if (error.name === 'CAMERA_CAPABILITIES_ERROR') {
            nextState = StreamState.OverconstrainedError;
          }

          if (error.name === 'MEDIA_DEVICES_IS_UNDEFINED') {
            nextState = StreamState.UnsupportedDevice;
          }
        }

        // eslint-disable-next-line no-undef
        if (error instanceof OverconstrainedError) {
          nextState = StreamState.OverconstrainedError;
        }

        if (error?.name === 'NotAllowedError') {
          nextState = StreamState.AccessDenied;
        }

        setState((prevState) => ({
          ...prevState,
          error,
          state: nextState,
          errorMessage: getErrorMessage(error),
        }));
      }
    },

    stopStream: () => {
      cacheRef.current.stream?.getTracks().forEach((track) => track.stop());
    },
  }));

  const helpers = useCreateConst(() => ({
    isStateIdle: () => cacheRef.current.state === StreamState.Idle,
    isStateReady: () => cacheRef.current.state === StreamState.Ready,
    isStateFailed: () => cacheRef.current.state === StreamState.Failed,
    isStateInitializing: () => cacheRef.current.state === StreamState.Initializing,
    isStateAccessDenied: () => cacheRef.current.state === StreamState.AccessDenied,
    isStateUnsupportedDevice: () => cacheRef.current.state === StreamState.UnsupportedDevice,
    isStateOverconstrainedError: () => cacheRef.current.state === StreamState.OverconstrainedError,
  }));

  useEffect(() => actions.stopStream, []);

  return [state, actions, helpers];
};

export { useStream, StreamState };
