import { createSelector } from '@reduxjs/toolkit';

import { generateProducts } from './helpers';

export const getProducts = (state) => state.products;

export const getProductsEntity = (state) => getProducts(state).entity;

export const getMetaEntities = (state) => getProductsEntity(state).entities.meta;

export const getRequestsEntities = (state) => getProductsEntity(state).entities.requests;

export const getProductsEntities = (state) => getProductsEntity(state).entities.products;

export const getRequestsByCategory = (state, categoryId) =>
  getProductsEntity(state).requests[categoryId];

export const getProductIdsByCategory = (state, categoryId) =>
  getProductsEntity(state).productIds[categoryId];

export const getFilters = (state) => getProductsEntity(state).filters;

export const getNavigationCategoryId = (state) => getProducts(state).navigationCategoryId;

export const getProductById = (state, id) => {
  const {
    entities: { meta, products, requests },
  } = getProductsEntity(state);

  return {
    meta: meta[id],
    product: products[id],
    request: requests[id],
  };
};

export const getProductsByIds = (state, ids) => ids.map((id) => getProductById(state, id));

export const getProductsByCategoryId = (state, categoryId) => {
  const { requests, productIds, entities } = getProductsEntity(state);

  return {
    products: productIds[categoryId].map((productId) => ({
      ...entities.meta[productId],
      ...entities.products[productId],
    })),
    isProductsFetching: requests[categoryId].isFetching,
    isProductsFirstFetching: !requests[categoryId].done,
  };
};

export const getProductBrands = (state) => getProducts(state).brands;

export const getProductsByBrand = (state, brandId) => {
  const { productsByBrand } = getProducts(state);

  return {
    request: productsByBrand.requests[brandId],
    products: productsByBrand.products[brandId],
  };
};

export const getCategoryListing = (state, categoryId, char) => {
  const { categoryListings } = getProducts(state);

  const categoryListing = categoryListings[categoryId] ?? {};

  const productByChar = categoryListing[char] ?? {
    products: [],
    error: null,
    isFetching: false,
    isRequestDone: false,
  };

  return productByChar;
};

// selectors

export const getProductMatchesByCategory = createSelector(
  getProductIdsByCategory,
  getMetaEntities,
  getProductsEntities,
  (ids, meta, products) => generateProducts({ ids, meta, products }),
);

const createRelatedProducts = (similarProducts, meta, products) => ({
  ...similarProducts,
  products: generateProducts({
    ids: similarProducts.ids,
    meta,
    products,
  }),
});

const SIMILAR_PRODUCT_DEFAULT_STATE = {
  ids: [],
  error: null,
  isFetching: true,
  isRequestDone: false,
};

export const getSimilarProducts = (state) => getProducts(state).similarProductsByProductId;

export const getRelatedProducts = (state) => getProducts(state).relatedProductsByProductId;

export const getSimilarProductsByProductId = createSelector(
  (state, productId) => getSimilarProducts(state)[productId] ?? SIMILAR_PRODUCT_DEFAULT_STATE,
  getMetaEntities,
  getProductsEntities,
  createRelatedProducts,
);

export const getRelatedProductsByProductId = createSelector(
  (state, productId) => getRelatedProducts(state)[productId] ?? SIMILAR_PRODUCT_DEFAULT_STATE,
  getMetaEntities,
  getProductsEntities,
  createRelatedProducts,
);

export const getProductListByIds = createSelector(
  (_, ids) => ids,
  getMetaEntities,
  getProductsEntities,
  (ids, meta, products) => generateProducts({ ids, meta, products }),
);
