import { ROUTES, PRODUCT_CATEGORIES_PATHS } from 'router/constants';

import SignUpNotification from 'containers/sign-up-notification';

import { RetakeQuiz } from 'components/notifications';

export const NOTIFICATIONS = [
  {
    shouldRender: ({ isCompletedFullQuiz, isAuthenticated }) => !isCompletedFullQuiz && isAuthenticated,
    Component: RetakeQuiz,
    paths: [ROUTES.PRODUCT, ...PRODUCT_CATEGORIES_PATHS],
  },
  {
    shouldRender: ({ isAnonymous }) => isAnonymous,
    Component: SignUpNotification,
    paths: [
      ROUTES.SEARCH,
      ROUTES.COMPARE,
      ROUTES.ARTICLES,
      ROUTES.DASHBOARD,
      ROUTES.SEARCH_HISTORY,
      ROUTES.SEARCH_NOT_FOUND,
    ],
  },
];
