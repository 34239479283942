import NiceModal from '@ebay/nice-modal-react';

import { MODALS } from 'constants/modals';

import { IlluminationCheckHelp, VideoCaptureHelp, RetakeVideoCapture } from 'components/modals';

export const COMPONENT_BY_MODAL_ID = {
  [MODALS.VideoCaptureHelp]: VideoCaptureHelp,
  [MODALS.RetakeVideoCapture]: RetakeVideoCapture,
  [MODALS.IlluminationCheckHelp]: IlluminationCheckHelp,
};

Object.entries(COMPONENT_BY_MODAL_ID).forEach(([modalId, component]) => NiceModal.register(modalId, component));
