import { createMigrate } from 'redux-persist';

import { QUIZ_TYPES } from 'constants/quiz';

import { DEFAULT_STATE, DEFAULT_STATE_OF_QUIZ } from './constants';

const migrations = {
  0: ({ answersByIds, confirmedIds, isDone, isStarted }) => {
    return {
      ...DEFAULT_STATE,
      [QUIZ_TYPES.REGULAR]: {
        ...DEFAULT_STATE_OF_QUIZ,
        isDone,
        isSaved: isDone,
        isStarted,
        answersByQuestionIds: answersByIds,
        confirmedQuestionIds: confirmedIds,
      },
    };
  },
  1: () => {
    return {
      ...DEFAULT_STATE,
    };
  },
};

export default createMigrate(migrations);
