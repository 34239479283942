import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { ROUTES } from 'router/constants';

import { selectors } from 'store/modules/user';

function GuestRoute({ isAuthenticated, redirectPath, component: Component, ...otherProps }) {
  return (
    <Route
      {...otherProps}
      render={(props) => (isAuthenticated ? <Redirect to={redirectPath} /> : <Component {...props} />)}
    />
  );
}

GuestRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
  redirectPath: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
};

GuestRoute.defaultProps = {
  redirectPath: ROUTES.DASHBOARD,
};

const mapStateToProps = (state) => ({
  isAuthenticated: selectors.getIsAuthenticated(state),
});

export default connect(mapStateToProps)(GuestRoute);
