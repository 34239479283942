import { useEffect } from 'react';
import LogRocket from 'logrocket';

import { CriticalErrorType, IlluminationCheckState, ILLUMINATION_TEXTS } from 'constants/video-capture';

const useInitializeFaceDetectorEffect = ({
  faceDetectorState,
  faceDetectorActions,
  faceDetectorHelpers,
  illuminationState,
  illuminationActions,
}) => {
  useEffect(() => {
    if (illuminationState.state !== IlluminationCheckState.InitializingFaceDetector) {
      return;
    }
    if (faceDetectorHelpers.isStateIdle()) {
      illuminationActions.setText(ILLUMINATION_TEXTS.INITIALIZING_FACE_DETECTOR);
      faceDetectorActions.createDetector();
      return;
    }
    if (faceDetectorHelpers.isStateReady()) {
      illuminationActions.resetCriticalError(CriticalErrorType.FaceDetectorFailed);
      illuminationActions.setIlluminationState(IlluminationCheckState.StartingCamera);
      return;
    }
    if (faceDetectorHelpers.isStateFailed()) {
      illuminationActions.setCriticalError(CriticalErrorType.FaceDetectorFailed);
      LogRocket.error('illumination-check-face-detector-initializing', faceDetectorState.error);
    }
  }, [faceDetectorState.state, illuminationState.state]);
};

export default useInitializeFaceDetectorEffect;
