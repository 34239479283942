import { useMemo } from 'react';

import { ROUTES } from 'router/constants';

import { PRODUCT_CATEGORIES_MAP } from 'constants/products';

export default function useNavigationItemProps({
  setState,
  lastOpenedCategoryId,
  countOfProductsInCart,
  countOfComparedProducts,
}) {
  return useMemo(() => {
    const lastOpenedCategory = PRODUCT_CATEGORIES_MAP[lastOpenedCategoryId];

    return {
      cart: {
        count: countOfProductsInCart,
        onAnimationEnd: () =>
          setState((prevState) => ({
            ...prevState,
            shouldAnimateCart: false,
          })),
      },
      compare: {
        link: ROUTES.COMPARE.replace(':categoryId', lastOpenedCategory),
        count: countOfComparedProducts,
        onAnimationEnd: () =>
          setState((prevState) => ({
            ...prevState,
            shouldAnimateCompare: false,
          })),
      },
      matches: {
        link: ROUTES.PRODUCT_CATEGORIES.replace(':categoryId', lastOpenedCategory),
      },
    };
  }, [countOfProductsInCart, lastOpenedCategoryId, countOfComparedProducts]);
}
