import axios from 'axios';

import CONFIG from 'constants/config';
import { SelfieId } from 'constants/video-capture';

import { getId } from 'helpers/id';

export const uploadAssetToCloudinary = ({ file, tags, type, publicId, metadata, uploadPreset, onUpdateProgress }) => {
  const body = new FormData();
  body.append('file', file);
  body.append('public_id', publicId);
  body.append('upload_preset', uploadPreset);
  tags.forEach((tag) => body.append('tags[]', tag));
  if (metadata) {
    body.append(
      'context',
      Object.entries(metadata)
        .filter(([_, value]) => value !== undefined && value !== null)
        .reduce((acc, [key, value], index, arr) => {
          const substring = `${acc}${key}=${value}`;
          return index === arr.length - 1 ? substring : `${substring}|`;
        }, ''),
    );
  }
  return axios.post(`${CONFIG.REVEA_CLOUDINARY_URL}/${type}/upload`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = progressEvent.loaded / progressEvent.total;
      onUpdateProgress?.(percentCompleted);
    },
  });
};
export const uploadReveaAssetToCloudinary = async ({ tags, ...otherProps }) => {
  const data = await uploadAssetToCloudinary({ tags: ['skinguide', CONFIG.RELEASE_STAGE, ...tags], ...otherProps });

  return data.data.secure_url;
};

export const uploadVideoCaptureCenteredImageToCloudinary = ({ base64, metadata, dataSubmissionId, onUpdateProgress }) =>
  uploadReveaAssetToCloudinary({
    file: base64,
    type: 'image',
    tags: ['selfie', `selfie-${SelfieId.Center}`],
    metadata,
    publicId: `${dataSubmissionId}/${SelfieId.Center}-${Date.now() + getId()}`,
    uploadPreset: 'selfie',
    onUpdateProgress,
  });

export const uploadVideoCaptureVideoToCloudinary = async ({ blob, metadata, dataSubmissionId, onUpdateProgress }) =>
  uploadReveaAssetToCloudinary({
    file: blob,
    type: 'video',
    tags: ['video'],
    publicId: `${dataSubmissionId}/video-${Date.now() + getId()}`,
    metadata,
    uploadPreset: 'video-capture',
    onUpdateProgress,
  });

export const uploadIlluminationCheckPictureToCloudinary = ({ image: file, metadata }) =>
  uploadReveaAssetToCloudinary({
    file,
    type: 'image',
    tags: ['illumination-check'],
    metadata,
    publicId: `illumination-check/${Date.now() + getId()}`,
    uploadPreset: 'selfie',
  });
