import { useEffect } from 'react';

import { CriticalErrorType, STARTING_CAMERA_TEXTS, IlluminationCheckState } from 'constants/video-capture';

const useCameraAccessEffect = ({
  streamState,
  streamActions,
  streamHelpers,
  illuminationState,
  illuminationActions,
  onCameraRestrictions,
}) => {
  useEffect(() => {
    if (illuminationState.state !== IlluminationCheckState.StartingCamera) {
      return;
    }
    if (streamHelpers.isStateIdle()) {
      illuminationActions.setText(STARTING_CAMERA_TEXTS.STARTING_CAMERA);
      streamActions.createStream();
      return;
    }
    if (streamHelpers.isStateReady() && !illuminationState.isVideoReady) {
      illuminationActions.setText(STARTING_CAMERA_TEXTS.WAITING_VIDEO);
      illuminationActions.resetCriticalError(CriticalErrorType.CameraAccess);
      return;
    }
    if (streamHelpers.isStateReady() && illuminationState.isVideoReady) {
      illuminationActions.setIlluminationState(IlluminationCheckState.Instructions);
      return;
    }
    if (streamHelpers.isStateAccessDenied()) {
      illuminationActions.setCriticalError(CriticalErrorType.CameraAccess);
      return;
    }
    if (streamHelpers.isStateOverconstrainedError()) {
      onCameraRestrictions();
      return;
    }
    if (streamHelpers.isStateFailed()) {
      illuminationActions.setCriticalError(CriticalErrorType.UnknownError);
    }
  }, [illuminationState.state, illuminationState.isVideoReady, streamState, onCameraRestrictions]);
};

export default useCameraAccessEffect;
