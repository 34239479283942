import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ROUTES } from 'router/constants';

import DefaultLink from 'components/link';

import Link from '../side-bar-navigation-link';
import Navigation from '../side-bar-navigation';

import { NAVIGATION_LINKS } from './constants';

import styles from './styles.module.scss';

function SideBarGuestContent({ push, goToQuiz }) {
  const links = useMemo(
    () =>
      NAVIGATION_LINKS.map((link) =>
        link.id === 'quiz'
          ? {
              ...link,
              onClick: () => goToQuiz(),
            }
          : link,
      ),
    [push],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <Link className={styles.login} onClick={() => push(ROUTES.LOGIN)}>
          LOG IN
        </Link>

        <span className={styles.info}>
          Don&apos;t have an account?{' '}
          <DefaultLink onClick={goToQuiz} className={styles.link}>
            Take our Skin Quiz
          </DefaultLink>
        </span>
      </div>

      <div className={styles.middle}>
        <Navigation push={push} links={links} />
      </div>
    </div>
  );
}

SideBarGuestContent.propTypes = {
  push: PropTypes.func.isRequired,
  goToQuiz: PropTypes.func.isRequired,
};

export default SideBarGuestContent;
