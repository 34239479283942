import classnames from 'classnames';
import React from 'react';

import { ReactComponent as CloseSVG } from 'static/svg/close.svg';

import styles from './styles.module.scss';

export function Wrapper({ title, children, closeModal, className, titleClassName }) {
  return (
    <div className={classnames(className, styles.wrapper)} onClick={(event) => event.stopPropagation()}>
      {!!closeModal && (
        <button type="button" onClick={closeModal} tabIndex={0} className={styles.closeButton}>
          <CloseSVG className={styles.closeIcon} />
        </button>
      )}

      {!!title && <p className={classnames(styles.title, titleClassName)}>{title}</p>}

      {children}
    </div>
  );
}
