import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Checkbox } from 'components/fields';

import styles from './styles.module.scss';

function List({ name, value, list, onChange, className }) {
  const classNames = classnames(styles.list, className);

  const onClickHandler = useCallback(
    ({ name: id, value: isChecked }) =>
      onChange({ name, value: isChecked ? [...value, id] : value.filter((item) => item !== id) }),
    [name, value, onChange],
  );

  return (
    <ul className={classNames}>
      {list.map(({ id, label }) => (
        <li key={id} className={styles.item}>
          <Checkbox name={id} value={value.includes(id)} label={label} onChange={onClickHandler} />
        </li>
      ))}
    </ul>
  );
}

List.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

List.defaultProps = {
  value: [],
  className: null,
};

export default List;
