import { put, take, fork, select } from 'redux-saga/effects';

import { QUIZ_TYPES } from 'constants/quiz';

import {
  thunks as userThunks,
  actions as userActions,
  selectors as userSelectors,
} from 'store/modules/user';
import { actions as quizActions } from 'store/modules/quiz';

function* watchOnUserLogin() {
  yield take(userActions.authenticated);
  yield take(userThunks.getSurveyResults.fulfilled);

  const isCompletedFullQuiz = yield select(userSelectors.getIsCompletedFullQuiz);

  if (isCompletedFullQuiz) {
    yield put(quizActions.done(QUIZ_TYPES.REGULAR));
    yield put(quizActions.saved(QUIZ_TYPES.REGULAR));
  }
}

export default function* userSaga() {
  yield fork(watchOnUserLogin);
}
