export const SELFIE_TYPE = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

export const RESOLUTIONS = [{ width: 1920, height: 1440 }];

export const IDEAL_RESOLUTION = RESOLUTIONS[0];

export const RESOLUTION_ASPECT_RATIO = 4 / 3;

export const THRESHOLD_ANGLE = 3;

export const MIN_DEGREES_FOR_SIDE_OF_FACE = 50;
export const MIN_THRESHOLD_FOR_CENTER_OF_FACE = 0;

export const ANGLES = {
  [SELFIE_TYPE.LEFT]: {
    YAW: MIN_DEGREES_FOR_SIDE_OF_FACE,
  },
  [SELFIE_TYPE.RIGHT]: {
    YAW: -MIN_DEGREES_FOR_SIDE_OF_FACE,
  },
  [SELFIE_TYPE.CENTER]: {
    YAW: MIN_THRESHOLD_FOR_CENTER_OF_FACE,
  },
};

export const SELFIE_ORDER = [SELFIE_TYPE.LEFT, SELFIE_TYPE.CENTER, SELFIE_TYPE.RIGHT];

export const SELFIE_DATA = {
  [SELFIE_TYPE.LEFT]: {
    text: '1. Turn right',
    title: 'Left',
    serverKey: 'selfieLeftUrl',
  },
  [SELFIE_TYPE.RIGHT]: {
    text: '3. Turn left',
    title: 'right',
    serverKey: 'selfieRightUrl',
  },
  [SELFIE_TYPE.CENTER]: {
    text: '2. Center',
    title: 'center',
    serverKey: 'selfieCenterUrl',
  },
};
