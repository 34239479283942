import { normalize } from 'normalizr';

import { isEmpty } from 'helpers/is';

import { productsSchema } from './schema';

export const parseFiltersFromResponse = (filters) => {
  return Object.keys(filters).reduce((acc, categoryId) => {
    const { priceMax, priceMin, highlights, texture } = filters[categoryId];

    return {
      ...acc,
      [categoryId]: {
        texture,
        highlights,
        price: {
          min: priceMin,
          max: priceMax,
        },
      },
    };
  }, {});
};

export const createSearchParamsForFilters = (filter) => {
  if (isEmpty(filter)) {
    return null;
  }

  const { texture, highlights, price = {} } = filter;

  return {
    texture,
    priceMin: price.min || null,
    priceMax: price.max || null,
    highlights,
  };
};

export const parseFiltersForSave = (filters) => {
  return Object.keys(filters).reduce((acc, key) => {
    const { price: { min: priceMin, max: priceMax } = {}, highlights = [], texture = [] } = filters[
      key
    ];

    return [
      ...acc,
      {
        id: key,
        texture,
        priceMin: priceMin || null,
        category: key,
        priceMax: priceMax || null,
        highlights,
      },
    ];
  }, []);
};

export const transformProductsBrandResponse = (products) =>
  products.reduce((acc, product) => {
    let { category } = product;

    if (category === null) {
      category = 'other';
    }

    if (!acc[category]) {
      // eslint-disable-next-line no-param-reassign
      acc[category] = [];
    }

    acc[category].push(product);

    return acc;
  }, {});

export const normalizeProducts = (dataFromResponse) => normalize(dataFromResponse, productsSchema);

export const generateProducts = ({ ids, requests = {}, meta, products }) =>
  ids.map((id) => ({
    meta: meta[id],
    product: products[id],
    request: requests[id],
  }));
