/* eslint-disable no-param-reassign */
import { useRef } from 'react';
import gsap from 'gsap';
import throttle from 'lodash.throttle';

import { useCreateConst } from 'hooks/video-capture/use-create-const';

import { getDForPartOfCircle, getPathDForVerticalLine, getStrokeWidth } from '../helpers';

const useRangesApi = ({ refs, ranges }) => {
  const cacheRef = useRef({
    angle: 0,
    rangeTwin: null,
    curveTwin: null,
  });
  return useCreateConst(() => {
    let prevIndexRange = 0;
    // const halfRange = range / 2;
    let rangeTimeline = null;
    const focusRange = (index) => {
      if (!rangeTimeline) {
        rangeTimeline = gsap.timeline({ paused: true });
        ranges.forEach((currentRange, currentIndex) => {
          rangeTimeline.add(currentIndex.toString());
          rangeTimeline.to(refs.range.current, {
            attr: { d: getDForPartOfCircle(currentRange.uiFrom, currentRange.uiTo) },
          });
        });
      }
      if (prevIndexRange !== index) {
        rangeTimeline.tweenTo((index + 1).toString());
        prevIndexRange = index;
      }
      refs.range.current.style.opacity = '0.75';
      refs.range.current.style.fill = 'rgba(255, 255, 255)';
    };
    const animationBaseRange = throttle(() => {
      // cacheRef.current.curveTwin?.kill();
      cacheRef.current.rangeTwin?.kill();
      const { angle } = cacheRef.current;
      // cacheRef.current.rangeTwin = gsap.to(refs.activeRange.current, {
      //   attr: {
      //     d: getPathDForPartOfCircle(angle - halfRange, angle + halfRange),
      //   },
      // });
      cacheRef.current.curveTwin = gsap.to(refs.curve.current, {
        attr: {
          d: getPathDForVerticalLine(angle),
          'stroke-width': getStrokeWidth(angle, 8),
        },
      });
    }, 35);
    return {
      highlightRange: (angle) => {
        cacheRef.current.angle = angle * 2;
        animationBaseRange();
      },
      focusRange,
      completeRange: () => {
        refs.range.current.style.fill = 'rgba(107, 214, 195)';
      },
      activeRange: () => {
        refs.range.current.style.opacity = '0.9';
        refs.range.current.style.fill = '#d1f4da';
      },
    };
  });
};
export default useRangesApi;
