import { getId } from 'helpers/id';

import guestExplanationMatches from 'static/images/guest-explanation-matches.png';
import guestExplanationCompare from 'static/images/guest-explanation-compare.png';
import guestExplanationDashboard from 'static/images/guest-explanation-dashboard.png';

import styles from './styles.module.scss';

export const STEPS = [
  {
    id: getId(),
    image: guestExplanationDashboard,
    title: 'Skin Care Quiz:',
    description: 'A clinically based skin assessment',
    classNameImage: styles.imageQuiz,
  },
  {
    id: getId(),
    image: guestExplanationMatches,
    title: 'Your product match score:',
    description: 'Products scored on fit for your skin',
    classNameImage: styles.imageMatches,
  },
  {
    id: getId(),
    image: guestExplanationCompare,
    title: 'Compare products:',
    description: 'Detailed comparison of performance and product details',
    classNameImage: styles.imageCompare,
  },
];
