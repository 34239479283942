import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { PreviousPathProvider } from 'contexts/previous-path';

function PrevPath({ children, history }) {
  const [{ prevPath, prevSearch }, setState] = useState({
    prevPath: null,
    prevSearch: '',
    currentPath: history.location.pathname,
    currentSearch: history.location.search,
  });

  useEffect(() => {
    const unlisten = history.listen(() => {
      setState((prevState) => ({
        prevPath: prevState.currentPath,
        prevSearch: prevState.currentSearch,
        currentPath: history.location.pathname,
        currentSearch: history.location.search,
      }));
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const value = useMemo(() => ({ prevPath, prevSearch }), [prevPath, prevSearch]);

  return <PreviousPathProvider value={value}>{children}</PreviousPathProvider>;
}

PrevPath.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default PrevPath;
