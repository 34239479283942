import React, { memo, useRef, forwardRef, useImperativeHandle } from 'react';

import { SLIDER_WIDTH, SLIDER_HEIGHT } from '../../constants';

import useSliderApi from './hooks/use-slider-api';

import styles from './styles.module.scss';

const Slider = forwardRef(({ x, y, color, stroke = 'white', width = SLIDER_WIDTH, height = SLIDER_HEIGHT }, ref) => {
  const arrowRef = useRef(null);
  const sliderApi = useSliderApi({ arrowRef });
  useImperativeHandle(ref, () => sliderApi, []);
  return (
    <svg
      x={x}
      y={y}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${SLIDER_WIDTH} ${SLIDER_HEIGHT}`}
    >
      <path
        d="M23.967 17.1567H11.549L1.26097 8.82837L11.549 0.5H23.967C27.538 0.5 30.4328 3.39484 30.4328 6.96582V10.6909C30.4328 14.2619 27.538 17.1567 23.967 17.1567Z"
        ref={arrowRef}
        style={{ fill: color, stroke }}
        className={styles.path}
      />
    </svg>
  );
});
export default memo(Slider);
