import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import segment from 'libs/analytic-segment';

import withData from 'hocs/with-data';

import { selectors as productsSelectors } from 'store/modules/products';
import { selectors as userSelectors, actions as userActions } from 'store/modules/user';

import { ROUTES } from 'router/constants';

import HeaderView from 'components/header';

const mapStateToProps = (state) => ({
  name: userSelectors.getName(state),
  isAnonymous: userSelectors.getIsAnonymous(state),
  isAuthenticated: userSelectors.getIsAuthenticated(state),
  currentCategoryId: productsSelectors.getNavigationCategoryId(state),
});

const mapDispatchToProps = {
  logout: () => {
    segment.reset();

    return userActions.logout();
  },
  goToRoute: push,
};

const getData = ({ isAuthenticated, goToRoute }) => ({
  goToQuiz: () => goToRoute(ROUTES.QUIZ),
  onLogoClick: () => goToRoute(isAuthenticated ? ROUTES.DASHBOARD : ROUTES.ROOT),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withData(getData))(HeaderView);
