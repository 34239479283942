import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/modal';

import Content from './components/content';

function NotificationBuild({ isUpdatedBuild }) {
  return <Modal isVisible={isUpdatedBuild}>{() => <Content />}</Modal>;
}

NotificationBuild.propTypes = {
  isUpdatedBuild: PropTypes.bool.isRequired,
};

export default NotificationBuild;
