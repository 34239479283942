import { createAsyncThunk } from '@reduxjs/toolkit';

import { datumApi } from 'api/datum';

import { normalizeProducts } from './helpers';

const fetchProductsByIds = createAsyncThunk('blog/fetchProductsByIds', async ({ productIds }) => {
  const data = await datumApi.getProductsByIds(productIds);

  const {
    entities: { products, meta },
    result,
  } = normalizeProducts(data);

  const nextState = {
    entities: {
      meta,
      products,
    },
    productIds: result,
  };

  return nextState;
});

export { fetchProductsByIds };
