import { takeEvery } from 'redux-saga/effects';

import makeRequest from './make-request';

export default function* watchRequest({
  action,
  callToAPI,
  transformForRequest,
  transformFromResponse,
}) {
  yield takeEvery(action, makeRequest, { callToAPI, transformForRequest, transformFromResponse });
}
