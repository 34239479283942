import { QUIZ_TYPES } from 'constants/quiz';

export const getUser = (state) => state.user;

export const getEmail = (state) => getUser(state).email;

export const getName = (state) => getUser(state).name;

export const getUserId = (state) => getUser(state).id;

export const getToken = (state) => getUser(state).token;

export const getSkinProfile = (state) => getUser(state).skinProfile;

export const getIsPermanent = (state) => getUser(state).isPermanent;

export const getIsAnonymous = (state) => getUser(state).isAnonymous;

export const getIsAuthenticated = (state) => getUser(state).isAuthenticated;

export const getPolicyAcceptedAt = (state) => getUser(state).policyAcceptedAt;

export const getIsPolicyAccepted = (state) => !!getPolicyAcceptedAt(state);

export const getSurveyResults = (state) => getUser(state).surveyResults;

export const getIsCompletedFullQuiz = (state) =>
  getSurveyResults(state).some(({ version }) => version === QUIZ_TYPES.REGULAR);

export const getLastRegularQuizAnswers = (state) =>
  getSurveyResults(state).find((quiz) => quiz.version === QUIZ_TYPES.REGULAR)?.answers ?? {};
