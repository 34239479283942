import React, { memo, useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { VideoCaptureRoute } from 'router/constants';
import CONFIG from 'constants/config';

import { useSuspenseContext } from 'hooks/use-suspense-context';

import { uploadIlluminationCheckPictureToCloudinary } from 'helpers/cloudinary';

import { ImageCaptureStep } from 'store/modules/image-capture/types';

import { VideoCapture } from './components/video-capture';
import { VideoUploader } from './components/video-uploader';
import { InitializeCamera } from './components/initialize-camera';
import { IlluminationCheck } from './components/illumination-check';
import { CameraRestrictions } from './components/camera-restrictions';
import { PreloadMediaPipeResources } from './components/preload-media-pipe-resources';
import { useVideoCaptureState } from './hooks/use-video-capture-state';

import styles from './styles.module.scss';

export const VideoCaptureFlow = memo(
  ({
    basePath,
    withoutUpload = false,
    videoContextData,
    dataSubmissionId,
    onRecordingSuccess,
    onUploadingSuccess,
    onCameraRestriction,
    onIlluminationSuccess,
  }) => {
    const history = useHistory();
    const isSuspended = useSuspenseContext();
    const [isUnsupportedDevice, setIsUnsupportedDevice] = useState(false);
    const [videoCaptureState, videoCaptureActions] = useVideoCaptureState();

    const updatePath = (path) => {
      if (!basePath) {
        return;
      }
      history.replace(`${basePath}${path}`);
    };

    useEffect(() => {
      updatePath(VideoCaptureRoute.VideoCaptureInitializeCamera);
    }, []);

    const onInitializeCameraSuccess = useCallback(() => {
      videoCaptureActions.initializeCameraSuccess();
      updatePath(VideoCaptureRoute.VideoCaptureIllumination);
    }, [onIlluminationSuccess, videoCaptureActions.illuminationCheckSuccess]);
    const onIlluminationCheckSuccess = useCallback(() => {
      videoCaptureActions.illuminationCheckSuccess();
      onIlluminationSuccess?.();
      updatePath(VideoCaptureRoute.VideoCaptureRecording);
    }, [onIlluminationSuccess, videoCaptureActions.illuminationCheckSuccess]);
    const onCameraRestrictions = useCallback(() => {
      videoCaptureActions.cameraRestrictionsFailed();
      onCameraRestriction?.();
      updatePath(VideoCaptureRoute.VideoCaptureCameraRestrictions);
    }, [onCameraRestriction, videoCaptureActions.cameraRestrictionsFailed]);
    const onUnsupportedDevice = useCallback(() => {
      videoCaptureActions.cameraRestrictionsFailed();
      onCameraRestriction?.();
      updatePath(VideoCaptureRoute.VideoCaptureCameraRestrictions);
      setIsUnsupportedDevice(true);
    }, [onCameraRestriction, videoCaptureActions.cameraRestrictionsFailed]);
    const onVideoCaptured = useCallback(
      ({ videoBlob, image, metadata }) => {
        if (!withoutUpload) {
          videoCaptureActions.recordingSuccess({ videoBlob, image, metadata });
        }
        onRecordingSuccess?.({ videoBlob, image, metadata });
        if (withoutUpload) {
          return;
        }
        updatePath(VideoCaptureRoute.VideoCaptureUploading);
      },
      [onRecordingSuccess, videoCaptureActions.recordingSuccess],
    );
    const onUploaded = useCallback(
      ({ videoUrl, imageUrl }) => {
        videoCaptureActions.uploaded({ videoUrl, imageUrl });
        onUploadingSuccess?.({ videoUrl, imageUrl });
      },
      [onRecordingSuccess, videoCaptureActions.uploaded],
    );

    const onTakeIlluminationCheckPicture = useCallback(({ image, metadata }) => {
      if (CONFIG.IS_LOCAL_BUILD) {
        return;
      }

      uploadIlluminationCheckPictureToCloudinary({
        image,
        metadata: {
          ...metadata,
          dataSubmissionId,
        },
      });
    }, []);

    const videoMetadata = useMemo(
      () => ({
        ...videoContextData,
        ...videoCaptureState.metadata,
      }),
      [videoCaptureState.metadata, videoContextData],
    );

    return (
      <div className={styles.wrapper}>
        <PreloadMediaPipeResources />

        {isSuspended ? null : (
          <>
            {videoCaptureState.state === ImageCaptureStep.InitializeCamera && (
              <InitializeCamera
                onSuccess={onInitializeCameraSuccess}
                onUnsupportedDevice={onUnsupportedDevice}
                onCameraRestrictions={onCameraRestrictions}
              />
            )}

            {videoCaptureState.state === ImageCaptureStep.IlluminationCheck && (
              <IlluminationCheck
                onSuccess={onIlluminationCheckSuccess}
                onTakePicture={onTakeIlluminationCheckPicture}
                onCameraRestrictions={onCameraRestrictions}
              />
            )}

            {videoCaptureState.state === ImageCaptureStep.CameraRestrictions && (
              <CameraRestrictions isUnsupportedDevice={isUnsupportedDevice} />
            )}

            {videoCaptureState.state === ImageCaptureStep.Capture && <VideoCapture onVideoCaptured={onVideoCaptured} />}

            {videoCaptureState.state === ImageCaptureStep.Uploading && (
              <VideoUploader
                image={videoCaptureState.image}
                videoBlob={videoCaptureState.videoBlob}
                onUploaded={onUploaded}
                videoContextData={videoMetadata}
                dataSubmissionId={dataSubmissionId}
              />
            )}
          </>
        )}
      </div>
    );
  },
);
