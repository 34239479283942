import { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { getId } from 'helpers/id';

import FloatingElementsContext from 'contexts/floating-elements';

function FloatingElement({ priority, variable, children: element }) {
  const { addElement, removeElement, updateElement } = useContext(FloatingElementsContext);

  const firstRenderRef = useRef(true);
  const { current: id } = useRef(getId());

  useEffect(() => {
    addElement({ id, variable, element, priority });

    return () => removeElement(id);
  }, []);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;

      return;
    }

    updateElement({ id, element });
  }, [element]);

  return null;
}

FloatingElement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.elementType])
    .isRequired,
  variable: PropTypes.string.isRequired,
  priority: PropTypes.number,
};

FloatingElement.defaultProps = {
  priority: 0,
};

export default FloatingElement;
