import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import Title from 'components/title';
import Button from 'components/button';

import { ReactComponent as CloseSVG } from 'static/svg/close.svg';

import styles from './styles.module.scss';

function Content({ product, removeProduct, closeModal }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.modal} role="button" onClick={(event) => event.stopPropagation()}>
        <div onClick={closeModal} role="button" tabIndex={0} className={styles.close}>
          <CloseSVG className={styles.closeIcon} />
        </div>

        <Title className={styles.title} sansSerif size="md">
          REMOVE THIS PRODUCT?
        </Title>

        <p className={styles.description}>
          Are you sure you want to remove <span className={styles.productName}>{product.name}</span>{' '}
          from your favorites?
        </p>

        <Button className={styles.button} size="lg" onClick={removeProduct}>
          YES, REMOVE
        </Button>

        <Link className={styles.link} onClick={closeModal}>
          Nevermind
        </Link>
      </div>
    </div>
  );
}

Content.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
};

export default Content;
