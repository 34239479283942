import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseSVG } from 'static/svg/close.svg';

import styles from './styles.module.scss';

function SideBarLayout({ children, onCloseSideBar }) {
  return (
    <div className={styles.wrapper}>
      <button type="button" onClick={onCloseSideBar} className={styles.buttonClose}>
        <CloseSVG className={styles.iconClose} />
      </button>

      {children}
    </div>
  );
}

SideBarLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  onCloseSideBar: PropTypes.func.isRequired,
};

export default SideBarLayout;
