import { createSlice } from '@reduxjs/toolkit';

import createPersistReducer from 'store/helpers/create-persist-reducer';

import { logout } from 'store/modules/user/actions';

import * as thunks from './thunks';
import * as helpers from './helpers';
import * as selectors from './selectors';
import persistMigrate from './persist-migrate';

import { DEFAULT_STATE, DEFAULT_STATE_OF_QUIZ } from './constants';

const { reducer: defaultReducer, actions } = createSlice({
  name: 'quiz',
  initialState: DEFAULT_STATE,
  reducers: {
    start: (draftState, { payload: quizType }) => {
      draftState[quizType].isStarted = true;
    },

    done: (draftState, { payload: quizType }) => {
      draftState[quizType].isDone = true;
    },

    save: (draftState, { payload: quizType }) => {
      draftState[quizType].error = null;
      draftState[quizType].isSaving = true;
    },

    saved: (draftState, { payload: quizType }) => {
      draftState[quizType].isSaved = true;
      draftState[quizType].isSaving = false;
    },

    failSave: (draftState, { payload: { quizType, error } }) => {
      draftState[quizType].error = error;
      draftState[quizType].isSaved = false;
      draftState[quizType].isSaving = false;
    },

    reset: (draftState, { payload: quizType }) => {
      draftState[quizType] = DEFAULT_STATE_OF_QUIZ;
    },

    resetAll: () => DEFAULT_STATE,

    submitQuestionAnswer: (draftState, { payload: { id, value, quizType } }) => {
      draftState[quizType].answersByQuestionIds[id] = value;
    },

    confirmQuestionAnswer: (draftState, { payload: { id, prevValue, quizType } }) => {
      let nextConfirmedQuestionIds = [...new Set([...draftState[quizType].confirmedQuestionIds, id])];
      let nextAnswersByQuestionIds = draftState[quizType].answersByQuestionIds;

      const isDifferentValue =
        draftState[quizType].answersByQuestionIds[id] !== prevValue &&
        prevValue !== undefined &&
        draftState[quizType].confirmedQuestionIds.includes(id);

      if (isDifferentValue) {
        const questionIndex = draftState[quizType].confirmedQuestionIds.indexOf(id);
        nextConfirmedQuestionIds = draftState[quizType].confirmedQuestionIds.slice(0, questionIndex + 1);
        nextAnswersByQuestionIds = {};

        nextConfirmedQuestionIds.forEach((questionId) => {
          const value = draftState[quizType].answersByQuestionIds[questionId];

          if (value) {
            nextAnswersByQuestionIds[questionId] = value;
          }
        });
      }

      draftState[quizType].answersByQuestionIds = nextAnswersByQuestionIds;
      draftState[quizType].confirmedQuestionIds = nextConfirmedQuestionIds;
    },

    nextQuestion: () => {},
  },
  extraReducers: {
    [logout]: () => DEFAULT_STATE,
  },
});

const reducer = createPersistReducer(
  {
    key: 'quiz',
    version: 1,
    migrate: persistMigrate,
  },
  defaultReducer,
);

export { helpers, selectors, reducer, actions, thunks };
