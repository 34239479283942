import { useEffect } from 'react';

const useOnFaceDetectEffect = ({ isCheckingStep, onFaceDetected, onFaceNoDetected, isFaceNotDetected }) => {
  useEffect(() => {
    if (!isCheckingStep) {
      return;
    }
    if (isFaceNotDetected) {
      onFaceNoDetected();
      return;
    }
    onFaceDetected();
  }, [isFaceNotDetected]);
};
export default useOnFaceDetectEffect;
