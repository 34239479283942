import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DefaultGauge from '../default-gauge';

import styles from './styles.module.scss';

function GaugeWithGradient({ size, onClick, children, className, customStyles }) {
  const classNames = classnames(className, styles.wrapper, styles[size]);

  return (
    <DefaultGauge size={size} onClick={onClick} className={classNames} customStyles={customStyles}>
      <svg className={styles.gradient}>
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stopColor="#EDE9E8" />
            <stop offset="100%" stopColor="rgb(173, 218, 185)" />
          </linearGradient>
        </defs>
      </svg>

      <div className={styles.content}>
        {children || (
          <div className={styles.texts}>
            <span className={styles.text}>
              match <br /> score
            </span>
            <span className={styles.question}>?</span>
          </div>
        )}
      </div>
    </DefaultGauge>
  );
}

GaugeWithGradient.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  className: PropTypes.string,
  customStyles: PropTypes.shape({}),
};

GaugeWithGradient.defaultProps = {
  size: 'lg',
  onClick: null,
  children: null,
  className: null,
  customStyles: null,
};

export default GaugeWithGradient;
